import { DateFormatPipe } from "ngx-moment";

export class DateFormatter {

    private dateFormatPipe: DateFormatPipe;
    private static MASK = "YYYY-MM-DDTHH:mm:ss.SSSZ";

    constructor() {
        this.dateFormatPipe = new DateFormatPipe();
    }

    startOfDay(date: Date | null): string | null {
        if (date == null) {
            return null;
        }
        let startDate = new Date(date);
        startDate.setHours(0, 0, 0, 0);
        return this.dateFormatPipe.transform(startDate, DateFormatter.MASK);
    }

    endOfDay(date: Date | null): string | null {
        if (date == null) {
            return null;
        }

        let endDate = new Date(date);
        endDate.setHours(23, 59, 59, 999);
        return this.dateFormatPipe.transform(endDate, DateFormatter.MASK);
    }

    endOfMonth(date: Date): string | null {
        let endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0); // get last day of the month
        endDate.setHours(23, 59, 59, 999);
        return this.dateFormatPipe.transform(endDate, DateFormatter.MASK);
    }

    startOfMonth(date: Date): string | null {
        let startDate = new Date(date.getFullYear(), date.getMonth(), 1); // get first day of the month
        startDate.setHours(0, 0, 0, 0);
        return this.dateFormatPipe.transform(startDate, DateFormatter.MASK);
    }

    thirtyDaysAgoStart(date: Date): string | null {
        let startDate = new Date(date);
        startDate.setDate(date.getDate() - 30); // subtract 30 days
        startDate.setHours(0, 0, 0, 0);
        return this.dateFormatPipe.transform(startDate, DateFormatter.MASK);
    }
}
