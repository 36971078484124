import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSelect } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DateFormatPipe } from 'ngx-moment';
import { NgxPermissionsService } from 'ngx-permissions';
import { MenuItem } from 'primeng/api';
import { OrderFilter } from 'src/app/core/common/filters/order.filter';
import { DateUtils } from 'src/app/core/helper/date-utils';
import { PageResponse } from 'src/app/core/model/page-response';
import { Pageable } from 'src/app/core/model/pageable';
import { OrderResponse } from 'src/app/core/model/response/order.response';
import { AffiliateMinimalResponse, ChargeStatus } from 'src/app/core/model/v1.model';
import { PagePermissions } from 'src/app/core/permissions/page-permissions';
import { AlertService } from 'src/app/core/services/alert.service';
import { FileUtilsService } from 'src/app/core/services/file-utils.service';
import { AppService } from 'src/app/core/services/v0/app.service';
import { AffiliateServiceV1 } from 'src/app/core/services/v1/affiliate.service.v1';
import { ChargeServiceV1 } from 'src/app/core/services/v1/charge.service.v1';
import { Data } from '../../core/model/data';
import { Transaction } from '../../core/model/transaction';
declare var $: any;

export interface DialogData {
  transacao: Transaction;
}

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss']
})

export class TransactionsComponent implements OnInit {
  pageEvent: PageEvent;
  exportPermission = PagePermissions.REPORT_APPROVED_TRANSACTION_EXPORT;
  showExportBtn: boolean;
  transactions = new MatTableDataSource<OrderResponse>();
  startDueDate = new Date(Date.now());
  endDueDate = new Date(Date.now());
  startPaymentDate = new Date(Date.now());
  endPaymentDate = new Date(Date.now());
  limitDueDate = new Date(this.startDueDate.getFullYear(), this.startDueDate.getMonth() + 3, this.startDueDate.getDate());
  limitPaymentDate = new Date(this.startPaymentDate.getFullYear(), this.startPaymentDate.getMonth() + 3, this.startPaymentDate.getDate());
  dueDateValid = true;
  PaymentDateValid = true;
  loading = true;
  crumbs: MenuItem[];
  check = [];
  rangeValid = true;
  dateUtils: DateUtils = new DateUtils();
  filters = new OrderFilter();
  listaStatus: ChargeStatus[];
  clientPermissions = PagePermissions.CUSTOMER_EDIT_DETAILS;
  transactionDetailPermission = PagePermissions.TRANSACTION_DETAILS;
  createTransactionPermissions = PagePermissions.TRANSACTION_NEW;

  @ViewChild('allSelectedForAffiliate') private allSelectedForAffiliate: MatOption;
  searchAffiliateForm: UntypedFormGroup;

  status: number = 0;
  type: string = '';

  displayedColumns: string[] = [
    'orderDate',
    'client',
    'installments',
    'value',
    'type',
    'status'];

  pageResponse: PageResponse;
  public pageable: Pageable;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  @ViewChild('select') select: MatSelect;
  @ViewChild('allSelected') private allSelected: MatOption;

  viewFilter = true;
  enableDueDate: boolean = false;
  enablePaymentDate: boolean = false;
  enableMetadata: boolean = false;
  affiliate: AffiliateMinimalResponse[];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private chargeService: ChargeServiceV1,
    public data: Data,
    public dialog: MatDialog,
    private appService: AppService,
    private fileUtilService: FileUtilsService,
    private permissionsService: NgxPermissionsService,
    private affiliateService: AffiliateServiceV1,
    private alertService: AlertService,
  ) { }

  ngOnInit() {
    this.enableDueDate = true;
    this.initCrumbs();
    this.startDueDate.setMonth(this.startDueDate.getMonth() - 1);
    this.filters.startDueDate = this.startDueDate;
    this.filters.endDueDate = this.endDueDate;
    this.pageResponse = {
      pageNumber: 0,
      totalPages: 0,
      pageSize: 10,
      totalElements: 0,
      content: null
    };
    this.searchAffiliateForm = this.formBuilder.group({
      orderAffiliate: new UntypedFormControl('')
    });
    this.affiliateService.getAll().subscribe(result => this.affiliate = result);
    this.permissionsService.hasPermission(this.exportPermission).then(hasPermission => {
      this.showExportBtn = hasPermission;
    });
    this.listaStatus = Object.values(ChargeStatus).filter(x => typeof x === 'string');
    this.filterParam();
  }

  public initCrumbs() {
    this.crumbs = [
      { label: 'Lista de vendas' }
    ];
  }

  filterParam() {
    this.pageable = { page: 0, size: 10 };
    this.cleanFilter();
    if (this.validateDate()) {
      this.search();
    }
  }

  cleanFilter() {
    this.filters.startDueDate = this.formatDate(this.startDueDate, this.enableDueDate);
    this.filters.endDueDate = this.formatDate(this.endDueDate, this.enableDueDate);
    this.filters.startPaymentDate = this.formatDate(this.startPaymentDate, this.enablePaymentDate);
    this.filters.endPaymentDate = this.formatDate(this.endPaymentDate, this.enablePaymentDate);
  }

  private formatDate(date: Date, enable: boolean): Date {
    return enable ? date : null;
  }

  search() {
    this.loading = true;
    this.chargeService.getChargeList(this.filters, this.pageable)
      .subscribe(result => {
        this.showResult(result);
        this.loading = false;
      },
        error => {
          this.alertService.handleError(error);
        }
      );
  }

  showResult(result) {
    if (result != null) {
      this.pageResponse.pageNumber = result.pageable.pageNumber;
      this.pageResponse.totalElements = result.totalElements;
      this.pageResponse.totalPages = result.totalPages;
      this.pageResponse.content = result.content;
      this.transactions = this.pageResponse.content;
    } else {
      this.pageResponse.pageNumber = 0;
      this.pageResponse.totalElements = 0;
      this.pageResponse.totalPages = 0;
      this.transactions = this.pageResponse.content;
    }
  }

  exportToExcel() {
    this.cleanFilter();
    if (this.validateDate()) {
      this.loading = true;
      this.chargeService.exportToExcel(this.filters)
        .subscribe(result => {
          let dateFormatPipe: DateFormatPipe = new DateFormatPipe();
          let date = dateFormatPipe.transform(new Date(), "YYYY-MM-DD-HH-mm");
          this.fileUtilService.downloadExcelFromResult(result, `Relatorio_Vendas_${date}.xlsx`);
          this.loading = false;
        });
    }
  }

  //paginação
  public handlePage(event?: PageEvent) {
    this.pageable.page = event.pageIndex;
    this.search();
    return event;
  }

  //ordenação
  public handleSort(event?: any) {
    this.pageable = { page: 0, size: 5 };
    this.pageable = Object.assign({}, this.pageable, { sort: `${event.active},${event.direction}` });
    this.search();
  }

  toggleAllSelection() {
    if (this.allSelected.selected) {
      this.select.options.forEach((item: MatOption) => item.select());
    } else {
      this.select.options.forEach((item: MatOption) => item.deselect());
    }
  }

  optionClick() {
    let cont = 0;
    this.select.options.forEach((item: MatOption) => item.selected ? cont++ : null);
    if (this.allSelected.selected) {
      this.allSelected.deselect();
      return false;
    }
    if (cont == this.select.options.length - 1)
      this.allSelected.select();
  }

  limitDate() {
    this.limitDueDate = new Date(this.startDueDate.getFullYear(), this.startDueDate.getMonth() + 3, this.startDueDate.getDate());
    this.validateDate();
    this.limitPaymentDate = new Date(this.startPaymentDate.getFullYear(), this.startPaymentDate.getMonth() + 3, this.startPaymentDate.getDate());
    this.validateDate();
  }

  validateDate(): boolean {
    if (!this.dateUtils.compareEndToBeginMonth(this.endDueDate, this.startDueDate, 3) && this.enableDueDate) {
      this.dueDateValid = false;
      return false;
    } else {
      this.dueDateValid = true;
      if (!this.dateUtils.compareEndToBeginMonth(this.endPaymentDate, this.startPaymentDate, 3) && this.enablePaymentDate) {
        this.PaymentDateValid = false;
        return false;
      } else {
        this.PaymentDateValid = true;
        return true;
      }
    }
  }

  cleanMetadata() {
    this.filters.metadataValue = '';
  }

  getStatusColor(status) {
    return "transaction-status transaction-" + status;
  }

  tosslePerOneForAffiliate(all) {
    if (this.allSelectedForAffiliate.selected) {
      this.allSelectedForAffiliate.deselect();
      return false;
    }
    if (this.searchAffiliateForm.controls.orderAffiliate.value.length == this.affiliate.length) {
      this.allSelectedForAffiliate.select();
    }
  }

  toggleAllSelectionForAffiliate() {
    if (this.allSelectedForAffiliate.selected) {
      this.searchAffiliateForm.controls.orderAffiliate
        .patchValue([0, ...this.affiliate.map(item => item.id)]);
    } else {
      this.searchAffiliateForm.controls.orderAffiliate.patchValue([]);
    }
  }

  compareId(id1: number, id2: number) {
    if (id2 === 0) {
      return id1 === 0;
    }
    return id1 && id2 && id1 === id2;
  }

  has2Cards(sale: any) {
    if (sale.lastTransaction.amount == sale.value) {
      return false;
    } else if (sale.lastTransaction.amount > 0) {
      return true;
    } else if (Math.abs(sale.lastTransaction.amount) != sale.value) {
      return true;
    }
  }

}
