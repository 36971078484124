import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class ImageUploadService {

  imageCrop:EventEmitter<Blob>;

  constructor(private http: HttpClient) { 
    this.imageCrop = new EventEmitter<Blob>();
  }
}