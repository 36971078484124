import { HttpParams } from "@angular/common/http";
import { DateFormatPipe } from "ngx-moment";
import { ApprovedTransactionFilter, TransactionByReasonFilter } from "../common/filters/approved-transactions.filter";
import { ChargeReportFilter } from "../common/filters/charge-report.filter";
import { OrderFilter } from "../common/filters/order.filter";
import { ReportOrderFilter } from "../common/filters/report-order.filter";
import { SubscriptionFilter } from "../common/filters/subscription.filter";
import { TransactionFilter } from "../common/filters/transaction.filter";
import { TransactionsReportFilter } from "../common/filters/transactions-report.filter";
import { Pageable } from "../model/pageable";
import { CancelationReasonReportFilter, DisputeFilter } from "../model/v1.model";
import { DateFormatter } from "src/app/date-formatter";

/** Get a object {} param and return the equivalent object for HttpParams class,
*  to be used in HttpClient get or post */
export function toHttpParams(params): HttpParams {
    return Object.getOwnPropertyNames(params)
        .reduce((p, key) => p.set(key, params[key]), new HttpParams());
}

export function getParamsApprovedTransactions(filter: TransactionByReasonFilter) {
    let formatter: DateFormatter = new DateFormatter();
    let params = {
        start: formatter.startOfDay(filter.start),
        end: formatter.endOfDay(filter.end),
        establishmentId: filter.establishmentId
    };
    return {
        params: Object.getOwnPropertyNames(params)
            .reduce((p, key) => p.set(key, params[key]), new HttpParams())
    };
}

export function getParamsTransactionReport(filter: TransactionsReportFilter) {
    let affiliateIDs = filter.affiliateIDs == null ? null : filter.affiliateIDs;
    let paymentMethod = filter.paymentMethod == null ? null : filter.paymentMethod;
    let transactionSearch = (filter.transactionSearch == null || filter.transactionSearch.toString() == "null") ? '' : filter.transactionSearch;
    let bin = (filter.bin == null || filter.bin.toString() == "null") ? '' : filter.bin;
    let lastFour = (filter.lastFour == null || filter.lastFour.toString() == "null") ? '' : filter.lastFour;
    let document = (filter.document == null || filter.document.toString() == "null") ? '' : filter.document;
    let status = filter.status == null ? null : filter.status;
    let formatter = new DateFormatter();
    let start = filter.start == null ? null : formatter.startOfDay(filter.start);
    let end = filter.end == null ? null : formatter.endOfDay(filter.end);
    let httpParams = new HttpParams();
    if (affiliateIDs != null) {
        affiliateIDs = affiliateIDs.filter(aAffiliate => aAffiliate != 0);
        httpParams = httpParams.set("affiliateIDs", affiliateIDs.toString());
    }
    if (paymentMethod != null) {
        httpParams = httpParams.set("paymentMethod", paymentMethod.toString());
    }
    if (transactionSearch != '') {
        httpParams = httpParams.set("transactionSearch", transactionSearch);
    }
    if (bin != '') {
        httpParams = httpParams.set("bin", bin);
    }
    if (start != null) {
        httpParams = httpParams.set("start", start);
    }
    if (end != null) {
        httpParams = httpParams.set("end", end);
    }
    if (lastFour != '') {
        httpParams = httpParams.set("lastFour", lastFour);
    }
    if (document != '') {
        httpParams = httpParams.set("document", document);
    }
    if (status != null) {
        status = status.filter(aType => aType != '');
        httpParams = httpParams.set("status", status.toString());
    }

    return httpParams;
}



export function getParamsDispute(filter: DisputeFilter): HttpParams {
    let type = filter.type == null ? null : filter.type;
    let status = filter.status == null ? null : filter.status;
    let descriptor = filter.descriptor == null ? null : filter.descriptor;
    let cardNumber = filter.cardNumber == null ? null : filter.cardNumber;
    let httpParams = new HttpParams();
    httpParams = httpParams.set("startAlertDate", filter.startAlertDate.toISOString());
    httpParams = httpParams.set("endAlertDate", filter.endAlertDate.toISOString());
    httpParams = httpParams.set("startAuthorizationDate", filter.startAuthorizationDate.toISOString());
    httpParams = httpParams.set("endAuthorizationDate", filter.endAuthorizationDate.toISOString());
    if (type != null) {
        type = type.filter(aType => aType != '');
        httpParams = httpParams.set("type", type.toString());
    }
    if (status != null) {
        status = status.filter(aType => aType != '');
        httpParams = httpParams.set("status", status.toString());
    }
    if (descriptor != null) {
        httpParams = httpParams.set("descriptor", descriptor);
    }
    if (cardNumber != null) {
        httpParams = httpParams.set("cardNumber", cardNumber);
    }
    return httpParams;
}

export function exportParamsChargeReport(filter: ChargeReportFilter): HttpParams {

    let dateFormatPipe: DateFormatPipe = new DateFormatPipe();
    let formatter: DateFormatter = new DateFormatter();
    let status = filter.status == null ? null : filter.status;
    let searchField = filter.searchField == null ? '' : filter.searchField;
    let affiliateIDs = filter.affiliateIDs == null ? '' : filter.affiliateIDs;
    let transactionSearch = filter.transactionSearch == null ? '' : filter.transactionSearch;
    let startPaymentDate = filter.startPaymentDate == null ? '' : formatter.startOfDay(filter.startPaymentDate);
    let endPaymentDate = filter.endPaymentDate == null ? '' : formatter.endOfDay(filter.endPaymentDate);
    let startDueDate = filter.startDueDate == null ? '' : dateFormatPipe.transform(filter.startDueDate, "YYYY-MM-DD");
    let endDueDate = filter.endDueDate == null ? '' : dateFormatPipe.transform(filter.endDueDate, "YYYY-MM-DD");
    let startLastChargeDate = filter.startLastChargeDate == null ? '' : formatter.startOfDay(filter.startLastChargeDate);
    let endLastChargeDate = filter.endLastChargeDate == null ? '' : formatter.endOfDay(filter.endLastChargeDate);
    let httpParams = new HttpParams();
    if (transactionSearch != '') {
        httpParams = httpParams.set("transactionSearch", transactionSearch);
    }
    if (startLastChargeDate != '') {
        httpParams = httpParams.set("startLastChargeDate", startLastChargeDate);
    }
    if (endLastChargeDate != '') {
        httpParams = httpParams.set("endLastChargeDate", endLastChargeDate);
    }
    if (startPaymentDate != '') {
        httpParams = httpParams.set("startPaymentDate", startPaymentDate);
    }
    if (endPaymentDate != '') {
        httpParams = httpParams.set("endPaymentDate", endPaymentDate);
    }
    if (startDueDate != '') {
        httpParams = httpParams.set("startDueDate", startDueDate);
    }
    if (endDueDate != '') {
        httpParams = httpParams.set("endDueDate", endDueDate);
    }
    if (searchField != '') {
        httpParams = httpParams.set("searchField", searchField);
    }
    if (affiliateIDs != '') {
        httpParams = httpParams.set("affiliateIDs", affiliateIDs.toString());
    }
    if (status != null) {
        status = status.filter(aType => aType != '');
        httpParams = httpParams.set("status", status.toString());
    }

    return httpParams;
}
export function getTransaction(filter: OrderFilter): HttpParams {

    let dateFormatPipe: DateFormatPipe = new DateFormatPipe();
    let formatter: DateFormatter = new DateFormatter();
    let status = filter.status == null ? null : filter.status;
    let searchField = filter.searchField == null ? '' : filter.searchField;
    let affiliateIDs = filter.affiliateIDs == null ? '' : filter.affiliateIDs;
    let transactionSearch = filter.transactionSearch == null ? '' : filter.transactionSearch;
    let paymentMethod = filter.paymentMethod == null ? '' : filter.paymentMethod;
    let startPaymentDate = filter.startPaymentDate == null ? '' : formatter.startOfDay(filter.startPaymentDate);
    let endPaymentDate = filter.endPaymentDate == null ? '' : formatter.endOfDay(filter.endPaymentDate);
    let startDueDate = filter.startDueDate == null ? '' : dateFormatPipe.transform(filter.startDueDate, "YYYY-MM-DD");
    let endDueDate = filter.endDueDate == null ? '' : dateFormatPipe.transform(filter.endDueDate, "YYYY-MM-DD");
    let metadata = filter.metadataValue == null ? '' : filter.metadataValue;
    let httpParams = new HttpParams();
    if (transactionSearch != '') {
        httpParams = httpParams.set("transactionSearch", transactionSearch);
    }
    if (startPaymentDate != '') {
        httpParams = httpParams.set("startPaymentDateTime", startPaymentDate);
    }
    if (endPaymentDate != '') {
        httpParams = httpParams.set("endPaymentDateTime", endPaymentDate);
    }
    if (startDueDate != '') {
        httpParams = httpParams.set("startDueDate", startDueDate);
    }
    if (endDueDate != '') {
        httpParams = httpParams.set("endDueDate", endDueDate);
    }
    if (searchField != '') {
        httpParams = httpParams.set("searchField", searchField);
    }
    if (affiliateIDs != '') {
        httpParams = httpParams.set("affiliateIDs", affiliateIDs.toString());
    }
    if (paymentMethod != '') {
        httpParams = httpParams.set("paymentMethod", paymentMethod.toString());
    }
    if (status != null) {
        httpParams = httpParams.set("status", status.toString());
    }
    if (metadata != null) {
        httpParams = httpParams.set("metadata", metadata.toString());
    }

    return httpParams;
}

export function getParamsCharges(filter: OrderFilter): HttpParams {
    let formatter: DateFormatter = new DateFormatter();
    let status = filter.status == null ? null : filter.status;
    let startDueDate = filter.startDueDate == null ? null : filter.startDueDate;
    let endDueDate = filter.endDueDate == null ? null : filter.endDueDate;
    let startPaymentDate = filter.startPaymentDate == null ? null : formatter.startOfDay(filter.startPaymentDate);
    let endPaymentDate = filter.endPaymentDate == null ? null : formatter.endOfDay(filter.endPaymentDate);
    let searchField = filter.searchField == null ? null : filter.searchField;
    let affiliateIDs = filter.affiliateIDs == null ? null : filter.affiliateIDs;
    let transactionSearch = filter.transactionSearch == null ? null : filter.transactionSearch;
    let httpParams = new HttpParams();
    if (status != null) {
        httpParams = httpParams.set("status", status.toString());
    }
    if (startDueDate != null) {
        httpParams = httpParams.set("startDueDate", startDueDate.toISOString().slice(0,10));
    }
    if (endDueDate != null) {
        httpParams = httpParams.set("endDueDate", endDueDate.toISOString().slice(0,10));
    }
    if (startPaymentDate != null) {
        httpParams = httpParams.set("startPaymentDateTime", startPaymentDate);
    }
    if (endPaymentDate != null) {
        httpParams = httpParams.set("endPaymentDateTime", endPaymentDate);
    }
    if (searchField != null) {
        httpParams = httpParams.set("searchField", searchField);
    }
    if (affiliateIDs != null) {
        httpParams = httpParams.set("affiliateIDs", affiliateIDs.toString());
    }
    if (transactionSearch != null) {
        httpParams = httpParams.set("transactionSearch", transactionSearch);
    }
    return httpParams;
}

export function getParamsSubscription(filter: SubscriptionFilter): HttpParams {
    let status = filter.status == null ? null : filter.status.filter(aStatus => aStatus != '');
    let planIDs = filter.planIDs == null ? null : filter.planIDs;
    let type = filter.type == null ? null : filter.type.filter(aType => aType != '');

    let formatter = new DateFormatter();
    let start = filter.start == null ? null : formatter.startOfDay(filter.start);
    let end = filter.end == null ? null : formatter.endOfDay(filter.end);

    let httpParams = new HttpParams();
    if (status != null) {
        httpParams = httpParams.set("status", status.toString());
    }
    if (planIDs != null) {
        httpParams = httpParams.set("planIDs", planIDs.toString());
    }
    if (type != null) {
        httpParams = httpParams.set("type", type.toString());
    }
    if (start != null) {
        httpParams = httpParams.set("start", start);
    }
    if (end != null) {
        httpParams = httpParams.set("end", end);
    }

    return httpParams;
}

export function transactionFiltertoHttpParams(transactionFilter: TransactionFilter, pageable?: Pageable) {
    transactionFilter.document = typeof transactionFilter.document == 'undefined' ? '' : transactionFilter.document;
    transactionFilter.name = typeof transactionFilter.name == 'undefined' ? '' : transactionFilter.name;
    transactionFilter.type = typeof transactionFilter.type == 'undefined' ? '' : transactionFilter.type;
    transactionFilter.customer = typeof transactionFilter.customer == 'undefined' ? 0 : transactionFilter.customer;
    let params = { type: transactionFilter.type, startDate: transactionFilter.startDate, endDate: transactionFilter.endDate, status: transactionFilter.status, cardBin: transactionFilter.cardBin, name: transactionFilter.name, document: transactionFilter.document, customer: transactionFilter.customer };
    if (pageable) {
        params = Object.assign({}, params, pageable);
    }
    return {
        params: Object.getOwnPropertyNames(params)
            .reduce((p, key) => p.set(key, params[key]), new HttpParams())
    };
}
export function orderFiltertoHttpParams(filter: ReportOrderFilter, pageable?: Pageable) {
    filter.status = (typeof filter.status == 'undefined' || filter.status == null) ? '' : filter.status;
    let params = { dateType: filter.dateType, startDate: filter.startDate, endDate: filter.endDate, status: filter.status, idPlans: filter.idPlans };
    if (pageable) {
        params = Object.assign({}, params, pageable);
    }
    return {
        params: Object.getOwnPropertyNames(params)
            .reduce((p, key) => p.set(key, params[key]), new HttpParams())
    };
}
export function cancelationReasonOrderReportFiltertoHttpParams(filter: CancelationReasonReportFilter) {
    let dateFormatPipe: DateFormatter = new DateFormatter();
    let type = (filter.type == null || filter.type.toString() == "null") ? '' : filter.type;
    let params = {
        begin: dateFormatPipe.startOfDay(filter.begin),
        end: dateFormatPipe.endOfDay(filter.end),
        type: type,
        products: filter.products
    };

    return {
        params: Object.getOwnPropertyNames(params)
            .reduce((p, key) => p.set(key, params[key]), new HttpParams())
    };
}
export function filtertoHttpParams(filter: any, pageable?: Pageable) {
    if (pageable) {
        filter = Object.assign({}, filter, pageable);
    }
    return {
        params: Object.getOwnPropertyNames(filter)
            .reduce((p, key) => p.set(key, filter[key]), new HttpParams())
    };
}

export function getParamsDisputed(filter: DisputeFilter) {

    let dateFormatPipe: DateFormatter = new DateFormatter();
    let descriptor = filter.descriptor == null ? null : filter.descriptor;
    let cardNumber = filter.cardNumber == null ? null : filter.cardNumber;
    let isRefunded = (filter.isRefunded == null || filter.isRefunded.toString() == "null") ? '' : filter.isRefunded;
    let isConciliated = (filter.isConciliated == null || filter.isConciliated.toString() == "null") ? '' : filter.isConciliated;
    let status = filter.status == null ? null : filter.status;
    let type = filter.type == null ? null : filter.type;
    let startAlertDate = filter.startAlertDate == null ? null : dateFormatPipe.startOfDay(filter.startAlertDate);
    let endAlertDate = filter.endAlertDate == null ? null : dateFormatPipe.endOfDay(filter.endAlertDate);
    let startAuthorizationDate = filter.startAuthorizationDate == null ? null : dateFormatPipe.startOfDay(filter.startAuthorizationDate);
    let endAuthorizationDate = filter.endAuthorizationDate == null ? null : dateFormatPipe.endOfDay(filter.endAuthorizationDate);
    let httpParams = new HttpParams();
    if (descriptor != null) {
        httpParams = httpParams.set("descriptor", descriptor.toString());
    }
    if (cardNumber != null) {
        httpParams = httpParams.set("cardNumber", cardNumber.toString());
    }
    if (isRefunded != null) {
        httpParams = httpParams.set("isRefunded", isRefunded.toString());
    }
    if (isConciliated != null) {
        httpParams = httpParams.set("isConciliated", isConciliated.toString());
    }
    if (startAlertDate != null) {
        httpParams = httpParams.set("startAlertDate", startAlertDate);
    }
    if (endAlertDate != null) {
        httpParams = httpParams.set("endAlertDate", endAlertDate);
    }
    if (startAuthorizationDate != null) {
        httpParams = httpParams.set("startAuthorizationDate", startAuthorizationDate);
    }
    if (endAuthorizationDate != null) {
        httpParams = httpParams.set("endAuthorizationDate", endAuthorizationDate);
    }
    if (type != null) {
        type = type.filter(aType => aType != '');
        httpParams = httpParams.set("type", type.toString());
    }
    if (status != null) {
        status = status.filter(aType => aType != '');
        httpParams = httpParams.set("status", status.toString());
    }

    return httpParams;
}

export function toHttpParamsPage(pageable?: Pageable) {

    let params = {};

    if (pageable) {
        params = Object.assign({}, params, pageable);
    }
    return {
        params: Object.getOwnPropertyNames(params)
            .reduce((p, key) => p.set(key, params[key]), new HttpParams())
    };
}
