export enum RefundReasonType {
    DUPLICATE_CHARGE,
    IMPROPER_CHARGE,
    COSTUMER_WITHDRAWAL,
    OTHERS
}

export enum RefundReason {
    IMPROPER_CHARGE = "IMPROPER_CHARGE",
    DUPLICATE_CHARGE = "DUPLICATE_CHARGE",
    COSTUMER_WITHDRAWAL = "COSTUMER_WITHDRAWAL",
    OTHERS = "OTHERS",
}