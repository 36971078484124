<div fxLayout='column' fxLayout.lt-md="column">

  <mat-card>
    <mat-card-content>
      <form [formGroup]="userData" (ngSubmit)="changePass()">
        <mat-form-field>
          <input type="text" matInput placeholder="Email" name="email" formControlName="email" readonly>
        </mat-form-field>
        <mat-form-field>
          <input type="text" matInput placeholder="Senha Atual" type="password" formControlName="oldPassword" required>
        </mat-form-field>
        <mat-form-field>
          <input type="text" matInput placeholder="Nova Senha" type="password" name="newPassword"
            formControlName="newPassword" (keyup)="verifyPass()" required>
        </mat-form-field>
        <mat-form-field>
          <input type="text" matInput placeholder="Repetir Nova Senha" type="password"
            formControlName="newPasswordConfirm" (keyup)="verifyPass()" required>
        </mat-form-field>
        <div class="errors" *ngIf="!equal">
          <span>As senhas não são iguais</span>
        </div>
        <div class="btn-confirm" fxLayoutAlign="end center">
          <button mat-raised-button disabled *ngIf="!save">Salvar</button>
          <button mat-raised-button *ngIf="save">Salvar</button>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
