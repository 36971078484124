import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { DataFinder } from "../helper/data-finder";
import { ViaCepResponse } from '../model/v1.model';

@Injectable({
  providedIn: 'root'
})
export class EnderecoService {

  constructor(private http: HttpClient, private dataFinder: DataFinder) { }

  public getAddressByCep(cep): Observable<any> {
    return this.http.get<any>(`https://api.postmon.com.br/v1/cep/${cep}`);
  }
  public getAddressViaCep(cep): Observable<any> {
    return this.http.get<ViaCepResponse>(`https://viacep.com.br/ws/${cep}/json`)
  }

  public getAllEstados(options?): Promise<any> {
    return this.dataFinder.getUFJson();
  }

  public getCidadesByEstado(codIbge, options?): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/api/v1/cities/ibge/` + codIbge, options);
  }
}