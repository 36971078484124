import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-empty-accounts-component',
  templateUrl: './empty-accounts-component.component.html',
  styleUrls: ['./empty-accounts-component.component.scss']
})
export class EmptyAccountsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  contrateAgora() {
    window.location.assign("https://bempaggo.atlassian.net/servicedesk/customer/portal/3/create/51");
  }

}
