export class Perfil {
	id: number;
	nome: string;
	descricao: string;
	roles: any[];
	canEdit: boolean;
	tipo: any;
}

export class UserProfile {
	id: number;
	name: string;
	description: string;

	constructor(perfil: Perfil) {
		this.id = perfil.id;
		this.name = perfil.nome;
		this.description = perfil.descricao;
	}
}