<div class="content">

    <div style="position: relative; width: 98%; height: 100%;" >

        <div fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="start" >
            
            <div fxLayoutGap="10px">
                <span class="resume-field-title">Nome</span>
                <div class="resume-field-value">
                    <div class="money-label  undefined">
                        <span class="symbol">{{pagepay.name}}</span>
                    </div>
                </div>
            </div>
            
            <div fxLayoutGap="10px">
                <span class="resume-field-title">Descrição</span>
                <div class="resume-field-value">
                    <div class="money-label  undefined">
                        <span class="descricao">{{pagepay.description}}</span>
                    </div>
                </div>
            </div>
            <div fxLayoutGap="10px">
                <span class="resume-field-title">Loja</span>
                <div class="resume-field-value">
                    <div class="money-label  undefined">
                        <span class="descricao">{{pagepay.affiliateMinimal.name}}</span>
                    </div>
                </div>
            </div>
            
            <div fxLayoutGap="10px">
                <span class="resume-field-title">Página de retorno</span>
                <div class="resume-field-value">
                    <div class="money-label  undefined">
                        <span class="badge bg-green" *ngIf="pagepay.returnUrl">
                            <a id="returnUrl" class="clickable" href="{{pagepay.returnUrl}}"
                                target="_blank">Acessar página de retorno</a>
                        </span>
                        <span class="badge bg-red" *ngIf="!pagepay.returnUrl">Página de retorno não informada</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="info" fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px" fxLayoutAlign="space-evenly center"
            *ngIf="pagepay" style="margin-top: 50px">

            <div fxLayout="column" fxFlex="20" fxLayoutAlign="center center" *ngIf="pagepay.amount">
                <span class="resume-field-title">Valor</span>
                <div class="resume-field-value">
                    <div class="money-label  undefined">
                        <span class="amount">{{pagepay.amount | CustomCurrency:'BRL'}}
                        </span>
                    </div>
                </div>
            </div>

            <div fxLayout="column" fxFlex="20" fxLayoutAlign="center center" *ngIf="pagepay.amount">
                <span class="resume-field-title">Vendas</span>
                <div class="resume-field-value">
                    <div class="money-label  undefined">
                        <span class="txt">{{pagepay.sold}}</span>
                    </div>
                </div>
            </div>

            <div fxLayout="column" fxFlex="20" fxLayoutAlign="center center" *ngIf="pagepay.amount">
                <span class="resume-field-title">Limite de vendas</span>
                <div class="resume-field-value">
                    <div class="money-label  undefined">
                        <span class="txt" *ngIf="pagepay.limit < 1000000">{{pagepay.limit}}</span>
                        <span class="badge bg-green" *ngIf="pagepay.limit > 1000000">Sem limite</span>
                    </div>
                </div>
            </div> 

            <div fxLayout="column" fxFlex="20" fxLayoutAlign="center center" *ngIf="pagepay">
                <span class="resume-field-title">Status</span>
                <div class="resume-field-value">
                    <div class="money-label  undefined">
                        <span class="badge bg-green" *ngIf="pagepay.active">Ativo</span>
                        <span class="badge bg-gray" *ngIf="!pagepay.active">Inativo</span>
                    </div>
                </div>
            </div>
        </div>

        <mat-divider style="margin: 10px;"></mat-divider>

        <div class="sub-info" fxLayout.xs="column" fxLayout="row" fxLayoutGap="10px"
            fxLayoutAlign="space-evenly center">

            <div fxLayout="column" fxFlex="20" fxLayoutAlign="center center" *ngIf="pagepay">
                <span>Data de expiração</span>
                <div>
                    <span class="clipboard-wrapper">
                        <span class="txt" *ngIf="pagepay.expiration">{{pagepay.expiration | date:'dd/MM/yyyy HH:mm'
                            }}</span>
                        <span class="badge bg-green" *ngIf="!pagepay.expiration">Nunca expira</span>
                    </span>
                </div>
            </div>

            <div fxLayout="column" fxFlex="20" fxLayoutAlign="center center" *ngIf="pagepay">
                <span>Solicitar endereço</span>
                <div>
                    <span class="clipboard-wrapper">
                        <span class="badge bg-green" *ngIf="pagepay.addressRequired">Sim</span>
                        <span class="badge bg-red" *ngIf="!pagepay.addressRequired">Não</span>
                    </span>
                </div>
            </div>

            <div fxLayout="column" fxFlex="20" fxLayoutAlign="center center" *ngIf="pagepay">
                <span>Solicitar data de nascimento</span>
                <div>
                    <span class="clipboard-wrapper">
                        <span class="badge bg-green" *ngIf="pagepay.birthdateRequired">Sim</span>
                        <span class="badge bg-red" *ngIf="!pagepay.birthdateRequired">Não</span>
                    </span>
                </div>
            </div>

            <div fxLayout="column" fxFlex="20" fxLayoutAlign="center center" *ngIf="pagepay">
                <span>Envia e-mail de confirmação</span>
                <div>
                    <span class="clipboard-wrapper">
                        <span class="badge bg-green" *ngIf="pagepay.sendEmail">Sim</span>
                        <span class="badge bg-red" *ngIf="!pagepay.sendEmail">Não</span>
                    </span>
                </div>
            </div>

        </div>

    </div>
</div>