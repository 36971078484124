import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { EstablishmentCreateRequestV1, EstablishmentUpdateRequestV1 } from "../../model/v1.model";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class OnEstablishmentV1 {
  private header: HttpHeaders;

  constructor(private http: HttpClient) {
    this.header = new HttpHeaders();
    this.header.set("Access-Control-Allow-Origin", "*");
    this.header.set("Access-Control-Allow-Headers", "Location");
  }

  public onEstablishmentV1(): OnEstablishmentV1 {
    return new OnEstablishmentV1(this.http);
  }

  public create(affiliateId: number, establishment: EstablishmentCreateRequestV1): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/api/v1/affiliates/${affiliateId}/establishments`, establishment, { headers: this.header, observe: 'response' });
  }

  public getByUri(uri: string): Observable<any> {
    return this.http.get<any>(uri, { headers: this.header });
  }

  public getById(affiliateId: number, establishmentId: number): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/api/v1/affiliates/${affiliateId}/establishments/${establishmentId}`, { headers: this.header });
  }

  public update(affiliateId: number, establishmentId: number, establishment: EstablishmentUpdateRequestV1): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}/api/v1/affiliates/${affiliateId}/establishments/${establishmentId}`, establishment, { headers: this.header, observe: 'response' });
  }

  public getSetups(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/api/v1/establishments/setups`, { headers: this.header });
  }

  public getBrands(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/api/v1/establishments/brands`, { headers: this.header });
  }

  public getTransactionTypes(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/api/v1/establishments/transction-types`, { headers: this.header });
  }
}
