import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import { NavigationStart, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../core/auth/auth.service';
import { DataFinder } from '../../core/helper/data-finder';
import { Data } from '../../core/model/data';
@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('out', style({
        width: '260px',
      })),
      state('in', style({
        width: '70px',
      })),
      transition('* => *', animate('300ms')),
    ])
  ]
})

export class SidenavComponent implements OnInit {
  events: string[] = [];
  opened: boolean;
  size: string;
  mode = new UntypedFormControl('side');
  menu = [];
  animating = false;
  iconMenuTop: string;
  textIconMenuTop = "Esconder menu";
  initialized = false;
  isLoggedIn$: Observable<boolean>;
  checkoutClass: string;
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  environment = '';

  startAnimation() {
    this.animating = true;
    this.tick();
  }

  doneAnimation() {
    this.animating = false;
  }

  tick() {
    if (this.animating) { requestAnimationFrame(() => this.tick()); }
  }
  constructor(
    private router: Router,
    public authService: AuthService,
    private dataFinder: DataFinder,
    public data: Data) { }

  ngOnInit() {
    this.isLoggedIn$ = this.authService.isLoggedIn;
    if (window.location.hash.startsWith("#/pagamento/")) {
      this.checkoutClass = "-checkout";
    } else {
      this.checkoutClass = "";
    }
    this.screen();
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationStart) {
        this.dataFinder.getMenuJson().then(data => {
          this.setMenu(data);
          this.menu.forEach(node => {
            if (node.subMenu) {
              node.subMenu.forEach(subNode => {
                if (`/${subNode.path}` == val.url) {
                  node.subOpen = true;
                  subNode.ativo = true;
                } else {
                  subNode.ativo = false;
                }
              });
            } else {
              if (`/${node.path}` == val.url || `${node.path}` == val.url) {
                node.ativo = true;
              }
            }
          });
        });
      }
    });
    this.configEnviroment();
  }

  configEnviroment() {
    if (environment.production) {
      this.environment = 'Production';
    } else if (environment.developer) {
      this.environment = 'Developer';
    } else if (environment.sandbox) {
      this.environment = 'Sandbox';
    } else if (environment.local) {
      this.environment = 'Local';
    }
  }

  setMenu(data) {
    this.menu = data;
  }

  toggleSize() {
    this.size = this.size === 'out' ? 'in' : 'out';
    this.menu.forEach(element => {
      element.subOpen = false;
    });
    this.iconMenuTop = this.size === 'out' ? 'fas fa-angle-left hide-sidenav' : 'fas fa-angle-right';
    this.textIconMenuTop = this.size === 'out' ? 'Recolher' : 'Expandir';

  }
  menuAction(menuItem) {
    if (menuItem.subMenu) {
      if (this.size === 'out') {
        menuItem.subOpen = !menuItem.subOpen;
      }
    } else {
      this.router.navigate([menuItem.path]);
      this.data.storage = '';
      this.menu.forEach(element => {
        element.ativo = false;
      });
      this.menu.forEach(element => {
        if (element.subMenu) {
          element.subMenu.forEach(sub => {
            sub.ativo = false;
          });
        }
      });
      menuItem.ativo = true;
    }
  }

  openSide() {
    this.size = 'out';
  }

  closeSide() {
    this.size = 'in';
    this.menu.forEach(element => {
      element.subOpen = false;
    });
  }

  screen() {
    let x = screen.width;
    if (x < 600) {
      this.closeSide();
      /* this.size = 'in';
      this.iconMenuTop = "hide";
      this.textIconMenuTop = "hide"; */
    } else {
      this.size = 'out';
      this.iconMenuTop = "fas fa-angle-left hide-sidenav";
      this.textIconMenuTop = "Esconder menu";
    }
  }
}
