<app-not-allowed [classAux]="'shadow-none border-0'" *ngxPermissionsExcept="transactionPermissions"></app-not-allowed>

<div class="row" fxLayout="column" *ngxPermissionsOnly="transactionPermissions">

    <div class="view-filter hide" fxLayout='row' fxLayout.lt-md="column">

        <div fxFlex="100" class="filter-trans card-body bg-light pb-4 pt-0" fxLayout.lt-lg="column"
            fxLayoutAlign.lt-lg="space-around stretch" fxLayout="row" fxLayoutAlign="end center">
            <div fxLayout='row' fxLayoutAlign="end center" fxLayout.lt-md="column"
                fxLayoutAlign.lt-md="space-around stretch">
                <div fxFlex="20" class="mr-4 mt-sm-4">
                    <mat-form-field>
                        <mat-select placeholder="Status" [(ngModel)]="status">
                            <mat-option value="0">Todos</mat-option>
                            <mat-option *ngFor="let status of listaStatus" [value]="status.value">{{status.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div fxFlex="20"  class="mr-4 mt-sm-4">
                    <mat-form-field>
                        <mat-select placeholder="Tipo" [(ngModel)]="type">
                            <mat-option value="">Todos</mat-option>
                            <mat-option *ngFor="let type of listTransactionType" [value]="type.name">
                                {{type.name | translate}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div fxFlex="20"  class="mr-4 mt-sm-4">
                    <mat-form-field>
                        <input matInput [matDatepicker]="startDate" readonly (click)="startDate.open()"
                            [(ngModel)]="begin" placeholder="Início" [max]="end">
                        <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
                        <mat-datepicker #startDate></mat-datepicker>
                    </mat-form-field>
                </div>

                <div fxFlex="20"  class="mr-4 mt-sm-4">
                    <mat-form-field>
                        <input matInput [matDatepicker]="endDate" readonly (click)="endDate.open()" [(ngModel)]="end"
                            placeholder="Fim" [min]="begin">
                        <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
                        <mat-datepicker #endDate></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>

            <div fxLayout='row' fxLayoutAlign="end center" fxLayout.lt-md="column"
                fxLayoutAlign.lt-md="space-around stretch">

                <div class="mr-4 mt-sm-4">
                    <mat-form-field>
                        <input matInput [(ngModel)]="seisPrimeirosDigitosCartao" mask="000000"
                            placeholder="6 primeiros números do Cartão">
                    </mat-form-field>
                </div>

                <div class="mr-4 mt-sm-4">
                    <mat-form-field>
                        <input matInput [(ngModel)]="quatroUltimosDigitosCartao" mask="0000"
                            placeholder="4 últimos números do Cartão">
                    </mat-form-field>
                </div>

                <div class="mr-4 mt-sm-4">
                    <button mat-raised-button class="btn-search mt-2" (click)="filter()">Pesquisar</button>
                </div>
            </div>
        </div>

    </div>

    <div fxFlex="100" fxLayout="row" fxLayoutAlign="center start">
        <div class="bg-light icon-view-filter pr-2 pl-2 pointer font-weight-bold" tooltip (click)="filterView()">
            <span *ngIf="!viewFilter">
                Exibir Filtro<i class="fas fa-chevron-down ml-2" matTooltip="Exibir Filtro"></i>
            </span>
            <span *ngIf="viewFilter">
                Esconder Filtro<i class="fas fa-chevron-up ml-2" matTooltip="Esconder Filtro"></i>
            </span>

        </div>
    </div>

</div>

<div class="row mt-0" *ngxPermissionsOnly="transactionPermissions">
    <h2>Ultimas Transações</h2>


    <div class="border-0 shadow-none " [ngClass]="{'hide' : pageResponse.totalElements == 0}">
        <div class="div-table">

            <table mat-table [dataSource]="transactions" matSort (matSortChange)="handleSort($event)">

                <ng-container matColumnDef="business_name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Empresa</th>
                    <td mat-cell *matCellDef="let row"> {{row.establishment}} </td>
                </ng-container>

                <ng-container matColumnDef="payment_gateway">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Adquirente</th>
                    <td mat-cell *matCellDef="let row"> <img src="assets/img/acquirers/{{row.paymentGateway?.toLowerCase()}}.svg" />
                    </td>
                </ng-container>

                <ng-container matColumnDef="establishment_code">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> EC </th>
                    <td mat-cell *matCellDef="let row"> {{row.establishmentCode}} </td>
                </ng-container>

                <ng-container matColumnDef="transaction_date">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Data/Hora </th>
                    <td mat-cell *matCellDef="let row">
                        {{row.transactionDate | date: 'dd/MM/yyyy HH:mm'}} </td>
                </ng-container>

                <ng-container matColumnDef="card_brand">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Bandeira </th>
                    <td mat-cell *matCellDef="let row">
                        <div class="bandeira">
                            <img src="assets/img/brands/{{row.cardBrand.toLowerCase()}}.png">
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="card_bin">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Cartão
                    </th>
                    <td mat-cell *matCellDef="let row">
                        {{ (row.cardBin + row.cardLast4) | creditCardMask }} </td>
                </ng-container>

                <ng-container matColumnDef="value">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Valor </th>
                    <td mat-cell *matCellDef="let row"> {{ row.value | CustomCurrency:'BRL' }} </td>
                </ng-container>

                <ng-container matColumnDef="lr">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Código de Retorno</th>
                    <td mat-cell *matCellDef="let row">
                        <span>{{row.lr | translate}}</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="type">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Tipo</th>
                    <td mat-cell *matCellDef="let row">
                        <span>{{row.type | translate}}</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
                    <td mat-cell *matCellDef="let row">
                        <span class="badge p-2" [ngClass]="getStatusColor(row.status)">{{row.status | translate}}
                        </span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header
                        style="max-width: 12px; width: 12px !important;">
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <i class="fas fa-eye fa-2x text-black-50 pointer"
                            matTooltip="Clique para visualizar os dados da transação"></i>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

                <div *ngxPermissionsOnly="transactionDetailPermission; else dontHavePermission; then hasPermission">
                </div>
                <ng-template #dontHavePermission>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </ng-template>
                <ng-template #hasPermission>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="hover-row"
                        matTooltipPosition="above" (click)="detailTransaction(row , $event)"></tr>
                </ng-template>

            </table>
        </div>
        <mat-paginator [ngClass]="{'hide' : pageResponse.totalElements == 0}" [pageIndex]="pageResponse.pageNumber"
            (page)="pageEvent = handlePage($event)" [pageSize]="pageResponse.pageSize"
            [length]="pageResponse.totalElements">
        </mat-paginator>
    </div>
    <div [ngClass]="{'hide' : pageResponse.totalElements > 0}" class="no-data-message">
        <span>Nenhuma transação encontrada.</span>
    </div>
</div>