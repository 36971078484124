<mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
<div class="container mb-4" *ngIf="!loading">
  <div fxLayout="column" fxLayoutGap="10px" style="margin-bottom: 10px;">
    <div fxLayout="row">
      <div fxFlex fxLayout="row" fxLayoutAlign="start center">
        <p-breadcrumb id="breadcrumb" [model]="crumbs"></p-breadcrumb>
      </div>
      <div fxFlex fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
        <button *ngIf="!editMode" mat-raised-button color="primary" (click)="edit()">Editar</button>
        <button *ngIf="editMode" mat-raised-button color="primary" (click)="save()">Salvar</button>
        <button *ngIf="editMode" mat-raised-button color="warn" (click)="cancel()">Cancelar</button>
      </div>
    </div>
  </div>

  <div class="shadow-none mt-2">
    <mat-card>
      <mat-tab-group animationDuration="1ms">
        <mat-tab label="Vendas avulsas" fxFlex fxLayoutGap="15px">
          <app-brands-list [brandsList]="brandsList" [establishmentList]="establishmentList" [editMode]="editMode"></app-brands-list>
        </mat-tab>
        <mat-tab label="Novas assinaturas" fxLayoutGap="15px">
          <app-brands-list [brandsList]="brandsList" [establishmentList]="establishmentList" [editMode]="editMode"></app-brands-list>
        </mat-tab>
        <mat-tab label="Recorrência" fxLayoutGap="15px">
          <app-brands-list [brandsList]="brandsList" [establishmentList]="establishmentList" [editMode]="editMode"></app-brands-list>
        </mat-tab>
      </mat-tab-group>
    </mat-card>
  </div>
  <div class="shadow-none mt-2">
    <mat-card>
      <app-payment-routes-history class="mt-3"></app-payment-routes-history>
    </mat-card>
  </div>
</div>
