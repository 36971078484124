export class OrderFilter {
    status: number[];
    startDueDate: Date;
    endDueDate: Date;
    startPaymentDate: Date;
    endPaymentDate: Date;
    searchField: string;
    affiliateIDs: number[] = [];
    paymentMethod: number;
    transactionSearch: string;
    metadataValue: string;
}
