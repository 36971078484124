import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { AcquirerSetup, AffiliateMinimalResponse, Credentials, EstablishmentCreateRequestV1, EstablishmentPriority, Setup } from 'src/app/core/model/v1.model';
import { AlertService } from 'src/app/core/services/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { OnEstablishmentV1 } from 'src/app/core/services/v1/establishment.service.v1';
import { AffiliateServiceV1 } from 'src/app/core/services/v1/affiliate.service.v1';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-establishment-create',
  templateUrl: './establishment-create.component.html',
  styleUrls: ['./establishment-create.component.scss']
})
export class EstablishmentCreateComponent implements OnInit {
  @ViewChild("createEcForm") createEcForm: NgForm;
  affiliatesList: AffiliateMinimalResponse[] = [];
  acquirerList: AcquirerSetup[] = [];
  selectedAcquirer: AcquirerSetup;
  selectedAcquirerSetup: Setup;
  idAffiliate: number;
  establishment: EstablishmentCreateRequestV1 = new EstablishmentCreateRequestV1();
  credentialsMap: { [field: string]: Credentials } = {};
  showRadio: boolean = false;
  Priority = EstablishmentPriority; // Expor o enum para o template

  constructor(
    private alertService: AlertService,
    private onAffiliateV1: AffiliateServiceV1,
    private onEstablishmentV1: OnEstablishmentV1,
    private router: Router,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.establishment.priority = 1;
    this.establishment.active = true;
    this.establishment.requireSecurityCode = true;
    this.getActiveAffiliates();
    this.getAcquirers();
  }

  loadSetup(): void {
    if (this.selectedAcquirer.configurations.length > 1) {
      this.showRadio = true;
    } else {
      this.selectedAcquirerSetup = this.selectedAcquirer.configurations[0].setup;
      this.loadFields();
      this.showRadio = false;
    }
  }

  loadFields() {
    this.establishment.credentials = [];
    this.credentialsMap = {};
    this.selectedAcquirerSetup.fields.forEach(field => {
      this.establishment.credentials.push({ 'field': field.field, 'value': "" });
      this.credentialsMap[field.field] = new Credentials();
      this.credentialsMap[field.field].field = field.field;
      this.credentialsMap[field.field].value = null;
    });
  }

  async save() {
    if (this.createEcForm.valid) {
      this.establishment.acquirer = this.selectedAcquirer.acquirer;
      this.establishment.version = this.selectedAcquirerSetup.version;

      for (const key in this.credentialsMap) {
        if (this.credentialsMap.hasOwnProperty(key)) {
          const credential = this.credentialsMap[key];
          this.establishment.credentials.find(credential => credential.field === key).value = credential.value;
        }
      }
      this.alertService.loading('Salvando...');
      try {
        const response = await lastValueFrom(this.onEstablishmentV1.onEstablishmentV1().create(this.idAffiliate, this.establishment));
        let location = response.headers.get('Location');
        const establishmentResponse = await lastValueFrom(this.onEstablishmentV1.onEstablishmentV1().getByUri(location));
        this.alertService.closeAll();
        this.router.navigate(['/settings/establishment', this.idAffiliate, establishmentResponse.id]);
      } catch (error) {
        this.alertService.handleError(error);
      }
    }
  }

  cancel() {
    this.router.navigate(['/settings/establishments']);
  }

  private getActiveAffiliates() {
    this.onAffiliateV1.getAll().subscribe(result => {
      this.affiliatesList = result;
      this.affiliatesList.sort((a, b) => (a.name < b.name) ? -1 : 1);
    });
  }

  private async getAcquirers() {
    await this.onEstablishmentV1.onEstablishmentV1().getSetups().subscribe(result => {
      for (const acquirer of result) {
        for (const configuration of acquirer.configurations) {
          if (configuration.isCreable) {
            this.acquirerList.push(acquirer);
            break;
          }
        }
      }
      this.sortAcquirerList();
    });
  }

  verifyMethod(method: string): boolean {
    if (this.selectedAcquirerSetup == null) {
      return false;
    } else {
      return this.selectedAcquirerSetup.methods.includes(method);
    }
  }

  sortAcquirerList() {
    this.acquirerList.sort((a, b) => {
      const translatedA = this.translate.instant('establishment.acquirer.' + a.acquirer);
      const translatedB = this.translate.instant('establishment.acquirer.' + b.acquirer);
      return translatedA.localeCompare(translatedB);
    });
  }

}
