<div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between start" class="mb-2">
  <mat-toolbar role="toolbar" class="task-header w-75">
  </mat-toolbar>
  <i (click)="onNoClick()" title="Fechar" class="fas fa-times pt-2 pointer"></i>
</div>
<div class="content">
  <div fxLayout='column' fxLayout.lt-md="column">
    <div class="box-data-card mb-3">
      <span class="text-title">
        <i class="fal fa-database"></i> Dados da Transação</span>
    </div>

    <div class="secondary-box" fxLayout='column'>

      <p fxLayout='row' *ngIf="dadosTransacao.tid">
        <span class="first-info">TID:
          <b>{{dadosTransacao.tid}}</b>
        </span>
        <app-copy-to-clipboard [value]="dadosTransacao.tid"></app-copy-to-clipboard>
      </p>

      <p fxLayout='row' *ngIf="dadosTransacao.yourReferenceId != null">
        <span class="first-info">Código da transação:
          <b>{{dadosTransacao.yourReferenceId}}</b>
        </span>
        <app-copy-to-clipboard [value]="dadosTransacao.yourReferenceId"></app-copy-to-clipboard>
      </p>

      <p fxLayout='row' *ngIf="dadosTransacao.nsuNumber">
        <span class="first-info">NSU:
          <b>{{dadosTransacao.nsuNumber}}</b>
        </span>
        <app-copy-to-clipboard [value]="dadosTransacao.nsuNumber"></app-copy-to-clipboard>
      </p>

      <p fxLayout='row' *ngIf="dadosTransacao.nsuCapture">
        <span class="first-info">NSU da captura:
          <b>{{dadosTransacao.nsuCapture}}</b>
        </span>
        <app-copy-to-clipboard [value]="dadosTransacao.nsuCapture"></app-copy-to-clipboard>
      </p>

      <p fxLayout='row' *ngIf="dadosTransacao.authorizationNumber">
        <span class="first-info">Código de autorização:
          <b>{{dadosTransacao.authorizationNumber}}</b>
        </span>
        <app-copy-to-clipboard [value]="dadosTransacao.authorizationNumber"></app-copy-to-clipboard>
      </p>

    </div>
  </div>
</div>
