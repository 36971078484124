import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { DateFormatPipe } from 'ngx-moment';
import { NgxPermissionsService } from 'ngx-permissions';
import { SubscriptionFilter } from 'src/app/core/common/filters/subscription.filter';
import { PageResponse } from 'src/app/core/model/page-response';
import { Pageable } from 'src/app/core/model/pageable';
import { SubscriptionResponse } from 'src/app/core/model/subscription.dto';
import { ChargeOrderStatus, OrderType, PlanResponse } from 'src/app/core/model/v1.model';
import { PagePermissions } from 'src/app/core/permissions/page-permissions';
import { AlertService } from 'src/app/core/services/alert.service';
import { PlanServiceV1 } from 'src/app/core/services/v1/plan.service.v1';
import { SubscriptionServiceV1 } from 'src/app/core/services/v1/subscription.service.v1';

declare var $: any;

@Component({
  selector: 'subscription-report',
  templateUrl: './subscription-report.component.html',
  styleUrls: ['./subscription-report.component.scss']
})
export class SubscriptionReportComponent implements OnInit {

  exportPermission = PagePermissions.REPORT_SUBSCRIPTION_EXPORT;
  showExportBtn: boolean;
  filters: SubscriptionFilter = new SubscriptionFilter();
  subscriptions = new MatTableDataSource<SubscriptionResponse>();
  rangeValid: boolean = true;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = [
    'id',
    'plan',
    'customer',
    'registerDate',
    'status',
    'type'
  ];

  public pageable: Pageable;


  loadingTab: boolean = true;
  pageResponse: PageResponse;
  pageEvent: PageEvent;
  planos: PlanResponse[];
  loading: boolean = true;
  types: any[];
  status: any[];
  searchTypesForm: UntypedFormGroup;
  searchStatusForm: UntypedFormGroup;
  searchPlansForm: UntypedFormGroup;
  @ViewChild('allSelectedForStatus') private allSelectedForStatus: MatOption;
  @ViewChild('allSelectedForTypes') private allSelectedForTypes: MatOption;
  @ViewChild('allSelectedForPlans') private allSelectedForPlans: MatOption;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private alertService: AlertService,
    private planService: PlanServiceV1,
    private permissionsService: NgxPermissionsService,
    private subscriptionServiceV1: SubscriptionServiceV1

  ) { }

  ngOnInit(): void {
    this.pageResponse = {
      pageNumber: 0,
      totalPages: 0,
      pageSize: 10,
      totalElements: 0,
      content: null
    };
    this.searchStatusForm = this.formBuilder.group({
      orderStatus: new UntypedFormControl('')
    });
    this.searchTypesForm = this.formBuilder.group({
      orderTypes: new UntypedFormControl('')
    });
    this.searchPlansForm = this.formBuilder.group({
      orderPlans: new UntypedFormControl('')
    });
    this.types = Object.values(OrderType).filter(x => typeof x === 'string');
    this.status = Object.values(ChargeOrderStatus).filter(x => typeof x === 'string');
    this.filters.start = moment().startOf('month').toDate();
    this.filters.end = new Date();
    this.permissionsService.hasPermission(this.exportPermission).then(hasPermission => {
      this.showExportBtn = hasPermission;
    });
    this.planService.getAllRecurrents().subscribe(result => this.planos = result);
    this.filter();
  }

  confirmDateRange() {
    let start = moment(this.filters.start);
    let end = moment(this.filters.end);
    let difference = end.diff(start, 'days');
    if (difference >= 31) {
      this.rangeValid = false;
    } else {
      this.filters.start = this.filters.start;
      this.filters.end = this.filters.end;
      this.rangeValid = true;
    }
    return this.rangeValid;
  }

  actionLoading(status: boolean) {
    setTimeout(() => {
      this.loading = status;
      this.loadingTab = status;
    }, 500);

  }

  filter() {
    this.pageable = { page: 0, size: 10 };
    if (this.confirmDateRange()) {
      this.search()
    }
  }

  public handlePage(event?: PageEvent) {
    this.pageable.page = event.pageIndex;
    this.search();
    return event;
  }

  private search() {
    this.loading = true;
    this.subscriptionServiceV1.getAll(this.filters, this.pageable)
      .subscribe(result => {
        this.showResult(result);
        this.actionLoading(false);
      },
        error => {
          this.actionLoading(false);
          this.alertService.error("Erro", "Ocorreu um erro interno em nosso servidor. Se o erro persistir, por favor, contate o suporte.");
        });
  }

  showResult(result) {
    if (result != null) {
      this.pageResponse.pageNumber = result.pageable.pageNumber;
      this.pageResponse.totalElements = result.totalElements;
      this.pageResponse.totalPages = result.totalPages;
      this.pageResponse.content = result.content;
      this.subscriptions = this.pageResponse.content;
    } else {
      this.pageResponse.pageNumber = 0;
      this.pageResponse.totalElements = 0;
      this.pageResponse.totalPages = 0;
      this.subscriptions = this.pageResponse.content;

    }
    this.loading = false;
  }

  downloadCsv() {
    this.loading = true;
    this.subscriptionServiceV1.downloadCsvSubscrption(this.filters)
      .subscribe(response => {
        let binaryData = ["\ufeff", response];
        let downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob(<any>binaryData, { type: "application/text" }));
        let dateFormatPipe: DateFormatPipe = new DateFormatPipe();
        let date = dateFormatPipe.transform(new Date(), "YYYY-MM-DD-HH-mm");
        downloadLink.setAttribute('download', `Relatorio_Assinaturas_${date}.csv`);
        document.body.appendChild(downloadLink);
        downloadLink.click();
        this.loading = false;
      },
        error => {
          this.alertService.error("Erro", "Ocorreu um erro interno em nosso servidor. Se o erro persistir, por favor, contate o suporte.");
        });
  }

  tosslePerOneForStatus(all) {
    if (this.allSelectedForStatus.selected) {
      this.allSelectedForStatus.deselect();
      return false;
    }
    if (this.searchStatusForm.controls.orderStatus.value.length == this.status.length) {
      this.allSelectedForStatus.select();
    }
  }
  toggleAllSelectionForStatus() {
    if (this.allSelectedForStatus.selected) {
      this.searchStatusForm.controls.orderStatus
        .patchValue([...this.status.map(item => item), 0]);
    } else {
      this.searchStatusForm.controls.orderStatus.patchValue([]);
    }
  }

  tosslePerOneForTypes(all) {
    if (this.allSelectedForTypes.selected) {
      this.allSelectedForTypes.deselect();
      return false;
    }
    if (this.searchTypesForm.controls.orderTypes.value.length == this.types.length) {
      this.allSelectedForTypes.select();
    }
  }
  toggleAllSelectionForTypes() {
    if (this.allSelectedForTypes.selected) {
      this.searchTypesForm.controls.orderTypes
        .patchValue([...this.types.map(item => item), 0]);
    } else {
      this.searchTypesForm.controls.orderTypes.patchValue([]);
    }
  }

  tosslePerOneForPlans(all) {
    if (this.allSelectedForPlans.selected) {
      this.allSelectedForPlans.deselect();
      return false;
    }
    if (this.searchPlansForm.controls.orderPlans.value.length == this.planos.length) {
      this.allSelectedForPlans.select();
    }
  }
  toggleAllSelectionForPlans() {
    if (this.allSelectedForPlans.selected) {
      this.searchPlansForm.controls.orderPlans
        .patchValue([0, ...this.planos.map(item => item.id)]);

    } else {
      this.searchPlansForm.controls.orderPlans.patchValue([]);
    }
  }

  compareId(id1: number, id2: number) {
    if (id2 === 0) {
      return id1 === 0;
    }
    return id1 && id2 && id1 === id2;
  }
}


