export enum CardDashType { 
    blue = "primary",
    gray = "secondary",
    green = "success",
    red = "danger",
    yellow = "warning",
    lightBlue = "info",
    lightGray = "light",
    dark = "dark",
    white = "white"
};

export class CardDash {
    type: CardDashType;
    icon: string;
    mainText: string;
    mainValue: number;
    minorText: string;
    minorValue: number;
    nameIcon: String;
    name: String;
}
