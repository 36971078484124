<form #createEcForm="ngForm" fxLayout="column" fxLayoutGap="15">
  <div fxLayout="column">
    <mat-form-field>
      <mat-label>Adquirente</mat-label>
      <mat-select placeholder="Adquirente" [(ngModel)]="selectedAcquirer" name="acquirer"
        (selectionChange)="loadSetup()" required>
        <mat-option *ngFor=" let acquirer of acquirerList" [value]="acquirer">
          <img name="imgAcquirer" style="max-width: 35px;"
            src="assets/img/acquirers/{{acquirer.acquirer.toLowerCase()}}.svg" />
          {{ ("establishment.acquirer."+acquirer.acquirer) | translate }}</mat-option>
      </mat-select>
      <mat-error>Adquirente é obrigatória</mat-error>
    </mat-form-field>
    <mat-form-field>
  <mat-label>Loja</mat-label>
  <mat-select placeholder="Loja" [(ngModel)]="idAffiliate" name="idAffiliate" required>
    <mat-option *ngFor=" let affiliate of affiliatesList " [value]="affiliate.id">{{affiliate.businessName}} -
      {{affiliate.document | mask: '00.000.000/0000-00||000.000.000-00'}}</mat-option>
  </mat-select>
  <mat-error>Filial é obrigatória</mat-error>
</mat-form-field>
  </div>
  <mat-radio-group aria-label="Select an option" name="acquirerSetup" [(ngModel)]="selectedAcquirerSetup"
    (change)="loadFields()" *ngIf="showRadio">
    <mat-radio-button class="mr-3" *ngFor="let configuration of selectedAcquirer.configurations"
      [value]="configuration.setup">{{("establishment.version."+configuration.setup.version) |
      translate}}</mat-radio-button>
  </mat-radio-group>
  <div *ngIf="selectedAcquirerSetup">
    <div *ngFor="let field of selectedAcquirerSetup.fields">
      <mat-form-field *ngIf="field.isCreateable">
        <mat-label>{{ ("establishment.field."+field.field) | translate }}</mat-label>
        <input matInput [placeholder]="('establishment.field.'+field.field) | translate"
          [(ngModel)]="credentialsMap[field.field].value" [name]="field.field" required>
        <mat-error>{{ ("establishment.field."+field.field) | translate }} é obrigatório</mat-error>
      </mat-form-field>
    </div>
  </div>

  <div fxLayout="column">
    <strong>Outras informações</strong>
    <mat-form-field *ngIf="verifyMethod('CREDIT_CARD')">
      <mat-label>Identificação na fatura</mat-label>
      <input matInput #softDescriptor maxlength="13" placeholder="Identificação na fatura"
        [(ngModel)]="establishment.softDescriptor" name="softDescriptor">
      <mat-hint align="end">{{softDescriptor.value.length}} / 13</mat-hint>
    </mat-form-field>


    <mat-form-field *ngIf="verifyMethod('CREDIT_CARD')">
      <mat-label>Prioridade</mat-label>
      <mat-select placeholder="Priority" [(ngModel)]="establishment.priority" name="priority" required>
        <mat-option [value]="Priority.MAIN">Principal</mat-option>
        <mat-option [value]="Priority.OVERFLOW">Transbordo</mat-option>
      </mat-select>
      <mat-error>Priority is required</mat-error>
    </mat-form-field>

    <mat-slide-toggle [(ngModel)]="establishment.requireSecurityCode" [ngModelOptions]="{standalone: true}"
      *ngIf="verifyMethod('CREDIT_CARD')">
      {{ establishment.requireSecurityCode ? "CVV Obrigatório": "CVV Opcional" }}
    </mat-slide-toggle>

    <mat-slide-toggle [(ngModel)]="establishment.active" [ngModelOptions]="{standalone: true}">
      {{ establishment.active ? "Ativo": "Inativo" }}
    </mat-slide-toggle>
  </div>
  <mat-card-actions class="border-top pt-2 ml-1">
    <button mat-raised-button color="primary" (click)="save()">Salvar</button>
    <button mat-raised-button color="warn" (click)="cancel()">Cancelar</button>
  </mat-card-actions>
</form>