import { Component, Input } from "@angular/core";

@Component({
    selector: 'card-box',
    templateUrl: './card-box.component.html',
    styleUrls: ['./card-box.component.scss']
})
export class CardBoxComponent {
    @Input() title: string;
    @Input() icon: string;
}