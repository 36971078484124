import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { DateUtils } from 'src/app/core/helper/date-utils';
import { CustomCurrencyPipe } from 'src/app/core/helper/pipe/custom-currency.pipe';
import { Data } from 'src/app/core/model/data';
import { DataCard, DataCardColors } from 'src/app/core/model/data-card';
import { AccountResponse, AlertChargebackType, DisputeFilter } from 'src/app/core/model/v1.model';
import { AccountServiceV2 } from 'src/app/core/services/v2/account.service.v2';
import { FutureTransfersTableComponent } from './future-transfers-table/future-transfers-table.component';
import { ReceivablesTableComponent } from './receivables-table/receivables-table.component';
import { TransfersTableComponent } from './transfers-table/transfers-table.component';

@Component({
  selector: 'my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})

export class MyAccountComponent implements OnInit {
  crumbs: MenuItem[];
  loading: Boolean = false;
  datasCard: DataCard[];
  accounts: AccountResponse[];
  loadingTab: boolean = false;
  sellerId: any;

  @ViewChild('selectType') selectType: MatSelect;
  @ViewChild('selectStatus') selectStatus: MatSelect;
  @ViewChild('receivablesTable') receivablesTable: ReceivablesTableComponent;
  @ViewChild('transfersTable') transfersTable: TransfersTableComponent;
  @ViewChild('futureTransfersTable') futureTransfersTable: FutureTransfersTableComponent;

  constructor(
    private cdr: ChangeDetectorRef,
    private router: Router,
    public data: Data,
    private currencyPipe: CustomCurrencyPipe,
    private accountServiceV2: AccountServiceV2) { }

  ngOnInit() {
    this.createCards();
    this.listAccounts();
  }

  listAccounts() {
    this.accountServiceV2.getAccounts().subscribe(response => {
      this.accounts = response;
      if (this.accounts && this.accounts.length > 0) {
        this.sellerId = this.accounts[0].code;
        this.loadCards(this.sellerId);
      } else {
        this.loading = false;
        this.router.navigate(['/empty-accounts']);
      }
    });
  }

  public initCrumbs() {
    this.crumbs = [
      { label: 'Financeiro' }
    ];
  }

  loadCards(sellerId: any) {
    this.loading = true;
    this.accountServiceV2.getBalances(sellerId).subscribe(response => {
      this.datasCard[0].value = this.currencyPipe.transform(response.currentBalance, 'BRL');
      this.datasCard[1].value = this.currencyPipe.transform(response.accountBalance, 'BRL');
      this.datasCard[2].value = this.currencyPipe.transform(response.currentBlockedBalance, 'BRL');
      this.cdr.detectChanges();
      this.loading = false;
    });
  }

  optionClick(sellerId: string) {
    this.loadCards(sellerId);
  }

  onTabChanged(event: MatTabChangeEvent) {
    if (event.index === 0) {
      this.receivablesTable.loadData(this.sellerId);
    } else if (event.index === 1) {
      this.transfersTable.loadData(this.sellerId);
    } else if (event.index === 2) {
      this.futureTransfersTable.loadData(this.sellerId);
    }
}

  private createCards() {
    let totalQtdDataCard: DataCard = { text: null, value: this.currencyPipe.transform(0, 'BRL'), icon: DataCardColors.iconDolar, bgcard: DataCardColors.bgcardValueUp, mask: false, total: false, isMyAccount: true, textMinor: "SALDO DISPONÍVEL" };
    let totalAprovadasDataCard: DataCard = { text: null, value: this.currencyPipe.transform(0, 'BRL'), icon: DataCardColors.iconCalendar, bgcard: DataCardColors.bgCardValueTotal, mask: true, total: false, isMyAccount: true, textMinor: "SALDO A RECEBER" };
    let totalRecusadasDataCard: DataCard = { text: null, value: this.currencyPipe.transform(0, 'BRL'), icon: DataCardColors.iconExclamation, bgcard: DataCardColors.bgcardValueDown, mask: true, total: false, isMyAccount: true, textMinor: "SALDO BLOQUEADO" };
    this.datasCard = [totalQtdDataCard, totalAprovadasDataCard, totalRecusadasDataCard];
  }

}
