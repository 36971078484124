import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ProductResponse } from "../../model/v1.model";

@Injectable({
  providedIn: 'root'
})
export class ProductServiceV1 {

  editProduct: EventEmitter<boolean>;

  constructor(private http: HttpClient) {
    this.editProduct = new EventEmitter<boolean>();
  }

  public getByUri(uri: string): Observable<ProductResponse> {
    return this.http.get<ProductResponse>(uri);
  }

}