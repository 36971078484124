import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { MenuItem } from "primeng/api";
import { SaleCancelComponent } from 'src/app/components/sale-cancel/sale-cancel.component';
import { CobrancaStatusNew } from 'src/app/core/model/cobranca';
import { SalesDetailsResponse } from 'src/app/core/model/response/sales-details-response';
import { PagePermissions } from 'src/app/core/permissions/page-permissions';
import { AlertService } from 'src/app/core/services/alert.service';
import { AppService } from 'src/app/core/services/v0/app.service';
import { ChargeServiceV1 } from 'src/app/core/services/v1/charge.service.v1';
import { OnChargeV2 } from 'src/app/core/services/v2/charge.service.v2';
import { CreditCard, Charge, Transaction } from 'src/app/core/model/v2.model';
import { CreditCardApp } from 'src/app/components/credit-card/credit-card.component';
import { CaptureChargeComponent } from 'src/app/components/capture-charge/capture-charge.component';
import { CancelChargeComponent } from 'src/app/components/cancel-charge/cancel-charge.component';
import { OrderServiceV1 } from 'src/app/core/services/v1/order.service.v1';
import { EventListComponent } from './event-list/event-list.component';

@Component({
  selector: 'app-sales-details',
  templateUrl: './sales-details.component.html',
  styleUrls: ['./sales-details.component.scss']
})
export class SalesDetailsComponent implements OnInit {
  loading = true;
  crumbs: MenuItem[];
  listaStatus: CobrancaStatusNew[] = new Array();
  enableRefundButton: boolean = false;
  canCancelCharge: boolean = false;
  cancelationPermissions = PagePermissions.CANCELATION_SALES_DETAIL;
  order: any;
  charge: Charge = new Charge();
  // transaction: SalesDetailsResponse;
  transactionsV2: Transaction[] = new Array();
  transactionsWithoutRefundV2: Transaction[] = new Array();
  creditCards: CreditCard[] = new Array();
  cardsFromCreditCardComponent: CreditCardApp[] = new Array();
  paymentMethodCharge: string;

  @ViewChild(EventListComponent, { static: false }) events: EventListComponent;

  constructor(
    private route: ActivatedRoute,
    private onChage: OnChargeV2,
    private onOrderV1: OrderServiceV1,
    private alertService: AlertService,
    private permissionsService: NgxPermissionsService,
    private appService: AppService,
    public dialog: MatDialog,
  ) { }

  async ngOnInit() {
    this.loading = true;
    this.initCrumbs();

    //Busca status da cobranca
    this.appService.getChargeStatus().then(data => {
      this.listaStatus = data.cobrancaStatus;
    });

    this.creditCards = new Array();
    this.cardsFromCreditCardComponent = new Array();

    this.transactionsV2 = new Array();
    this.transactionsWithoutRefundV2 = new Array();

    // await this.getChargeById();
    await this.getChargeByIdV2();

    this.permissionsService.hasPermission(PagePermissions.CANCELATION_SALES_DETAIL).then(hasPermission => {
      this.enableRefundButton = hasPermission;
    });

    this.loading = false;
  }

  initCrumbs() {
    this.crumbs = [
      { label: 'Vendas', routerLink: ['/sales/transactions'] },
      { label: this.route.snapshot.paramMap.get('id') },
    ];
  }

  // async getChargeById(): Promise<void> {
  //   console.log("getChargeById");
  //   try {
  //     const id = this.route.snapshot.paramMap.get('id');
  //     if (id) {
  //       this.transaction = await this.chargeServiceV1.getById(id);
  //     } else {
  //       this.alertService.handleError('ID no encontrado en los parámetros de la ruta');
  //     }
  //   } catch (error) {
  //     this.alertService.handleError(error);
  //   }
  // }

  async getChargeByIdV2(): Promise<void>  {
    console.log("getChargeByIdV2");
    try {
      const id = this.route.snapshot.paramMap.get('id');
      this.charge = await this.onChage.getById(id);
      this.setupCharge();
    } catch (error) {
      this.alertService.handleError(error);
    }
  }

  setupCharge() {
    this.transactionsWithoutRefundV2 = this.charge.transactions.map((transaction) => {
      if (transaction.type != "REFUND") {
        return transaction;
      } else {
        return null;
      }
    }).filter((data) => data != null);

    this.getOrder();
    this.paymentMethodCharge = this.hasPaymentMethod(this.transactionsWithoutRefundV2);

    if (this.paymentMethodCharge === "CREDIT_CARD_SPLIT") {
      this.transactionsV2 = this.charge.transactions.map((transaction) => {
        if (transaction.type != "REFUND") {
          return transaction;
        } else {
          return null;
        }
      }).filter((data) => data != null);

      this.cardsFromCreditCardComponent = this.charge.transactions.map((transaction) => {

        if (transaction.type != "REFUND") {
          const data: CreditCardApp = new CreditCardApp();
          data.card = transaction.card;
          data.installments = transaction.installments != null ? transaction.installments : this.charge.installments;
          data.value = transaction.value;
          return data;
        } else {
          return null;
        }

      }).filter((data) => data != null);

    } else if (this.paymentMethodCharge === "CREDIT_CARD") {

      this.transactionsV2.push(this.charge.transactions[0]);

      const data: CreditCardApp = new CreditCardApp();
      data.card = this.charge.transactions[0].card;
      data.installments = this.charge.transactions[0].installments != null ? this.charge.transactions[0].installments : this.charge.installments;
      data.value = this.charge.transactions[0].value;
      this.cardsFromCreditCardComponent.push(data);

    } else {
      this.creditCards = [];
    }
    this.canCancelCharge = this.charge.transactions[0].status == 'AWAITING_PAYMENT' ? true : false;
  }

  hasPaymentMethod(transactions: Transaction[]): string {
    const creditCardOccurrences = transactions.reduce((count, transaction) => {
      if (transaction.paymentMethod === "CREDIT_CARD") {
        return count + 1;
      }
      return count;
    }, 0);

    if (creditCardOccurrences > 1) {
      if (this.charge.value > transactions[0]?.value) {
        return "CREDIT_CARD_SPLIT";
      } else {
        return "CREDIT_CARD";
      }
    } else {
      return transactions[0]?.paymentMethod;
    }
  }

  getStatusColor(status) {
    return "transaction-status transaction-" + status;
  }

  getStatusIcon(status) {
    switch (status) {
      case "PAY":
        return "fas fa-check-circle";
      case "CHARGEBACK":
        return "far fa-angry";
      case "CANCELED":
        return "fas fa-ban";
      case "REFUND":
        return "fas fa-backspace";
      case "PENDING":
        return "fas fa-exclamation-circle";
      case "OVERDUE":
        return "far fa-calendar-times";
      case "AUTHORIZED":
        return "fas fa-check-circle";
      default:
        return "fas fa-bug";
    }
  }

  refundCharge() {
    const dialogRef = this.dialog.open(SaleCancelComponent, { data: { charge: this.charge, paymentMethod: this.paymentMethodCharge } });
    dialogRef.afterClosed().subscribe(result => {
      this.ngOnInit();
    });
  }

  captureCharge() {
    const dialogRef = this.dialog.open(CaptureChargeComponent, { data: this.charge });
    dialogRef.afterClosed().subscribe(result => {
      this.ngOnInit();
    });
  }

  cancelCharge() {
    const dialogRef = this.dialog.open(CancelChargeComponent, { data: this.charge });
    dialogRef.afterClosed().subscribe(result => {
      this.ngOnInit();
    });
  }

  getOrder() {
    this.onOrderV1.getById(this.charge.order.id).subscribe(
      result => {
        this.order = result;
      },
      error => {
        this.alertService.handleError(error);
      }
    );
  }

  isExpired(date): boolean {
    return date < Date.now() ? true : false;
  }
}
