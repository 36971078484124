import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { toHttpParams } from "../../helper/http.helper";
import { Pageable } from "../../model/pageable";
import { PagePayResponse, PlanFrequency, PlanPutRequest, PlanResponse, SubscribersReponse } from "../../model/v1.model";

@Injectable({
  providedIn: 'root'
})
export class PlanServiceV1 {

  editPlan: EventEmitter<boolean>;

  constructor(private http: HttpClient) {
    this.editPlan = new EventEmitter<boolean>();
  }
  public create(request: PlanResponse): Observable<any> {
    return this.http.post<PlanResponse>(`${environment.apiUrl}/api/v1/plans`, request, { observe: 'response' });
  }

  public getById(id: number): Observable<PlanResponse> {
    return this.http.get<PlanResponse>(`${environment.apiUrl}/api/v1/plans/${id}`)
  }

  public getBySku(sku: string): Observable<PlanResponse[]> {
    return this.http.get<PlanResponse[]>(`${environment.apiUrl}/api/v1/plans/sku`, { params: toHttpParams({ sku: sku }) })
  }
  public getByUri(uri: string): Observable<PlanResponse> {
    return this.http.get<PlanResponse>(uri);
  }
  public getByUriWithToken(uri: string, token: string): Observable<PlanResponse> {
    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.get<PlanResponse>(uri, options);
  }

  public active(plan: PlanResponse): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}/api/v1/plans/${plan.id}/active`, null);
  }

  public inactive(plan: PlanResponse): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}/api/v1/plans/${plan.id}/inactive`, null);
  }

  public update(plan: PlanResponse, request: PlanPutRequest): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}/api/v1/plans/${plan.id}`, request);
  }

  public getAllPagePaysByPlan(plan: PlanResponse): Observable<PagePayResponse[]> {
    return this.http.get<PagePayResponse[]>(`${environment.apiUrl}/api/v1/plans/${plan.id}/pagepays`)
  }

  public getAllSubscribersById(id: number, pageable: Pageable): Observable<SubscribersReponse[]> {
    let params = new HttpParams();
    params = params.set("size", pageable.size.toString()).set("page", pageable.page.toString());
    return this.http.get<SubscribersReponse[]>(`${environment.apiUrl}/api/v1/plans/${id}/subscribers`, {params: params})
  }

  

  public getAllRecurrents(): Observable<PlanResponse[]> {
    return this.http.get<PlanResponse[]>(`${environment.apiUrl}/api/v1/plans/recurrent`)
  }

  public getMaxInstallmentsFromFrequency(frequency: PlanFrequency): number {
    let installments: number = 12;
    if (frequency.toString() === PlanFrequency.WEEKLY.toString() ||
      frequency.toString() === PlanFrequency.BIWEEKLY.toString() ||
      frequency.toString() === PlanFrequency.MONTHLY.toString()) {
      installments = 1;
    } else if (frequency.toString() === PlanFrequency.BIMONTHLY.toString()) {
      installments = 2;
    } else if (frequency.toString() === PlanFrequency.QUARTERLY.toString()) {
      installments = 3;
    } else if (frequency.toString() === PlanFrequency.SEMESTRAL.toString()) {
      installments = 6;
    }
    return installments;
  }

  public uploadImage(planId: number, request: Blob) {
    let formData: FormData = new FormData();
    formData.append('file', request);
    return this.http.post<any>(`${environment.apiUrl}/api/v1/checkouts/${planId}/image`, formData);
}
}