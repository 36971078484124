<div class="content container">

  <div fxLayout="column" fxLayoutAlign="center stretch" fxLayout.lt-lg="column" fxLayoutAlign.lt-lg="center stretch"
    fxLayoutGap="20px">
    <div class="card">
      <div class="title">
        <h4>Pagamento da assinatura</h4>
      </div>
      <div class="info-container" fxFlex fxLayout="row" >
        <div *ngIf="paymentMethod == 'pix'">
          <table>
            <tr>
              <td>
                <span fxFlex><strong>Nome do plano</strong>: {{pixMinimal.planName}}</span>
              </td>
            </tr>
            <tr>
              <td>
                <span fxFlex><strong>Valor</strong>: {{pixMinimal.value | CustomCurrency:'BRL'}}</span>
              </td>
            </tr>
            <tr>
              <td>
                <span *ngIf="status != 'APPROVED'" fxFlex> <strong>Data de expiração</strong>: {{dateFormated | date: 'dd/MM/yyyy HH:mm'}}</span>
              </td>
            </tr>
            <tr>
              <td>
                <img *ngIf="status != 'APPROVED'" src="{{image}}" class="image-payment" alt="Pix" width="200px" />
              </td>
            </tr>
            <tr>
              <td>
                <img *ngIf="status == 'APPROVED'" src="..\..\assets\img\qrcode-checked.png" class="image-payment" alt="Pago" />
              </td>
            </tr>
            <tr>
              <td>
                <button *ngIf="status != 'APPROVED'" mat-raised-button class="btn-search btn-copy" (click)="getText()"
                  style="width: 200px">{{text}}</button>
              </td>
            </tr>
            <tr>
              <td>
                <span fxFlex class="status" *ngIf="status == 'APPROVED'" widht="200px">Pago!</span>
              </td>
            </tr>
          </table>
        </div>
        <div *ngIf="paymentMethod == 'bankslip'">
          <table>
            <tr>
              <td>
                <span fxFlex><strong>Nome do plano</strong>: {{planName}}</span>
              </td>
            </tr>
            <tr>
              <td>
                <span fxFlex><strong>Valor</strong>: {{value | CustomCurrency:'BRL'}}</span>
              </td>
            </tr>
            <tr>
              <td>
                <span *ngIf="status != 'APPROVED'" fxFlex> <strong>Data de expiração</strong>: {{dateFormated | date: 'dd/MM/yyyy'}}</span>
              </td>
            </tr>
            <tr>
              <td>
                <span *ngIf="status != 'APPROVED'" fxFlex> <strong>Linha digitável</strong>: {{barcode}}</span>
              </td>
            </tr>
            <tr>
              <td>
                <img *ngIf="status != 'APPROVED'" src="{{image}}" class="image-payment" alt="Boleto" width="400px" />
              </td>
            </tr>
            <tr>
              <td>
                <img *ngIf="status == 'APPROVED'" src="..\..\assets\img\bankslip-pago.png" class="image-payment" alt="Pago" />
              </td>
            </tr>
            <tr>
              <td>
                <span fxFlex class="status" *ngIf="status == 'APPROVED'" widht="200px">Pago!</span>
              </td>
            </tr>
          </table>
        </div>
        <span *ngIf="status != 'APPROVED'" class="vertical-line"></span>
        <div *ngIf="status != 'APPROVED'" class="list">
          <ol>
            <li>A efetivação do pagamento pode levar até dois dias úteis.</li>
              <li>Após confirmado, você receberá uma notificação por e-mail.</li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</div>