<section class="dash-card">
    <div *ngIf="data else">
        <div class="{{data.bgcard}}">
            <div class="card-data">
                <div class="card-icon pull-left {{data.bgcard}}">
                    <i class="{{data.icon}}"></i>
                </div>
                <div class="text-right">
                    <div class="row" *ngIf="data.isMyAccount">
                        <span class="text-minor-card col-12">{{data.textMinor}}</span>
                    </div>
                    <span class="value-card">
                        {{ data.value !== null && data.value !== undefined ? data.value : "R$ 0,00" }}
                    </span>
                    <div class="row" *ngIf="!data.isMyAccount">
                        <span class="text-card col-12">{{ data.total !== false && data.total !== undefined ? "Nº de vendas: " +
                            data.total : "Nº de vendas:" }}</span>
                    </div>


                    <div class="row">
                        <span class="text-card desc col-12">{{data.text}}</span>
                    </div>
                </div>
            </div>
        </div>

    </div>
</section>
