import { Component, Input } from "@angular/core";
import { DataCard } from "../../core/model/data-card";

@Component({
    selector: 'data-card',
    templateUrl: './data-card.component.html',
    styleUrls: ['./data-card.component.scss']
})
export class DataCardComponent {
    @Input() data: DataCard;
}