<app-not-allowed [classAux]="'shadow-none border-0'" *ngxPermissionsExcept="signaturePermissions"></app-not-allowed>

<ng-container *ngxPermissionsOnly="signaturePermissions">
  <div class="row mt-3" *ngIf="signatures">
    <h2>Assinaturas</h2>
    <div class="border-0 shadow-none" *ngIf="signatures.length > 0">
      <div class="div-table">
        <table mat-table [dataSource]="signatures" matSort>


          <ng-container matColumnDef="dt_pedido">
            <th mat-header-cell *matHeaderCellDef> Data de Aquisição </th>
            <td mat-cell *matCellDef="let row" data-view-transaction="true">
              {{row.registerDate | date: 'dd/MM/yyyy HH:mm'}} </td>
          </ng-container>

          <ng-container matColumnDef="product_nome">
            <th mat-header-cell *matHeaderCellDef> Produto </th>
            <td mat-cell *matCellDef="let row" data-view-transaction="true">
              <div class="limit-text" data-toggle="tooltip" data-html="true"
                [title]="'<b>#'+ row.id + '</b> ' + row.nome">
                <b>#{{row.id}}</b> {{row.product}}
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="valor">
            <th mat-header-cell *matHeaderCellDef class="pr-2"> Valor </th>
            <td mat-cell *matCellDef="let row" data-view-transaction="true">
              {{row.subscriptionAmount / 100 | CustomCurrency:'BRL'}} </td>
          </ng-container>

          <ng-container matColumnDef="periodicidade">
            <th mat-header-cell *matHeaderCellDef class="pr-2"> Periodicidade</th>
            <td mat-cell *matCellDef="let row" data-view-transaction="true">
              {{ row.frequency | translate}} </td>
          </ng-container>

          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef class="pr-2"> Status</th>
            <td mat-cell *matCellDef="let row" fxLayout="row" fxLayoutAlign="start center" data-view-transaction="true">
              <span [ngClass]="row.ativo == true ? 'ativo' : 'inativo'" class="p-1"
                data-view-transaction="true">{{row.orderStatus|translate}}</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let row" class="center-text">
              <i class="fas fa-cog fa-2x text-black-50" id="dropdownMenuButton" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false"></i>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a class="dropdown-item" (click)="viewInstallments(row , $event , true)">Parcelas</a>
                <a class="dropdown-item" *ngIf="!row.dtCancelamento" (click)="cancelaAssinatura(row)">Cancelar
                  Assinatura</a>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

          <div *ngxPermissionsOnly="transactionDetailPermission; else dontHavePermission; then hasPermission">
          </div>
          <ng-template #dontHavePermission>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </ng-template>
          <ng-template #hasPermission>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="hover-row"
              (click)="viewInstallments(row , $event)"></tr>
          </ng-template>

        </table>
      </div>
    </div>
    <div *ngIf="signatures.length == 0" class="no-data-message">
      <span>Nenhuma Assinatura encontrada.</span>
    </div>
  </div>

  <div class="content h-100" fxLayout="row" fxLayoutAlign="center center" *ngIf="!signatures">
    <mat-spinner></mat-spinner>
  </div>
</ng-container>
