<mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
<h2 mat-dialog-title>Capturar pagamento</h2>
<mat-dialog-content class="mb-5">
  Deseja realemte capturar o pagamento desta venda no valor de <span class="font-weight-bold">{{charge.value/100 |
    CustomCurrency:'BRL' }}</span>?
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions align="end">
  <button mat-button class="btn btn-light" mat-dialog-close>Cancelar</button>
  <button mat-button class="btn btn-success" (click)="captureCharge()" cdkFocusInitial>Sim</button>
</mat-dialog-actions>
