<mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
<div class="container inside-container">

  <report-approved-transactions-filter [filters]="filters" (filterClick)="filter()" [showExportBtn]="showExportBtn"></report-approved-transactions-filter>

  <div fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between none" fxLayout="column" class="report-data-card"
    *ngIf="datasCard">

    <div fxFlex="60" fxLayout="row" fxLayoutAlign="start stretch" fxLayout='row'>
      <data-card fxFlex="33" *ngFor="let index of [0,1,2]"
        [ngStyle.gt-sm]="{'margin-right': ( index <= 2 ? '10px': '0px' )  }" [data]="datasCard[index]"></data-card>
    </div>

    <div fxFlex="40" fxLayout="row" fxLayoutAlign="start stretch" fxLayout='row'>
      <data-card fxFlex="50" *ngFor="let index of [3,4]" [data]="datasCard[index]"
        [ngStyle.gt-sm]="{'margin-right': ( index < 4 ? '10px': '0px' )  }"></data-card>
    </div>

  </div>

  <div [ngClass]="{'hide' : !transactions.data || !transactions.data.length}">
    <div class="div-table">
      <table mat-table [dataSource]="transactions" multiTemplateDataRows matSort style="width: 100%;">

        <ng-container matColumnDef="empresa">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Loja </th>
          <td mat-cell *matCellDef="let row"> {{row.nomeEmpresa}} </td>
        </ng-container>
        <ng-container matColumnDef="nomeAdquirente">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Adquirente</th>
          <td mat-cell *matCellDef="let row">
            <img src="assets/img/acquirers/{{row.nomeAdquirente?.toLowerCase()}}.svg" />
          </td>
        </ng-container>
        <ng-container matColumnDef="estabelecimento">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> EC </th>
          <td mat-cell *matCellDef="let row"> {{row.estabelecimento}} </td>
        </ng-container>
        <ng-container matColumnDef="total">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Total </th>
          <td mat-cell *matCellDef="let row"> {{row.total}} </td>
        </ng-container>
        <ng-container matColumnDef="aprovadas">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Aprovadas </th>
          <td mat-cell *matCellDef="let row"> {{row.aprovadas}} </td>
        </ng-container>
        <ng-container matColumnDef="aprovacao">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Aprovação </th>
          <td mat-cell *matCellDef="let row"> {{row.aprovadas / row.total *100 | percentValue}} </td>
        </ng-container>
        <ng-container matColumnDef="receita">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Receita </th>
          <td mat-cell *matCellDef="let row"> {{row.receita / 100 | CustomCurrency:'BRL'}} </td>
        </ng-container>
        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let row" [attr.colspan]="displayedColumns.length">
            <div class="example-element-detail" [@detailExpand]="row == expandedElement ? 'expanded' : 'collapsed'">
              <div fxFlex fxLayout="column" fxLayoutAlign="space-around stretch"
                *ngFor="let bandeira of row.administradoras">
                <div class="p-2" fxLayout="column" fxLayoutAlign="center center" *ngIf="bandeira.qtd > 0" fxLayoutGap="1">
                  <div>
                    <img class="mr-1" src="assets/img/brands/{{bandeira.nome.toLowerCase()}}.png" />
                    {{bandeira.nome}} </div>
                  <div>{{bandeira.qtd}}</div>
                </div>

              </div>
            </div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="example-element-row"
          [class.example-expanded-row]="expandedElement === row" (click)="expandedElement = row">
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
      </table>
    </div>
  </div>
  <div [ngClass]="{'hide' : transactions.data && transactions.data.length}" class="no-data-message">
    <span>Nenhuma transação encontrada.</span>
  </div>
</div>
