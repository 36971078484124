import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { SubscriptionFilter } from "../../common/filters/subscription.filter";
import { getParamsSubscription } from "../../helper/http.helper";
import { Pageable } from "../../model/pageable";
import { Observable } from "rxjs";

@Injectable({ providedIn: 'root' })
export class SubscriptionServiceV1 {

    constructor(private http: HttpClient) { }

    getAll(filter: SubscriptionFilter, pageable: Pageable) {
        let params = getParamsSubscription(filter);
        params = params.set('size', pageable.size.toString());
        params = params.set('page', pageable.page.toString());
        return this.http.get(`${environment.apiUrl}/api/v1/reports/subscriptions`, { params: params });
    }
    downloadCsvSubscrption(filters: SubscriptionFilter) {
        return this.http.get(`${environment.apiUrl}/api/v1/reports/subscriptions/csv`, { params: getParamsSubscription(filters), responseType: 'blob' as 'json' });
    }
    
    createSubscription(data): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}/api/v1/subscriptions`, data);
    }

}

