<div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between start" class="mb-6">
  <mat-toolbar role="toolbar">
    Nova senha
  </mat-toolbar>
  <i (click)="onNoClick()" title="Fechar" class="fas fa-times pointer"></i>
</div>

<div class="content">
  <div fxLayout='row' fxLayoutAlign="space-between center">
    <span>{{newPassword}}</span>
    <div fxLayout="row" fxLayoutAlign="end center">
      <mat-error *ngIf="copyMsg">Senha copiado</mat-error>
      <button class="btn btn-search" type="button" ngxClipboard [cbContent]="newPassword"
        (click)="showMsgCopy()">Copiar</button>
    </div>
  </div>
</div>
