import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { CardIdRequest, LastTransactionResponse, OrderResponse } from "../../model/v1.model";

@Injectable({
  providedIn: 'root'
})

export class OrderServiceV1 {
  constructor(private http: HttpClient) {
  }

  public getByIdWithToken(order: OrderResponse, token: string): Observable<OrderResponse> {
    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.get<OrderResponse>(`${environment.apiUrl}/api/v1/orders/${order.id}`, options);
  }

  public getById(orderId: number): Observable<OrderResponse> {
    return this.http.get<any>(`${environment.apiUrl}/api/v1/orders/${orderId}`);
  }

  public getByUriWithToken(uri: string, token: string): Observable<OrderResponse> {
    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.get<any>(uri, options);
  }

  public getByOrderIdWithToken(orderId: number, token: string): Observable<OrderResponse> {
    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.get<any>(`${environment.apiUrl}/api/v1/orders/${orderId}`, options);
  }

  public getLastTransactionByUriWithToken(uri: string, token: string): Observable<LastTransactionResponse> {
    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.get<any>(uri, options);
  }

  public setOrderCardWithToken(order: OrderResponse, token: string, card: CardIdRequest): Observable<any> {
    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.put<OrderResponse>(`${environment.apiUrl}/api/v1/orders/${order.id}/card`, card, options);
  }
}
