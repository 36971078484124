import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PagePermissions } from 'src/app/core/permissions/page-permissions';
import { DialogData } from 'src/app/pages/transactions/transactions.component';
import { Transaction, TransactionStatus } from '../../../core/model/transaction';
import { TransacaoService } from '../../../core/services/v0/transaction.service';

@Component({
  selector: 'app-transaction-detail',
  templateUrl: './transaction-detail.component.html',
  styleUrls: ['./transaction-detail.component.scss']
})
export class TransactionDetailComponent implements OnInit {

  dadosTransacao: Transaction;
  listaStatus: TransactionStatus[];
  clientPermissions = PagePermissions.CUSTOMER_EDIT_DETAILS;

  constructor(
    private router: Router,
    private transactionService: TransacaoService,
    public dialogRef: MatDialogRef<TransactionDetailComponent>,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  ngOnInit() {
    this.dadosTransacao = this.data.transacao;
    this.transactionService.getStatus().then(data => {
      this.listaStatus = data.transactionStatus;
    });
  }

  clienteClick(idCliente:number) {
    if (idCliente != null && idCliente > 0) {
      this.dialogRef.close();
      this.router.navigate(["/subscribers", idCliente]);
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  getStatusColor(status) {

    if(this.listaStatus) {
      for (let i=0; i < this.listaStatus.length; i++) {
        if(this.translate.instant(status) === this.listaStatus[i].value) {
          return this.listaStatus[i].textColorClass;
        }
      }
       return "";
     }
  
    }
   
}
