import { Component, OnInit, ViewChild } from '@angular/core';
import { MatOption } from '@angular/material/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSelect } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { DateFormatPipe } from 'ngx-moment';
import { MenuItem } from 'primeng/api';
import { DateUtils } from 'src/app/core/helper/date-utils';
import { CardDash, CardDashType } from 'src/app/core/model/card-dash';
import { Data } from 'src/app/core/model/data';
import { PageResponse } from 'src/app/core/model/page-response';
import { Pageable } from 'src/app/core/model/pageable';
import { AlertChargebackStatus, AlertChargebackType, DisputeFilter, DisputeResponse } from 'src/app/core/model/v1.model';
import { AlertService } from 'src/app/core/services/alert.service';
import { AppService } from 'src/app/core/services/v0/app.service';
import { ChargebackServiceV1 } from 'src/app/core/services/v1/chargeback.service.v1';
import { DisputeService } from 'src/app/core/services/v1/dispute-service';

@Component({
  selector: 'app-disputed',
  templateUrl: './disputed.component.html',
  styleUrls: ['./disputed.component.scss']
})

export class DisputedComponent implements OnInit {
  disputes = new MatTableDataSource<DisputeResponse>();
  displayedColumns: string[] =
    ['type',
      'authorizationDate',
      'alertDate',
      'card',
      'descriptor',
      'amount',
      'refunded',
      'status'
    ];

  endAutorizationDate = new Date(Date.now());
  startAutorizationDate = new Date(this.endAutorizationDate.getFullYear(), this.endAutorizationDate.getMonth() - 1, this.endAutorizationDate.getDate());
  enableAutorizationDate: boolean = false;

  endAlertDate = new Date(Date.now());
  startAlertDate = new Date(this.endAlertDate.getFullYear(), this.endAlertDate.getMonth() - 1, this.endAlertDate.getDate());
  enableAlertDate: boolean = true;

  alertsAllCount: number;
  alertsAllCountCurrentMonth: number;
  resolvedCount: number;
  crumbs: MenuItem[];
  loading: Boolean = false;
  cardDashDanger: CardDash = new CardDash();
  cardDashSucess: CardDash = new CardDash();
  cardDashOpen: CardDash = new CardDash();
  buttonEnabled: string = "todos";
  idsPossiveis: string[] = ["todos", "reembolsados", "nao_reembolsados", "resolvidos"];


  dateUtils: DateUtils = new DateUtils();

  @ViewChild('selectType') selectType: MatSelect;
  @ViewChild('allSelectedType') private allSelectedType: MatOption;

  @ViewChild('selectStatus') selectStatus: MatSelect;
  @ViewChild('allSelectedStatus') private allSelectedStatus: MatOption;

  filters: DisputeFilter;

  AlertStatus: AlertChargebackStatus[];
  AlertType: AlertChargebackType[];

  viewFilter: boolean = true;
  resolvedCurrentMonth: number;
  alertDateValid: boolean;
  authorizationDateValid: boolean;
  isDateValid: boolean;
  public pageable: Pageable;
  pageResponse: PageResponse;
  rangeValid: boolean = true;
  loadingTab: boolean = true;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    public data: Data,
    private chargebackServiceV1: ChargebackServiceV1,
    private disputeService: DisputeService,
    private alertService: AlertService,
    private appService: AppService) { }

  ngOnInit() {
    this.filters = new DisputeFilter();
    this.pageResponse = {
      pageNumber: 0,
      totalPages: 0,
      pageSize: 10,
      totalElements: 0,
      content: null
    };
    this.initCrumbs();
    this.appService.getAlertStatus().then(data => {
      this.AlertStatus = data.alertStatus;
    });
    this.appService.getAlertType().then(data => {
      this.AlertType = data.alertType;
    });
    this.pageable = { page: 0, size: 10 };
    this.loadCards();
    this.filterParam();
  }

  public initCrumbs() {
    this.crumbs = [
      { label: 'Alertas de Disputas' }
    ];
  }

  //paginação
  public handlePage(event?: PageEvent) {
    this.pageable.page = event.pageIndex;
    this.search();
    return event;
  }

  //ordenação
  public handleSort(event?: any) {
    this.pageable = { page: 0, size: 10 };
    this.pageable = Object.assign({}, this.pageable, { sort: `${event.active},${event.direction}` });
    this.search();
  }


  loadCards() {
    this.loading = true;
    this.chargebackServiceV1.getMetricAlerts().subscribe(response => {

      this.alertsAllCount = response.alertsCount;
      this.alertsAllCountCurrentMonth = response.alertsCountCurrentMonth;
      this.resolvedCount = response.resolvedCount;
      this.resolvedCurrentMonth = response.resolvedCurrentMonth;
      this.loadCardDash();
      this.loading = false;
    });
  }

  getStatusColor(status) {
    for (let i = 0; i < this.AlertStatus.length; i++) {
      if (status === this.AlertStatus[i].value) {
        let aux = this.AlertStatus[i].colorClass;
        return aux;
      }
    }
    return "bg-gray";
  }

  confirmDateRange() {
    let startAlertDate = moment(this.filters.startAlertDate);
    let endAlertDate = moment(this.filters.endAlertDate);
    let difference = endAlertDate.diff(startAlertDate, 'days');
    if (difference > 31) {
      this.rangeValid = false;
    } else {
      this.filters.startAlertDate = this.filters.startAlertDate;
      this.filters.endAlertDate = this.filters.endAlertDate;
      this.rangeValid = true;
    }
    return this.rangeValid;
  }

  actionLoading(status: boolean) {
    setTimeout(() => {
      this.loading = status;
      this.loadingTab = status;
    }, 500);

  }

  filterParam() {
    this.cleanFilter();
    let isValidDate = this.isValidDate();
    if (isValidDate) {
      this.search()
    }
  }

  downloadCsv() {
    if (this.confirmDateRange()) {
      this.loading = true;
      this.disputeService.downloadCsvDisputed(this.filters)
        .subscribe(response => {
          let binaryData = ["\ufeff", response];
          let downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(<any>binaryData, { type: "application/json" }));
          let dateFormatPipe: DateFormatPipe = new DateFormatPipe();
          let date = dateFormatPipe.transform(new Date(), "YYYY-MM-DD-HH-mm");
          downloadLink.setAttribute('download', `Relatorio_Disputas_${date}.csv`);
          document.body.appendChild(downloadLink);
          downloadLink.click();
          this.actionLoading(false);
        },
          error => {
            this.actionLoading(false);
            this.alertService.error("Erro", "Ocorreu um erro interno em nosso servidor. Se o erro persistir, por favor, contate o suporte.");
          });
    }
  }

  public search() {
    this.loadingTab = true;
    this.disputeService.getAll(this.filters, this.pageable).subscribe(
      result => {
        this.loadingTab = false;
        this.showResult(result);
      },
      error => {
        this.alertService.handleError(error);
      }
    );
  }

  public alteraVariavel(id) {
    this.buttonEnabled = id;
    document.getElementById('todos').classList.remove('selecionado');
    document.getElementById('resolvidos').classList.remove('selecionado');
    document.getElementById('reembolsados').classList.remove('selecionado');
    document.getElementById('nao_reembolsados').classList.remove('selecionado');
    document.getElementById('nao_conciliados').classList.remove('selecionado');

    if (this.buttonEnabled == 'reembolsados') {
      this.filters.isRefunded = true;
      this.filters.isConciliated = null;
    }
    if (this.buttonEnabled == 'nao_reembolsados') {
      this.filters.isRefunded = false;
      this.filters.isConciliated = null;
    }
    if (this.buttonEnabled == 'nao_conciliados') {
      this.filters.isConciliated = false;
      this.filters.isRefunded = null;
    }
    if (this.buttonEnabled == 'resolvidos') {
      this.filters.isConciliated = true;
      this.filters.isRefunded = true;
    }
    if (this.buttonEnabled == 'todos') {
      this.filters.isConciliated = null;
      this.filters.isRefunded = null;
    }

    document.getElementById(this.buttonEnabled).classList.add('selecionado');
    this.filterParam();
  }


  showResult(result) {
    if (result != null) {
      this.pageResponse.content = result.content;
      this.pageResponse.pageNumber = result.pageable.pageNumber;
      this.pageResponse.totalElements = result.totalElements;
      this.pageResponse.totalPages = result.totalPages;
      this.disputes = this.pageResponse.content;
    } else {
      this.pageResponse.pageNumber = 0;
      this.pageResponse.totalElements = 0;
      this.pageResponse.totalPages = 0;
      this.disputes = new MatTableDataSource<DisputeResponse>();
    }
  }

  isValidDate() {
    this.alertDateValid = false;
    this.authorizationDateValid = false;
    if (this.enableAlertDate) {
      let minDate = new Date(this.endAlertDate.getFullYear(), this.endAlertDate.getMonth() - 1, this.endAlertDate.getDate());
      this.alertDateValid = minDate.getTime() <= this.startAlertDate.getTime();
    } else {
      this.startAlertDate = null;
      this.endAlertDate = null;
    }
    if (this.enableAutorizationDate) {
      let minDate = new Date(this.endAutorizationDate.getFullYear(), this.endAutorizationDate.getMonth() - 1, this.endAutorizationDate.getDate());
      this.authorizationDateValid = minDate.getTime() <= this.startAutorizationDate.getTime();
    } else {
      this.startAutorizationDate = null;
      this.endAutorizationDate = null;
    }
    this.isDateValid = ((this.enableAlertDate || this.enableAutorizationDate) && (this.alertDateValid || this.authorizationDateValid)) || (!this.enableAlertDate && !this.enableAlertDate);
    return this.isDateValid;
  }

  cleanFilter() {
    this.filters.startAuthorizationDate = this.formatDate(this.startAutorizationDate, this.enableAutorizationDate);
    this.filters.endAuthorizationDate = this.formatDate(this.endAutorizationDate, this.enableAutorizationDate);
    this.filters.startAlertDate = this.formatDate(this.startAlertDate, this.enableAlertDate);
    this.filters.endAlertDate = this.formatDate(this.endAlertDate, this.enableAlertDate);
  }

  private formatDate(date: Date, enable: boolean): Date {
    return enable ? date : null;
  }

  loadCardDash() {
    this.cardDashDanger.type = CardDashType.red;
    this.cardDashDanger.icon = "fas fa-exclamation-triangle";
    this.cardDashDanger.mainText = "No mês";
    this.cardDashDanger.mainValue = this.alertsAllCountCurrentMonth;
    this.cardDashDanger.minorText = "Total";
    this.cardDashDanger.minorValue = this.alertsAllCount;
    this.cardDashDanger.name = "Alertas Recebidos";
    this.cardDashDanger.nameIcon = "far fa-tired";

    this.cardDashSucess.type = CardDashType.green;
    this.cardDashSucess.icon = "fas fa-check";
    this.cardDashSucess.mainText = "No mês";
    this.cardDashSucess.mainValue = this.resolvedCurrentMonth;
    this.cardDashSucess.minorText = "Total";
    this.cardDashSucess.minorValue = this.resolvedCount;
    this.cardDashSucess.name = "Alertas resolvidos";
    this.cardDashSucess.nameIcon = "far fa-smile";

    this.cardDashOpen.type = CardDashType.yellow;
    this.cardDashOpen.icon = "fas fa-exclamation";
    this.cardDashOpen.mainText = "No mês";
    this.cardDashOpen.mainValue = (this.alertsAllCountCurrentMonth || 0) - (this.resolvedCurrentMonth || 0); 
    this.cardDashOpen.minorValue = (this.alertsAllCount || 0) - (this.resolvedCount || 0);
    this.cardDashOpen.minorText = "Total";
    this.cardDashOpen.name = "Alertas em aberto";
    this.cardDashOpen.nameIcon = "far fa-hand-paper";
  }

  toggleAllSelection(select: string) {
    switch (select) {
      case 'allSelectedType':
        if (this.allSelectedType.selected) {
          this.selectType.options.forEach((item: MatOption) => item.select());
        } else {
          this.selectType.options.forEach((item: MatOption) => item.deselect());
        }
        break;
      case 'allSelectedStatus':
        if (this.allSelectedStatus.selected) {
          this.selectStatus.options.forEach((item: MatOption) => item.select());
        } else {
          this.selectStatus.options.forEach((item: MatOption) => item.deselect());
        }
        break;
      default:
        break;
    }
  }

  optionClick(select: string) {
    switch (select) {
      case 'selectType':
        let cont = 0;
        this.selectType.options.forEach((item: MatOption) => item.selected ? cont++ : null);
        if (this.allSelectedType.selected) {
          this.allSelectedType.deselect();
          return false;
        }
        if (cont == this.selectType.options.length - 1) {
          this.allSelectedType.select();
        }
        break;
      case 'selectStatus':
        let cont1 = 0;
        this.selectStatus.options.forEach((item: MatOption) => item.selected ? cont1++ : null);
        if (this.allSelectedStatus.selected) {
          this.allSelectedStatus.deselect();
          return false;
        }
        if (cont1 == this.selectStatus.options.length - 1) {
          this.allSelectedStatus.select();
        }
        break;
      default:
        break;
    }
  }

  isEnableAlertDate() {
    return this.enableAlertDate;
  }


  isEnableAutorizationDate() {
    return this.enableAutorizationDate;
  }

}
