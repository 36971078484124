import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { CardRequest, CardRequestV1 } from "../../model/v1.model";


export class OnCard {


    constructor(private http: HttpClient) { }

    public createByDocument(document: string, card: CardRequest, token: string): Observable<any> {

        return this.http.post<any>(`${environment.apiUrl}/api/v1/customers/document/${document}/cards`, card, {
            observe: 'response',
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            })
        });
    }

    public create(customerId: number, card: CardRequestV1, token: string): Observable<any> {

        return this.http.post<any>(`${environment.apiUrl}/api/v1/customers/${customerId}/cards`, card, {
            observe: 'response',
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            })
        });
    }

    public getCardByUriWithToken(uri: string, token?: string): Observable<number> {
        let options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            })
        };
        return this.http.get<number>(`${uri}`, options);
    }

    public getCardByIdWithToken(id, token: string): Observable<number> {
        let options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            })
        };
        return this.http.get<number>(`${environment.apiUrl}/api/v1/`, options);
    }

    public getCardByUri(uri: string): Observable<any> {
        return this.http.get<any>(uri);
    }
}


