import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ClipboardService } from 'ngx-clipboard';
import { TransacaoService } from 'src/app/core/services/v0/transaction.service';
import { TransactionServiceV1 } from 'src/app/core/services/v1/transaction.service.v1';
import { environment } from "src/environments/environment";





@Component({
  selector: 'billing-rule-payment-page',
  templateUrl: './billing-rule-payment-page.component.html',
  styleUrls: ['./billing-rule-payment-page.component.scss']
})

export class BillingRulePaymentPage implements OnInit {
  /*  QrCode
   Data de expiração
   pix copia e cola 
   valor
   plano  
   "00020101021226770014BR.GOV.BCB.PIX2555api.itau/pix/qr/v2/6492c652-1bcb-449f-8263-3848770ecbb25204000053039865802BR5925ZOOP TECNOLOGIA E MEIOS D6009SAO PAULO62070503***630483B0
   */

  emv: string;
  image: string;
  longDate: number;
  dateFormated: Date;
  idCompany: number;
  idCharge: number;
  uri: string;
  value: number;
  planName: string;
  text: string;
  approved: boolean = false;


  constructor(
    private route: ActivatedRoute,
    private transactionService: TransacaoService,
    private transactionServiceV1: TransactionServiceV1,
    private clipboardService: ClipboardService
  ) {
  }

  ngOnInit() {
    this.text = 'Copiar código pix';
    let body: {};
    this.idCompany = parseInt(this.route.snapshot.paramMap.get('idCompany'));
    this.idCharge = parseInt(this.route.snapshot.paramMap.get('idCharge'));
    this.transactionServiceV1.billingRulePost(body, this.idCompany, this.idCharge)
      .subscribe(data => {

        if (data.body != null) {
          this.approved = true;
          this.emv = data.body.emv;
          this.longDate = data.body.expirationDate;
          this.dateFormated = new Date(this.longDate);
          this.value = data.body.value;
          this.planName = data.body.planName;
          this.image = `${environment.apiUrl}/api/v1/image/qrcode?code=${this.emv}`;
        }
        else {
          this.transactionServiceV1.getByUri(data.headers.get('Location'))
            .subscribe(response => {
              this.approved = false;
              this.emv = response.emv;
              this.longDate = response.expirationDate;
              this.value = response.value;
              this.planName = response.planName;
              this.image = `${environment.apiUrl}/api/v1/image/qrcode?code=${this.emv}`;
              this.dateFormated = new Date(this.longDate);
            });
        }
      });
    /* this.emv = '00020101021226770014BR.GOV.BCB.PIX2555api.itau/pix/qr/v2/6492c652-1bcb-449f-8263-3848770ecbb25204000053039865802BR5925ZOOP TECNOLOGIA E MEIOS D6009SAO PAULO62070503***630483B0'
    this.image=`${environment.apiUrl}/api/v1/image/qrcode?code=${this.emv}`; */

  }

  getText() {
    this.clipboardService.copyFromContent(this.emv);
    this.text = 'Copiado!'

  }
}
