import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from './alert.service';
@Injectable({
    providedIn: 'root'
})
export class ErrorsHandler {

    constructor(private alertService:AlertService, private router:Router){}

    public redirectError(redirectUrl:string, msg?:string){
        if(redirectUrl){
            this.alertService.error('Erro', msg).then(ok => {
                this.router.navigate([redirectUrl]);    
            });
            
        }else{
            this.router.navigate(['notallowed']);
        }
    }
}