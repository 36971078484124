import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { CustomCurrencyPipe } from 'src/app/core/helper/pipe/custom-currency.pipe';
import { PageResponse } from 'src/app/core/model/page-response';
import { Pageable } from 'src/app/core/model/pageable';
import { AlertChargebackStatus, DisputeFilter, DisputeResponse } from 'src/app/core/model/v1.model';
import { AlertService } from 'src/app/core/services/alert.service';
import { AppService } from 'src/app/core/services/v0/app.service';
import { DisputeService } from 'src/app/core/services/v1/dispute-service';
import * as moment from 'moment';
import { DateFormatPipe } from 'ngx-moment';

@Component({
  selector: 'app-disputed-list',
  templateUrl: './disputed-list.component.html',
  styleUrls: ['./disputed-list.component.scss']
})
export class DisputedListComponent implements OnInit {
  viewFilter = true;
  disputes = new MatTableDataSource<DisputeResponse>();
  displayedColumns: string[] =
    [ 'type',
      'authorizationDate',
      'alertDate',
      'card',
      'descriptor',
      'amount',
      'refunded',
      'status'
    ];

  pageResponse: PageResponse;
  public name$: Observable<string>;
  public pageable: Pageable;
  pageEvent: PageEvent;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  alertStatusList: AlertChargebackStatus[] = new Array();
  color = "alerts.status.not-found";
  loading: Boolean = false;
  rangeValid: boolean = true;
  loadingTab:boolean = true;
  filters: DisputeFilter;

  constructor(
    public translate: TranslateService,
    private alertService: AlertService,
    private disputeService: DisputeService,
    private appService: AppService
  ) { 
    translate.use('pt-br');

  }

  ngOnInit() {
    this.name$ = this.translate.stream('HOME.TITLE');
    this.pageResponse = {
      pageNumber: 0,
      totalPages: 0,
      pageSize: 10,
      totalElements: 0,
      content: null
    };

    this.appService.getAlertStatus().then(data => {
      this.alertStatusList = data.alertStatus;
    });
    this.pageable = { page: 0, size: 10 };
  }

  filterParam(filter: DisputeFilter) {
    this.filters = filter;
    this.search();
  }

  //paginação
  public handlePage(event?: PageEvent) {
    this.pageable.page = event.pageIndex;
    return event;
  }

  //ordenação
  public handleSort(event?: any) {
    this.pageable = { page: 0, size: 10 };
    this.pageable = Object.assign({}, this.pageable, { sort: `${event.active},${event.direction}` });
    this.search();
  }

  public search() {
    this.disputeService.getAll(this.filters, this.pageable).subscribe(
      result => {
        this.showResult(result);
      },
      error => {
        this.alertService.handleError(error);
      }
    );
  }

  showResult(result) {
    if (result != null) {
      this.pageResponse.content = result.content;
      this.disputes.paginator = this.paginator;
      this.disputes.sort = this.sort;
      this.pageResponse.pageNumber = result.pageable.pageNumber;
      this.pageResponse.totalElements = result.totalElements;
      this.pageResponse.totalPages = result.totalPages;
      this.disputes = this.pageResponse.content;
    } else {
      this.pageResponse.pageNumber = 0;
      this.pageResponse.totalElements = 0;
      this.pageResponse.totalPages = 0;
      this.disputes = new MatTableDataSource<DisputeResponse>();
    }
  }

  getStatusColor(status) {
    for (let i = 0; i < this.alertStatusList.length; i++) {
      if (status === this.alertStatusList[i].value) {
        let aux = this.alertStatusList[i].colorClass;
        return aux;
      }
    }
    return "bg-gray";
  }

  confirmDateRange(){
    let startAlertDate = moment(this.filters.startAlertDate);
    let endAlertDate = moment(this.filters.endAlertDate);
    let difference = endAlertDate.diff(startAlertDate, 'days');
    if(difference > 31){
      this.rangeValid = false;
    } else {
      this.filters.startAlertDate = this.filters.startAlertDate;
      this.filters.endAlertDate = this.filters.endAlertDate;
      this.rangeValid = true;
    }
    return this.rangeValid;
  }

  actionLoading( status:boolean ) {
    setTimeout( ()=>{
        this.loading = status;
        this.loadingTab =  status;
    } , 500 );
   
  }

  downloadCsv() {
    if (this.confirmDateRange()) {
      this.loading = true;
      this.disputeService.downloadCsvDisputed(this.filters)
      .subscribe(response => {
        let binaryData = ["\ufeff", response];
        let downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob(<any>binaryData, {type: "application/json"}));
        let dateFormatPipe: DateFormatPipe = new DateFormatPipe();
        let date = dateFormatPipe.transform(new Date(), "YYYY-MM-DD-HH-mm");
        downloadLink.setAttribute('download', `Relatorio_Disputas_${date}.csv`);
        document.body.appendChild(downloadLink);
        downloadLink.click();
        this.actionLoading( false );
      },
      error => {
        this.actionLoading( false );
        this.alertService.error("Erro", "Ocorreu um erro interno em nosso servidor. Se o erro persistir, por favor, contate o suporte.");
      });
    }
  }
}