
export class ChargeReportFilter{
    startLastChargeDate?: Date;
    endLastChargeDate?: Date;
    startPaymentDate?: Date;
    endPaymentDate?: Date;
    startDueDate?: Date;
    endDueDate?: Date;
    searchField?: string;
    status?: string[];
    affiliateIDs?: number[] = [];
    transactionSearch?: string;
}