import { Component, Input } from "@angular/core";
import { DataCard } from "src/app/core/model/data-card";

@Component({
    selector: 'mini-data-card',
    templateUrl: './mini-data-card.component.html',
    styleUrls: ['./mini-data-card.component.scss']
})
export class MiniDataCardComponent {
    @Input() data: DataCard;
}