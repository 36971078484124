<mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>

<div class="container" *ngIf="!loading">

  <div fxLayout="column" fxLayoutGap="10px" style="margin-bottom: 10px;">
    <div fxLayout="row">
      <div fxFlex fxLayout="row" fxLayoutAlign="start center">
        <p-breadcrumb id="breadcrumb" [model]="crumbs"></p-breadcrumb>
      </div>

      <div fxFlex fxLayout="row" fxLayoutAlign="end center">
        <!-- <button type="button" class="btn btn-primary" name="newLink" [routerLink]="['new']">+ Novo</button> -->
      </div>
    </div>
  </div>

  <!-- Filtros -->
  <div class="card mb-0 shadow-none" fxLayout="row" fxLayoutAlign="space-between stretch" *ngIf="viewFilter">

    <div fxLayout='column' fxFlex fxLayoutAlign="start stretch" fxLayout.lt-md="column"
      fxLayoutAlign.lt-md="space-around stretch" fxLayoutGap="10px">
      <div fxLayout='row' fxLayoutAlign="start stretch" fxLayout.lt-md="column"
        fxLayoutAlign.lt-md="space-around stretch" fxLayoutGap="10px">
        <mat-slide-toggle [(ngModel)]="enableDate">Por data</mat-slide-toggle>

        <!-- Pesquisa por data -->
        <div>
          <div fxFlex>
            <mat-form-field>
              <input matInput class="date" [matDatepicker]="startDate" readonly (click)="startDate.open()"
                [(ngModel)]="begin" placeholder="Data início:" [max]="end" disabled="{{!isEnable()}}">
              <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
              <mat-datepicker #startDate></mat-datepicker>
            </mat-form-field>
          </div>

          <div fxFlex>
            <mat-form-field>
              <input matInput class="date" [matDatepicker]="endDate" readonly (click)="endDate.open()" [(ngModel)]="end"
                placeholder="Data fim:" [min]="begin" [max]="begin30" disabled="{{!isEnable()}}">
              <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
              <mat-datepicker #endDate></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div fxLayout='row' fxLayoutAlign="start stretch" fxLayout.lt-md="column"
        fxLayoutAlign.lt-md="space-around stretch" fxLayoutGap="10px">
        <!-- Parametros -->
        <div fxFlex>
          <div fxFlex>
            <mat-form-field>
              <input matInput [(ngModel)]="filters.name" placeholder="Nome" id="nomeCliente" disabled="{{isEnable()}}">
            </mat-form-field>
          </div>

          <div fxFlex>
            <mat-form-field>
              <input matInput mask="000.000.000-00 || 00.000.000/0000-00" [(ngModel)]="filters.document"
                placeholder="CPF/CNPJ" id="cpfCliente" disabled="{{isEnable()}}">
            </mat-form-field>
          </div>

          <div fxFlex>
            <mat-form-field>
              <input matInput mask="000000" [(ngModel)]="filters.cardBin" placeholder="6 primeiros dígitos"
                id="primeirosDigitos" disabled="{{isEnable()}}">
            </mat-form-field>
          </div>

          <div fxFlex>
            <mat-form-field>
              <input matInput mask="0000" [(ngModel)]="filters.cardLast4" placeholder="4 últimos dígitos"
                id="ultimosDigitos" disabled="{{isEnable()}}">
            </mat-form-field>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="end center">
          <button mat-raised-button class="btn-search" (click)="filterParam()">Pesquisar</button>
        </div>
      </div>

    </div>

  </div>

  <div class="shadow-none" *ngIf="show" [ngClass]="{'hide' : pageResponse.totalElements == 0}"
    style="margin-top: 10px;">

    <div class="div-table">

      <table mat-table [dataSource]="clients" matSort id="tableCliente" (matSortChange)="handleSort($event)">
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Id </th>
          <td mat-cell *matCellDef="let row"> {{row.id}} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Nome </th>
          <td mat-cell *matCellDef="let row"> {{row.name}} </td>
        </ng-container>

        <!-- CPF Column -->
        <ng-container matColumnDef="document">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> CPF/CNPJ </th>
          <td mat-cell *matCellDef="let row"> {{row.document | mask: '000.000.000-00 || 00.000.000/0000-00'}} </td>
        </ng-container>

        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
          <td mat-cell *matCellDef="let row"> {{row.email}} </td>
        </ng-container>
      <!-- Actions -->

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row class="hover-row" (click)="detailClient(row.id)" matTooltip="Clique para detalhar o cliente"
          *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

    </div>

    <mat-paginator [ngClass]="{'hide' : pageResponse.totalElements == 0}" [pageIndex]="pageResponse.pageNumber"
      (page)="pageEvent = handlePage($event)" [pageSize]="pageResponse.pageSize" [length]="pageResponse.totalElements">
    </mat-paginator>
  </div>

  <div [ngClass]="{'hide' : pageResponse.totalElements > 0}" class="no-data-message">
    <span>Nenhum cliente encontrado</span>
  </div>

</div>
