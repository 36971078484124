<mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>

<div class="container" *ngIf="!loading">

    <div fxLayout="column" fxLayoutGap="10px" style="margin-bottom: 10px;">
        <div fxLayout="row">
            <div fxFlex fxLayout="row" fxLayoutAlign="start center">
                <p-breadcrumb id="breadcrumb" [model]="crumbs"></p-breadcrumb>
            </div>

            <div fxFlex fxLayout="row" fxLayoutAlign="end center">
                <button type="button" class="btn btn-primary" name="newLink" [routerLink]="['new']">+ Novo</button>
            </div>
        </div>
    </div>

    <!-- Filter begin -->
    <div class="card mb-0 shadow-none" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch">

        <div fxLayout='row' fxLayoutAlign="start stretch" fxLayout.lt-md="column"
            fxLayoutAlign.lt-md="space-around stretch" fxLayoutGap="10px">

            <div fxFlex="80">
                <mat-form-field class="input-nome">
                    <input matInput [(ngModel)]="name" placeholder="Nome" id="productName">
                </mat-form-field>
            </div>

        </div>

        <div fxFlex fxLayout="row" fxLayoutAlign="end center">
            <button mat-raised-button class="btn-search" name="search" (click)="search()">Pesquisar</button>
        </div>

    </div>

    <!-- data begin -->
    <div class="shadow-none" style="margin-top: 10px;">
        <table mat-table [dataSource]="paymentLinks" matSort id="productTable">
            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header id="tableHeaderId"> Id </th>
                <td mat-cell *matCellDef="let row; let i = index;" [attr.id]="'productsTdId_'+i"> {{ row.id }} </td>
            </ng-container>

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header id="tableHeaderName"> Nome </th>
                <td mat-cell *matCellDef="let row; let i = index;" [attr.id]="'productsTdName_'+i"> {{ row.name }} </td>
            </ng-container>

            <ng-container matColumnDef="amount">
                <th mat-header-cell *matHeaderCellDef mat-sort-header id="tableHeaderName"> Valor </th>
                <td mat-cell *matCellDef="let row; let i = index;" [attr.id]="'productsTdName_'+i"> {{row.amount |
                    currency:'BRL'}} </td>
            </ng-container>

            <ng-container matColumnDef="sold">
                <th mat-header-cell *matHeaderCellDef mat-sort-header id="tableHeaderName"> Vendas </th>
                <td mat-cell *matCellDef="let row; let i = index;" [attr.id]="'productsTdName_'+i"> {{ row.sold }}
                </td>
            </ng-container>
            <ng-container matColumnDef="active">
                <th mat-header-cell *matHeaderCellDef mat-sort-header id="tableHeaderName"> Status </th>
                <td mat-cell *matCellDef="let row">
                    <span class="badge bg-green" *ngIf="row.active">Ativo</span>
                    <span class="badge bg-gray" *ngIf="!row.active">Inativo</span>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="hover-row" matTooltipPosition="above"
                matTooltip="Clique para visualizar os detalhes" [routerLink]="['/paymentLinks/', row.id]">
            </tr>
            <!-- Row shown when there is no matching data. -->
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="5">Nenhum link encontrado!</td>
            </tr>
        </table>
        <mat-paginator [pageSizeOptions]="[10]"></mat-paginator>
    </div>
</div>