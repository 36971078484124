import { Component, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AuthService } from '../../core/auth/auth.service';
import { User } from '../../core/model/user';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() size: string;
  isLoggedIn$: Observable<boolean>;
  headerText: string;
  user: User = new User();

  constructor(public authService: AuthService, private router: Router, private activatedRoute: ActivatedRoute, private _snackBar: MatSnackBar) { }

  ngOnInit() {
    this.isLoggedIn$ = this.authService.isLoggedIn;
    this.router.events.subscribe((params: Params) => {
      this.user = this.authService.getCurrentUser();
      this.router.events.pipe(filter(event => event instanceof NavigationEnd))
        .pipe(
          map(() => {
            let child = this.activatedRoute.firstChild;
            while (child) {
              if (child.firstChild) {
                child = child.firstChild;
              } else if (child.snapshot.data && child.snapshot.data['header']) {
                return child.snapshot.data['header'];
              } else {
                return null;
              }
            }
            return null;
          })).subscribe((header: any) => {
            this.headerText = header;
          });
    });
  }

  onLogout() {
    this.authService.logout();
  }

}
