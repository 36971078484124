import { Component, OnInit, Input } from "@angular/core";
import { ImageCroppedEvent, ImageTransform } from "ngx-image-cropper";
import { ImageUploadService } from "src/app/core/services/v1/image-upload.service";

@Component({
    selector: 'app-image-upload',
    templateUrl: './image-upload.component.html',
    styleUrls: ['./image-upload.component.scss']
})
export class ImageUploadComponent implements OnInit {
    @Input()
    public CROP_WIDTH:number;
    @Input()
    public CROP_HEIGHT:number;
    @Input()
    public imageSrc:string;
    @Input()
    public disabled:boolean;
    imageChangedEvent: any;
    file: File;
    selectedImageSrc: string;
    croppedImage: any;
    scaleValue: number;
    imageTransform: ImageTransform;

    constructor(private imageUploadService:ImageUploadService,
    ) { }

    ngOnInit() {
        this.imageTransform = {};
        this.scaleValue = 1;
        this.imageChangedEvent = '';
    }

    onSelect(event) {
        this.imageChangedEvent = event;
        this.file = event.addedFiles[0];
    }

    async imageCropped(event: ImageCroppedEvent) {
        const newDataURI = await this.resizedataURL(event.base64, 400, 400);
        this.imageUploadService.imageCrop.emit(this.dataURItoBlob(newDataURI));
    }

    cancelCrop() {
        this.file = null;
        this.croppedImage = null;
        this.scaleValue = 1;
        this.imageUploadService.imageCrop.emit(null);
        this.transform();
    }
    zoomIn() {
        this.scaleValue += .1;
        this.transform();
    }
    zoomOut() {
        this.scaleValue -= .1;
        this.transform();
    }
    transform() {
        this.imageTransform = {
            ...this.imageTransform,
            scale: this.scaleValue
        };
    }

    dataURItoBlob(dataURI) {
        var byteString;
        if (dataURI.split(',')[0].indexOf('base64') >= 0)
            byteString = atob(dataURI.split(',')[1]);
        else
            byteString = unescape(dataURI.split(',')[1]);

        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        // write the bytes of the string to a typed array
        var ia = new Uint8Array(byteString.length);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ia], { type: mimeString });
    }



    // Takes a data URI and returns the Data URI corresponding to the resized image at the wanted size.
    resizedataURL(datas, wantedWidth, wantedHeight){
    return new Promise(async function(resolve,reject){

        // We create an image to receive the Data URI
        const img = document.createElement('img');

        // When the event "onload" is triggered we can resize the image.
        img.onload = function()
        {        
            // We create a canvas and get its context.
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            // We set the dimensions at the wanted size.
            canvas.width = wantedWidth;
            canvas.height = wantedHeight;

            // We resize the image with the canvas method drawImage();
            ctx.drawImage(img, 0, 0, wantedWidth, wantedHeight);

            const dataURI = canvas.toDataURL();

            // This is the return of the Promise
            resolve(dataURI);
        };

        // We put the Data URI in the image's src attribute
        img.src = datas;

    })
}
}