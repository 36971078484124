import { Directive } from "@angular/core";
import { Validator, ValidatorFn, UntypedFormControl, AbstractControl, NG_VALIDATORS } from "@angular/forms";
import { ValidationHelper } from "../validation.helper";

@Directive({
    selector: '[cpfValido]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: CpfValidator, multi: true }
      ]
  })
export class CpfValidator implements Validator {
  validator: ValidatorFn;
  
  constructor(public validationHelper:ValidationHelper) {
    this.validator = this.validateCpfFactory();
  }
  
  validate(c: UntypedFormControl) {
    return this.validator(c);
  }
  
  validateCpfFactory() : ValidatorFn {
      return (c: AbstractControl) => {
        //valida aqui
        if(c.value == "" || c.value == null){
          return {
            msg: "CPF é obrigatório"
          }
        }else{
          let cpfValido = this.validationHelper.validaCpf(c.value);
          if(!cpfValido){
            return {
              msg: "CPF inválido"
            }
          }
        }
      return null;
    }
  }
}