import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { NewCustomerRequest } from '../../model/request/new-customer-request';
import { NewCustomerResponse } from "../../model/response/new-customer-response";
import { OnAddress } from './on-address';
import { OnCard } from './on-card';

@Injectable({
  providedIn: 'root'
})
export class OnCustomer {

  constructor(private http: HttpClient) {
  }

  public create(customer, token?: string): Observable<any> {
    let header = new HttpHeaders();
    header = header.set("Authorization", "Bearer " + token);
    header = header.set("Access-Control-Allow-Origin", "*");
    header = header.set("Access-Control-Allow-Headers", "Location");
    return this.http.post<any>(`${environment.apiUrl}/api/v1/customers`, customer, { headers: header, observe: 'response' });
  }

  public createByCheckout(customer: NewCustomerResponse, token: string): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/api/v1/customers`, customer, {
      observe: 'response',
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    });
  }

  public update(customer: NewCustomerRequest, id: number, token?: string): Observable<any> {
    if (token) {
      let header = new HttpHeaders();
      header = header.set("Authorization", "Bearer " + token);
      return this.http.post<any>(`${environment.apiUrl}/api/v1/customers/${id}`, customer, { headers: header });
    }
    return this.http.put<any>(`${environment.apiUrl}/api/v1/customers/${id}`, customer);
  }

  public updateById(customer: NewCustomerResponse, id: number, token: string): Observable<any> {
    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.put<any>(`${environment.apiUrl}/api/v1/customers/${id}`, customer, options);
  }
  
  public getClientByURI(uri: string, token?: string): Observable<any> {
    let header = new HttpHeaders();
    header = header.set("Authorization", "Bearer " + token);
    return this.http.get<any>(`${uri}`);
  }

  public getByDocument(documento, token?: string): Observable<any> {
    if (token) {
      let header = new HttpHeaders();
      header = header.set("Authorization", "Bearer " + token);
      return this.http.get<any>(`${environment.apiUrl}/api/v1/customers/document/${documento}`, { headers: header });
    }
    return this.http.get<any>(`${environment.apiUrl}/api/v1/customers/document/${documento}`);
  }

  public isCustomer(document: string, token: string): Observable<any> {
    let header = new HttpHeaders();
    header = header.set("Authorization", "Bearer " + token);
    header = header.set("Access-Control-Allow-Origin", "*");
    header = header.set("Access-Control-Allow-Headers", "Location");
    return this.http.get<any>(`${environment.apiUrl}/api/v1/customers/document/${document}/exists`, { headers: header, observe: 'response' });
  }

  public getByUriWithToken(location: string, token: string): Observable<NewCustomerResponse> {
    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.get<NewCustomerResponse>(location, options);
  }

  public onAddress(): OnAddress{
    return new OnAddress(this.http);
  }

  public onCard(): OnCard{
    return new OnCard(this.http);
  }

}