<mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
<div class="container mb-4" *ngIf="!loading">
  <div fxLayout="column" fxLayoutGap="10px" style="margin-bottom: 10px;">
    <div fxLayout="row">
      <div fxFlex fxLayout="row" fxLayoutAlign="start center">
        <p-breadcrumb id="breadcrumb" [model]="crumbs"></p-breadcrumb>
      </div>
      <div fxFlex fxLayout="row" fxLayoutAlign="end center">
        <!-- buttons here -->
      </div>
    </div>
  </div>
  <mat-card>
    <div class="mb-4">
      <mat-card-title>Empresa</mat-card-title>
      <mat-card-subtitle>Configurações relacionadas a sua empresa</mat-card-subtitle>
      <div fxLayout="row" fxLayoutGap="15" class="mb-4">
        <mat-card class="card-button" [routerLink]="['users']">
          <i class="fas fa-user mb-2"></i>
          <mat-card-content>
            <p>Usuários</p>
          </mat-card-content>
        </mat-card>
      </div>
      <mat-divider></mat-divider>
    </div>
    <div class="mb-4">
      <mat-card-title>Pagamentos</mat-card-title>
      <mat-card-subtitle>Configurações relacionadas a pagamentos</mat-card-subtitle>
      <div fxLayout="row" fxLayoutGap="15" class="mb-4">
        <mat-card class="card-button" [routerLink]="['establishments']">
          <i class="fas fa-sitemap mb-2"></i>
          <mat-card-content>
            <p>Rotas de Pagamento</p>
          </mat-card-content>
        </mat-card>
      </div>
      <mat-divider></mat-divider>
    </div>
    <div class="mb-4">
      <mat-card-title>Integrações e API</mat-card-title>
      <mat-card-subtitle>Configurações relacionadas a integrações e API</mat-card-subtitle>
      <div fxLayout="row" fxLayoutGap="15" class="mb-4">
        <!-- <mat-card class="card-button" [routerLink]="['token']">
          <i class="fas fa-key mb-2"></i>
          <mat-card-content>
            <p>Token</p>
          </mat-card-content>
        </mat-card> -->
      </div>
    </div>
  </mat-card>
</div>
