
export class PlanResponse {
	id:number;
    accessionQty:number;
    active:boolean;
    name:string;
	description:string;
	sku:string;
	type:number;
	accessionValue:number;
	value:number;
	floatValue:number;
	frequency:number;
	chargeQty:number;
	expirationDays:number;
	chargeDays:number;
	trialDays:number;
	accessionLimitQty:number;
	overdueBillGenerate:boolean;
	hasDelivery:boolean;
	softDescriptor:string;
	chargeSameDocument:boolean;

}
