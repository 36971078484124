import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";


@Injectable({
    providedIn : 'root'
})
export class TokenService {

    constructor(private http: HttpClient) { }

    public createToken(){
        return this.http.post<any>(`${environment.apiUrl}/api/v1/tokenMaster`, null)
    }

    public getToken(){
        return this.http.get<any>(`${environment.apiUrl}/api/v1/tokenMaster`)
    }

}