import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class DataShare {

    public data: any;

    public constructor() { }

    

}