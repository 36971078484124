import { AddressRequest } from "./address.request"

export class CustomerRequest{

    areaCode:string
    birthDate:string
    email:string
    name:string
    phone:string
    document:string
    referenceId:string
    addresses:AddressRequest
    
}