import { HttpClient, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { lastValueFrom } from "rxjs";
import { environment } from "src/environments/environment";


@Injectable({
    providedIn: 'root'
})
export class OnetimeService {

    constructor(private http: HttpClient) {
    }


    async authorizeAndCapture(data): Promise<any> {
        try {
            const response: HttpResponse<any> = await lastValueFrom(this.http.post<any>(
                `${environment.apiUrl}/api/v2/charges/credit/card`,
                data,
                { observe: 'response' }
            ));

            if (response.status === 201 && response.headers.get('Location')) {
                const locationUrl = response.headers.get('Location');
                return lastValueFrom(this.http.get(locationUrl));
            }

            return response.body;
        } catch (error) {
            return Promise.reject(error);
        }
    }

}