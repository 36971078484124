import { Endereco } from "./endereco";

export class Client {
    ativo: boolean;
    bandeira: string;
    cartaoCvv: string;
    cartaoNumero: string;
    cartaoValidadeAno: number;
    cartaoValidadeMes: number;
    cpfCnpjCliente: string;
    cpfCnpjTitular: string;
    ddd: string;
    dataNascimentoFormatado: string;
    dtRegistroFormatado: string;
    dtNascimento: string;
    email: string;
    id: number;
    listaEnderecos: Array<Endereco>;
    nome: string;
    nomeTitular: string;
    telefone: string;
    idAdministradora:number;
}