export class CardRequest {

    idCustomer:number
    securityCode:string
    number:string
    expirationYear:string
    expirationMonth:string
    holderName:string
    holderDocument:string
    cardBrand:string
    isDefault:boolean

}