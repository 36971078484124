import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { toHttpParams } from "../../helper/http.helper";
import { UserRequest } from "../../model/request/user-request";
import { User } from "../../model/user";

@Injectable({
  providedIn: 'root'
})
export class UserService {


  private header: HttpHeaders;

  constructor(private http: HttpClient) {
    this.header = new HttpHeaders();
    this.header.set("Access-Control-Allow-Origin", "*");
    this.header.set("Access-Control-Allow-Headers", "Location");
  }

  public create(user: UserRequest): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/api/v2/users`, user, {
      headers: this.header, responseType: "json", observe: 'response'
    });
  }

  public update(id: number, user: UserRequest): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}/api/v2/users/${id}`, user);
  }

  public getByUri(uri: string): Observable<any> {
    return this.http.get<any>(uri);
  }

  public findById(id: number): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/api/v2/users/${id}`);
  }

  public findAll(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/api/v2/users`);
  }
  public resetPasswordByUsername(user): Observable<any> {
    let headers = { params: toHttpParams({ username: user }) };
    return this.http.post<any>(`${environment.apiUrl}/api/v2/users/reset-password`, null, headers);
  }

  public resetPasswordByUserId(user: User): Observable<any> {
    let teste = "";
    let headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    return this.http.put<any>(`${environment.apiUrl}/api/v2/users/${user.id}/reset-password`, teste, { responseType: 'text' as 'json' });
  }

  public updatePassword(email, senha): Observable<any> {
    let headers = { params: toHttpParams({ email: email, senha: senha }) };
    return this.http.post<any>(`${environment.apiUrl}/api/v2/users/edit`, null, headers);
  }

}
