import { Directive, Input } from "@angular/core";
import { AbstractControl, AsyncValidator, NG_ASYNC_VALIDATORS, ValidationErrors, ValidatorFn } from "@angular/forms";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { CardServiceV1 } from "../../services/v1/card.service.v1";

@Directive({
  selector: '[cardNumberValid]',
  providers: [
    { provide: NG_ASYNC_VALIDATORS, useExisting: CardNumberValidator, multi: true }
  ]
})
export class CardNumberValidator implements AsyncValidator {
  validator: ValidatorFn;
  @Input('token') token: string;

  constructor(private cardService: CardServiceV1) {
  }

  validate(c: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
    return this.cardService.validCardWithToken(c.value, this.token).pipe(
      map(isValid => {
        return isValid ? null : { 'cardNumberValid': true };
      })
    );
  }
}