import { Pipe } from "@angular/core";

// tslint:disable-next-line:use-pipe-transform-interface
@Pipe({
    name: "creditCardMask",
    pure: false
})
export class CardHidePipe {
    transform(plainCreditCard: string): string {
        let beginSection = plainCreditCard.slice(0, 4) + ' ' + plainCreditCard.slice(4, 6);
        let endSection = plainCreditCard.slice(6, 10);
        return beginSection + 'XX XXXX ' + endSection;
    }
}
