<mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>

<div class="container" *ngIf="!loading">

  <div fxLayout="column" fxLayoutGap="10px" style="margin-bottom: 10px;">
    <div fxLayout="row">
      <div fxFlex fxLayout="row" fxLayoutAlign="start center">
        <p-breadcrumb id="breadcrumb" [model]="crumbs"></p-breadcrumb>
      </div>

      <div fxLayout="row" fxLayoutAlign="end">
        <button name="newPlan" type="button" class="btn btn-primary" [routerLink]="['new']"> + Novo Plano
        </button>
      </div>
    </div>
  </div>

  <!-- Filter begin -->
  <div class="card mb-0 shadow-none" fxLayout="row" fxLayoutAlign="space-between stretch">

    <div style="width: 50%;" fxLayout='row' fxLayoutAlign="start stretch" fxLayout.lt-md="column" fxLayoutAlign.lt-md="space-around stretch"
      fxLayoutGap="10">

        <mat-form-field fxFlex="75">
          <input matInput [(ngModel)]="name" placeholder="Nome" id="productName">
        </mat-form-field>

    </div>
    <div fxFlex fxLayout="row" fxLayoutAlign="end center">
      <button mat-raised-button class="btn-search" name="search" (click)="search()">Pesquisar</button>
    </div>
  </div>

  <!-- data begin -->
  <div class="content" fxLayout='column' fxLayoutGap="10px" style="margin-top: 10px;">
    <div fxLayout='column' fxLayout.lt-md="column" fxLayoutGap="10px">
      <div fxLayout="column">
        <table mat-table [dataSource]="plans" matSort id="plansTable">
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header id="tableHeaderId"> Id </th>
            <td mat-cell *matCellDef="let row; let i = index;" [attr.id]="'plansTdId_'+i"> {{ row.id }} </td>
          </ng-container>

          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header id="tableHeaderName"> Nome </th>
            <td mat-cell *matCellDef="let row; let i = index;" [attr.id]="'plansTdName_'+i"> {{ row.name }} </td>
          </ng-container>

          <ng-container matColumnDef="frequency">
            <th mat-header-cell *matHeaderCellDef mat-sort-header id="tableHeaderFrequency"> Periodicidade </th>
            <td mat-cell *matCellDef="let row; let i = index;" [attr.id]="'plansTdFrequency_'+i">
              {{ row.frequency | translate }} </td>
          </ng-container>

          <ng-container matColumnDef="value">
            <th mat-header-cell *matHeaderCellDef mat-sort-header id="tableHeaderValue"> Valor </th>
            <td mat-cell *matCellDef="let row; let i = index;" [attr.id]="'plansTdValue_'+i">
              {{ row.value | CustomCurrency:'BRL' }} </td>
          </ng-container>

          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header id="tableHeaderName"> Status </th>
            <td mat-cell *matCellDef="let row">
              <span class="badge bg-green" *ngIf="row.active">Ativo</span>
              <span class="badge bg-gray" *ngIf="!row.active">Inativo</span>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" id="planToolTip" class="hover-row"
            matTooltipPosition="above" matTooltip="Clique para visualizar os dados do plano" (click)="goToPlan(row)">
          </tr>
        </table>

        <mat-paginator [pageSizeOptions]="[10]"></mat-paginator>
      </div>
    </div>
  </div>
</div>