import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-show-environment',
  templateUrl: './show-environment.component.html',
  styleUrls: ['./show-environment.component.scss']
})
export class ShowEnvironmentComponent implements OnInit {
  enviroment: string;

  constructor() { }

  ngOnInit(): void {
    environment.production ? this.enviroment = 'production' : null;
  }

}
