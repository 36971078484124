import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Charge } from 'src/app/core/model/v2.model';
import { AlertService } from 'src/app/core/services/alert.service';
import { OnChargeV2 } from 'src/app/core/services/v2/charge.service.v2';

@Component({
  selector: 'app-capture-charge',
  templateUrl: './capture-charge.component.html',
  styleUrls: ['./capture-charge.component.scss']
})
export class CaptureChargeComponent implements OnInit {
  loading: boolean = false;

  constructor(
    private onChargeV2: OnChargeV2,
    private alertService: AlertService,
    public dialogRef: MatDialogRef<CaptureChargeComponent>,
    @Inject(MAT_DIALOG_DATA) public charge: Charge,
  ) { }

  ngOnInit(): void {
  }

  public captureCharge() {
    this.loading = true;
    this.onChargeV2.captureCharge(this.charge.id).subscribe(result => {
      this.alertService.success('Sucesso', 'Venda capturada com sucesso').then(() => this.dialogRef.close(true));
    },
      error => {
        this.alertService.error('Erro', error.error.message);
        this.loading = false;
      });
  }

}
