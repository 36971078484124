<form #editEcForm="ngForm" fxLayout="column">
  <div *ngFor="let field of establishment.credentials">
    <mat-form-field *ngIf="field != null" [hidden]="field.isReadOnly">
      <mat-label>{{("establishment.field."+field.field) | translate}}</mat-label>
      <input matInput [placeholder]="('establishment.field.'+field.field) | translate" [(ngModel)="{{field.value}}"
        [name]="field.field" value="{{field.value}}" required>
      <mat-error>{{ ("establishment.field."+field.field) | translate }} é obrigatório</mat-error>
    </mat-form-field>
  </div>

  <strong style="padding-bottom: 20px;">Outras informações</strong>

  <div fxFlex="30" class="secondary-data" *ngIf="verifyMethod('CREDIT_CARD')">
    <mat-form-field class="example-full-width" appearance="fill">
      <mat-label>Soft descriptor</mat-label>
      <input matInput #softDescriptor maxlength="13" placeholder="Informe soft descriptor"
        [(ngModel)]="establishment.softDescriptor" name="softDescriptor">
      <mat-hint align="end">{{softDescriptor.value.length}} / 13</mat-hint>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Priority</mat-label>
      <mat-select placeholder="Priority" [(ngModel)]="establishment.priority" name="priority" required>
        <mat-option [value]="Priority.MAIN">Principal</mat-option>
        <mat-option [value]="Priority.OVERFLOW">Transbordo</mat-option>
      </mat-select>
      <mat-error>Priority is required</mat-error>
    </mat-form-field>
  </div>
  <div class="secondary-data" *ngIf="verifyMethod('CREDIT_CARD')">
    <span fxFlex>
      <mat-slide-toggle class="secondary-data subtitle" [(ngModel)]="establishment.requireSecurityCode"
        [ngModelOptions]="{standalone: true}">{{ establishment.requireSecurityCode ?
        "CVV Obrigatório": "CVV Opcional" }}
      </mat-slide-toggle>
    </span>
  </div>
  <div fxFlex="50">
    <div class="secondary-data">
      <mat-slide-toggle class="secondary-data subtitle" [(ngModel)]="establishment.active"
        [ngModelOptions]="{standalone: true}">{{ establishment.active ?
        "Ativo": "Inativo" }}</mat-slide-toggle>
    </div>
  </div>
  <mat-card-actions class="pl-2">
    <button mat-raised-button color="primary" (click)="update()">Salvar</button>
    <button mat-raised-button color="warn" (click)="cancel()">Cancelar</button>
  </mat-card-actions>
</form>