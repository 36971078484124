import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MenuItem } from 'primeng/api';
import { DataShare } from "src/app/core/helper/provider/data-share";
import { Perfil, UserProfile } from "src/app/core/model/perfil";
import { UserRequest } from "src/app/core/model/request/user-request";
import { User, UserStatusType } from "src/app/core/model/user";
import { PagePermissions } from "src/app/core/permissions/page-permissions";
import { AlertService } from "src/app/core/services/alert.service";
import { PerfilService } from "src/app/core/services/v0/perfil.services";
import { UserService } from "src/app/core/services/v0/user.service";

@Component({
  selector: 'app-user-details-edit',
  templateUrl: './user-details-edit.component.html',
  styleUrls: ['./user-details-edit.component.scss']
})
export class UserDetailsEditComponent implements OnInit {
  loading: boolean = true;
  editPermission = PagePermissions.USER_EDIT;
  profiles: UserProfile[];
  profile: UserProfile;
  perfis: Perfil[];
  perfil: Perfil;
  user: User;
  userStatus: boolean;
  @ViewChild("userEditForm") userEditForm;
  crumbs: MenuItem[];

  constructor(private alertService: AlertService, private route: ActivatedRoute, private userService: UserService, private dataShare: DataShare, private router: Router, private perfilService: PerfilService) {
  }

  ngOnInit(): void {
    if (this.dataShare.data) {
      this.user = this.dataShare.data;
      if (this.user.status == null) {
        this.user.ativo = true;
      } else {
        this.user.ativo = this.user.status == UserStatusType.ACTIVE ? true : false;
      }
    } else {
      this.router.navigate(['notallowed']);
    }
    this.getProfiles();
    this.initCrumbs();
  }

  public initCrumbs() {
    if (this.user.id == null) {
      this.crumbs = [
        { label: 'Usuários', routerLink: ['/settings/users'] },
        { label: 'Novo' }
      ];
    } else {
      this.crumbs = [
        { label: 'Usuários', routerLink: ['/settings/users'] },
        { label: this.user.id, routerLink: ['/settings/users/details/', this.user.id] },
        { label: 'Editar' }
      ];
    }

  }

  onSubmit() {
    if (this.user.profiles.length <= 0) {
      this.alertService.error('Erro', "Selecione os perfis de acesso!");
    }
    if (this.userEditForm.valid && this.user.profiles.length > 0) {
      this.loading = true;
      let request: UserRequest = new UserRequest();
      request.name = this.user.name;
      request.email = this.user.email;
      request.profilesId = [];
      for (let profile of this.user.profiles) {
        request.profilesId.push(profile.id);
      }
      if (this.user.id) {
        request.status = this.user.ativo ? UserStatusType.ACTIVE : UserStatusType.INACTIVE;
        request.username = this.user.username;
        this.edit(request);
      } else {
        request.username = this.user.email;
        this.create(request);
      }
    }
  }

  cancel() {
    if (this.user.id) {
      this.router.navigate(['/settings/users/details/', this.user.id]);
    } else {
      this.router.navigate(['/settings/users']);
    }

  }

  public async create(request: UserRequest) {
    const response = await this.userService.create(request);
    response.subscribe(result => {
      if (result.status == 201) {
        this.getUserDetails(result.headers.get('Location'));
      } else {
        this.alertService.error('Erro', "");
      }
      this.loading = false;
    },
      error => {
        this.alertService.error('Erro', error.error[0].message);
        this.loading = false;
      });
  }

  private edit(request: UserRequest) {
    this.userService.update(Number(this.user.id), request).subscribe(result => {
      if (result) {
        this.alertService.success('Sucesso', 'Usuário editado com sucesso.').then(success => {
          this.dataShare.data = null;
          this.router.navigate(['/settings/users/details/' + this.user.id]);
        });
      } else {
        this.alertService.error('Erro', result.message);
      }
      this.loading = false;
    },
      error => {
        this.alertService.error('Erro', 'Erro nos dados do usuário. \n' + error.error.message);
        this.loading = false;
      });
  }

  private getUserDetails(location: string) {
    this.userService.getByUri(location).subscribe(response => {
      this.router.navigate(['settings/users/details/', response.id]);
    });
  }

  private getProfiles() {
    this.perfilService.findAll().subscribe({
      next: (result) => {
        if (result) {
          const data = result;
          data.sort((a, b) => a.nome.localeCompare(b.nome));
          this.perfis = data;
          this.loading = false;
        }
      },
      error: (err) => {
        this.loading = false;
      }
    });
  }

  getPermissoes(perfil: Perfil) {
    let labels = [];
    // for (let role of perfil.roles) {
    //   labels.push(role.label);
    // }
    // labels.join(", ");
    return labels;
  }

  addPerfil(perfil: Perfil) {
    const existingProfile = this.user.profiles.find(p => p.id === perfil.id);
    if (!existingProfile) {
      this.user.profiles.push(new UserProfile(perfil));
    }
  }

  removePerfil(perfil: Perfil) {
    if (perfil.tipo != 2) {
      let indexOf = this.user.profiles.indexOf(new UserProfile(perfil));
      if (indexOf > -1) this.user.profiles.splice(indexOf, 1);
    }
  }

}
