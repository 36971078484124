<div id="imageUpload" fxLayout="column" fxLayoutAlign="center">
    <div fxFlex="100" fxLayoutAlign="row" fxLayoutAlign="center">
        <div *ngIf="!file">
            <ngx-dropzone style="width: 400px; height: 220px; justify-content: center;" [disabled]="disabled" (change)="onSelect($event)" id="imageDropzone"
                fxLayout="column">
                <ngx-dropzone-label>
                    <i class="fa fa-camera fa-5x" *ngIf="!imageSrc"></i>
                    <img [attr.src]="imageSrc" style="width: auto; height: auto; max-width: 350px; max-height: 200x; border-radius: 20px;" *ngIf="imageSrc"/>
                </ngx-dropzone-label>
                <label style="font-size: small; font-style: italic; color: #ACACAC;">Clique para
                    selecionar ou arraste aqui a imagem desejada.</label>
            </ngx-dropzone>
        </div>
        <div fxLayout="column" *ngIf="file" fxLayoutAlign="center">
            <div fxLayout="row" fxLayoutGap="5px" style="justify-content: space-between;">
                <div fxLayoutGap="5px">
                    <button class="btn btn-dark" type="button" id="zoomOut" (click)="zoomOut()"><i
                            class="fa fa-search-minus" aria-hidden="true"></i></button>
                    <button class="btn btn-dark" type="button" id="zoomIn" (click)="zoomIn()"><i
                            class="fa fa-search-plus" aria-hidden="true"></i></button>
                </div>
                <div>
                    <button class="btn btn-dark" type="button" id="cancelCrop" (click)="cancelCrop()"><i
                            class="fa fa-trash" aria-hidden="true"></i></button>
                </div>
            </div>
            <image-cropper style="max-height: 400px;" [imageFile]="file" format="png"
                (imageCropped)="imageCropped($event)" [cropperStaticWidth]="CROP_WIDTH"
                [cropperStaticHeight]="CROP_HEIGHT" [transform]="imageTransform">
            </image-cropper>
        </div>
    </div>
</div>