
<mat-list class="mb-4 mt-4">
  <mat-list-item *ngFor="let brand of brandsList" class="hover-bg">


    <div fxLayout="row" fxLayoutGap="20">
      <div>
        <img name="imgAcquirer" class="adquirente" style="max-width: 35px;"
        src="assets/img/brands/{{brand.nome.toLowerCase()}}.png" />
      </div>
      <div fxLayout='row' fxLayoutGap="20" *ngIf="!editMode">
        <div fxFlex>Prioridade 1</div>
        <div fxFlex>Prioridade 2</div>
        <div fxFlex>Prioridade 3</div>
      </div>
    </div>


    <div *ngIf="editMode" class="ml-4">
      <div fxFlex>
        <mat-form-field>
          <mat-label>Prioridade 1</mat-label>
          <!-- <mat-select-trigger>
          <img name="imgAcquirer" class="adquirente"
          src="assets/img/acquirers/semmargem/{{establishment.nomeAdquirente.toLowerCase()}}.svg" />
        </mat-select-trigger> -->
          <mat-select placeholder="Prioridade 1" [(ngModel)]="paymantRoute.establishmentId1" name="establishment1"
            required>
            <mat-option *ngFor=" let establishment of establishmentList " [value]="establishment.idEstabelecimento">
              <img name="imgAcquirer" style="max-width: 45px;"
                src="assets/img/acquirers/semmargem/{{establishment.idAdquirente}}.svg" />
              {{establishment.nomeFantasia}}</mat-option>
          </mat-select>
          <mat-error>A prioridade 1 é obrigatória</mat-error>
        </mat-form-field>
      </div>

      <div fxFlex>
        <mat-form-field>
          <mat-label>Prioridade 2</mat-label>
          <mat-select placeholder="Prioridade 2" [(ngModel)]="paymantRoute.establishmentId2" name="establishment2">
            <mat-option *ngFor=" let establishment of establishmentList " [value]="establishment.idEstabelecimento">
              {{establishment.nomeFantasia}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div fxFlex>
        <mat-form-field>
          <mat-label>Prioridade 3</mat-label>
          <mat-select placeholder="Prioridade 3" [(ngModel)]="paymantRoute.establishmentId3" name="establishment3">
            <mat-option *ngFor=" let establishment of establishmentList " [value]="establishment.idEstabelecimento">
              {{establishment.nomeFantasia}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>


    <mat-divider></mat-divider>





  </mat-list-item>
</mat-list>
