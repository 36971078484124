import { Component } from '@angular/core';
import { BaseTableComponent } from 'src/app/base/table/base-table/base-table.component';
import { AccountServiceV2 } from 'src/app/core/services/v2/account.service.v2';

@Component({
  selector: 'app-transfers-table',
  templateUrl: './transfers-table.component.html',
  styleUrls: ['./transfers-table.component.scss']
})
export class TransfersTableComponent extends BaseTableComponent {

  constructor(accountServiceV2: AccountServiceV2) {
    super(accountServiceV2);
    this.displayedColumns = ['date', 'status', 'amount'];
  }

  loadData(sellerId: any): void {
    this.accountService.getTransfers(sellerId, this.pageable).subscribe(response => {
      this.data = response.items;
      if (response.items.length > 0) {
        this.pageResponse.pageNumber = response.page;
        this.pageResponse.totalPages = response.totalPages;
        this.pageResponse.totalElements = response.total;
        this.pageResponse.pageSize = response.limit;
        this.pageResponse.content = response.items;
      } else {
        this.pageResponse.pageNumber = 0;
        this.pageResponse.totalPages = 0;
        this.pageResponse.totalElements = 0;
        this.pageResponse.pageSize = 0;
        this.pageResponse.content = [];
      }
    });
  }

}
