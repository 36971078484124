import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TransactionFilter } from '../../core/common/filters/transaction.filter';
import { Transaction } from '../../core/model/transaction';
import { TransacaoService } from '../../core/services/v0/transaction.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {
  filtersOpen = false;
  filters: TransactionFilter;
  rangeValid = true;
  transactions = new MatTableDataSource<Transaction>();
  show = true;
  
  end = new Date(Date.now());
  begin = new Date(Date.now());
  displayedColumns: string[] = [
    'id', 
    "codRetorno",
    'nomeCliente', 
    'estabelecimento',
    'dtRegistroFormatado', 
    'valor', 
    'bandeira', 
    'statusStr'];
  

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private transactionService: TransacaoService) {
   
  }


  ngOnInit() {
   this.begin.setMonth(this.begin.getMonth() - 3);
   this.filters.status = 0;
  //  this.filter();
  }

  // filter() {
  //   this.filters.dateStart = Date.parse(this.begin.toString());
  //   this.filters.dateEnd = Date.parse(this.end.toString());
  //   if (this.confirmDateRange()) {
  //     this.transactionService.getAllTransactions(this.filters.dateStart, this.filters.dateEnd, this.filters.status)
  //   .subscribe(result => {
  //     this.transactions = new MatTableDataSource<Transaction>(result);
  //     this.transactions.paginator = this.paginator;
  //     this.transactions.data.length > 0 ?  this.show = true : this.show = false;});
  //   }
  // }


  toggleFilters() {
    this.filtersOpen = !this.filtersOpen;
  }


}