<p-breadcrumb id="breadcrumb" [model]="crumbs"></p-breadcrumb>
<div class="container">
    <form fxLayout="column" fxLayoutGap="10px" #planForm="ngForm" name="planForm" (ngSubmit)="create()">
        <card-box class="card" title="Detalhes do Plano">
            <div fxLayout="column" id="divInfoPrincipal" fxLayoutGap="5px">
                <app-image-upload></app-image-upload>
                <div fxFlex>
                    <mat-form-field>
                        <input matInput placeholder="Nome" name="name" id="name" [(ngModel)]="this.plan.name" required>
                        <mat-error id="nameError">Nome é obrigatório</mat-error>
                    </mat-form-field>
                </div>
                <div fxFlex>
                    <mat-form-field>
                        <input matInput placeholder="Valor da adesão" currencyMask
                            [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', allowNegative: false }"
                            maxlength="13" max="999999.99" id="defaultValue" name="value" [(ngModel)]="this.plan.accessionValue">
                    </mat-form-field>
                </div>

                <div fxFlex>
                    <mat-form-field>
                        <input matInput placeholder="Período de avaliação (dias)" [(ngModel)]="this.plan.trialDays" name="value">
                    </mat-form-field>
                </div>

                <div fxFlex>
                    <mat-form-field>
                        <input matInput placeholder="Valor" currencyMask
                            [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', allowNegative: false }"
                            maxlength="13" max="999999.99" id="defaultValue" name="value" [(ngModel)]="this.plan.value"
                            required>
                        <mat-error id="valueError">Valor é obrigatório</mat-error>
                    </mat-form-field>
                </div>
                <div fxFlex>
                    <mat-form-field>
                        <mat-select placeholder="Periodicidade" id="frequency" name="frequency"
                            [(ngModel)]="this.plan.frequency" (selectionChange)="refreshInstallments()" required>
                            <mat-option *ngFor=" let frequency of frequencies" [value]="frequency">
                                {{frequency | translate }}</mat-option>
                        </mat-select>
                        <mat-error id="frequencyError">Periodicidade é obrigatória</mat-error>
                    </mat-form-field>
                </div>
                <div fxFlex>
                    <mat-slide-toggle name="chargeIndefinitely" [(ngModel)]="chargeIndefinitely" id="toggleCharges">
                        Cobrar até que a assinatura seja cancelada</mat-slide-toggle>
                    <mat-form-field *ngIf="!chargeIndefinitely">
                        <input matInput placeholder="Quantidade de Cobranças" mask="99999" name="charges" id="charges"
                            [(ngModel)]="this.plan.charges" required>
                        <i matSuffix class="fal fa-question-circle"
                            matTooltip="Informe o número de cobranças que o Bempaggo deve efetuar"></i>
                        <mat-error id="chargesError">Quantidade de Cobranças é obrigatória</mat-error>
                    </mat-form-field>
                </div>
            </div>
        </card-box>
        <card-box class="card" title="Detalhes da Cobrança">
            <div fxLayout="column" fxLayoutGap="5px">
                <!--
                    <div fxFlex>
                    <mat-form-field>
                        <mat-select placeholder="Tipo de Plano" name="type" id="type" [(ngModel)]="this.plan.type"
                            (selectionChange)="refreshInstallments()" required>
                            <mat-option *ngFor=" let type of types" [value]="type">{{type | translate }}</mat-option>
                        </mat-select>
                        <mat-error id="typeError">Tipo de Plano é obrigatório</mat-error>
                    </mat-form-field>
                </div>
            -->
                <div fxFlex>
                    <div fxLayout="column" fxLayoutGap="0px">
                        <mat-form-field>
                            <input matInput placeholder="Código de referência (SKU)" maxlength="40" id="sku" name="sku"
                                #sku="ngModel" [(ngModel)]="this.plan.sku" skuUnique>
                            <i class="fal fa-question-circle" matTooltip="Código identificador único do produto."
                                matSuffix></i>
                            <mat-error id="skuError" *ngIf="sku.invalid && (sku.dirty || sku.touched)">
                                SKU indisponível
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div fxFlex>
                    <mat-form-field>
                        <input matInput placeholder="Identificação na Fatura" maxlength="13"
                            [patterns]="alphanumericPattern" mask="aaaaaaaaaaaaa" id="softDescriptor"
                            name="softDescriptor" [(ngModel)]="this.plan.softDescriptor">
                        <i matSuffix class="fal fa-question-circle"
                            matTooltip="No caso da forma de pagamento ser cartão de crédito, informe qual a identificação deverá ser mostrada na fatura."></i>
                    </mat-form-field>
                </div>
                <div fxFlex>
                    <mat-form-field>
                        <mat-select placeholder="Parcelamento" name="maxInstallments" id="maxInstallments"
                            [(ngModel)]="this.plan.maxInstallments" required>
                            <mat-option [value]="1">Crédito à vista (sem parcelamento)</mat-option>
                            <mat-option *ngFor=" let installment of installments" [value]="installment">Até
                                {{installment}} parcelas</mat-option>
                        </mat-select>
                        <mat-error id="maxInstallmentsError">Parcelamento é obrigatório</mat-error>
                    </mat-form-field>
                </div>
            </div>
        </card-box>
        <div fxLayout="row" fxLayoutAlign="end" class="actions-buttons">
            <button mat-raised-button class="btn-cancel" type="button" id="cancel" (click)="cancel()">Cancelar</button>
            <button mat-raised-button class="btn-confirm" type="submit" id="save"
                [disabled]="!form.valid">Salvar</button>
        </div>

        <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="end">
        </div>
    </form>
</div>
