<mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
<div class="container inside-container">

  <div class="card shadow-none" fxLayout='row' fxLayout.lt-md="column" fxLayoutAlign="space-between stretch">
    <div fxLayout='column' fxLayout.lt-md="column" fxLayoutGap="20px">

      <div fxLayout='row' fxLayout.lt-md="column" fxLayoutGap="20px">
        <div>
          <mat-form-field>
            <input matInput [matDatepicker]="start" readonly (click)="start.open()" [(ngModel)]="startDate"
              placeholder="Início">
            <mat-datepicker-toggle matSuffix [for]="start"></mat-datepicker-toggle>
            <mat-datepicker #start></mat-datepicker>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field>
            <input matInput [matDatepicker]="end" readonly (click)="end.open()" [(ngModel)]="endDate" placeholder="Fim">
            <mat-datepicker-toggle matSuffix [for]="end"></mat-datepicker-toggle>
            <mat-datepicker #end></mat-datepicker>
          </mat-form-field>
        </div>
      </div>

      <div fxLayout='row' fxLayout.lt-md="column" fxLayoutGap="20px">
        <div>
          <form [formGroup]="searchAffiliateForm">
            <mat-form-field>
              <mat-select class="opcoesFiltro" placeholder="Loja" formControlName="orderAffiliate"
                [compareWith]="compareId" multiple [(ngModel)]="filters.affiliateIDs">
                <mat-option #allSelectedForAffiliate (click)="toggleAllSelectionForAffiliate()" [value]="0">Todos
                </mat-option>
                <mat-option *ngFor="let affiliate of affiliate" [value]="affiliate.id"
                  (click)="tosslePerOneForAffiliate(allSelectedForAffiliate.viewValue)">
                  {{affiliate.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </form>
        </div>

        <div fxFlex>
          <form [formGroup]="searchPlansForm">
            <mat-form-field>
              <mat-select class="opcoesFiltro" placeholder="Planos" [(ngModel)]="filters.planIDs" multiple
                [compareWith]="compareId" formControlName="orderPlans">
                <mat-option #allSelectedForPlans (click)="toggleAllSelectionForPlans()" [value]="0">Todos
                </mat-option>
                <mat-option *ngFor="let plano of planos" [value]="plano.id"
                  (click)="tosslePerOneForPlans(allSelectedForPlans.viewValue)">
                  {{plano.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </form>
        </div>

        <div fxFlex>
          <form [formGroup]="searchReasonForm">
            <mat-form-field>
              <mat-select class="opcoesFiltro" placeholder="Motivo" formControlName="refundReason"
                [(ngModel)]="filters.refundReason" [compareWith]="compareId" multiple>
                <mat-option #allSelectedForReason (click)="toggleAllSelectionForReason()" [value]="0">Todos
                </mat-option>
                <mat-option *ngFor="let aReason of refundReasons" [value]="aReason"
                  (click)="tosslePerOneForReason(allSelectedForReason.viewValue)">
                  {{aReason.description | translate}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </form>
        </div>
      </div>
      <!--  <div fxFlex="65">
        <mat-form-field>
          <input matInput [(ngModel)]="filters.transactionSearch" placeholder="Busque por TID, NSU, número do pedido ou número de autorização"
            >
        </mat-form-field>
      </div> -->
    </div>

    <div fxLayout="row" fxLayoutAlign="end center">
      <button mat-raised-button class="btn-search" (click)="filter()">
        Pesquisar
      </button>
    </div>
  </div>

  <div [ngClass]="{'hide' : pageResponse.totalElements == 0}">
    <div class="export">
      <a class="export-link" (click)="downloadCsv()">
        <span class="export-label">Exportar</span>
        <i class="fa fa-arrow-circle-down" aria-hidden="true"></i>
      </a>
    </div>
    <div class="div-table">
      <table mat-table [dataSource]="transactions" multiTemplateDataRows matSort style="width: 100%;">
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
          <td mat-cell *matCellDef="let row"> {{row.id}} </td>
        </ng-container>
        <ng-container matColumnDef="installments">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Cobrança</th>
          <td mat-cell *matCellDef="let row"> {{row.installments}} </td>
        </ng-container>
        <ng-container matColumnDef="card">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Modalidade</th>
          <td mat-cell *matCellDef="let row">
            <div class="bandeira">
              <img src="assets/img/brands/{{row.lastTransaction.cardBrand.toLowerCase()}}.png"> {{row.installments <= 1
                ? 'Crédito à Vista' : 'Crédito ' +row.installments+'x'}} </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="value">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Valor</th>
          <td mat-cell *matCellDef="let row"> {{row.lastTransaction.amount | CustomCurrency:'BRL'}} </td>
        </ng-container>
        <ng-container matColumnDef="customer.name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Nome</th>
          <td mat-cell *matCellDef="let row"> {{row.customer.name}} </td>
        </ng-container>
        <ng-container matColumnDef="refundDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Data do estorno</th>
          <td mat-cell *matCellDef="let row"> {{row.lastTransaction.transactionDate | date: 'dd/MM/yyyy HH:mm:ss'}} </td>
        </ng-container>
        <ng-container matColumnDef="refundReason">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Motivo</th>
          <td mat-cell *matCellDef="let row"> {{row.refundReason}} </td>
        </ng-container>
        <ng-container matColumnDef="user">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Usuário</th>
          <td mat-cell *matCellDef="let row"> {{row.user.name}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>



        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="hover-row" matTooltipPosition="above"
            matTooltip="Clique para visualizar os detalhes" [routerLink]="['/sales/sales-details/', row.id]"></tr>

      </table>
    </div>

    <mat-paginator [length]="pageResponse.totalElements" [pageSize]="pageResponse.pageSize"
      (page)="pageEvent = handlePage($event)" aria-label="Select page">
    </mat-paginator>
  </div>



  <div [ngClass]="{'hide' : pageResponse.totalElements > 0}" class="no-data-message">
    <span>Não encontramos dados para exibir para os filtros selecionados</span>
  </div>

</div>
