import { Component, Input, OnInit } from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';
import { TransactionStatus } from 'src/app/core/model/transaction';
import { Transaction } from 'src/app/core/model/v2.model';
import { PagePermissions } from 'src/app/core/permissions/page-permissions';
import { AppService } from 'src/app/core/services/v0/app.service';
import { TransactionDetailFromSalesDetailComponent } from '../details/transactionDetailFromSalesDetail.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-transaction-history',
  templateUrl: './transaction-history.component.html',
  styleUrls: ['./transaction-history.component.scss']
})
export class TransactionHistoryComponent implements OnInit {
  @Input() transactionList: Transaction[];

  transactionDetailsPermissions = PagePermissions.TRANSACTION_DETAILS;
  transactionStatusList: TransactionStatus[] = new Array();

  constructor(
    private appService: AppService,
    private permissionsService: NgxPermissionsService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    //Busca status da transação
    this.appService.getTransactionStatus().then(data => {
      this.transactionStatusList = data.transactionStatus;
    });
  }

  detailTransaction(history, event) {
    this.permissionsService.hasPermission(this.transactionDetailsPermissions).then(hasPermission => {
      if (hasPermission) {
        const dialogRef = this.dialog.open(TransactionDetailFromSalesDetailComponent, {
          width: '380px',
          height: '380px',
          data: { transaction: history }
        });
        dialogRef.afterClosed().subscribe(result => {
        });
      }
    });
  }

  getTransactionTextStatusColor(status) {
    for (let i = 0; i < this.transactionStatusList.length; i++) {
      if (status === this.transactionStatusList[i].value) {
        let aux = this.transactionStatusList[i].textColor;
        return aux;
      }
    }
    return "gray";
  }

  getTransactionStatusIcon(status) {
    for (let i = 0; i < this.transactionStatusList.length; i++) {
      if (status === this.transactionStatusList[i].value) {
        let aux = this.transactionStatusList[i].textColorClass;
        return aux;
      }
    }
    return "gray";
  }

}
