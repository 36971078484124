<div class="content">

    <h4>Compartilhe seu link de pagamento</h4>

    <div fxLayout="column" fxLayoutGap="10px">
        <div fxLayout='row' fxLayout.lt-md="column">
            <div fxFlex="80"> 
                <mat-form-field>
                    <mat-label>Link</mat-label>
                    <input matInput readonly=true [(ngModel)]=url #link>
                </mat-form-field>
            </div>
            <!-- <div fxFlex="20" class="pull-right" fxLayoutGap="10px">
                <span (click)="copyInputMessage(link)" class="fal fa-clone cursor"></span>
                <span (click)="click(url)" class="fal fa-external-link cursor"></span>
            </div> -->
        </div>
    </div>

    <div fxLayout="row" fxLayoutGap="10px">
        <shared-link-fb (click)=facebook() rel="nofollow" class="facebook-share-button cursor"></shared-link-fb>
        <shared-link-fb (click)=whatsapp() rel="nofollow" class="whatsapp-share-button cursor"></shared-link-fb>
        <shared-link-fb (click)=linkedin() rel="nofollow" class="linkedin-share-button cursor"></shared-link-fb>
        <shared-link-fb (click)=twitter() rel="nofollow" class="twitter-share-button cursor"></shared-link-fb>
        <shared-link-fb (click)="copyInputMessage(link)" rel="nofollow" class="copy-and-go cursor">Copiar link</shared-link-fb>
        <shared-link-fb (click)="click(url)" rel="nofollow" class="copy-and-go cursor">Abrir link</shared-link-fb>
    </div>
</div>