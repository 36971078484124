import { Component, OnInit, Input } from "@angular/core";
import { PlanResponse, PagePayResponse } from "src/app/core/model/v1.model";
import { PagePayServiceV1 } from "src/app/core/services/v1/pagepay.service.v1";
import { PlanServiceV1 } from "src/app/core/services/v1/plan.service.v1";

@Component({
    selector: 'app-plan-view',
    templateUrl: './plan-view.component.html',
    styleUrls: ['./plan-view.component.scss']
})
export class PlanViewComponent implements OnInit {
    [x: string]: any;

    @Input()
    plan: PlanResponse;
    pagepay: PagePayResponse;
    pagepays: PagePayResponse[];;

    constructor(public planService: PlanServiceV1,
        public pagepayService: PagePayServiceV1
    ) { }

    ngOnInit() {
        this.getPagePays();
    }

    getUrl(uuid: string) {
        return this.pagepayService.getUrl(uuid);
    }

    open(uuid: string): void {
        let url: string = this.pagepayService.getUrl(uuid);
        window.open(url, "_blank");
    }
    
    getPagePays() {
        this.planService.getAllPagePaysByPlan(this.plan).subscribe(
            result => {
                if (result.length > 0) {
                    this.pagepay = result[0];
                    this.pagepays = result;
                }
            },
            error => {
                this.alertService.handleError(error);
            }
        );
    }

}