<mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>

<h2 mat-dialog-title>Estornar cobrança</h2>

<mat-dialog-content class="mb-5">

  <div fxLayout='column'>
    <div *ngIf="data.paymentMethod !== 'PIX'">
      <mat-slide-toggle class="mb-1" [(ngModel)]="estornoFatura" required>Estorno
        na fatura</mat-slide-toggle>
    </div>

    <mat-form-field>
      <mat-select placeholder="Motivo do estorno" [(ngModel)]="motivo">
        <mat-option *ngFor="let motivo of motivos" [value]="motivo">{{motivo.description}}</mat-option>
      </mat-select>
    </mat-form-field>

    <span>Deseja realmente efetuar o estorno no valor de
      {{ data.charge.value/100 | CustomCurrency:'BRL'}}?</span>
  </div>

</mat-dialog-content>

<mat-divider></mat-divider>

<mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close class="btn-go-back">Fechar</button>
  <button mat-raised-button class="btn-confirm" (click)="submit()">Confirmar</button>
</mat-dialog-actions>
