import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { environment } from "src/environments/environment";
import { toHttpParams } from "../../helper/http.helper";

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(private http: HttpClient) { }

  public getAllChargesByOrder(pedidoId): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/api/v2/subscriptions/${pedidoId}/charges`);
  }

  getSubscriptionsByCustomerId(clienteId): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/api/v2/customers/${clienteId}/subscriptions`);
  }

  cancelaAssinatura(pedidoId: number, motivo: number): Observable<any> {
    return this.http.delete<any>(`${environment.apiUrl}/api/v1/subscriptions/${pedidoId}`,
      { params: toHttpParams({ reasonId: motivo }), observe: 'response' },
    );
  }

}