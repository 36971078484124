<div style="position: relative; width: 98%; height: 100%;" id="editProduct">
    <div fxLayout="row" fxLayoutAlign="space-between center">
        <div fxLayout="column">
            <h2 id="name">{{plan.name}}</h2>
        </div>
        <button type="button" class="btn btn-dark" id="editPlan"
            (click)="this.planService.editPlan.emit(false)">Editar</button>
    </div>
    <div class="shadow-none border-0">
        <div class="card-body bg-light">
            <div fxLayout="row" fxLayoutGap="15px">
                <div fxLayout="column" fxLayoutGap="5px">
                    <div fxLayout="row" fxLayoutGap="3px">
                        <label id="labelType"><b>Tipo de Plano</b></label>
                        <label id="type">{{plan.type | translate}}</label>
                    </div>
                    <div fxLayout="row" fxLayoutGap="3px">
                        <label id="labelSku"><b>SKU</b></label>
                        <label id="sku">{{plan.sku}}</label>
                    </div>
                    <div fxLayout="row" fxLayoutGap="3px">
                        <label id="labelSoftDescriptor"><b>Identificação na Fatura</b></label>
                        <label id="softDescriptor">{{plan.softDescriptor}}</label>
                    </div>
                    <div fxLayout="row" fxLayoutGap="3px">
                        <label id="labelMaxInstallments"><b>Parcelamento</b></label>
                        <label id="maxInstallments">{{plan.maxInstallments == 1 ? 'Sem parcelamento' :
                            plan.maxInstallments+' parcelas'}}</label>
                    </div>
                </div>
                <div fxLayout="column" fxLayoutGap="5px">
                    <div fxLayout="row" fxLayoutGap="3px">
                        <label id="labelDefaultValue"><b>Valor</b></label>
                        <label id="defaultValue">{{plan.value | CustomCurrency:'BRL'}}</label>
                    </div>
                    <div fxLayout="row" fxLayoutGap="3px" *ngIf="plan.type.toString() === 'RECURRENT'">
                        <label id="labelFrequency"><b>Periodicidade</b></label>
                        <label id="frequency">{{plan.frequency | translate}}</label>
                    </div>
                    <div fxLayout="row" fxLayoutGap="3px" *ngIf="plan.type.toString() === 'RECURRENT'">
                        <label id="labelCharges"><b>Quantidade de Cobranças</b></label>
                        <label id="charges">{{plan.charges > 0 ? plan.charges : "Indefinido"}}</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>