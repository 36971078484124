
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class OrderStatusHelper {
  status: any[] = [];

  constructor() {
  }

  getOrderStatusColor(status): string {
    switch (status) {
      case "ACTIVE":
        return "bg-green";
      case "CHARGEBACK" || "CANCELED": 
        return "bg-red";
      case "PENDING" || "OVERDUE":
        return "bg-yellow";
      case "COUNTERCHARGE": 
        return "bg-orange";
      default: 
        return "bg-gray";
    }
  }

}