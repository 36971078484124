import { Component, OnInit } from "@angular/core";
import { Chart } from 'chart.js';
import { CustomCurrencyPipe } from "src/app/core/helper/pipe/custom-currency.pipe";
import { AlertService } from "src/app/core/services/alert.service";
import { ReportServiceV1 } from "src/app/core/services/v1/report.service.v1";

@Component({
    selector: 'liquido-report',
    templateUrl: './liquido-report.component.html',
    styleUrls: ['./liquido-report.component.scss']
})
export class LiquidoReportComponent implements OnInit {
    chart:any = {};

    data = {
              labels: [],
              datasets: []         
           };

    types = [ {
                name: 'Adesão',
                label: 'ACCESSION',
                color: 'green'
              },
              {
                name: 'Chargeback',  
                label: 'CHARGEBACK',
                color: 'red'
              },
              {
                name: 'Recorrente',  
                label: 'RECURRENT',
                color: 'blue'
              },
              {
                name: 'Avulsa',  
                label: 'LOOSE',
                color: '#F7DC6F'
              },
              {
                name: 'Estorno',  
                label: 'REFUND',
                color: 'black'
              }];
                  

    constructor(
        private reportServiceV1: ReportServiceV1, 
        private currencyPipe: CustomCurrencyPipe,
        private alertService: AlertService
        ){
        
    }

    ngOnInit(){
        this.reportServiceV1.getRelatorioLiquido().subscribe(response => {
            let data = [];
            let totalCount = 0;
            if(response.length > 0){
                data = response;
            }
            for(let i=0; i<data.length; i++){
                totalCount += data[i]['count'];
            }
            let total = [];

            for( let index in data ) {

                const findData = this.data.labels.find( d => d === data[index]['data'] );
                if (  !findData ) {
                    this.data.labels.push(data[index]['data']);   
                    total.push(0);
                }               
            }

            for( let obj of this.types ) { 
                let values = [];
                for ( let index in this.data.labels ) {
                    let findTypeData =  data.find( d => d['type'] === obj.label && d['data'] === this.data.labels[index]  );
                    if ( typeof findTypeData != 'undefined' ) {
                        values.push( findTypeData['amount'] );
                        total[index] += findTypeData['amount'];
                    } else {
                        values.push( 0.0 );
                    }                 
                } 

                this.data.datasets.push(
                    {
                        type:"bar",
                        "label":obj.name,
                        "backgroundColor":obj.color,
                        "borderColor":"",
                        "fill":false,
                        "data": values
                    }
                );                
            } 
            
            this.data.datasets.unshift(
                {
                    type:"line",
                    "label":'Total',
                    "backgroundColor":"orange",
                    "borderColor":"orange",
                    "fill":false,
                    "data": total
                }
            );

            this.chart = new Chart('canvas', {
                type:'bar',
                data: this.data,
                options: {
                    tooltips: {
                        callbacks: {
                          label: (tooltipItem, data) => {

                            var label = data.datasets[tooltipItem.datasetIndex].label || '';

                            if (label) {
                                label += ': ';
                            }
                            label += this.currencyPipe.transform(tooltipItem.yLabel, 'BRL');
                            return label;
                          }
                        }
                      },
                    maintainAspectRatio: false,
                    responsive: true,
                    legend: {
                      display: true
                    },
                    scales: {
                        xAxes: [{
                            stacked: true,
                            display: true,
                            maxBarThickness: 70
                        }],
                        yAxes: [{
                            stacked: true,
                            display: true,
                            ticks: {
                                beginAtZero: true,
                                userCallback: (label, index, labels) => {
                                    return this.currencyPipe.transform(label, 'BRL')
                                },
                            }
                        }]
                    }
                }
            })
        },
        error => {
          this.alertService.error("Erro", "Ocorreu um erro interno em nosso servidor. Se o erro persistir, por favor, contate o suporte.");
        });
    }
}