import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ClipboardService } from 'ngx-clipboard';
import { environment } from "src/environments/environment";





@Component({
  selector: 'payment-page',
  templateUrl: './payment-page.component.html',
  styleUrls: ['./payment-page.component.scss']
})

export class PaymentPage implements OnInit {

  code: string;
  date: string;
  longDate: number;
  dateFormated: Date;
  paymentMethod: string;
  image: string;
  barcode: string;
  value: number;
  copiado: boolean = false;
  text: string;

  constructor(
    private route: ActivatedRoute,
    private clipboardService: ClipboardService
  ) {
  }

  ngOnInit() {
    this.text = 'Pix copia e cola';
    this.route.queryParams.subscribe(
      params => {
        this.code = params.code;
        this.value = params.value;
        
        this.paymentMethod = params.paymentMethod;
        if (this.paymentMethod == 'pix') {
          this.image = `${environment.apiUrl}/api/v1/image/qrcode?code=${this.code}`;
        }
        if (this.paymentMethod == 'bankslip') {
          this.image = `${environment.apiUrl}/api/v1/image/barcode?code=${this.code}`;
        }
        this.date = params.date;
      }

    )


    this.longDate = parseInt(this.date);
    this.dateFormated = new Date(this.longDate);

  }

  getText(){
    this.clipboardService.copyFromContent(this.code);
    this.copiado = true;
    this.text = 'Copiado!'
    
  }



}
