import { Injectable } from "@angular/core";
import { first } from "rxjs/operators";
import { NgForm } from "@angular/forms";

@Injectable({
    providedIn: 'root'
})
export class ValidationHelper {


     // Valida cpf {
    validaCpf(cpf) {
        let soma = 0;
        let resto;
        let inputCPF = cpf;
        let invalidCpf: string[]  = ["00000000000", "11111111111", "22222222222", "3333333333", "44444444444", "55555555555", "66666666666", "77777777777", "88888888888", "99999999999"];

        if(invalidCpf.indexOf(inputCPF) !== -1) { return false; }
        // tslint:disable-next-line:radix
        for(let i=1; i<=9; i++) {soma = soma + parseInt(inputCPF.substring(i-1, i)) * (11 - i);
        resto = (soma * 10) % 11; }
    
        if((resto === 10) || (resto === 11)) {resto = 0;}
        
        // tslint:disable-next-line:radix
        if(resto !== parseInt(inputCPF.substring(9, 10))) {return false;}
    
        soma = 0;
        // tslint:disable-next-line:radix
        for(let i = 1; i <= 10; i++) {soma = soma + parseInt(inputCPF.substring(i-1, i))*(12-i);
        resto = (soma * 10) % 11;}
    
        if((resto === 10) || (resto === 11)) {resto = 0;}
    
        // tslint:disable-next-line:radix
        if(resto !== parseInt(inputCPF.substring(10, 11))) {return false;}
        return true;
    }


    
  numberToStringValue(value: number | string, separator:string = '.') {
    const fractionSize = 2;
    const PADDING = "000000";
    let [integer, fraction = ""] = (value || "").toString().split(separator);
    fraction = fractionSize > 0 ? (fraction + PADDING).substring(0, fractionSize) : "";
    return integer + fraction;
  }
  confirmDateRange(dateStart:Date, dateEnd:Date, range:number) {
    let rangeTest = dateEnd.valueOf() - dateStart.valueOf();
    return rangeTest > range ?  false : true;
  }
  validateTelefone(inputTelefone:string){
    if(inputTelefone && inputTelefone != "" && inputTelefone.length >= 10){
      let firstDigit = inputTelefone.substring(2, 3)[0];
      let secondDigit = inputTelefone.substring(3, 4)[0];
      let ddd = inputTelefone.substring(0, 1)[0];
      let isCelular:boolean = firstDigit == "9";

      if(ddd == "0"){
        return false;
      }
     if(isCelular && inputTelefone.length == 11 && parseInt(secondDigit) > 6){
       return true;
     } else if(!isCelular && inputTelefone.length == 10 && parseInt(firstDigit) < 7){
       return true;
     }else{
       return false;
     }
    } else {
      return false;
    }
  }

  setFormAsDirty(form:NgForm){
    for(let control in form.controls){
      form.controls[control].markAsDirty({onlySelf:true});
      form.controls[control].markAsTouched({onlySelf:true});
      form.controls[control].markAsPristine({onlySelf:true});
    }
  }
  validaCartao(validadeAno:number, validadeMes:number):boolean {
    let dataAtual = new Date(Date.now());
    let mesAtual = dataAtual.getMonth() + 1;
    if (validadeAno > dataAtual.getFullYear()) {
      return true;
    }else{
      if (validadeAno == dataAtual.getFullYear()) {
        if (validadeMes > mesAtual) {
          return true;
        }
      }
    }
    return false
  }
}