import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { CustomerImportFilter } from "../../common/filters/customer-import.filter";
import { DataFinder } from "../../helper/data-finder";
import { filtertoHttpParams } from "../../helper/http.helper";
import { Pageable } from "../../model/pageable";

@Injectable({
  providedIn: 'root'
})
export class CustomerImportService {

  constructor(private http: HttpClient, private dataFinder: DataFinder) { }

  public getStatus(): Promise<any> {
    return this.dataFinder.getCustomerImportStatusJson();
  }

  public getFilter(filter: CustomerImportFilter, pageable: Pageable): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/api/v1/customerImport/wallet/filter`, filtertoHttpParams(filter, pageable));
  }

  public getFileById(id: number): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/api/v1/customerImport/file/${id}`);
  }

  public getRejectionsById(id: number): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/api/v1/customerImport/rejections/${id}`);
  }

  public scheduleWalletImportExcel(fileIn, filter) {
    let file: FormData = new FormData();
    file.append('file', fileIn[0]);
    return this.http.post<any>(`${environment.apiUrl}/api/v1/customerImport/wallet/excel`, file, filtertoHttpParams(filter));
  }

  public cancelImport(id: number) {
    return this.http.put<any>(`${environment.apiUrl}/api/v1/customerImport/cancel/${id}`, null);
  }
}
