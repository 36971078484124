<mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
<div fxLayout="column" fxLayoutGap="10px" class="mb-2">
  <div fxLayout="row">
    <div fxFlex fxLayout="row" fxLayoutAlign="start center">
      <p-breadcrumb id="breadcrumb" [model]="crumbs"></p-breadcrumb>
    </div>
  </div>
</div>
<div class="container" fxLayout='column' fxLayoutGap="10px" *ngIf="client && !loading">
  <mat-card>
    <mat-card-header>
      <mat-card-title>
        <h3>{{client.name}}</h3>
      </mat-card-title>
      <mat-card-subtitle>
        <span *ngIf="client.document">
          {{ client.document | mask: '000.000.000-00 || 00.000.000/0000-00'}}
        </span>
      </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content fxLayout="column" class="ml-3" fxLayoutGap="15">
      <div fxLayout="row" fxLayoutGap="15">
        <div fxFlex="12">
          <mat-form-field appearance="fill" *ngIf="client.birthdate">
            <mat-label>Data Nascimento</mat-label>
            <input matInput [value]="client.birthdate | date: 'dd/MM/yyyy'" name="clientDob" disabled>
          </mat-form-field>
        </div>
        <div fxFlex="12">
          <mat-form-field appearance="fill" *ngIf="client.dataRegistroFormatado">
            <mat-label>Cliente desde</mat-label>
            <input matInput [value]="client.dataRegistroFormatado | date: 'dd/MM/yyyy'" name="clientDateCreate"
              disabled>
          </mat-form-field>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div fxLayout="row" fxLayoutGap="15">
        <div fxFlex="13">
          <mat-form-field appearance="fill" *ngIf="client.phone">
            <mat-label>Telefone</mat-label>
            <input matInput [value]="(client.phone.code + client.phone.number) | mask: '(00) 0000-0000 || (00) 00000-0000'"
              name="clientPhone" disabled>
          </mat-form-field>
        </div>
        <div fxFlex="35">
          <mat-form-field appearance="fill" *ngIf="client.email">
            <mat-label>E-mail</mat-label>
            <input matInput [value]="client.email" name="clientEmail" disabled>
          </mat-form-field>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
  <card-box class="mb-3">
    <div class="tab-content" id="nav-tabContent">
      <!-- Listagem das assinaturas  -->
      <div fxFlex class="tab-pane fade show active" id="nav-assinaturas" role="tabpanel"
        aria-labelledby="nav-assinaturas-tab">
        <app-client-details-signature *ngIf="true" (clientSignaturesReload)="filter()" [client]="client">
        </app-client-details-signature>
      </div>
      <!-- Listagem dos cartoes -->
      <div class="tab-pane fade" id="nav-cartoes" role="tabpanel" aria-labelledby="nav-cartoes-tab">
        <app-client-details-card *ngIf="viewTab.card" [client]="client"></app-client-details-card>
      </div>
    </div>
  </card-box>
</div>
