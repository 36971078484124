<div class="shadow-none" style="margin-top: 10px;">
    <div class="export">
        <a class="export-link" (click)="downloadCsv()">
        <span class="export-label">Exportar</span>
        <i class="fa fa-arrow-circle-down" aria-hidden="true"></i>
        </a> 
    </div>

    <div class="div-table">
        <table mat-table [dataSource]="disputes" matSort (matSortChange)="handleSort($event)">

            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef mat-sort-header id="tableHeaderId"> Tipo </th>
                <td mat-cell *matCellDef="let row; let i = index;" [attr.id]="'disputesTdId_'+i">
                    <i class="fas fa-user-secret text-danger"></i> {{ row.type | translate }}
                </td>
            </ng-container>

            <ng-container matColumnDef="authorizationDate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header id="tableHeaderId"> Data da Venda
                </th>
                <td mat-cell *matCellDef="let row; let i = index;" [attr.id]="'disputesTdId_'+i">
                    {{ row.authorizationDate | date: 'dd/MM/yyyy HH:mm'}} </td>
            </ng-container>

            <ng-container matColumnDef="alertDate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header id="tableHeaderId"> Data do Alerta </th>
                <td mat-cell *matCellDef="let row; let i = index;" [attr.id]="'disputesTdId_'+i"> {{
                    row.alertDate |
                    date: 'dd/MM/yyyy HH:mm'}}
                </td>
            </ng-container>

            <ng-container matColumnDef="card">
                <th mat-header-cell *matHeaderCellDef mat-sort-header id="tableHeaderId"> Cartão </th>
                <td mat-cell *matCellDef="let row; let i = index;" [attr.id]="'disputesTdId_'+i"> {{
                    row.cardBin +
                    "******" +
                    row.cardLast4 }} </td>
            </ng-container>

            <ng-container matColumnDef="descriptor">
                <th mat-header-cell *matHeaderCellDef mat-sort-header id="tableHeaderId"> Identificador </th>
                <td mat-cell *matCellDef="let row; let i = index;" [attr.id]="'disputesTdId_'+i"> {{
                    row.descriptor }}
                </td>
            </ng-container>

            <ng-container matColumnDef="amount">
                <th mat-header-cell *matHeaderCellDef mat-sort-header id="tableHeaderId"> Valor </th>
                <td mat-cell *matCellDef="let row; let i = index;" [attr.id]="'disputesTdId_'+i"> 
                    {{row.amount | CustomCurrency:'BRL'}}
                </td>
            </ng-container>

            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header id="tableHeaderId"> Status </th>
                <td mat-cell *matCellDef="let row; let i = index;" [attr.id]="'disputesTdId_'+i">
                    <span class="badge" [ngClass]="getStatusColor(row.status)">{{row.status | translate}}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="refunded">
                <th mat-header-cell *matHeaderCellDef mat-sort-header id="tableHeaderId"> Estornado </th>
                <td mat-cell *matCellDef="let row; let i = index;" [attr.id]="'disputesTdId_'+i">
                    <span class="badge bg-green" *ngIf="row.refunded == true"><i class="fas fa-check-circle white"></i> Sim</span>
                    <span class="badge bg-red" *ngIf="row.refunded == false"><i class="fas fa-times-circle white"></i> Não</span>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

            <tr mat-row *matRowDef="let row; columns: displayedColumns;" id="planToolTip" matTooltipPosition="above">
            </tr>
        </table>
    </div>

    <mat-paginator [ngClass]="{'hide' : pageResponse.totalElements == 0}" [pageIndex]="pageResponse.pageNumber"
        (page)="pageEvent = handlePage($event)" [pageSize]="pageResponse.pageSize"
        [length]="pageResponse.totalElements">
    </mat-paginator>

</div>

<div [ngClass]="{'hide' : pageResponse.totalElements > 0}" class="no-data-message">
    <span>Nenhum alerta encontrado.</span> 
</div>