import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { firstValueFrom, Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { ChargeReportFilter } from "../../common/filters/charge-report.filter";
import { OrderFilter } from "../../common/filters/order.filter";
import { exportParamsChargeReport, getParamsCharges, getTransaction } from "../../helper/http.helper";
import { ChargeReports } from "../../model/charges-report";
import { Pageable } from "../../model/pageable";

@Injectable({
    providedIn: 'root'
})
export class ChargeServiceV1 {

    constructor(private http: HttpClient) {
    }

    public downloadCsvReportCharges(filters: ChargeReportFilter) {
        return this.http.get(`${environment.apiUrl}/api/v1/charges/reports/csv`, { params: exportParamsChargeReport(filters), responseType: 'blob' as 'json' });
    }

    public reportCharge(filter: ChargeReportFilter, pageable: Pageable): Observable<ChargeReports[]> {
        let params = exportParamsChargeReport(filter);
        params = params.set("size", pageable.size.toString()).set("page", pageable.page.toString());
        return this.http.get<any>(`${environment.apiUrl}/api/v1/charges/reports`, { params: params });
    }


    public getChargeList(filter: OrderFilter, pageable: Pageable) {
        let params = getTransaction(filter);
        params = params.set("size", pageable.size.toString()).set("page", pageable.page.toString());
        return this.http.get<any>(`${environment.apiUrl}/api/v1/charges`, { params: params });
    }

    public exportToExcel(filter: OrderFilter) {
        return this.http.get(`${environment.apiUrl}/api/v1/charges/excel`, { params: getParamsCharges(filter), responseType: 'blob' as 'json' });
    }

    public refund(id, request): Observable<any> {
        return this.http.post(`${environment.apiUrl}/api/v1/charges/${id}/refund`, request);
    }

}