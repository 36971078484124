import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort, MatSortable } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { NgxPermissionsService } from "ngx-permissions";
import { MenuItem } from "primeng/api";
import { Pageable } from "src/app/core/model/pageable";
import { PlanResponse } from "src/app/core/model/response/plan.response";
import { PagePayResponse, SubscribersReponse } from "src/app/core/model/v1.model";
import { PagePermissions } from "src/app/core/permissions/page-permissions";
import { AlertService } from "src/app/core/services/alert.service";
import { PlanServiceV1 } from "src/app/core/services/v1/plan.service.v1";

@Component({
  selector: 'app-subscribers-list',
  templateUrl: './subscribers-list.component.html',
  styleUrls: ['./subscribers-list.component.scss']
})
export class SubscribersListComponent implements OnInit {

  pageEvent:  PageEvent;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @Input()
  plan: PlanResponse;
  subscribersList: MatTableDataSource<SubscribersReponse>;
  crumbs: MenuItem[];
  displayedColumns: string[] = ['id', 'name'];
  clientPermissions = PagePermissions.CUSTOMER_EDIT_DETAILS;
  public pageable: Pageable;
  public pageResponse: { pageNumber: number; totalPages: number; pageSize: number; totalElements: number; content: any; };


  constructor(
    private alertService: AlertService,
    private planService: PlanServiceV1,
    private permissionsService: NgxPermissionsService,
    private router: Router
  ) { }

  ngOnInit() {
    this.crumbs = [{ label: 'Clientes' }]
    this.pageable = { page: 0, size: 10 };
    this.pageResponse = {
      pageNumber: 0,
      totalPages: 0,
      pageSize: 10,
      totalElements: 0,
      content: null
    };
    this.search();
  }

  search() {
    let idPlan: number = this.plan.id
    this.planService.getAllSubscribersById(idPlan, this.pageable)
      .subscribe(result => {
        this.showResult(result);
      });
  }

  showResult(result) {
    if (result != null) {
      this.pageResponse.pageNumber = result.pageable.pageNumber;
      this.pageResponse.totalElements = result.totalElements;
      this.pageResponse.totalPages = result.totalPages;
      this.pageResponse.content = result.content;
      this.subscribersList = this.pageResponse.content;
    } else {
      this.pageResponse.pageNumber = 0;
      this.pageResponse.totalElements = 0;
      this.pageResponse.totalPages = 0;
      this.subscribersList = this.pageResponse.content;
    }
  }

  public handlePage(event?: PageEvent) {
    this.pageable.page = event.pageIndex;
    this.search();
    return event;
  }

  detailClient(id) {
    this.permissionsService.hasPermission(this.clientPermissions).then(hasPermission => {
      if (hasPermission) {
        this.router.navigate(['subscribers/', id]);
      } else {
        this.alertService.errorPermission();
      }
    });
  }


}
