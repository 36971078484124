<mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>

<div class="container" fxLayout="column" fxLayoutGap="10px" *ngIf="!loading">

    <div fxLayout="column" fxLayoutGap="10px">
        <div fxLayout="row">
            <div fxFlex fxLayout="row" fxLayoutAlign="start center">
                <p-breadcrumb id="breadcrumb" [model]="crumbs"></p-breadcrumb>
            </div>

            <div fxFlex fxLayout="row" fxLayoutAlign="end center">
                <!-- <button type="button" class="btn btn-primary" name="newLink" [routerLink]="['new']">+ Novo</button> -->
            </div>
        </div>
    </div>


    <!-- Filter begin -->
    <div class="card mb-0 shadow-none filter" fxLayout="row" fxLayoutAlign="space-between stretch" *ngIf="viewFilter">

        <div fxLayout='column' fxFlex fxLayoutAlign="start stretch" fxLayout.lt-md="column"
            fxLayoutAlign.lt-md="space-around stretch" fxLayoutGap="10px">

            <!-- Primeira linha de filtros -->
            <div fxLayout='row' fxLayoutAlign="start stretch" fxLayout.lt-md="column"
                fxLayoutAlign.lt-md="space-around stretch" fxLayoutGap="10px">

                <div fxLayout='column' fxLayoutGap="10px">
                    <mat-slide-toggle [(ngModel)]="enableAutorizationDate">Data da venda</mat-slide-toggle>
                    <div fxLayout='row' fxLayoutGap="4px">
                        <mat-form-field>
                            <input matInput [matDatepicker]="startAutorizationDateFF" readonly
                                (click)="startAutorizationDateFF.open()" [(ngModel)]="startAutorizationDate"
                                placeholder="Início:" disabled="{{!isEnableAutorizationDate()}}">
                            <mat-datepicker-toggle matSuffix [for]="startAutorizationDateFF"></mat-datepicker-toggle>
                            <mat-datepicker #startAutorizationDateFF></mat-datepicker>
                        </mat-form-field>

                        <mat-form-field>
                            <input matInput [matDatepicker]="endAutorizationDateFF" readonly
                                (click)="endAutorizationDateFF.open()" [(ngModel)]="endAutorizationDate"
                                placeholder="Fim:" [min]="startAutorizationDate"
                                disabled="{{!isEnableAutorizationDate()}}">
                            <mat-datepicker-toggle matSuffix [for]="endAutorizationDateFF"></mat-datepicker-toggle>
                            <mat-datepicker #endAutorizationDateFF></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="errors center-text"
                        *ngIf="(!authorizationDateValid && enableAutorizationDate && startAutorizationDate != null)">
                        <span>Favor selecionar um período máximo de um mês</span>
                    </div>

                </div>

                <div fxLayout='column' fxLayoutGap="10px">
                    <mat-slide-toggle [(ngModel)]="enableAlertDate">Data do alerta</mat-slide-toggle>
                    <div fxLayout='row' fxLayoutGap="4px">
                        <mat-form-field>
                            <input matInput [matDatepicker]="startAlertDateFF" readonly
                                (click)="startAlertDateFF.open()" [(ngModel)]="startAlertDate" placeholder="Início:"
                                disabled="{{!isEnableAlertDate()}}">
                            <mat-datepicker-toggle matSuffix [for]="startAlertDateFF"></mat-datepicker-toggle>
                            <mat-datepicker #startAlertDateFF></mat-datepicker>
                        </mat-form-field>

                        <mat-form-field>
                            <input matInput [matDatepicker]="endAlertDateFF" readonly (click)="endAlertDateFF.open()"
                                [(ngModel)]="endAlertDate" placeholder="Fim:" [min]="startAlertDate"
                                disabled="{{!isEnableAlertDate()}}">
                            <mat-datepicker-toggle matSuffix [for]="endAlertDateFF"></mat-datepicker-toggle>
                            <mat-datepicker #endAlertDateFF></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="errors center-text"
                        *ngIf="!alertDateValid && enableAlertDate && startAlertDate != null">
                        <span>Favor selecionar um período máximo de um mês</span>
                    </div>
                </div>

            </div>

            <!-- Segunda linha de filtros -->
            <div fxLayout='row' fxLayoutAlign="space-between stretch" fxLayout.lt-md="column"
                fxLayoutAlign.lt-md="space-around stretch" fxLayoutGap="10">

                <div fxFlex="40">
                    <mat-form-field>
                        <input type="number" maxlength="20" matInput [(ngModel)]="filters.cardNumber"
                            placeholder="Cartão" id="cardNumber">
                    </mat-form-field>
                </div>

                <div fxFlex="60">
                    <mat-form-field>
                        <input matInput maxlength="80" [(ngModel)]="filters.descriptor" placeholder="Identificador"
                            id="descriptor">
                    </mat-form-field>
                </div>

                <div fxFlex>
                    <mat-form-field>
                        <mat-select placeholder="Tipo" #selectType multiple [(ngModel)]="filters.type">
                            <mat-option #allSelectedType (click)="toggleAllSelection('allSelectedType')"
                                value="">Todos</mat-option>
                            <mat-divider></mat-divider>
                            <mat-option (click)="optionClick('selectType')" *ngFor="let type of AlertType"
                                [value]="type.valueFilter">
                                {{type.name | translate}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div fxFlex>
                    <mat-form-field>
                        <mat-select placeholder="Status" #selectStatus multiple [(ngModel)]="filters.status">
                            <mat-option #allSelectedStatus (click)="toggleAllSelection('allSelectedStatus')"
                                value="">Todos</mat-option>
                            <mat-divider></mat-divider>
                            <mat-option (click)="optionClick('selectStatus')" *ngFor="let status of AlertStatus"
                                [value]="status.valueFilter">
                                {{status.name | translate}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

            </div>

        </div>

        <div fxLayout="row" fxLayoutAlign="end center">
            <button mat-raised-button class="btn-search" (click)="filterParam()">Pesquisar</button>
        </div>
    </div>
    <!-- Filter end -->

    <!-- Cards -->
    <div fxLayout='row' fxLayoutAlign="start stretch" fxLayout.lt-md="column" fxLayoutGap="10px">
        <app-card-dash fxFlex [data]="cardDashDanger"></app-card-dash>
        <app-card-dash fxFlex [data]="cardDashSucess"></app-card-dash>
        <app-card-dash fxFlex [data]="cardDashOpen"></app-card-dash>
    </div>



    <!-- Lista de disputas -->
    <div class="shadow-none" style="margin-top: 10px;">
        <div class="export">
            <a class="export-link" (click)="downloadCsv()">
                <span class="export-label">Exportar</span>
                <i class="fa fa-arrow-circle-down" aria-hidden="true"></i>
            </a>
        </div>

        <div class="div-table">
            <button class="categorias selecionado" id="todos" (click)="alteraVariavel('todos')">Todos</button>
            <button class="categorias" id="resolvidos" (click)="alteraVariavel('resolvidos')">Resolvidos</button>
            <button class="categorias" id="reembolsados" (click)="alteraVariavel('reembolsados')">Reembolsados</button>
            <button class="categorias" id="nao_reembolsados" (click)="alteraVariavel('nao_reembolsados')">Não
                reembolsados</button>
            <button class="categorias" id="nao_conciliados" (click)="alteraVariavel('nao_conciliados')">Não
                conciliados</button>
            <mat-progress-bar mode="indeterminate" *ngIf="loadingTab"></mat-progress-bar>
            <table mat-table [dataSource]="disputes" matSort (matSortChange)="handleSort($event)">


                <ng-container matColumnDef="type">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header id="tableHeaderId"> Tipo </th>
                    <td mat-cell *matCellDef="let row; let i = index;" [attr.id]="'disputesTdId_'+i">
                        <i class="fas fa-user-secret text-danger"></i> {{ row.type | translate }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="authorizationDate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header id="tableHeaderId"> Data da Venda
                    </th>
                    <td mat-cell *matCellDef="let row; let i = index;" [attr.id]="'disputesTdId_'+i">
                        {{ row.authorizationDate | date: 'dd/MM/yyyy HH:mm'}} </td>
                </ng-container>

                <ng-container matColumnDef="alertDate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header id="tableHeaderId"> Data do Alerta </th>
                    <td mat-cell *matCellDef="let row; let i = index;" [attr.id]="'disputesTdId_'+i"> {{
                        row.alertDate |
                        date: 'dd/MM/yyyy HH:mm'}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="card">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header id="tableHeaderId"> Cartão </th>
                    <td mat-cell *matCellDef="let row; let i = index;" [attr.id]="'disputesTdId_'+i"> {{
                        row.cardBin +
                        "******" +
                        row.cardLast4 }} </td>
                </ng-container>

                <ng-container matColumnDef="descriptor">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header id="tableHeaderId"> Identificador </th>
                    <td mat-cell *matCellDef="let row; let i = index;" [attr.id]="'disputesTdId_'+i"> {{
                        row.descriptor }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="amount">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header id="tableHeaderId"> Valor </th>
                    <td mat-cell *matCellDef="let row; let i = index;" [attr.id]="'disputesTdId_'+i">
                        {{row.amount | CustomCurrency:'BRL'}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header id="tableHeaderId"> Status </th>
                    <td mat-cell *matCellDef="let row; let i = index;" [attr.id]="'disputesTdId_'+i">
                        <span class="badge" [ngClass]="getStatusColor(row.status)">{{row.status | translate}}</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="refunded">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header id="tableHeaderId"> Estornado </th>
                    <td mat-cell *matCellDef="let row; let i = index;" [attr.id]="'disputesTdId_'+i">
                        <span class="badge bg-green" *ngIf="row.refunded == true"><i
                                class="fas fa-check-circle white"></i> Sim</span>
                        <span class="badge bg-red" *ngIf="row.refunded == false"><i
                                class="fas fa-times-circle white"></i> Não</span>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

                <tr mat-row *matRowDef="let row; columns: displayedColumns;" id="planToolTip"
                    matTooltipPosition="above"></tr>
            </table>
        </div>

        <mat-paginator [ngClass]="{'hide' : pageResponse.totalElements == 0}" (page)="pageEvent = handlePage($event)"
            [pageSize]="pageResponse.pageSize" [length]="pageResponse.totalElements" aria-label="Select page">
        </mat-paginator>

    </div>

    <div [ngClass]="{'hide' : pageResponse.totalElements > 0}" class="no-data-message">
        <span>Nenhum alerta encontrado.</span>
    </div>

</div>