import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from "@angular/core";
import { Client } from "src/app/core/model/client";
import * as lodash from 'lodash';
import { Administradora } from "src/app/core/model/administradora";
import { AppService } from "src/app/core/services/v0/app.service";


@Component({
    selector: 'app-administradora-select',
    template: ` <mat-form-field>
                    <mat-select placeholder="Bandeira" [(ngModel)]="administradoraSelecionada" [required]="isRequired" [disabled]="loading" name="administradora">
                        <mat-option *ngFor=" let administradora of administradoras " [value]="administradora.nome" id="administradora" >{{administradora.nome}}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="isRequired">Bandeira é obrigatória</mat-error>
                </mat-form-field>`
  })

export class AdministradoraSelectComponent implements OnInit {
    administradoras:Administradora[];
    loading:boolean;
    @Input() administradoraSelecionada:string;
    @Input() isRequired:boolean;
    @Input() token:string;
    
    constructor(private appService:AppService){
        
    }
    
    ngOnInit(){
        this.appService.getAllAdministradoras(this.token).subscribe(result => this.administradoras = result);
        this.isRequired = this.isRequired == null ? false : this.isRequired;
    }
}