import { Injectable } from "@angular/core";
import * as moment from 'moment';

@Injectable({
    providedIn: 'root'
})
export class StringUtils {
    months: string[] = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
    //paterns:
    

    constructor(){

    }

    public getAnos(){
        let hoje = moment();
        let anos:string[] = [];
        for(let i = 0; i < 16; i++){
            anos[i] = (hoje.year()+i).toString();
        }

        return anos;
    }

    public getMonths(){
        return this.months;
    }

    public isNumeric(value) {
        return /^-{0,1}\d+$/.test(value);
    }
    confirmDateRange(beginDate:string, endDate:string) {
        /*
        let dateStart = Date.parse(beginDate);
        let dateEnd = Date.parse(endDate);
        let rangeValid = false;
        let range = dateEnd.valueOf() - dateStart.valueOf();
        range > 8056740000 ? rangeValid = false : rangeValid = true;
        return rangeValid;
        */
       return true;
      }
}

export class BempaggoConstants {
    public static readonly JAVA_MAX_INT:number = 2147483647;
    public static readonly PATTERN_CHARGE_DAYS = {'9': { pattern: new RegExp('\[0-4\]')}};
    public static readonly PATTERN_ALPHANUMERIC = {'a': { pattern: new RegExp('\[a-zA-Z0-9\]')}};
    public static readonly PHONE_PATTERN = {'9': {pattern: new RegExp('^([9][0-9]{4}-[0-9]{4})|([5-9][0-9]{3}-[0-9]{4})|([1-9][0-9]{3}-[0-9]{4})$')}};
}