import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from "src/environments/environment";
import { ClientsFilter } from '../../common/filters/clients.filter';
import { filtertoHttpParams } from '../../helper/http.helper';
import { Pageable } from '../../model/pageable';
import { AddressRequest } from '../../model/v1.model';


@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(private http: HttpClient) { }

  public getByDocument(documento, token?: string): Observable<any> {
    let headers = new HttpHeaders();
    if (token) {
      headers = headers.set("Authorization", "Bearer " + token);
    }
    return this.http.get<any>(`${environment.apiUrl}/api/customer/document/${documento}`, { headers });
  }

  // Setado apenas para evitar erro no componente
  public getAll(filter: ClientsFilter, pageable: Pageable): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/everest/v1/customers`, filtertoHttpParams(filter, pageable));
  }

  public getById(id): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/api/v1/customers/${id}`);
  }

  public getAddress(url, token?: string): Observable<any> {
    if (token) {
      let header = new HttpHeaders();
      header = header.set("Authorization", "Bearer " + token);
      return this.http.get<any>(`${url}`, { headers: header });
    }
    return this.http.get<any>(`${url}`);
  }

  public createAddress(url, address: AddressRequest, token?: string): Observable<any> {
    if (token) {
      let header = new HttpHeaders();
      header = header.set("Authorization", "Bearer " + token);
      return this.http.post<any>(`${url}`, address, { headers: header });
    }
    return this.http.post<any>(`${url}`, address);
  }

}