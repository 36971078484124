import { Component, OnInit, Input } from "@angular/core";
import { PagePayResponse } from "src/app/core/model/v1.model";
import { PagePayServiceV1 } from "src/app/core/services/v1/pagepay.service.v1";
import { BempaggoConstants } from "src/app/core/helper/string-utils";
import { AuthService } from "src/app/core/auth/auth.service";

@Component({
    selector: 'app-pagepay-view',
    templateUrl: './pagepay-view.component.html',
    styleUrls: ['./pagepay-view.component.scss']
})
export class PagePayViewComponent implements OnInit {
    [x: string]: any;

    @Input()
    pagepay: PagePayResponse;
    javaMaxInt: number = BempaggoConstants.JAVA_MAX_INT;
    companyId: number;

    constructor(
        public pagepayService: PagePayServiceV1,
        private authService: AuthService
    ) { }

    ngOnInit() {
        this.companyId = this.authService.getCurrentUser().idEmpresa;
        this.getPagepay(this.pagepay);
    }
    getPagepay(pagepay: PagePayResponse) {
        this.pagepayService.getById(pagepay.id).subscribe(
            result => {
                this.pagepay = result;
                this.pagepay.affiliateMinimal.name = result.affiliateMinimal.name;
            },
            error => {
                this.alertService.handleError(error);
            }
        );
    }
}