import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { DateFormatPipe } from 'ngx-moment';
import { NgxPermissionsService } from 'ngx-permissions';
import { TransactionsReportFilter } from 'src/app/core/common/filters/transactions-report.filter';
import { PageResponse } from 'src/app/core/model/page-response';
import { Pageable } from 'src/app/core/model/pageable';
import { TransactionReportResponse } from 'src/app/core/model/response/transactions-report-response';
import { AffiliateMinimalResponse, TransactionStatus } from 'src/app/core/model/v1.model';
import { PagePermissions } from 'src/app/core/permissions/page-permissions';
import { AlertService } from 'src/app/core/services/alert.service';
import { TransacaoService } from 'src/app/core/services/v0/transaction.service';
import { AffiliateServiceV1 } from 'src/app/core/services/v1/affiliate.service.v1';
import { ReportServiceV1 } from 'src/app/core/services/v1/report.service.v1';
declare var $: any;

@Component({
  selector: 'transactions-report',
  templateUrl: './transactions-report.component.html',
  styleUrls: ['./transactions-report.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class TransactionsReportComponent implements OnInit {
  end = new Date(Date.now());
  begin = new Date(this.end.getFullYear(), this.end.getMonth() - 1, this.end.getDate());
  exportPermission = PagePermissions.REPORT_TRANSACTION_EXPORT;
  showExportBtn: boolean;
  filters: TransactionsReportFilter = new TransactionsReportFilter();
  expandedElement: TransactionReportResponse | null;
  transactions = new MatTableDataSource<TransactionReportResponse>();
  rangeValid: boolean = true;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = [
    'transactionDate',
    'customerName',
    'paymentMethod',
    'value',
    'status',
    'returnMsg'
  ];

  public pageable: Pageable;

  loadingTab: boolean = true;
  pageResponse: PageResponse;
  pageEvent: PageEvent;
  affiliate: AffiliateMinimalResponse[];
  loading: boolean = true;
  status: any[];
  searchStatusForm: UntypedFormGroup;
  searchAffiliateForm: UntypedFormGroup;
  @ViewChild('allSelectedForStatus') private allSelectedForStatus: MatOption;
  @ViewChild('allSelectedForAffiliate') private allSelectedForAffiliate: MatOption;
  enableDate: boolean = true;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private alertService: AlertService,
    private permissionsService: NgxPermissionsService,
    private affiliateService: AffiliateServiceV1,
    private transactionService: TransacaoService,
    private reportServiceV1: ReportServiceV1

  ) { }

  ngOnInit(): void {
    this.pageResponse = {
      pageNumber: 0,
      totalPages: 0,
      pageSize: 10,
      totalElements: 0,
      content: null
    };
    this.searchStatusForm = this.formBuilder.group({
      orderStatus: new UntypedFormControl('')
    });
    this.searchAffiliateForm = this.formBuilder.group({
      orderAffiliate: new UntypedFormControl('')
    });
    this.status = Object.values(TransactionStatus).filter(x => typeof x === 'string');
    this.filters.start = moment().startOf('month').toDate();
    this.filters.end = new Date();
    this.permissionsService.hasPermission(this.exportPermission).then(hasPermission => {
      this.showExportBtn = hasPermission;
    });
    this.affiliateService.getAll().subscribe(result => this.affiliate = result);
    this.filter();
  }

  confirmDateRange() {
    let start = moment(this.filters.start);
    let end = moment(this.filters.end);
    let difference = end.diff(start, 'days');
    if (difference >= 31) {
      this.rangeValid = false;
    } else {
      this.filters.start = this.filters.start;
      this.filters.end = this.filters.end;
      this.rangeValid = true;
    }
    return this.rangeValid;
  }

  actionLoading(status: boolean) {
    setTimeout(() => {
      this.loading = status;
      this.loadingTab = status;
    }, 500);

  }

  cleanFilter() {
    if (!this.enableDate) {
      this.filters.end = null;
      this.filters.start = null;
    }
  }

  filter() {
    this.pageable = { page: 0, size: 10 };
    let filtrar = false;
    if (!this.enableDate) {
      this.filters.end = null;
      this.filters.start = null;
      filtrar = true;
    }
    else {
      if (this.confirmDateRange()) {
        filtrar = true
      }
    }
    if (filtrar) {
      this.search();
    }
  }

  public handlePage(event?: PageEvent) {
    this.pageable.page = event.pageIndex;
    this.search();
    return event;
  }

  private search() {
    this.loading = true;
    this.reportServiceV1.getTransactionReport(this.filters, this.pageable)
      .subscribe(result => {
        this.showResult(result);
        this.actionLoading(false);
      },
        error => {
          this.actionLoading(false);
          this.alertService.error("Erro", "Ocorreu um erro interno em nosso servidor. Se o erro persistir, por favor, contate o suporte.");
        });
  }

  showResult(result) {
    if (result != null) {
      this.pageResponse.pageNumber = result.pageable.pageNumber;
      this.pageResponse.totalElements = result.totalElements;
      this.pageResponse.totalPages = result.totalPages;
      this.pageResponse.content = result.content;
      this.transactions = this.pageResponse.content;
    } else {
      this.pageResponse.pageNumber = 0;
      this.pageResponse.totalElements = 0;
      this.pageResponse.totalPages = 0;
      this.transactions = this.pageResponse.content;

    }
    this.loading = false;
  }

  downloadCsv() {
    this.loading = true;
    this.reportServiceV1.getTransactionReportCsv(this.filters)
      .subscribe(response => {
        let binaryData = ["\ufeff", response];
        let downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob(<any>binaryData, { type: "application/text" }));
        let dateFormatPipe: DateFormatPipe = new DateFormatPipe();
        let date = dateFormatPipe.transform(new Date(), "YYYY-MM-DD-HH-mm");
        downloadLink.setAttribute('download', `Relatorio_Transacoes_${date}.csv`);
        document.body.appendChild(downloadLink);
        downloadLink.click();
        this.loading = false;
      },
        error => {
          this.alertService.error("Erro", "Ocorreu um erro interno em nosso servidor. Se o erro persistir, por favor, contate o suporte.");
        });
  }

  tosslePerOneForStatus(all) {
    if (this.allSelectedForStatus.selected) {
      this.allSelectedForStatus.deselect();
      return false;
    }
    if (this.searchStatusForm.controls.orderStatus.value.length == this.status.length) {
      this.allSelectedForStatus.select();
    }
  }
  toggleAllSelectionForStatus() {
    if (this.allSelectedForStatus.selected) {
      this.searchStatusForm.controls.orderStatus
        .patchValue([...this.status.map(item => item), 0]);
    } else {
      this.searchStatusForm.controls.orderStatus.patchValue([]);
    }
  }


  tosslePerOneForAffiliate(all) {
    if (this.allSelectedForAffiliate.selected) {
      this.allSelectedForAffiliate.deselect();
      return false;
    }
    if (this.searchAffiliateForm.controls.orderAffiliate.value.length == this.affiliate.length) {
      this.allSelectedForAffiliate.select();
    }
  }
  toggleAllSelectionForAffiliate() {
    if (this.allSelectedForAffiliate.selected) {
      this.searchAffiliateForm.controls.orderAffiliate
        .patchValue([...this.affiliate.map(item => item.id), 0]);

    } else {
      this.searchAffiliateForm.controls.orderAffiliate.patchValue([]);
    }
  }

  getStatusColor(status) {

    return "transaction-status transaction-" + status;
  }


  compareId(id1: number, id2: number) {
    if (id2 === 0) {
      return id1 === 0;
    }
    return id1 && id2 && id1 === id2;
  }

  isEnable() {
    return this.enableDate;
  }
}