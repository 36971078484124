<mat-drawer-container class="container{{checkoutClass}}" id="parentContainer" autosize>
  <mat-drawer autosize #drawer class="sidenav" (@slideInOut.done)="doneAnimation()"
    (@slideInOut.start)="startAnimation()" [@slideInOut]="size" *ngIf="isLoggedIn$ | async as isLoggedIn" mode="side"
    opened>
    <mat-toolbar>
      <div class="page-title menu">
        <div>
          <span [class.hide]="size=='in'">Bempaggo</span>
          <span (click)="toggleSize()">
            <i class="{{iconMenuTop}}" matTooltip="{{textIconMenuTop}}"></i>
          </span>
        </div>
      </div>
    </mat-toolbar>

    <mat-nav-list *ngIf="isLoggedIn$ | async">
      <div class="list-items" [ngClass]="{'hide' : size=='in'}">
        <div *ngFor="let menuItem of menu" class="item-menu">
          <a name="{{menuItem.text}}" [class.menu-ativo]="menuItem.ativo || menuItem.subMenuAtivo"
            [class.menu-inativo]="!menuItem.ativo" mat-list-item (click)="menuAction(menuItem)"
            title="{{menuItem.text}}">
            <i class="{{menuItem.icon}}"></i>
            <span [ngClass]="{'hide' : size=='in'}" class="menu-text-margin-left">{{menuItem.text}}</span>
            <a class="pull-right" [ngClass]="{'hide' : size=='in'}" *ngIf="!menuItem.subOpen && menuItem.subMenu">
              <i class="fal fa-angle-down"></i>
            </a>
            <a class="pull-right" [ngClass]="{'hide' : size=='in'}" *ngIf="menuItem.subOpen">
              <i class="fal fa-angle-up"></i>
            </a>
          </a>
          <div class="sub-items" [ngClass]="{'hide' : !menuItem.subOpen}" *ngFor="let subItem of menuItem.subMenu">
            <a name="{{subItem.text}}" [class.sub-menu-ativo]="subItem.ativo" (click)="menuAction(subItem, menuItem)"
              title="{{subItem.text}}">
              <span>{{subItem.text}}</span></a>
          </div>
        </div>
      </div>

      <div class="list-items center-text" [ngClass]="{'hide' : size=='out'}">
        <div *ngFor="let menuItem of menu" class="item-menu">
          <a *ngIf="menuItem.subMenu" [ngClass]="{'menu-ativo' : menuItem.ativo == true}" mat-list-item
            (click)="menuAction(menuItem)" title="{{menuItem.text}}" [matMenuTriggerFor]="menu">
            <i class="{{menuItem.icon}}" style="color: #79829c;" (hover)="style='color: red;'"></i>
            <mat-menu #menu="matMenu" yPosition="below" xPosition="after" overlapTrigger="false">
              <button mat-menu-item *ngFor="let subItem of menuItem.subMenu"
                (click)="menuAction(subItem, menuItem)">{{subItem.text}}</button>
            </mat-menu>
          </a>
          <a *ngIf="!menuItem.subMenu" [ngClass]="{'menu-ativo' : menuItem.ativo == true}" mat-list-item
            (click)="menuAction(menuItem)" title="{{menuItem.text}}">
            <i class="{{menuItem.icon}}" style="color: #79829c;"></i>
          </a>
        </div>
      </div>
    </mat-nav-list>
  </mat-drawer>

  <mat-drawer-content [class.content]="isLoggedIn$ | async">
    <app-header [size]="size"></app-header>
    <router-outlet></router-outlet>
  </mat-drawer-content>

</mat-drawer-container>

<!--MEMU AJUDA -->
<div class="help">
  <a href="https://bempaggo.atlassian.net/servicedesk" target="_blank">
    <i class="fal fa-question-circle"></i><span [class.hide]="size=='in'">Central de Ajuda</span>
  </a>
</div>