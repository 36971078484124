<div fxLayout="column" fxLayoutAlign="start stretch">
  <div class="pagamento-da-venda" fxLayout="row" fxFlex>
    <b>Splits</b>
  </div>
  <mat-divider class="mt-2"></mat-divider>
  <table mat-table [dataSource]="chargeSplits" *ngIf="chargeSplits">

    <ng-container matColumnDef="affiliate">
      <th mat-header-cell *matHeaderCellDef> Loja </th>
      <td mat-cell *matCellDef="let split"> {{split.affiliate.name}} </td>
    </ng-container>

    <ng-container matColumnDef="amount">
      <th mat-header-cell *matHeaderCellDef> Valor </th>
      <td mat-cell *matCellDef="let split"> {{split.amount/100 | CustomCurrency:'BRL'}} </td>
    </ng-container>

    <ng-container matColumnDef="percentage">
      <th mat-header-cell *matHeaderCellDef> % </th>
      <td mat-cell *matCellDef="let split"> {{((split.amount/100)/(chargeValue/100)*100).toFixed(2)}}% </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

  </table>

  <span class="msgEmpty" *ngIf="!chargeSplits">Não há splits para esta cobrança</span>
</div>
