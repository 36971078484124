import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { AuthService } from '../../core/auth/auth.service';
import { User } from '../../core/model/user';
import { AlertService } from '../../core/services/alert.service';
import { UserService } from '../../core/services/v0/user.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
  userData: UntypedFormGroup;
  error = '';
  loading = false;
  user: User = new User();
  equal = true;
  save = false;

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private userService: UserService,
    private authService: AuthService,
    private alertService: AlertService) { }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('currentUser'));
    this.userData = this._formBuilder.group({
      email: [this.user.email],
      oldPassword: [''],
      newPassword: [''],
      newPasswordConfirm: ['']
    });

  }

  changePass() {

    if (this.equal) {
      this.authService.login(this.user.username, this.userData.value.oldPassword)
        .subscribe(
          data => {
            this.alertService.confirm('Confirmar', 'Deseja alterar sua senha?')
              .then(confirmed => {
                if (!confirmed) { return; }
                this.userService.updatePassword(this.userData.value.email, this.userData.value.newPassword)
                  .subscribe(
                    response => {
                      this.alertService.success('Confirmada', 'Dados alterados com sucesso.');
                    },
                    error => {
                      this.alertService.error('Erro', 'Erro, senha incorreta.');
                    });
              });
          },
          error => {
            this.alertService.error('Erro', 'Erro, confira sua senha atual');
          });
    }
  }

  verifyPass() {
    if (this.userData.value.newPassword !== this.userData.value.newPasswordConfirm || this.userData.value.newPassword == '') {
      this.equal = false;
      this.save = false;
    } else {
      this.equal = true;
      this.save = true;
    }
  }
}

