<div class="geral" fxLayout='column'  class="card mb-0 shadow-none" *ngIf="data else">

  <div >

    <div fxLayout='row' fxLayoutGap="20px">
      <!-- Title -->
      <div fxLayout='column' fxLayoutAlign="start start">
        <div class="title-text">{{ data.mainText }}</div>
        <div class="title-value">{{ data.mainValue !== null && data.mainValue !== undefined ? data.mainValue : "0" }}
        </div>
      </div>

      <!-- Icon -->
      <div fxFlex fxLayoutAlign="end start">
        <div class="icon-shape text-white shadow bg-{{ data.type }}">
          <i class="{{ data.icon }}"></i>
        </div>
      </div>
    </div>

    <div fxLayout='row'>
      <!-- subTitle -->
      <div fxLayout='column' fxLayoutAlign="end start">
        <div class="subtitle-text">{{ data.minorText }}</div>
        <div class="subtitle-value">{{ data.minorValue !== null && data.minorValue !== undefined ? data.minorValue : "0"
          }}
        </div>
      </div>

      <!-- period -->
      <div fxFlex fxLayout='row' fxLayoutAlign="end end">
        <span class="text-{{ data.type }} mr-1"><i class="{{ data.nameIcon }}"></i></span>
        <span class="text-muted">{{ data.name }}</span>
      </div>
    </div>

  </div>

</div>