import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { DataFiles } from "../model/data-files";

@Injectable({
    providedIn: 'root'
})
export class DataFinder {
    constructor(private http: HttpClient) { }

    /** Return the list of menus to be used in the sidenav */
    public getMenuJson(): Promise<any> {
        return this.getJsonASync(DataFiles.MENU);
    }
    public getUFJson(): Promise<any> {
        return this.getJsonASync(DataFiles.UF);
    }
    /** Return the list of transactions status and colors */
    public getChargeStatusJson(): Promise<any> {
        return this.getJsonASync(DataFiles.CHARGE_STATUS);
    }
    /** Return the list of transactions status and colors */
    public getTransactionStatusJson(): Promise<any> {
        return this.getJsonASync(DataFiles.TRANSACTION_STATUS);
    }
    /** Return the list of transactions status and colors NOVO FEITO POR EVETON*/
    public getTransactionStatus(): Promise<any> {
        return this.getJsonASync(DataFiles.TRANSACTION_STATUS_NEW);
    }
    /** Return the list of customer_import status and colors */
    public getCustomerImportStatusJson(): Promise<any> {
        return this.getJsonASync(DataFiles.CUSTOMER_IMPORT_STATUS);
    }
    /** Return the list of customer_import status and colors */
    public getAlertStatus(): Promise<any> {
        return this.getJsonASync(DataFiles.ALERT_STATUS);
    }
    public getAlertType(): Promise<any> {
        return this.getJsonASync(DataFiles.ALERT_TYPE);
    }
    /**
   * Returns the object of the given file inside assets/data/, without extension
   * @param fileName Path to a specific json file.
   */
    public getJsonASync(fileName: DataFiles): Promise<any> {
        return new Promise((resolve, reject) => {
            this.http.get(`assets/data/${fileName}.json`).subscribe(
                data => {
                    resolve(data);
                },
                error => {
                    reject(`Fail to fetch ${fileName}.json file`);
                }
            );
        }).catch((reason) => this.handleError(reason));
    }

    public handleError(error: Response | any) {
        let errorMsg: string;
        if (error instanceof Response) {
            const body = error.json() || '';
            const err = JSON.stringify(body);
            errorMsg = `${error.status} - ${error.statusText || ''} ${err}`;
        } else {
            errorMsg = error.message ? error.message : error.toString();
        }
        throw new Error(errorMsg);
    }
}