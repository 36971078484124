import * as moment from "moment";
import { lastValueFrom } from "rxjs";
import { SubscriptionRequestV1 } from "src/app/core/model/request/subscription.request";
import { CardRequestV1, OrderType, ProfileType } from "src/app/core/model/v1.model";
import { AlertService } from "src/app/core/services/alert.service";
import { CustomerService } from "src/app/core/services/v0/customer.service";
import { OnetimeService } from "src/app/core/services/v0/onetime-transaction";
import { OnCustomer as OnCustomerV1 } from "src/app/core/services/v1/customer.service.v1";
import { SubscriptionServiceV1 } from "src/app/core/services/v1/subscription.service.v1";
import { NewTransactionComponent } from "./new-transaction.component";

export class ManuallyKeyedTransaction {
  constructor(
    private component: NewTransactionComponent,
    private onCustomer: CustomerService,
    private onCustomerV1: OnCustomerV1,
    private onetimeService: OnetimeService,
    private subscriptionServiceV1: SubscriptionServiceV1,
    private alertService: AlertService
  ) { }

  async doTransaction(document: string, cardRequest: CardRequestV1, token: string) {
    this.component.camposVazios = new Array();
    this.component.isInvalidFirstStepV1();
    this.component.isInvalidSecondStep();
    this.component.isInvalidThirdStepV1();

    if (this.component.camposVazios.length > 0) {
      this.alertService.error('Erro', 'Há um ou mais campos que não foram preenchidos: ' + this.component.camposVazios);
      this.component.loading = false;
      this.component.camposVazios = [];
    } else {
      try {
        this.component.loading = true;
        this.alertService.loading("Processando...");
        if (this.component.typeTransaction == OrderType.LOOSE) {
          await this.doOnTimePayment();
        } else {
          await this.doCustomer();
          await this.doAddress();
          await this.doCard(parseInt(this.component.customerId), this.component.cardRequest, this.component.token);
          await this.doSubscriptionPayment();
        }
      } catch (error) {
        this.showError(error, this.alertService);
      }
      this.component.loading = false;
    }
  }

  async doCustomer() {
    if (this.component.customerId && parseInt(this.component.customerId) > 0) {
      await lastValueFrom(this.onCustomerV1.update(this.component.clientDataSendV1, parseInt(this.component.customerId)));
    } else {
      const clientUrl = await lastValueFrom(this.onCustomerV1.create(this.component.clientDataSendV1));
      const url = clientUrl.headers.get('Location');
      const clientResult = this.onCustomerV1.getClientByURI(url);
      const result = await lastValueFrom(clientResult);
      this.component.customerId = result.id;
      this.component.customerDocument = result.document;
    }
  }

  async doAddress() {
    const result = await lastValueFrom(this.onCustomerV1.getByDocument(this.component.clientDataV1.document));
    if (result.birthdate != null) {
      let birthdate = new Date(result.birthdate);
      this.component.clientDataV1.birthdate = moment.utc(birthdate).format('DD/MM/YYYY');
    }
    this.component.linkAddress = result.addresses;
    this.component.linkBestAddress = result.bestAddress;
    this.component.transacaoDataSend.idCliente = parseInt(this.component.customerId);
    if (this.component.newAddress == true) {
      await lastValueFrom(this.onCustomer.createAddress(this.component.linkAddress, this.component.addressSelected));
      const resultClient = lastValueFrom(this.onCustomer.getAddress(this.component.linkAddress));
      this.component.addressesList = await resultClient.then();
    }
  }

  async doCard(id: number, cardRequest: CardRequestV1, token: string) {
    const customerResult = await lastValueFrom(this.onCustomerV1.onCard().create(id, cardRequest, token));
    const result = await lastValueFrom(this.onCustomerV1.onCard().getCardByUriWithToken(customerResult.headers.get('Location')));
    this.component.cardId = result;
  }

  async doSubscriptionPayment() {
    let subscription = new SubscriptionRequestV1();
    subscription.planId = this.component.transacaoData.idProduto;
    subscription.affiliateId = this.component.transacaoData.affiliateId;
    subscription.customerId = Number(this.component.customerId);
    subscription.payment.installments = 1;
    subscription.payment.profileId = this.component.cardId;
    subscription.payment.type = ProfileType.CREDIT_CARD;
    const result = await lastValueFrom(this.subscriptionServiceV1.createSubscription(subscription));
    if (result.success) {
      this.getTransactionInfo(result.data);
    } else {
      this.alertService.error('Erro', 'Não foi possível efetuar a transação. <br/>' + result.message);
    }
  }

  async doOnTimePayment() {
    let cardRequest = {
      cardNumber: this.component.cardRequest.number,
      expiration: {
        month: this.component.cardRequest.expiration.month,
        year: this.component.cardRequest.expiration.year
      },
      cvv: this.component.cardRequest.cvv ? this.component.cardRequest.cvv : null,
      holder: {
        name: this.component.cardRequest.holder.name,
        document: this.component.cardRequest.holder.document
      }
    };

    let phoneRequest = {
      countryCode: "55",
      areaCode: this.component.clientDataV1.phone.code,
      number: this.component.clientDataV1.phone.number
    };

    let customerRequest = {
      name: this.component.clientDataV1.name,
      document: this.component.clientDataV1.document,
      birthdate: this.component.clientDataV1.birthdate,
      phone: phoneRequest,
      email: this.component.clientDataSendV1.email
    };

    let oneTimeRequest = {
      affiliateId: this.component.loose.affiliateId,
      card: cardRequest,
      customer: customerRequest,
      value: Math.round(this.component.loose.value * 100),
      installments: this.component.installments,
      yourReferenceId: this.component.loose.referenceId
    };
    try {
      const result = await this.onetimeService.authorizeAndCapture(oneTimeRequest);
      this.getChargeInfo(result);
    } catch (error) {
      this.showError(error, this.alertService);
    }

  }

  getChargeInfo(data: any) {
    if (data && data.status === "PAY") {
      // Sucesso, o pagamento foi aprovado
      this.alertService.success('Sucesso', "Aprovado").then(success => {
        if (success) {
          this.component.navigateToTransactions(data.id);
        }
      });
    } else {
      const errorMessage = data && data.transactions && data.transactions.length > 0 ?
        this.alertService.error('Não Autorizada', `[${data.transactions[0].returnCode}]  ${data.transactions[0].returnMessage}`)
        :
        this.alertService.error('Erro', 'Não foi possível efetuar a transação.');
    }
  }

  getTransactionInfo(data: any) {
    if (data.status === "APPROVED") {
      this.alertService.success('Sucesso', "Aprovado").then(success => {
        if (success) {
          this.component.navigateToTransactions(data.idCharge);
        }
      });
    } else {
      this.alertService.error('Erro', 'Não foi possível efetuar a transação. <br/>' + data.message);
    }
  }

  showError(error: any, alertService: AlertService) {
    if (error && Array.isArray(error.error)) {
      const messages = error.error.map((err: any) => {
        const translatedMessage = this.translateError(err.code, err.message);
        return `[${err.code}] ${translatedMessage}`;
      }).join('<br/>');
      alertService.error('Erro', messages);
    } else if (error && error.error && error.error.message && error.error.data && error.error.data.code) {
      const translatedMessage = this.translateError(error.error.data.code, error.error.message);
      alertService.warning('Não aprovado', `[${error.error.data.code}] ${translatedMessage}`);
    } else if (error && error.error && error.error.message) {
      alertService.error('Erro', `${error.error.message}`);
    } else if (error && error.error) {
      alertService.error('Erro', `${error.error.error}`);
    } else {
      alertService.error('Erro', 'Não foi possível efetuar a transação. <br/>' + JSON.stringify(error));
    }
  }

  translateError(code: string, message: string): string {
    return errorTranslations[code] || message || "Erro desconhecido.";
  }

}

const errorTranslations = {
  "01": "Não há estabelecimento de pagamento",
};