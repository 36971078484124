import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from "@angular/core";
import { MatDialog } from '@angular/material/dialog';
import { Observable } from "rxjs";
import { AuthService } from "../../auth/auth.service";
import { environment } from "src/environments/environment";
import { PagePayPutRequest, PagePayRequest, PagePayResponse } from "../../model/v1.model";

@Injectable({
    providedIn: 'root'
})
export class PagePayServiceV1 {

    editPagepay: EventEmitter<boolean>;

    constructor(private http: HttpClient, public dialog: MatDialog, private authService: AuthService) {
        this.editPagepay = new EventEmitter<boolean>();
    }
    public getByUri(uri: string): Observable<PagePayResponse> {
        return this.http.get<PagePayResponse>(uri);
    }
    public getByName(name: string): Observable<PagePayResponse> {
        return this.http.get<PagePayResponse>(`${environment.apiUrl}/api/v1/pagepays`);
    }

    public createWithoutPlan(request: PagePayRequest): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}/api/v1/checkouts`, request, { observe: 'response' });
    }
    public uploadImage(pagepayId: number, request: Blob) {
        let formData: FormData = new FormData();
        formData.append('file', request);
        return this.http.post<any>(`${environment.apiUrl}/api/v1/checkouts/${pagepayId}/image`, formData);
    }
    public update(pagepay: PagePayResponse, request: PagePayPutRequest): Observable<any> {
        return this.http.put<any>(`${environment.apiUrl}/api/v1/checkouts/${pagepay.id}`, request);
    }
    public getById(id: number): Observable<PagePayResponse> {
        return this.http.get<PagePayResponse>(`${environment.apiUrl}/api/v1/checkouts/${id}`);
    }
    public getAll(): Observable<PagePayResponse> {
        return this.http.get<PagePayResponse>(`${environment.apiUrl}/api/v1/checkouts/all`);
    }
    public active(pagepay: PagePayResponse): Observable<any> {
        return this.http.put<any>(`${environment.apiUrl}/api/v1/checkouts/${pagepay.id}/active`, null);
    }
    public inactive(pagepay: PagePayResponse): Observable<any> {
        return this.http.put<any>(`${environment.apiUrl}/api/v1/checkouts/${pagepay.id}/inactive`, null);
    }
    public getUrl(uuid: string) {
        let companyId: number = this.authService.getCurrentUser().idEmpresa;
        return `${location.protocol}://${location.host}/#/pagamento/${companyId}/${uuid}`;
    }

}