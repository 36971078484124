import { Injectable } from "@angular/core";
import * as moment from 'moment';

@Injectable({
    providedIn: 'root'
})
export class DateUtils {
    constructor() {

    }

    //return false if endDate is 'days' after  beginDate
    compareEndToBeginDays(endDate: Date, beginDate: Date, days: number) {
        let beginDateDays = new Date(beginDate);
        beginDateDays.setDate(beginDate.getDate() + days);

        return !moment(endDate).isAfter(beginDateDays);
    }

    compareEndToBeginMonth(endDate: Date, beginDate: Date, month: number) {
        if(beginDate == null || endDate == null){
            return false;
        }
        let beginDateMonth = new Date(beginDate);
        beginDateMonth.setMonth(beginDate.getMonth() + month);
        return !moment(endDate).isAfter(beginDateMonth);
    }

    compareBeginToEndDays(beginDate: Date, endDate: Date, days: number) {

    }

}