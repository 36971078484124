<mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>

<mat-dialog-content class="mb-5 mt-2 font-weight-bold">
 <h5>Deseja realmente cancelar este {{charge.transactions[0].paymentMethod | translate}}?</h5>
</mat-dialog-content>
<mat-dialog-content class="mb-5 font-weight-light">
  <h6>O cancelamento não poderá ser desfeito.</h6>
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions align="end">
  <button mat-button class="btn btn-light" mat-dialog-close>Fechar</button>
  <button mat-button class="btn btn-success" (click)="cancelCharge()" cdkFocusInitial>Sim, Cancelar</button>
</mat-dialog-actions>
