import { UserProfile } from "./perfil";

export class User {
    id: string;
    name: string;
    username: string;
    email: string;
    password?: string;
    nomeEmpresa: string;
    profiles: UserProfile[] = [];
    status: UserStatusType;
    ativo: boolean;
}

export enum UserStatusType {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
    PENDING = 'PENDING'
}