import { Component, OnInit, ViewChild } from "@angular/core";
import { PagePayResponse } from "src/app/core/model/v1.model";
import { AlertService } from "src/app/core/services/alert.service";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, MatSortable } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { MenuItem } from 'primeng/api';
import { PagePayServiceV1 } from "src/app/core/services/v1/pagepay.service.v1";

@Component({
  selector: 'app-pagepay-list',
  templateUrl: './pagepay-list.component.html',
  styleUrls: ['./pagepay-list.component.scss']
})
export class PagepayListComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  name: string;
  paymentLinks: MatTableDataSource<PagePayResponse>;
  crumbs: MenuItem[];
  displayedColumns: string[] = ['id', 'name', 'amount', 'sold', 'active'];
  companyId: number;
  shareUrl: string;
  loading: Boolean = false;
  
  constructor(
    private alertService: AlertService,
    private pagepayService: PagePayServiceV1
  ) { }

  ngOnInit() {
    this.initCrumbs();
    this.getAll();
  }

  public initCrumbs() {
    this.crumbs = [
      //{ label: 'Link de Pagamento', routerLink: ['/paymentLinks'] },
      { label: 'Link de Pagamento' }
    ];
  }

  getUrl(uuid: string) {
    return this.pagepayService.getUrl(uuid);
  }

  getAll() {
    this.loading = true;
    this.pagepayService.getAll().subscribe(
      result => {
        this.setPagePayList(result);
      },
      error => {
        this.alertService.handleError(error);
      }
    )
    this.loading = false;
  }

  getByName() {
    this.pagepayService.getByName(this.name).subscribe(
      result => {
        this.setPagePayList(result);
      },
      error => {
        this.alertService.handleError(error);
      }
    )
  }

  setPagePayList(result) {
    this.paymentLinks = new MatTableDataSource<PagePayResponse>(result);
    this.paymentLinks.paginator = this.paginator;
    this.paymentLinks.sort = this.sort;
    this.sortDataSource('active', 'desc');
    this.sortDataSource('id', 'desc');
  }
  sortDataSource(id: string, start: string) {
    this.paymentLinks.sort.sort(<MatSortable>({ id: id, start: start }));
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.paymentLinks.filter = filterValue.trim().toLowerCase();

    if (this.paymentLinks.paginator) {
      this.paymentLinks.paginator.firstPage();
    }
  }

}