import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { AppService } from 'src/app/core/services/v0/app.service';
import { CovenantService } from 'src/app/core/services/v0/covenant.service';

@Component({
  selector: 'app-payment-routes',
  templateUrl: './payment-routes.component.html',
  styleUrls: ['./payment-routes.component.scss']
})
export class PaymentRoutesComponent implements OnInit {
  crumbs: MenuItem[];
  loading: Boolean;
  establishmentList = [];
  brandsList = [];
  editMode: boolean = false;

  constructor(
    private conveniosService: CovenantService,
    private appService: AppService,
  ) { }

  ngOnInit(): void {
    this.loading = true;
    this.initCrumbs();
    this.loading = false;
  }

  public initCrumbs() {
    this.crumbs = [
      { label: 'Configurações' },
      { label: 'Rotas de Pagamento' }
    ];
    this.getAllEstablishment();
    this.getAllBrands();
  }

  getAllEstablishment() {
    this.conveniosService.getAllCovenants()
      .subscribe(result => {
        result.forEach(establishment => {
          this.establishmentList.push(establishment);
        });
      });
  }

  getAllBrands() {
    this.appService.getAllAdministradoras()
      .subscribe(result => {
        result.forEach(administradora => {
          this.brandsList.push(administradora);
        });
      });
  }

  edit() {
    this.editMode = true;
  }

  save() {
    this.editMode = false;
  }

  cancel() {
    this.editMode = false;
  }

}
