import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Chart } from 'chart.js';
import { CustomCurrencyPipe } from 'src/app/core/helper/pipe/custom-currency.pipe';
import { MetricsServiceV1 } from 'src/app/core/services/v1/metrics-service';
import { Data } from '../../core/model/data';
import { DataCard, DataCardColors } from '../../core/model/data-card';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {
  mode = new UntypedFormControl('side');
  salesDataArray: DataCard[];
  datasRecorrente: DataCard[] = new Array();
  datasAssinaturas: DataCard[] = new Array();
  toolTipCount: any;
  chart = [];

  constructor(
    private currencyPipe: CustomCurrencyPipe,
    private metricsServiceV1: MetricsServiceV1,
    public data: Data,
    private router: Router) { }

  ngOnInit() {
      this.generateCard();
      this.generateCardSubscriptions();
      this.generateChart();    
  }

  generateCardSubscriptions() {
    this.metricsServiceV1.cardAssinaturasAtivas().subscribe(result => {
      this.datasAssinaturas.push({ text: "Total", value: result.totalAdimplentes });
      this.datasAssinaturas.push({ text: "Novas no Mês", value: result.totalMes });
      this.datasAssinaturas.push({ text: "Novas no dia", value: result.totalDia });
    });

    this.metricsServiceV1.cobrancasRecorrenteCard().subscribe(result => {
      this.datasRecorrente.push({ text: "A Cobrar", value: result.qtdFaltante });
      this.datasRecorrente.push({ text: "Aprovadas", value: result.qtdAprovada });
      this.datasRecorrente.push({ text: "Não Autorizadas", value: result.qtdNaoAutorizada });
    });
  }

  generateCard() {
    this.salesDataArray = new Array<DataCard>();

    this.salesDataArray.push({
      text: " Hoje",
      value: this.currencyPipe.transform(0, 'BRL'),
      icon: DataCardColors.iconValue,
      bgcard: DataCardColors.bgcardValue,
      order: 1
    });

    this.salesDataArray.push({
      text: " No Mês",
      value: this.currencyPipe.transform(0, 'BRL'),
      icon: DataCardColors.iconValue,
      bgcard: DataCardColors.bgcardValue,
      order: 3
    });

    this.salesDataArray.push({
      text: " Ticket Médio",
      value: this.currencyPipe.transform(0, 'BRL'),
      icon: DataCardColors.iconValue,
      bgcard: DataCardColors.bgcardValue,
      order: 2
    });

    //Consulta dados do dia
    this.metricsServiceV1.getSalesDay().subscribe(response => {
      if (response.data) {
        this.salesDataArray[0].value = this.currencyPipe.transform(response.data[0].valor, 'BRL');
        this.salesDataArray[0].total = response.data[0].total
      }
    });

    //Consulta dados do mês
    this.metricsServiceV1.getSalesMonth().subscribe(response => {
      if (response.data) {
        this.salesDataArray[1].value = this.currencyPipe.transform(response.data[0].valor, 'BRL');
        this.salesDataArray[1].total = response.data[0].total
      }
    });

    this.metricsServiceV1.getAvgTicket().subscribe(response => {
      if (response.data) {
        this.salesDataArray[2].value = this.currencyPipe.transform(response.data, 'BRL');
      }
    });

  }

  generateChart() {
    this.metricsServiceV1.getTotalCurrentMonth()
      .subscribe(response => {
        let totalCurrentMonth = response.data;

        let chartLabels = totalCurrentMonth.map(res => {
          let data = res.dataVenda.split("/");
          return `${data[0]}/${data[1]}`;
        });
        let chartData = totalCurrentMonth.map(res => res.total);

        this.chart = new Chart('canvas', {
          type: 'line',
          data: {
            labels: chartLabels,
            datasets: [{
              data: chartData,
              borderColor: '#3cba9f',
              fill: false
            }]
          },

          options: {
            maintainAspectRatio: false,
            responsive: true,
            legend: {
              display: false
            },
            scales: {
              xAxes: [{
                display: true
              }],
              yAxes: [{
                display: true,
                ticks: {
                  beginAtZero: true,
                  userCallback: function (label, index, labels) {
                    // when the floored value is the same as the value we have a whole number
                    if (Math.floor(label) === label) {
                      return label;
                    }

                  },
                }
              }]
            }
          }
        });
      });
  }

  goToTransactions(data) {
    if (data.bgcard === "card-count") {
      if (data.text === "Vendidos no dia") {
        let aux = new Date();
        let begin = new Date(aux.getFullYear(), aux.getMonth(), aux.getDate(), 0, 0, 0);
        let end = new Date(Date.now());
        let datas = [
          { dataInicio: begin, dataFim: end }
        ];
        this.data.storage = datas;
        this.router.navigate(['transactions']);
      } else {
        let aux = new Date();
        let begin = new Date(aux.getFullYear(), aux.getMonth(), aux.getDate(), 0, 0, 0);
        begin.setDate(1);
        let end = new Date(begin.getFullYear(), begin.getMonth() + 1, 0);

        let datas = [
          { dataInicio: begin, dataFim: end }
        ];
        this.data.storage = datas;
        this.router.navigate(['transactions']);
      }
    }
  }

}