import { Component, Input, OnInit } from '@angular/core';
import { CreditCard } from 'src/app/core/model/v2.model';

export class CreditCardApp {
  card: CreditCard;
  installments: number;
  value: number;
}

@Component({
  selector: 'app-credit-card',
  templateUrl: './credit-card.component.html',
  styleUrls: ['./credit-card.component.scss']
})
export class CreditCardComponent implements OnInit {
  @Input() creditCard: CreditCardApp;

  constructor() { }

  ngOnInit(): void {
  }

}
