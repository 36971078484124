import { HttpHeaders } from "@angular/common/http";
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import * as lodash from 'lodash';
import { StringUtils } from "src/app/core/helper/string-utils";
import { Administradora } from "src/app/core/model/administradora";
import { CardRequest } from "src/app/core/model/request/card.request";
import { CardBrandResponse } from "src/app/core/model/response/card-brand.response";


@Component({
    selector: 'app-payment-form',
    templateUrl: './payment-form.component.html',
    styleUrls: ['./payment-form.component.scss']
})

export class PaymentFormComponent implements OnInit {
    months: string[];
    years: string[];
    administradoras: Administradora[];
    administradoraSelecionada: number;
    cardBrands: CardBrandResponse[]
    selectedCardBrand: string

    token = this.route.snapshot.paramMap.get('token');
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.token
        })
    };

    @Input() card: CardRequest = new CardRequest();
    @ViewChild("paymentForm") paymentForm;
    @Output() btnClick = new EventEmitter();
    @Input() options: PaymentFormOptions;
    myRecaptcha: boolean;

    constructor(
        private route: ActivatedRoute,
        private stringUtils: StringUtils
    ) { }

    ngOnInit() {
        let paymentOptionsDefault: PaymentFormOptions = { loading: false, token: null, hasSubmit: true, submitBtnTxt: "Finalizar", hasCaptcha: false };
        this.options = lodash.merge(paymentOptionsDefault, this.options);
        this.months = this.stringUtils.getMonths();
        this.years = this.stringUtils.getAnos();
        this.myRecaptcha = false;
    }

    onSubmit() {
        if (this.paymentForm.valid) {
            if (this.btnClick.observers.length > 0) {
                this.btnClick.emit();
            }
        }
    }

    onScriptLoad() {
    }

    onScriptError() {
    }
}

export interface PaymentFormOptions {
    loading?: boolean;
    token?: string;
    hasSubmit?: boolean;
    submitBtnTxt?: string;
    hasCaptcha?: boolean;
}
