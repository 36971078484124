import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import * as lodash from 'lodash';
import { ValidationHelper } from "src/app/core/helper/validation.helper";
import { CustomerRequest } from "src/app/core/model/request/customer.request";
import { CustomerResponse } from "src/app/core/model/response/customer.response";
import { AlertService } from "src/app/core/services/alert.service";
import { CustomerService } from "src/app/core/services/v0/customer.service";

@Component({
    selector: 'app-client-form',
    templateUrl: './client-form.component.html',
    styleUrls: ['./client-form.component.scss']
})

export class ClientFormComponent implements OnInit {
    customerResponse: CustomerResponse = new CustomerResponse();
    customerRequest: CustomerRequest = new CustomerRequest();
    inputPhone: string;
    @ViewChild("customerForm") customerForm;
    @Input() options: ClientFormOptions;
    @Output() btnClick = new EventEmitter();

    constructor(
        public validationHelper: ValidationHelper,
        public customerService: CustomerService,
        public alertService: AlertService
    ) { }

    ngOnInit() {
        let clientOptionsDefault: ClientFormOptions = { layout: "row", showAddress: false, hasSubmit: true, submitBtnTxt: "Finalizar", showNotRequired: true, loading: false, fillFields: true, token: null };
        this.options = lodash.merge(clientOptionsDefault, this.options);
    }

    onSubmit() {
        if (this.customerForm.valid) {
            if (this.btnClick.observers.length > 0) {
                this.btnClick.emit();
            } else {

            }
        }
    }

    getClienteByDocumento() {
        if (this.customerRequest.document.length === 11 || this.validationHelper.validaCpf(this.customerRequest.document)) {
            this.customerService.getByDocument(this.customerRequest.document)
                .subscribe(result => {
                    if (this.options.fillFields) {
                        this.customerResponse = result.data;
                        if (this.customerResponse.areaCode && this.customerResponse.phone) {
                            this.inputPhone = `${this.customerResponse.areaCode}${this.customerResponse.phone}`;
                        }
                    } else {
                        this.customerResponse.id = result.data.id;
                    }
                },
                    responseError => {
                        //this.alertService.error("Erro", error.msg);
                        if (responseError.error.status == 400) {
                        }
                    });
        } else {
            this.alertService.error('Erro', 'CPF Inválido.');
        }
    }
}
export interface ClientFormOptions {
    layout?: string;
    showAddress?: boolean;
    hasSubmit?: boolean;
    submitBtnTxt?: string;
    showNotRequired: boolean;
    loading?: boolean;
    fillFields?: boolean;
    token?: string;
}