import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-environment-label',
  templateUrl: './environment-label.component.html',
  styleUrls: ['./environment-label.component.scss']
})
export class EnvironmentLabelComponent implements OnInit {

  environmentLabel: string | null = null;

  ngOnInit(): void {
    if (environment.developer) {
      this.environmentLabel = 'Desenvolvimento';
    } else if (environment.sandbox) {
      this.environmentLabel = 'Homologação';
    } else if (environment.local) {
      this.environmentLabel = 'Local';
    } else {
      this.environmentLabel = 'Unknown';
    }
  }

  isProduction(): boolean {
    return environment.production;
  }
}
