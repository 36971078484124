import { Component, OnInit, ViewChild } from '@angular/core';
import { AffiliateMinimalResponse, CancelationReasonReportFilter, CancelationReasonReportResponse, OrderType, ProductResponse } from 'src/app/core/model/v1.model';
import { AlertService } from 'src/app/core/services/alert.service';
import { DataCard, DataCardColors } from 'src/app/core/model/data-card';
import { ReportServiceV1 } from 'src/app/core/services/v1/report.service.v1';
import { CustomCurrencyPipe } from 'src/app/core/helper/pipe/custom-currency.pipe';
import { MatOption } from '@angular/material/core';
import { AffiliateServiceV1 } from 'src/app/core/services/v1/affiliate.service.v1';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Component({
    selector: 'app-cancelation-reason-order-report',
    templateUrl: './cancelation-reason-order-report.component.html',
    styleUrls: ['./cancelation-reason-order-report.component.scss']
  })

export class CancelationReasonOrderReportComponent implements OnInit {

  types:any[];
  products:ProductResponse[];
  filter:CancelationReasonReportFilter;
  response:CancelationReasonReportResponse;
  quantityDataCard:DataCard;
  valueDataCard:DataCard;
  affiliate: AffiliateMinimalResponse[];
  @ViewChild('allSelectedForAffiliate') private allSelectedForAffiliate: MatOption;
  searchAffiliateForm: UntypedFormGroup;
  
  displayedColumns: string[] = [
    'reason',
    'quantity',
    'income',
    'percent'];

  constructor(
    private orderReportService:ReportServiceV1,
    private alertService:AlertService,
    private currencyPipe: CustomCurrencyPipe,
    private formBuilder: UntypedFormBuilder,
    private affiliateService: AffiliateServiceV1
    ) {}

  ngOnInit() {
    this.types = Object.values(OrderType).filter(x => typeof x === 'string');
    this.searchAffiliateForm = this.formBuilder.group({
      orderAffiliate: new UntypedFormControl('')
    });
    this.affiliateService.getAll().subscribe(result => this.affiliate = result);
    this.filter = new CancelationReasonReportFilter();
    this.filter.products = [];
    this.filter.end = new Date();
    this.filter.begin = new Date();
    this.filter.begin.setDate(1);
    this.search();
  }

  search(){
    this.orderReportService.getCancelationReasonReport(this.filter).subscribe(
      result => {
        this.response = result;
        this.quantityDataCard = { text: 'Total', value:  result.quantity, icon: DataCardColors.iconQuantity, bgcard: DataCardColors.bgcardQuantity, mask: false };
        this.valueDataCard = { text: 'Valor total', value:  `${this.currencyPipe.transform(result.value, 'BRL')}`, icon: DataCardColors.iconDolar, bgcard: DataCardColors.bgCardValueTotal, mask: true };
      },
      error => this.alertService.handleError(error)
    );
    
  }
  downloadCsv() {
    throw new Error('Method not implemented');
  }

  tosslePerOneForAffiliate(all) {
    if (this.allSelectedForAffiliate.selected) {
      this.allSelectedForAffiliate.deselect();
      return false;
    }
    if (this.searchAffiliateForm.controls.orderAffiliate.value.length == this.affiliate.length) {
      this.allSelectedForAffiliate.select();
    }
  }
  toggleAllSelectionForAffiliate() {
    if (this.allSelectedForAffiliate.selected) {
      this.searchAffiliateForm.controls.orderAffiliate
        .patchValue([0, ...this.affiliate.map(item => item)]);

    } else {
      this.searchAffiliateForm.controls.orderAffiliate.patchValue([]);
    }
  }

  
}