import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/auth/auth.guard';
import { PagePermissions } from './core/permissions/page-permissions';
import { BillingRulePaymentPage } from './pages/billing-rule-payment-page/billing-rule-payment-page.component';
import { BillingRuleTransaction } from './pages/billing-rule-payment-page/billing-rule-transaction/billing-rule-transaction.component';
import { ClientDetailsComponent } from './pages/clients/client-details/client-details.component';
import { ClientsComponent } from './pages/clients/clients.component';
import { DisputedComponent } from './pages/disputed/disputed.component';
import { EstablishmentDetailsComponent } from './pages/establishment/establishment-details/establishment-details.component';
import { EstablishmentComponent } from './pages/establishment/establishment.component';
import { HomeComponent } from './pages/home/home.component';
import { CustomerImportWizardComponent } from './pages/import/customer-import-wizard/customer-import-wizard.component';
import { ImportWalletComponent } from './pages/import/import-wallet.component';
import { NewImportWalletComponent } from './pages/import/new-import/new-import-wallet.component';
import { LoginComponent } from './pages/login/login.component';
import { LogoutComponent } from './pages/logout/logout.component';
import { EmptyAccountsComponent } from './pages/my-account/empty-accounts-component/empty-accounts-component.component';
import { MyAccountComponent } from './pages/my-account/my-account.component';
import { TransferDetailsComponent } from './pages/my-account/transfer-details/transfer-details.component';
import { NotAllowedComponent } from './pages/not-allowed/not-allowed.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { NewPagepayComponent } from './pages/pagepay/new-pagepay/new-pagepay.component';
import { PagePayDetailsV1Component } from './pages/pagepay/pagepay-details/pagepay-details-v1.component';
import { PagepayListComponent } from './pages/pagepay/pagepay-list/pagepay-list.component';
import { SharedLinkFbComponent } from './pages/pagepay/shared/shared-link-fb/shared-link-fb.component';
import { NewPlanComponent } from './pages/plans/new-plan/new-plan.component';
import { PlansListComponent } from './pages/plans/plan-list.component';
import { PlanDetailsV1Component } from './pages/plans/plans-details/plan-details-v1.component';
import { CancelationReasonOrderReportComponent } from './pages/reports/cancelation-reason/cancelation-reason-order-report.component';
import { ChargesComponent } from './pages/reports/charges/charges.component';
import { DeniedComponent } from './pages/reports/denied/denied.component';
import { ReportsComponent } from './pages/reports/reports.component';
import { SubscriptionReportComponent } from './pages/reports/subscription/subscription-report.component';
import { AcquirerMessageTransactionReportComponent } from './pages/reports/transaction/acquirer-message/acquirer-message-transaction-report';
import { ApprovedTransactionReportComponent } from './pages/reports/transaction/approved/approved-transaction-report.component';
import { LiquidoReportComponent } from './pages/reports/transaction/liquido/liquido-report.component';
import { RefundTransactionReportComponent } from './pages/reports/transaction/refund/refund-transaction-report.component';
import { TransactionsReportComponent } from './pages/reports/transaction/transactions-report/transactions-report.component';
import { SalesDetailsComponent } from './pages/sales-details/sales-details.component';
import { PaymentRoutesComponent } from './pages/settings/payment-routes/payment-routes.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { NewTransactionComponent } from './pages/transactions/new-transaction/new-transaction.component';
import { PaymentPage } from './pages/transactions/new-transaction/payment-page/payment-page.component';
import { TransactionsComponent } from './pages/transactions/transactions.component';
import { UserDetailsEditComponent } from './pages/user/details/edit/user-details-edit.component';
import { UserDetailsComponent } from './pages/user/details/user-details.component';
import { UserQueryComponent } from './pages/user/query/user-query.component';
import { UserComponent } from './pages/user/user.component';
const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    data: { header: 'Dashboard', title: 'Bempaggo | Home', roles: PagePermissions.HOME },
    canActivate: [AuthGuard]
  },
  {
    path: 'my-account/transfer-details',
    component: TransferDetailsComponent,
    data: { header: 'Detalhes da transferência', title: 'Bempaggo | Financeiro', roles: PagePermissions.HOME },
    canActivate: [AuthGuard]
  },
  {
    path: 'login',
    component: LoginComponent,
    data: { title: 'Bempaggo | Login' }
  },
  {
    path: 'logout',
    component: LogoutComponent,
    data: { title: 'Bempaggo | Logout' }
  },
  {
    path: 'settings/user',
    component: UserComponent,
    data: { header: 'Alterar senha', title: 'Bempaggo | Alterar Senha' },
    canActivate: [AuthGuard]
  },
  {
    path: 'settings/users/details/:id',
    component: UserDetailsComponent,
    data: { header: 'Detalhes do usuário', title: 'Bempaggo | Detalhes do usuário', roles: PagePermissions.USER_PERMISSIONS },
    canActivate: [AuthGuard]
  },
  {
    path: 'settings/users',
    component: UserQueryComponent,
    data: { header: 'Usuário', title: 'Bempaggo | Lista de Usuário', roles: PagePermissions.USER_PERMISSIONS },
    canActivate: [AuthGuard]
  },
  {
    path: 'settings/users/new',
    component: UserDetailsEditComponent,
    data: { header: 'Novo Usuário', title: 'Bempaggo | Cria Usuário', roles: PagePermissions.USER_NEW },
    canActivate: [AuthGuard]
  },
  {
    path: 'settings/users/edit/:id',
    component: UserDetailsEditComponent,
    data: { header: 'Editar Usuário', title: 'Bempaggo | Edita Usuário', roles: PagePermissions.USER_EDIT },
    canActivate: [AuthGuard]
  },
  {
    path: 'sales/sales-details/:id',
    component: SalesDetailsComponent,
    data: {
      header: 'Detalhes da Venda',
      title: 'Bempaggo | Detalhes da Venda',
      roles: PagePermissions.TRANSACTION
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'sales/transactions',
    component: TransactionsComponent,
    data: {
      header: 'Histórico de Vendas',
      title: 'Bempaggo | Histórico de Vendas',
      roles: PagePermissions.TRANSACTION
    },
    canActivate: [AuthGuard]
  },
  // {
    // path: 'sales/transactions/new',
    // component: NewTransactionComponent,
    // data: {
      // header: 'Nova Venda',
      // title: 'Bempaggo | Nova Venda',
      // roles: PagePermissions.TRANSACTION_NEW
    // },
    // canActivate: [AuthGuard]
  // },
  {
    path: 'sales/transactions/payment',
    component: PaymentPage,
    data: {
      header: 'Pagamento',
      title: 'Bempaggo | Nova Venda',
      roles: PagePermissions.TRANSACTION_NEW
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'sales/import-customers',
    component: ImportWalletComponent,
    data: {
      header: 'Lista de Importações',
      title: 'Bempaggo | Lista de Importações',
      roles: PagePermissions.IMPORT_WALLET
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'sales/import',
    component: CustomerImportWizardComponent,
    data: {
      header: 'Nova Importação',
      title: 'Bempaggo | Lista de Importações',
      roles: PagePermissions.IMPORT_WALLET
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'sales/import-customers/new',
    component: NewImportWalletComponent,
    data: {
      header: 'Nova Importação',
      title: 'Bempaggo | Nova Importação',
      roles: PagePermissions.IMPORT_WALLET
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'subscribers/:id',
    component: ClientDetailsComponent,
    data: { header: 'Detalhes do Cliente', title: 'Bempaggo | Detalhes do Cliente', roles: PagePermissions.CUSTOMER_EDIT_DETAILS },
    canActivate: [AuthGuard]
  },
  {
    path: 'subscribers',
    component: ClientsComponent,
    data: { header: 'Lista de Clientes', title: 'Bempaggo | Clientes', roles: PagePermissions.CUSTOMER },
    canActivate: [AuthGuard]
  },
  {
    path: 'not-found',
    component: NotFoundComponent,
    data: { header: '', title: 'Bempaggo | 404' }
  },
  {
    path: 'notallowed',
    component: NotAllowedComponent,
    data: { header: '', title: 'Bempaggo | 403' },
    canActivate: [AuthGuard]
  },
  // {
    // path: 'plans',
    // component: PlansListComponent,
    // data: { header: 'Planos de Assinaturas', title: 'Bempaggo | Assinatura', roles: PagePermissions.PLAN },
    // canActivate: [AuthGuard]
  // },
  // {
    // path: 'plans/new',
    // component: NewPlanComponent,
    // data: { header: 'Nova Assinatura', title: 'Bempaggo | Assinatura', roles: PagePermissions.PLAN_CREATE },
    // canActivate: [AuthGuard]
  // },
  {
    path: 'plans/:id',
    component: PlanDetailsV1Component,
    data: { header: 'Detalhes da Assinatura', title: 'Bempaggo | Assinatura', roles: PagePermissions.PLAN },
    canActivate: [AuthGuard]
  },
  {
    path: 'shared',
    component: SharedLinkFbComponent,
    data: { header: 'Compartilhar', title: 'Bempaggo | Compartilhar', roles: PagePermissions.PAGE_PAY },
    canActivate: [AuthGuard]
  },
  {
    path: 'shared',
    component: SharedLinkFbComponent,
    data: { header: 'Compartilhamento', title: 'Bempaggo | Compartilhamento', roles: PagePermissions.USER_PERMISSIONS },
    canActivate: [AuthGuard]
  },
  // {
    // path: 'paymentLinks',
    // component: PagepayListComponent,
    // data: { header: 'Link de Pagamento', title: 'Bempaggo | Link de Pagamento', roles: PagePermissions.PLAN },
    // canActivate: [AuthGuard]
  // },
  // {
    // path: 'paymentLinks/new',
    // component: NewPagepayComponent,
    // data: { header: 'Novo Link de Pagamento', title: 'Bempaggo | Novo Link de Pagamento', roles: PagePermissions.PLAN },
    // canActivate: [AuthGuard]
  // },
  // {
    // path: 'paymentLinks/:id',
    // component: PagePayDetailsV1Component,
    // data: { header: 'Detalhes do Link', title: 'Bempaggo | Link de Pagamento', roles: PagePermissions.PLAN },
    // canActivate: [AuthGuard]
  // },
  {
    path: 'company/:idCompany/charge/:idCharge',
    component: BillingRulePaymentPage,
    data: {
      header: 'Página de Pagamento', title: 'Página de Pagamento',
    }
  },
  {
    path: ':paymentMethod/company/:idCompany/transaction/:idTransaction',
    component: BillingRuleTransaction,
    data: {
      header: 'Página de Pagamento', title: 'Página de Pagamento',
    }
  },
  // {
    // path: 'disputed',
    // component: DisputedComponent,
    // data: {
      // header: 'Risk Manager',
      // title: 'Bempaggo | Risk Manager',
      // roles: PagePermissions.REPORT_ORDER
    // },
    // canActivate: [AuthGuard]
  // },
  {
    path: 'settings',
    component: SettingsComponent,
    data: { header: 'Configurações', title: 'Bempaggo | Configurações', roles: PagePermissions.CONVENANT_DETAILS },
    canActivate: [AuthGuard]
  },
  {
    path: 'settings/paymentroutes',
    component: PaymentRoutesComponent,
    data: { header: 'Rotas de pagamento', title: 'Bempaggo | Rotas de pagamento', roles: PagePermissions.CONVENANT_DETAILS },
    canActivate: [AuthGuard]
  },
  {
    path: 'empty-accounts',
    component: EmptyAccountsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'my-account',
    component: MyAccountComponent,
    data: { header: 'Financeiro', title: 'Bempaggo | Financeiro', roles: PagePermissions.ADMIN },
    canActivate: [AuthGuard]
  },
  {
    path: 'settings/establishments',
    component: EstablishmentComponent,
    data: { header: 'Estabelecimentos', title: 'Bempaggo | Estabelecimentos', roles: PagePermissions.CONVENANT },
    canActivate: [AuthGuard]
  },
  {
    path: 'settings/establishment/:idAffiliate/:id',
    component: EstablishmentDetailsComponent,
    data: { header: 'Estabelecimento', title: 'Bempaggo | Estabelecimento', roles: PagePermissions.CONVENANT_DETAILS },
    canActivate: [AuthGuard]
  },
  {
    path: 'settings/establishment/new',
    component: EstablishmentDetailsComponent,
    data: { header: 'Novo estabelecimento', title: 'Bempaggo | Novo estabelecimento', roles: PagePermissions.CONVENANT_CREATE },
    canActivate: [AuthGuard]
  },
  // Reports begin
  {
    // Não entendi o que é esse relatório
    path: 'reports/denied',
    component: DeniedComponent,
    data: { header: 'Negados pela Adquirente', title: 'Bempaggo | Relatórios' },
    canActivate: [AuthGuard]
  },
  {//Não abre
    path: 'reports',
    component: ReportsComponent,
    data: { header: 'Relatório de Transações', title: 'Bempaggo | Relatórios' },
    canActivate: [AuthGuard]
  },
  {//ok - Melhorar (retirar as bandeiras e colocar para dentro)
    path: 'reports/approved-transactions',
    component: ApprovedTransactionReportComponent,
    data: { header: 'Transações aprovadas', title: 'Bempaggo | Transações aprovadas ', roles: PagePermissions.REPORT_APPROVED_TRANSACTION },
    canActivate: [AuthGuard]
  },
  {//ok - Melhorar (retirar as bandeiras e colocar para dentro)
    path: 'reports/refund-transactions',
    component: RefundTransactionReportComponent,
    data: { header: 'Estornos', title: 'Bempaggo | Estornos ', roles: PagePermissions.REPORT_APPROVED_TRANSACTION },
    canActivate: [AuthGuard]
  },
  {//ok
    path: 'reports/acquirer-message-transactions',
    component: AcquirerMessageTransactionReportComponent,
    data: { header: 'Transações por Motivo', title: 'Bempaggo | Transações por Motivo ', roles: PagePermissions.REPORT_ACQUIRER_MESSAGE },
    canActivate: [AuthGuard]
  },
  {//ok
    path: 'reports/charges',
    component: ChargesComponent,
    data: { header: 'Cobranças', title: 'Bempaggo | Cobranças ', roles: PagePermissions.REPORT_CHARGE },
    canActivate: [AuthGuard]
  },
  {//ok
    path: 'reports/subscription',
    component: SubscriptionReportComponent,
    data: { header: 'Assinaturas', title: 'Bempaggo | Assinaturas ', roles: PagePermissions.REPORT_SUBSCRIPTION },
    canActivate: [AuthGuard]
  },
  {//ok
    path: 'reports/transaction-net-revenue',
    component: LiquidoReportComponent,
    data: { header: 'Faturamento', title: 'Bempaggo | Faturamento ', roles: PagePermissions.REPORT_NET_REVENUE },
    canActivate: [AuthGuard]
  },
  {//ok
    path: 'reports/orders/cancelation/reason',
    component: CancelationReasonOrderReportComponent,
    data: { header: 'Cancelamentos', title: 'Bempaggo | Cancelamentos', roles: PagePermissions.REPORT_ORDER },
    canActivate: [AuthGuard]
  },
  {//ok
    path: 'reports/transactions',
    component: TransactionsReportComponent,
    data: { header: 'Transações', title: 'Bempaggo | Transações', roles: PagePermissions.REPORT_ORDER },
    canActivate: [AuthGuard]
  },
  //Reports end
  {
    path: '**',
    component: NotFoundComponent,
    data: { header: '', title: 'Bempaggo | 404' }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
