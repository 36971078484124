import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute, Router } from "@angular/router";
import { MenuItem } from "primeng/api";
import { PlanResponse, ProductResponse } from "src/app/core/model/v1.model";
import { AlertService } from "src/app/core/services/alert.service";
import { PlanServiceV1 } from "src/app/core/services/v1/plan.service.v1";

@Component({
    selector: 'app-plans-list',
    templateUrl: './plan-list.component.html',
    styleUrls: ['./plan-list.component.scss']
})
export class PlansListComponent implements OnInit {

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    view: boolean;
    name: string;
    product: ProductResponse;
    loading: Boolean;
    plans: MatTableDataSource<PlanResponse>;
    displayedColumns: string[] =
        [
            'id',
            'name',
            'frequency',
            'value',
            'status'
        ];
    crumbs: MenuItem[];

    constructor(
        private route: ActivatedRoute,
        private alertService: AlertService,
        private router: Router,
        private planService: PlanServiceV1
    ) { }

    ngOnInit() {
        this.initCrumbs();
        this.loading = true;
        this.planService.getAllRecurrents().subscribe(
            result => {
                this.setPlans(result);
            },
            error => {
                this.alertService.handleError(error);
            }
        );
        this.loading = false;
    }

    initCrumbs() {
        this.crumbs = [
            { label: 'Planos de Assinaturas'}
        ];
    }

    search() {

    }

    activeSlideChange(plan: PlanResponse) {
        if (plan.active) {
            this.planService.active(plan).subscribe(
                result => { },
                error => {
                    plan.active = !plan.active;
                    this.alertService.handleError(error)
                }
            );
        } else {
            this.planService.inactive(plan).subscribe(
                result => { },
                error => {
                    plan.active = !plan.active;
                    this.alertService.handleError(error)
                }
            );
        }
    }

    goToNewPlan() {
        this.router.navigate(['plans', 'new']);
    }

    goToPlan(plan: PlanResponse) {
        this.router.navigate(['plans', plan.id]);
    }

    setPlans(result) {
        this.plans = new MatTableDataSource<PlanResponse>(result);
        this.plans.paginator = this.paginator;
        this.plans.sort = this.sort;
    }
}