<div fxFlexFill class="container-box">
    <div fxLayout="column" fxFlexFill fxLayoutAlign="start center">

        <div>
            <img src="../../../assets/img/logo.png" class="mt-2" width=250>
        </div>

        <div class="text-box" fxLayout="column" fxLayoutAlign="start center">
            <h2>Desculpe!</h2>
            <h3>Você tentou acessar uma link que <b>não está disponível.</b></h3>
        </div>

    </div>
</div>