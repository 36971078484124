<div class="content" fxLayout='column' fxLayoutGap="10px">
  <div fxLayout='row' fxLayout.lt-md="row">
    <card-box fxFlex="100">
      <div class="chart-container mt-4" style="position: relative; width: 98%; height: 100%;">
        <div class="content h-100 mt-4 pb-5" fxLayout="row" fxLayoutAlign="center center" *ngIf="loadingTab">
          <mat-spinner></mat-spinner>
        </div>
        <mat-progress-bar mode="indeterminate" *ngIf="loading && !loadingTab"></mat-progress-bar>
        <div class="row" fxLayout="column" *ngIf="!loadingTab">
          <mat-slide-toggle [(ngModel)]="enableDate">Por data</mat-slide-toggle>

          <div class="view-filter" display="flex" fxLayout='column' fxLayout.lt-md="column">
            <div fxFlex="100" class="filter-trans card-body bg-light pb-4 pt-3" fxLayout='row' fxLayoutAlign="start"
              fxLayout.lt-lg="column" fxLayoutAlign.lt-lg="space-around stretch">
              <div fxLayout='row' fxLayout.lt-md="column" fxLayoutGap="20px">
                <div>
                  <mat-form-field>
                    <input matInput [matDatepicker]="start" readonly (click)="start.open()" [(ngModel)]="filters.start"
                      placeholder="Início" [max]="filters.end" disabled="{{!isEnable()}}">
                    <mat-datepicker-toggle matSuffix [for]="start"></mat-datepicker-toggle>
                    <mat-datepicker #start></mat-datepicker>
                  </mat-form-field>
                </div>
                <div>
                  <mat-form-field>
                    <input matInput [matDatepicker]="end" readonly (click)="end.open()" [(ngModel)]="filters.end"
                      placeholder="Fim" [min]="filters.start" disabled="{{!isEnable()}}">
                    <mat-datepicker-toggle matSuffix [for]="end"></mat-datepicker-toggle>
                    <mat-datepicker #end></mat-datepicker>
                  </mat-form-field>
                </div>
                <div>
                  <form [formGroup]="searchAffiliateForm">
                    <mat-form-field>
                      <mat-select class="opcoesFiltro" placeholder="Loja" formControlName="orderAffiliate"
                        [compareWith]="compareId" [(ngModel)]="filters.affiliateIDs" multiple>
                        <mat-option #allSelectedForAffiliate (click)="toggleAllSelectionForAffiliate()" [value]="0">
                          Todos
                        </mat-option>
                        <mat-option *ngFor="let affiliate of affiliate" [value]="affiliate.id"
                          (click)="tosslePerOneForAffiliate(allSelectedForAffiliate.viewValue)">
                          {{affiliate.name}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </form>
                </div>
                <div>
                  <form [formGroup]="searchStatusForm">
                    <mat-form-field>
                      <mat-select class="opcoesFiltro" placeholder="Status do pedido" formControlName="orderStatus"
                        [(ngModel)]="filters.status" [compareWith]="compareId" multiple>
                        <mat-option #allSelectedForStatus (click)="toggleAllSelectionForStatus()" [value]="0">Todos
                        </mat-option>
                        <mat-option *ngFor="let aStatus of status" [value]="aStatus"
                          (click)="tosslePerOneForStatus(allSelectedForStatus.viewValue)">
                          {{aStatus | translate}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </form>
                </div>
                <div class="elementosFiltro">
                  <mat-form-field>
                    <mat-select placeholder="Método de pagamento" id="paymentMethod"
                      [(ngModel)]="filters.paymentMethod">
                      <mat-option value="">Todos</mat-option>
                      <mat-option value="1">Cartão de crédito</mat-option>
                      <mat-option value="2">Pix</mat-option>
                      <mat-option value="3">Boleto</mat-option>
                      <mat-option value="4">Transferência</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <!-- <div fxFlex>
                <mat-form-field>
                  <input matInput [(ngModel)]="filters.paymentMethod" placeholder="Método">
                </mat-form-field>
                </div> -->
              </div>
            </div>
            <div fxFlex="100" class="filter-trans card-body bg-light pb-4 pt-3" fxLayout='row'
              fxLayoutAlign="start stretch" fxLayout.lt-lg="column" fxLayoutAlign.lt-lg="space-around">

              <div fxFlex>
                <mat-form-field>
                  <input matInput [(ngModel)]="filters.bin" mask="000000" placeholder="6 primeiros dígitos do cartão">
                </mat-form-field>
              </div>
              <div fxFlex>
                <mat-form-field>
                  <input matInput mask="0000" [(ngModel)]="filters.lastFour" placeholder="4 últimos digitos"
                    id="ultimosDigitos">
                </mat-form-field>
              </div>

              <div fxFlex>
                <mat-form-field>
                  <input matInput [(ngModel)]="filters.document" placeholder="Documento">
                </mat-form-field>
              </div>
              <div fxFlex="65">
                <mat-form-field>
                  <input matInput [(ngModel)]="filters.transactionSearch"
                    placeholder="Busque por TID, NSU, número do pedido ou número de autorização">
                </mat-form-field>
              </div>
              <div class="pesquisar" fxLayout="row" fxLayoutAlign="end">
                <div class="center-text">
                  <button mat-raised-button class="btn-search" fxLayoutAlign="end" (click)="filter()">Pesquisar</button>
                </div>
              </div>


            </div>
          </div>

          <div class="errors center-text" *ngIf="!rangeValid">
            <span>Favor selecionar um período máximo de 31 dias.</span>
          </div>
        </div>


      </div>
    </card-box>
  </div>
  <div class="tab-content">
    <div [ngClass]="{'hide' : pageResponse.totalElements == 0}">
      <div class="export">
        <a class="export-link" (click)="downloadCsv()">
          <span class="export-label">Exportar</span>
          <i class="fa fa-arrow-circle-down" aria-hidden="true"></i>
        </a>
      </div>
      <div class="div-table">
        <table mat-table [dataSource]="transactions" multiTemplateDataRows matSort style="width: 100%;">
          <ng-container matColumnDef="transactionDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Data de registro</th>
            <button onClick="toggleRow()">a</button>
            <td mat-cell *matCellDef="let row"> {{row.registerDate | date: 'dd/MM/yyyy'}} </td>
          </ng-container>

          <ng-container matColumnDef="customerName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Nome</th>
            <td mat-cell *matCellDef="let row"> {{row.customerName}} </td>
          </ng-container>
          <ng-container matColumnDef="paymentMethod">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Método de pagamento</th>
            <td mat-cell *matCellDef="let row"> {{row.paymentMethodName[0].toUpperCase() +
              row.paymentMethodName.substring(1).toLowerCase()}} </td>
          </ng-container>
          <ng-container matColumnDef="value">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Valor</th>
            <td mat-cell *matCellDef="let row"> {{row.value | CustomCurrency:'BRL'}} </td>
          </ng-container>
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
            <td mat-cell *matCellDef="let row" [ngClass]="getStatusColor(row.status)"> {{row.status | translate}} </td>
          </ng-container>
          <ng-container matColumnDef="returnMsg">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Mensagem de retorno</th>
            <td mat-cell *matCellDef="let row"> {{row.returnMsg | translate }} </td>
          </ng-container>


          <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let row2" [attr.colspan]="displayedColumns.length">
              <div class="example-element-detail" [@detailExpand]="row2 == expandedElement ? 'expanded' : 'collapsed'">
                <div fxLayout="column" fxLayoutAlign="space-around start">

                  <div class="mr-2">
                    • TID: {{row2.tid ? row2.tid : 'Sem informação'}}
                  </div>
                  <div class="mr-2">
                    • Número do pedido: {{row2.userReference ? row2.userReference : 'Sem informação'}}
                  </div>
                  <div class="mr-2">
                    • Número da autorização: {{row2.authorizationNumber ? row2.authorizationNumber : 'Sem informação'}}
                  </div>
                  <div class="mr-2">
                    • NSU: {{row2.nsuNumber ? row2.nsuNumber : 'Sem informação'}}
                  </div>
                  <div class="mr-2">
                    • Referência da transação: {{row2.transactionReference ? row2.transactionReference : 'Sem
                    informação'}}
                  </div>
                  <div class="mr-2">
                    • Adquirente: {{row2.paymentGatewayName ? row2.paymentGatewayName : 'Sem informação'}}
                  </div>
                  <div class="mr-2">
                    • Loja: {{row2.affiliateName ? row2.affiliateName : 'Sem informação'}}
                  </div>
                  <div class="mr-2">
                    • Tipo: {{row2.type ? (row2.type | translate) : 'Sem informação'}}
                  </div>
                  <div class="mr-2">
                    • Valor pago: {{row2.paidValue ? (row2.paidValue | CustomCurrency:'BRL') : 'Sem informação'}}
                  </div>
                  <div class="mr-2">
                    • Data da transação: {{row2.transactionDate ? (row2.transactionDate | date: 'dd/MM/yyyy') : 'Sem
                    informação'}}
                  </div>

                </div>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="example-element-row hover-row"
            [class.example-expanded-row]="expandedElement === row" (click)="expandedElement = row">
          </tr>
          <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>

        </table>
      </div>

      <mat-paginator [length]="pageResponse.totalElements" [pageSize]="pageResponse.pageSize"
        (page)="pageEvent = handlePage($event)" aria-label="Select page">
      </mat-paginator>
    </div>



    <div [ngClass]="{'hide' : pageResponse.totalElements > 0}" class="no-data-message">
      <span>Nenhuma transação para exibir</span>
    </div>
  </div>


</div>