import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { EstablishmentRequest } from "../../model/request/establishment.request";
import { Observable } from "rxjs";

export class OnEstablishment {

  constructor(private http: HttpClient, private affiliateId: number) { }

  public create(dadosEc: EstablishmentRequest): Observable<any> {
    let header = new HttpHeaders();
    header = header.set("Access-Control-Allow-Origin", "*");
    header = header.set("Access-Control-Allow-Headers", "Location");
    return this.http.post<any>(`${environment.apiUrl}/api/v1/affiliates/${this.affiliateId}/establishments`, dadosEc, { headers: header, observe: 'response' });
  }

  public getByUri(uri: string): Observable<any> {
    let header = new HttpHeaders();
    header = header.set("Access-Control-Allow-Origin", "*");
    header = header.set("Access-Control-Allow-Headers", "Location");
    return this.http.get<any>(uri, { headers: header });
  }
}
