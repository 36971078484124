import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MotivoCancelamento } from 'src/app/core/model/motivo-cancelamento';
import { CancelationReasonService } from 'src/app/core/services/v0/cancelation-reason.service';
import { OrderService } from 'src/app/core/services/v0/order.service';
import { AlertService } from '../../../../core/services/alert.service';
import { lastValueFrom } from 'rxjs';

export interface DialogData {
  pedidoId: number;
}

@Component({
  selector: 'app-modal-cancelamento',
  templateUrl: './modal-cancelamento.component.html',
  styleUrls: ['./modal-cancelamento.component.scss']
})
export class ModalCancelamentoComponent implements OnInit {
  motivo: number;
  motivos: MotivoCancelamento[];

  constructor(
    private signatureService: OrderService,
    private alertService: AlertService,
    public dialogRef: MatDialogRef<ModalCancelamentoComponent>,
    private cancelationReasonService: CancelationReasonService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit() {
    this.cancelationReasonService.getAllCancelationReason().subscribe(result => {
      this.motivos = result;
    });
  }

  async cancelaAssinatura() {
    if (!this.motivo) {
      return this.alertService.error('Erro', 'Favor selecionar o motivo do cancelamento');
    }
    let response = await lastValueFrom(this.signatureService.cancelaAssinatura(this.data.pedidoId, this.motivo));
    if (response.status == 200) {
      this.alertService.success('Cancelada', 'Assinatura cancelada com sucesso.');
      this.dialogRef.close(true);
    } else {
      this.alertService.error('Erro', 'Não foi possível cancelar a assinatura.');
    }
  }
}
