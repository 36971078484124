<mat-grid-list cols="1" rowHeight="300px">
    <mat-grid-tile>
        <mat-card>
            <mat-card-title>
                Importação de Arquivos
            </mat-card-title>
            <mat-card-content>
                <input id="singleFile" type="file" />
            </mat-card-content>
            <div class="btn-confirm">
                <button mat-raised-button >Carregar</button>
            </div>
        </mat-card>
    </mat-grid-tile>
</mat-grid-list>
