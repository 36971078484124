<div fxLayout="column" fxLayoutGap="5px">
  <div fxLayout="row" style="justify-content: space-between;">
    <h3 id="plansTitle">Clientes</h3>
  </div>
  <table mat-table [dataSource]="subscribersList" matSort id="plansTable">
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header id="tableHeaderId"> Id </th>
      <td mat-cell *matCellDef="let row; let i = index;" [attr.id]="'plansTdId_'+i"> {{ row.id }} </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header id="tableHeaderName"> Nome </th>
      <td mat-cell *matCellDef="let row; let i = index;" [attr.id]="'plansTdName_'+i"> {{ row.name }} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" id="planToolTip" class="hover-row"
      matTooltipPosition="above" matTooltip="Clique para visualizar os dados do plano" (click)="detailClient(row.id)">
    </tr>
  </table>

  <mat-paginator [length]="pageResponse.totalElements" [pageSize]="pageResponse.pageSize" (page)="pageEvent = handlePage($event)" aria-label="Select page">
  </mat-paginator>
</div>