<div fxLayout="column" fxLayoutGap="10px" style="margin-bottom: 10px;">
  <div fxLayout="row">
    <div fxFlex fxLayout="row" fxLayoutAlign="start center">
      <p-breadcrumb id="breadcrumb" [model]="crumbs"></p-breadcrumb>
    </div>
  </div>
</div>
<form name="userEditForm" #userEditForm="ngForm" fxLayout='column' fxLayoutGap="10px">
  <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
  <div class="container" fxLayout='column' fxLayoutGap="10px">
    <div fxLayout="column" fxLayout.lt-md="column" fxLayoutGap="10px">
      <card-box [title]="'Dados'" fxFlex="40">
        <div fxLayout='column' class="form-dados">
          <!-- EDITA DADOS DO USUARIO -->
          <mat-form-field fxFlex>
            <input matInput placeholder="Nome" maxlength="40" [(ngModel)]="user.name" name="name" required
              [disabled]="loading">
            <mat-error>Nome é obrigatório</mat-error>
          </mat-form-field>
          <mat-form-field fxFlex>
            <input matInput placeholder="Email" email [(ngModel)]="user.email" name="email" required
              [disabled]="loading">
            <mat-error>Formato de e-mail inválido</mat-error>
          </mat-form-field>
          <mat-slide-toggle fxFlex name="ativo" [(ngModel)]="user.ativo">Ativo</mat-slide-toggle>
        </div>
      </card-box>
      <card-box [title]="'Perfis'" fxFlex>
        <div fxLayout='column' class="form-dados">
          <mat-form-field>
            <mat-select placeholder="Perfil" [(value)]="perfil" name="perfil">
              <mat-option *ngFor="let perfil of perfis" [value]="perfil" (click)="addPerfil(perfil)">
                {{perfil.nome}} - {{ perfil.descricao }}
              </mat-option>
            </mat-select>
            <mat-error>Perfil é obrigatório</mat-error>
          </mat-form-field>
          <!-- EDITA PERFIS DO USUARIO -->
          <mat-list class="list-dados">
            <mat-list-item *ngFor="let perfil of user.profiles">
              <h4 matTooltip="{{getPermissoes(perfil)}}" mat-line>{{perfil.name}}</h4>
              <p mat-line> {{perfil.description}} </p>
              <i mat-list-icon class="fal fa-trash-alt pointer" (click)="removePerfil(perfil)"
                matTooltip="Remove perfil"></i>
            </mat-list-item>
          </mat-list>
        </div>
      </card-box>
    </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="end">
    <button mat-raised-button class="btn-cancel" (click)="cancel()" [disabled]="loading">Cancelar</button>
    <button mat-raised-button class="btn-confirm" (click)="onSubmit()" [disabled]="loading">Salvar</button>
  </div>
</form>