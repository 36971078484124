<mat-accordion class="example-headers-align mt-4" multi>
  <mat-expansion-panel>
    <mat-expansion-panel-header class="bg-light">
      <mat-panel-title>
        Histórico de alterações
      </mat-panel-title>
    </mat-expansion-panel-header>
    <mat-list>
      <mat-list-item *ngFor="let folder of folders">
        <mat-icon mat-list-icon>folder</mat-icon>
        <div mat-line>{{folder.name}}</div>
        <div mat-line> {{folder.updated | date}} </div>
        <mat-divider></mat-divider>
      </mat-list-item>
    </mat-list>
  </mat-expansion-panel>
</mat-accordion>
