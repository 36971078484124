
export enum RoleName {
    SHOW_DASHBOARD,
	TRANSACTION_NEW,
	TRANSACTION_QUERY,
	TRANSACTION_QUERY_EXPORT,
	TRANSACTION_DETAILS,
	CUSTOMER_NEW,
	CUSTOMER_DELETE,
	CUSTOMER_QUERY,
	CUSTOMER_DETAILS,
	CUSTOMER_EDIT,
	CUSTOMER_EDIT_SIGNATURE_CANCEL,
	CUSTOMER_EDIT_REFUND,
	CUSTOMER_EDIT_CHARGEBACK,
	CUSTOMER_EDIT_PAYMENT_VIEW,
	CUSTOMER_EDIT_PAYMENT_NEW,
	CUSTOMER_EDIT_PAYMENT_EDIT,
	CUSTOMER_EDIT_PAYMENT_DELETE,
	PLAN_NEW,
	PLAN_QUERY,
	PLAN_DETAILS,
	PLAN_EDIT,
	PLAN_DELETE,
	REPORT_CHURN,
	REPORT_CHURN_EXPORT,
	REPORT_NET_REVENUE,
	REPORT_NET_REVENUE_EXPORT,
	REPORT_APPROVED_TRANSACTION,
	REPORT_APPROVED_TRANSACTION_EXPORT,
	REPORT_ACQUIRER_MESSAGE,
	REPORT_ACQUIRER_MESSAGE_EXPORT,
	REPORT_ORDER,
	REPORT_SUBSCRIPTION,
	REPORT_SUBSCRIPTION_EXPORT,
	REPORT_CHARGE_EXPORT,
	REPORT_CHARGE,
	REPORT_TRANSACTION,
	REPORT_TRANSACTION_EXPORT,
	CONVENANT_QUERY,
	CONVENANT_DETAILS,
	CONVENANT_EDIT,
	CONVENANT_CREATE,
	ADMIN,
	USER_ADMIN,
	PERFIL_QUERY,
	PERFIL_NEW,
	PERFIL_EDIT,
	USER_QUERY,
	USER_NEW,
	USER_EDIT,
	PAGE_PAY,
	PAGE_PAY_QUERY,
	PAGE_PAY_NEW,
	PAGE_PAY_EDIT,
	IMPORT_WALLET,
	WHITELIST_NEW,
	WHITELIST_QUERY,
	CARD_NEW,
	CARD_EDIT,
	CARD_QUERY,
	SUBSCRIPTIONS_QUERY,
	WALLET_IMPORT_QUERY,
	CANCEL_IMPORT_ORDERS,
	AFFILIATE_CREATE,
	AFFILIATE_QUERY,
	AFFILIATE_EDIT,
	CANCELATION_REASON_QUERY,
	CANCELATION_REASON_CREATE,
	CANCELATION_REASON_EDIT,
	USER_REFUND

}