import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class Data {

    public storage: any;

    public constructor() { }

}