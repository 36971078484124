import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { NgxPermissionsService } from 'ngx-permissions';
import { MenuItem } from "primeng/api";
import { DateUtils } from 'src/app/core/helper/date-utils';
import { PageResponse } from 'src/app/core/model/page-response';
import { Pageable } from 'src/app/core/model/pageable';
import { PagePermissions } from 'src/app/core/permissions/page-permissions';
import { AlertService } from 'src/app/core/services/alert.service';
import { CustomerService } from 'src/app/core/services/v0/customer.service';
import { ClientsFilter } from '../../core/common/filters/clients.filter';
import { Client } from '../../core/model/client';
declare var $: any;

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss']
})

export class ClientsComponent implements OnInit {
  end = new Date(Date.now());
  begin = new Date(this.end.getFullYear(), this.end.getMonth() - 1, this.end.getDate());
  begin30 = new Date(Date.now());
  loading = true;
  clients = new MatTableDataSource<Client>();
  clientPermissions = PagePermissions.CUSTOMER_EDIT_DETAILS;
  show = true;
  filters = new ClientsFilter();
  crumbs: MenuItem[];

  dateUtils: DateUtils = new DateUtils();

  displayedColumns: string[] = ['id', 'name', 'document', 'email'];

  pageResponse: PageResponse;
  public pageable: Pageable;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  viewFilter = true;
  enableDate = true;

  constructor(
    private router: Router,
    private clientService: CustomerService,
    private alertService: AlertService,
    private permissionsService: NgxPermissionsService) {
  }

  ngOnInit() {
    this.initCrumbs();
    this.pageResponse = {
      pageNumber: 0,
      totalPages: 0,
      pageSize: 10,
      totalElements: 0,
      content: null
    };

    this.begin30.setDate(this.begin.getDate() + 31);
    this.filterParam();
  }

  initCrumbs() {
    this.crumbs = [
      { label: 'Clientes' }
    ];
  }

  cleanFilter() {
    if (this.enableDate) {
      this.filters.name = "";
      this.filters.document = "";
      this.filters.cardBin = "";
      this.filters.cardLast4 = "";
      this.filters.startRegisterDate = moment(this.begin).toDate().getTime();
      this.filters.endRegisterDate = moment(this.end).toDate().getTime();
    } else {
      this.filters.startRegisterDate = null;
      this.filters.endRegisterDate = null;
    }
  }

  filterParam() {
    this.pageable = { page: 0, size: 10 };
    this.cleanFilter();
    this.getAll();
  }

  public handlePage(event?: PageEvent) {
    this.pageable.page = event.pageIndex;
    this.getAll();
    return event;
  }

  public handleSort(event?: any) {
    this.pageable = { page: 0, size: 10 };
    this.pageable = Object.assign({}, this.pageable, { sort: `${event.active},${event.direction}` });
    this.getAll();
  }

  public getAll() {
    this.loading = true;

    this.clientService.getAll(this.filters, this.pageable)
      .subscribe(result => {
        this.pageResponse = result;
        this.showResult();
      });
    this.loading = false;
  }

  showResult() {
    this.pageResponse.pageSize = 10;
    this.clients = this.pageResponse.content;
    this.clients.paginator = this.paginator;
    this.clients.sort = this.sort;
  }

  detailClient(id) {
    this.permissionsService.hasPermission(this.clientPermissions).then(hasPermission => {
      if (hasPermission) {
        this.router.navigate(['subscribers/', id]);
      } else {
        this.alertService.errorPermission();
      }
    });
  }

  isEnable() {
    return this.enableDate;
  }
}
