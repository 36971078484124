
import { Injectable } from "@angular/core";
import * as moment from 'moment';
import { TransactionStatus } from "../model/transaction";
import { TransacaoService } from "../services/v0/transaction.service";
import { TranslateService } from "@ngx-translate/core";
import { OrderStatusHelper } from "./order-status.helper";

@Injectable({
    providedIn: 'root'
})
export class StatusHelper {

  transacaoStatus: TransactionStatus[];

  constructor(private transactionService: TransacaoService, private translate: TranslateService) {
    this.transactionService.getStatus().then(data => {
      this.transacaoStatus = data.transactionStatus;
    });
  }

  getTransacaoStatusColor(status): string {

    for (let i = 0; i < this.transacaoStatus.length; i++) {
      if (this.translate.instant(status) === this.transacaoStatus[i].name) {
        return this.transacaoStatus[i].colorClass;
      }
    }
    return "bg-gray";
  }
}
