import { Component, Input, OnInit } from '@angular/core';
import { OrderEvents } from 'src/app/core/model/v2.model';
import { AlertService } from 'src/app/core/services/alert.service';
import { OnOrderV2 } from 'src/app/core/services/v2/order.service.v2';
import { OnWebhookV2 } from 'src/app/core/services/v2/webhook.service';

@Component({
  selector: 'app-event-list',
  templateUrl: './event-list.component.html',
  styleUrls: ['./event-list.component.scss']
})
export class EventListComponent implements OnInit {
  @Input() orderId?: number;
  @Input() companyId?: number;
  loading: boolean = true;
  orderEvents: OrderEvents[] = [];
  displayedColumnsEvents: string[] = ['event', 'date', 'status', 'actions'];

  constructor(
    private onOrder: OnOrderV2,
    private onWebhook: OnWebhookV2,
    private alertService: AlertService
  ) { }

  ngOnInit(): void {
    this.loading = true;
    this.getOrderEvents();
    this.loading = false;
  }

  public getOrderEvents() {
    this.onOrder.getEventsById(this.orderId).subscribe(
      result => {
        this.orderEvents = result;
      },
      error => {
        this.alertService.handleError(error);
      }
    );
  }

  retryWebhook(eventId: number) {
    this.loading = true;
    this.onWebhook.resendWebhook(eventId).subscribe(
      result => {
        this.alertService.success('Sucesso','Webhook reenviado com sucesso!');
        this.ngOnInit();
      },
      error => {
        this.alertService.handleError(error);
      }
    );
    this.loading = false;
  }

}
