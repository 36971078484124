import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DateFormatter } from "src/app/date-formatter";
import { environment } from "src/environments/environment";
import { toHttpParams } from "../../helper/http.helper";

@Injectable({
    providedIn: 'root'
})
export class MetricsServiceV1 {

    constructor(private http: HttpClient) { }

    public getSalesDay(): Observable<any> {
        let date = new Date();
        let formatter = new DateFormatter();
        let start = formatter.startOfDay(date);
        let end = formatter.endOfDay(date);
        return this.getTransacaoDados(start, end);
    }

    public getSalesMonth(): Observable<any> {
        let date = new Date();
        let formatter = new DateFormatter();
        let firstDay = formatter.startOfMonth(date);
        let lastDay = formatter.endOfMonth(date);
        return this.getTransacaoDados(firstDay, lastDay);
    }

    public getAvgTicket(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/api/v1/metrics/avgTicket`);
    }

    public getTotalCurrentMonth(): Observable<any> {
        let date = new Date();
        let formatter = new DateFormatter();
        let params = {
            params: toHttpParams({
                dateStart: formatter.thirtyDaysAgoStart(date),
                dateEnd: formatter.endOfDay(date)
            })
        }
        return this.http.get<any>(`${environment.apiUrl}/api/v1/metrics/sales/daily`, params);
    }

    public cobrancasRecorrenteCard(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/api/v1/metrics/cardRecorrente`);
    }

    public cardAssinaturasAtivas(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/api/v1/metrics/card/assinaturasAtivas`);
    }

    private getTransacaoDados(dateStart: String, dateEnd: String): Observable<any> {
        let params = {
            params: toHttpParams({
                dateStart: dateStart,
                dateEnd: dateEnd
            })
        }
        return this.http.get<any>(`${environment.apiUrl}/api/v1/metrics/mrr`, params);
    }
}
