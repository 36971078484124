import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { BehaviorSubject } from "rxjs";
import { map } from 'rxjs/operators';
import { environment } from "src/environments/environment";
import { User } from '../model/user';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private loggedIn = new BehaviorSubject<boolean>(false);;
    private user:User = null;
    constructor(private http: HttpClient, private router: Router, private permissionsService:NgxPermissionsService) { 
    }
 
    login(username: string, password: string) {
        return this.http.post<any>(`${environment.apiUrl}/api/auth/signin`, { usernameOrEmail: username, password: password })
            .pipe(
              map(user => {
                // login successful if there's a jwt token in the response
                if (user && user.accessToken) {
                    this.loggedIn.next(true);
                    this.permissionsService.loadPermissions(user.roles);
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('currentUser', JSON.stringify(user));
                }
 
                return user;
            }));
    }
 
    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        this.setLogout();
        this.permissionsService.flushPermissions();
        this.router.navigate(['login']);
    }
    getCurrentUser(){
        let currentUser = localStorage.getItem('currentUser');
        if(currentUser){
            return JSON.parse(currentUser);
        }
        return null;
    }
    setLogout(){
        this.loggedIn.next(false);
    }
    setLoggedIn(){
        this.loggedIn.next(true);
    }
    get isLoggedIn() {
        return this.loggedIn.asObservable();
    }
    get getLoggedIn() {
        return this.loggedIn;
    }
}