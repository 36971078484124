import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { Cobranca, CobrancaStatus } from 'src/app/core/model/cobranca';
import { PagePermissions } from 'src/app/core/permissions/page-permissions';

import { NgxPermissionsService } from 'ngx-permissions';
import { TransactionFilter } from 'src/app/core/common/filters/transaction.filter';
import { StatusHelper } from 'src/app/core/helper/status-helper';
import { PageResponse } from 'src/app/core/model/page-response';
import { Pageable } from 'src/app/core/model/pageable';
import { Produto } from 'src/app/core/model/product';
import { Transaction } from 'src/app/core/model/transaction';
import { AlertService } from 'src/app/core/services/alert.service';
import { OrderService } from 'src/app/core/services/v0/order.service';
import { ModalCancelamentoComponent } from '../modal-cancelamento/modal-cancelamento.component';
import { ModalIntallmentsComponent } from '../modal-intallments/modal.intallments.component';
declare var $: any;

@Component({
  selector: 'app-client-details-signature',
  templateUrl: 'signature.component.html',
  styleUrls: ['signature.component.scss']
})
export class ClientDetailsSignatureComponent implements OnInit {
  checkedTransactions = [];
  loading = false;
  status: number = 0;
  aux = new Date();
  listaParcela: Array<Cobranca> = [];
  dataPedidoFormatado: string;
  printParcelas: Array<any>;
  printChargeBackSuccess: Array<string>;
  listaStatus: CobrancaStatus[];
  idSelectedCharge: number = 0;

  signatureCancelPermissions = PagePermissions.SIGNATURE_CANCEL;
  refundPermissions = PagePermissions.CUSTOMER_EDIT_REFUND;
  chargebackPermissions = PagePermissions.CUSTOMER_EDIT_CHARGEBACK;
  chargeAndRefundPermissions = PagePermissions.CUSTOMER_EDIT_REFUND_CHARGEBACK;
  transactionDetailsPermissions = PagePermissions.TRANSACTION_DETAILS;
  transactionParcelPermissions = PagePermissions.TRANSACTION_PARCEL;
  signaturePermissions = PagePermissions.SUBSCRIPTIONS_QUERY;

  signatureTransactions = new MatTableDataSource<Cobranca>();
  currentParcelas: Cobranca[] = new Array();
  displayedColumns: string[] = [
    'dt_pedido',
    'product_nome',
    'periodicidade',
    'valor',
    'status',
    'action'
  ];


  pageResponse: PageResponse;
  pageable: Pageable;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  transactions = new MatTableDataSource<Transaction>();
  filters: TransactionFilter;
  seisPrimeirosDigitosCartao: string;
  quatroUltimosDigitosCartao: string;
  end = new Date(Date.now());
  begin = new Date(Date.now());
  rangeValid = true;
  check = [];

  document: string;
  name: string;
  @Input() client = {};
  signatures: Produto[];
  selectedSignature: Produto;

  @Output() clientSignaturesReload: EventEmitter<any> = new EventEmitter();

  constructor(
    private route: ActivatedRoute,
    private orderService: OrderService,
    public dialog: MatDialog,
    private alertService: AlertService,
    private statusHelper: StatusHelper,
    private signatureService: OrderService,
    private permissionsService: NgxPermissionsService
  ) {
  }


  id = this.route.snapshot.paramMap.get('id');
  idPedido = this.route.snapshot.paramMap.get('idPedido');

  ngOnInit() {
    this.getClientSignatures();
  }


  getClientSignatures() {
    return this.signatureService.getSubscriptionsByCustomerId(this.client['id'])
      .subscribe(result => {
        this.signatures = result;
        if (this.idPedido) {
          for (let pedido of this.signatures) {
            if (pedido.id == parseInt(this.idPedido)) {
              this.getSignatureData(pedido);
              break;
            }
          }
          this.idPedido = null;
        } else if (this.selectedSignature) {
          for (let signature of this.signatures) {
            if (signature.id == this.selectedSignature.id) {
              this.selectedSignature = signature;
            }
          }
        } else {
          if (typeof this.signatures != 'undefined') {
            if (typeof this.signatures[0] != 'undefined') {
              this.getSignatureData(this.signatures[0]);
            }
          }
        }
        this.loading = false;
        setTimeout(() => {
          $('[data-toggle="tooltip"]').tooltip()
        }, 500);
      });
  }

  getSignatureData(signature) {
    this.listaParcela = [];
    this.selectedSignature = signature;
    this.orderService.getAllChargesByOrder(this.selectedSignature.id)
      .subscribe(result => {
        this.currentParcelas = result;
        this.signatureTransactions = new MatTableDataSource<Cobranca>(result);
        this.signatureTransactions.sort = this.sort;
        this.signatureTransactions.paginator = this.paginator;
        this.signatureTransactions.sortingDataAccessor = (item: any, property) => {
          switch (property) {
            case 'dtTransacaoFormatted': return moment(item.ultimaTransacaoDto.dataTransacaoFormatado, "DD/MM/YYYY").toDate().valueOf();
            case 'bandeira': return item.ultimaTransacaoDto.bandeira;
            case 'adquirente': return item.ultimaTransacaoDto.nomeAdquirente;
            case 'valorParcela': return item.ultimaTransacaoDto.valor;
            case 'status': return item.ultimaTransacaoDto.statusStr;
            default: return item[property];
          }
        };
      });
  }

  getStatusColor(status) {
    return this.statusHelper.getTransacaoStatusColor(status);
  }

  getTitle(dtCancelamento, motivoCancelamento) {
    let html = '';
    if (dtCancelamento) {
      html = 'Data de cancelamento: ' + moment(dtCancelamento).format('DD/MM/YYYY');
    }
    if (motivoCancelamento) {
      if (html != '') {
        html += "<br>";
      }
      html += 'Motivo de cancelamento: ' + motivoCancelamento;
    }
    return html;
  }

  viewInstallments(row, event: any = null, force = false) {
    let element: Element = <Element>event.target;
    let valueView = element.getAttribute('data-view-transaction');

    if (valueView || force) {
      const dialogRef = this.dialog.open(ModalIntallmentsComponent, {
        width: '680px',
        height: '400px',
        data: { signature: row }
      });
      dialogRef.afterClosed().subscribe(result => {
        this.getClientSignatures();
      });
    }
  }

  cancelaAssinatura(row) {

    this.permissionsService.hasPermission(this.signatureCancelPermissions).then(hasPermission => {
      if (hasPermission) {
        const dialogRef = this.dialog.open(ModalCancelamentoComponent, {
          width: '400px',
          height: '150px',
          data: { pedidoId: row.id }
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.getClientSignatures();
          }
        });
      } else {
        this.alertService.errorPermission();
      }
    });

  }

}

