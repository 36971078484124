import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class CovenantService {

  constructor(private http: HttpClient) { }

  public getAllCovenants(params: {
    sellerId?: string;
    ecInativos?: boolean;
  } = {}) {
    const httpParams = this.toHttpParams(params);
    return this.http.get<any>(`${environment.apiUrl}/api/v1/establishments`, { params: httpParams });
  }

  private toHttpParams(params: { [key: string]: any }): HttpParams {
    let httpParams = new HttpParams();
    Object.keys(params).forEach(key => {
      const value = params[key];
      if (value !== '' && value !== undefined && value !== null) {
        httpParams = httpParams.append(key, value);
      }
    });
    return httpParams;
  }

}
