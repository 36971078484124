<mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>

<div class="container" *ngIf="!loading">

    <div fxLayout="column" fxLayoutGap="10px" style="margin-bottom: 10px;">
        <div fxLayout="row">

            <div fxFlex fxLayout="row" fxLayoutAlign="start center">
                <p-breadcrumb id="breadcrumb" [model]="crumbs"></p-breadcrumb>
            </div>

            <div fxFlex fxLayout="row" fxLayoutAlign="end center" *ngxPermissionsOnly="createImportPermissions">
                <button type="button" class="btn btn-primary" name="newImport" (click)="newImport()">Nova
                    Importação</button>
            </div>

        </div>
    </div>

    <!-- Filter begin -->
    <div class="card mb-0 shadow-none" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch">

        <div fxLayout='row' fxLayoutAlign="start stretch" fxLayout.lt-md="column"
            fxLayoutAlign.lt-md="space-around stretch" fxLayoutGap="10px">
            <div fxFlex="35">
                <mat-form-field>
                    <input matInput [matDatepicker]="startDate" readonly (click)="startDate.open()" [(ngModel)]="begin"
                        placeholder="Início" [max]="end">
                    <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
                    <mat-datepicker #startDate></mat-datepicker>
                </mat-form-field>
            </div>

            <div fxFlex="35">
                <mat-form-field>
                    <input matInput [matDatepicker]="endDate" readonly (click)="endDate.open()" [(ngModel)]="end"
                        placeholder="Fim" [min]="begin">
                    <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
                    <mat-datepicker #endDate></mat-datepicker>
                </mat-form-field>
            </div>
        </div>

        <div fxFlex fxLayout="row" fxLayoutAlign="end center">
            <button mat-raised-button class="btn-search" (click)="filter()">Pesquisar</button>
        </div>
    </div>

    <!-- data begin -->
    <div class="shadow-none mt-3" [ngClass]="{'hide' : pageResponse.totalElements == 0}">
        <table mat-table [dataSource]="imports" matSort (matSortChange)="handleSort($event)">

            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
                <td mat-cell *matCellDef="let row">{{row.id}}</td>
            </ng-container>
            <ng-container matColumnDef="file_name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Arquivo</th>
                <td mat-cell *matCellDef="let row">{{row.fileName}}</td>
            </ng-container>
            <ng-container matColumnDef="register_date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Data de Registro</th>
                <td mat-cell *matCellDef="let row">{{row.registerDate | date: 'dd/MM/yyyy HH:mm'}}</td>
            </ng-container>
            <ng-container matColumnDef="imported">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Importados</th>
                <td mat-cell *matCellDef="let row">{{row.imported}}</td>
            </ng-container>
            <ng-container matColumnDef="rejected">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Rejeitados</th>
                <td mat-cell *matCellDef="let row">{{row.rejected}}</td>
            </ng-container>
            <ng-container matColumnDef="total">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Total</th>
                <td mat-cell *matCellDef="let row">{{row.total}}</td>
            </ng-container>
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
                <td mat-cell *matCellDef="let row">
                    <span class="badge" [ngClass]="getStatusColor(row.status)">{{row.status | translate}}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Ações</th>
                <td mat-cell *matCellDef="let row">
                    <span *ngIf="row.rejected && row.rejected > 0" (click)="downloadRejections(row.id, row.fileName)"
                        data-not-view-transaction="true" style="cursor: pointer;">
                        <i class="fal fa-file-csv fa-2x" matTooltip="Download CSV dos Rejeitados"></i> </span>
                    <span *ngIf="row.status == 'SCHEDULED' || row.status == 'IN_PROGRESS' " (click)="cancel(row.id)"
                        data-not-view-transaction="true" style="cursor: pointer;">
                        <i class="fal fa-times-circle fa-2x" matTooltip="Cancela Importação"></i></span>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator [ngClass]="{'hide' : pageResponse.totalElements == 0}" [pageIndex]="pageResponse.pageNumber"
            (page)="pageEvent = handlePage($event)" [pageSize]="pageResponse.pageSize"
            [length]="pageResponse.totalElements"></mat-paginator>
    </div>

    <div [ngClass]="{'hide' : pageResponse.totalElements > 0}" class="no-data-message">
        <span>Nada para exibir.</span>
    </div>
</div>