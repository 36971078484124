<mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>

<div class="container" *ngIf="!loading">

  <div fxLayout='column' fxLayoutGap="10px">

    <div fxLayout="row" >

      <div fxFlex fxLayout="row" fxLayoutAlign="start center">
        <p-breadcrumb id="breadcrumb" [model]="crumbs"></p-breadcrumb>
      </div>


    </div>

    <!-- Card superior com informações gerais da venda -->
    <card-box name="card-superior">

      <div class="info" fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px" fxLayoutAlign="start center">

        <div fxLayout="column" fxFlex="30" fxLayoutAlign="start start">
          <span class="resume-field-title"><i class="fas fa-donate"></i> Valor da transferência </span>
          <div class="resume-field-value">
            <div class="money-label  undefined">
              <span class="amount mr-2">{{14 | CustomCurrency:'BRL'}}</span>
              <span class="badge" [ngClass]="getStatusColor('PAY')">Sucesso <i
                  [ngClass]="getStatusIcon('PAY')">
                </i></span>
            </div>
          </div>
        </div>


        <div fxLayout="column" fxFlex="30" fxLayoutAlign="start start">
          <span><i class="fas fa-calendar"></i> Data do pagamento</span>
          <div>{{today | date:'dd/MM/yyyy'}} </div>
        </div>

        <div fxLayout="column" fxFlex="40" fxLayoutAlign="start start">
          <span><i class="fas fa-exclamation-circle"></i> Tipo</span>
          <div>Automática
          </div>
        </div>



      </div>

      <mat-divider style="margin: 10px;"></mat-divider>

      <div class="sub-info" fxLayout.xs="column" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">



        <div fxLayout="column" fxFlex="30" fxLayoutAlign="start start">
          <span><i class="fas fa-calendar"></i> Data da criação</span>
          <div>{{today | date:'dd/MM/yyyy HH:mm:ss'}} </div>
        </div>

        <div fxLayout="column" fxFlex="30" fxLayoutAlign="start start">
          <span><i class="fas fa-info-circle"></i> ID do pagamento</span>
          <div>{{1}} </div>
        </div>
        <div fxLayout="column" fxFlex="30" fxLayoutAlign="start start">
          <span><i class="fas fa-calendar"></i> Número do comprovante</span>
          <div>{{1221}} </div>
        </div>




      </div>
    </card-box>

    <!-- Card inferior -->
    <div fxLayout.xs="column" fxFlex fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="space-between stretch">

      <!-- timeline begin -->
      <card-box fxFlex="30" name="card-inferior">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px" fxLayoutAlign="start start">

          <div>
            <p><b>Histórico</b></p>
            <ul class="timeline">
              <li>
                <p><b><i [ngClass]="getTransactionStatusIcon('APPROVED')">
                    </i><span [ngClass]="getTransactionTextStatusColor('APPROVED')"> Aprovada</span></b></p>
                <p><i class="far fa-calendar-alt"></i> {{today | date:
                  'dd/MM/yyyy HH:mm'}}</p>
                <p>
                  <span >00 - Sucesso
                  </span>


                </p>
                <!-- <p *ngIf="history?.userReference">Número do pedido: {{history?.userReference}}</p>
                <p><b>{{history?.amount | CustomCurrency:'BRL'}}</b></p> -->
                <!-- <button type="button" class="btn-detail" (click)="detailTransaction(history, event)">
                  <i class="fa fa-info" aria-hidden="true"></i>
                </button> -->
              </li>
            </ul>
          </div>

        </div>
      </card-box>
      <!-- timeline end -->

      <!-- card lateral begin -->
      <card-box fxFlex name="card-inferior">
        <div fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="start stretch">

          <!-- Favorecido -->
          <div fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="start stretch">
            <div class="pagamento-da-venda" fxLayout="row" fxFlex>
              <b>Favorecido</b>
            </div>

            <mat-divider class="mb-3" style="margin: 10px;"></mat-divider>

            <div fxFlex fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start start">
              <div fxLayout="row" fxFlex="250px">
                <span><i class="fas fa-user-check"></i> Nome </span>
              </div>
              <div fxLayout="row" fxFlex>
                <span>Casa do shopp comercio de bebidas ltda</span>
              </div>
            </div>

            <div fxFlex fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start start">
              <div fxLayout="row" fxFlex="250px">
                <span><i class="fas fa-arrow-alt-circle-right"></i> CNPJ</span>
              </div>
              <div fxLayout="row" fxFlex>
                <span>99.999.999/0001-99</span>
              </div>
            </div>

            <div fxFlex fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start start">
              <div fxLayout="row" fxFlex="250px">
                <span><i class="fas fa-donate"></i> Banco</span>
              </div>
              <div fxLayout="row" fxFlex>
                <span>Banco do Brasil</span>
              </div>
            </div>

            <div fxFlex fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start start">
              <div fxLayout="row" fxFlex="250px">
                <span><i class="far fa-calendar-alt"></i> Agência</span>
              </div>
              <div fxLayout="row" fxFlex>
                <span>220001</span>
              </div>
            </div>

            <div fxFlex fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start start">
              <div fxLayout="row" fxFlex="250px">
                <span><i class="far fa-calendar-alt"></i> Conta</span>
              </div>
              <div fxLayout="row" fxFlex>
                <span>158452</span>
              </div>
            </div>

          </div>

          <br>

          <!-- Operações incluidas nessa transferência-->
          <div fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="start stretch">
            <div class="pagamento-da-venda" fxLayout="row" fxFlex>
              <b>Operações incluídas nesta transferência</b>
            </div>
            <mat-divider class="mb-3" style="margin: 10px;"></mat-divider>
            <div class="div-table">
              <mat-progress-bar mode="indeterminate" *ngIf="loadingTab"></mat-progress-bar>

              <table mat-table [dataSource]="transferOperations" matSort (matSortChange)="handleSort($event)">

                <ng-container matColumnDef="date">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header id="tableHeaderId"> Data</th>
                  <td mat-cell *matCellDef="let row; let i = index;" [attr.id]="'disputesTdId_'+i">
                    {{ row.date | date: 'dd/MM/yyyy' }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="description">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header id="tableHeaderId"> Descrição</th>
                  <td mat-cell *matCellDef="let row; let i = index;" [attr.id]="'disputesTdId_'+i"> {{
                    row.description
                    }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="amount">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header id="tableHeaderId"> Valor </th>
                  <td mat-cell *matCellDef="let row; let i = index;" [attr.id]="'disputesTdId_'+i">
                    {{row.amount | CustomCurrency:'BRL'}}
                  </td>
                </ng-container>

                <ng-container matColumnDef="fee">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header id="tableHeaderId"> Taxa </th>
                  <td mat-cell *matCellDef="let row; let i = index;" [attr.id]="'disputesTdId_'+i">
                    {{row.fee | CustomCurrency:'BRL'}}
                  </td>
                </ng-container>

                <ng-container matColumnDef="due">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header id="tableHeaderId"> A receber </th>
                  <td mat-cell *matCellDef="let row; let i = index;" [attr.id]="'disputesTdId_'+i">
                    {{row.due | CustomCurrency:'BRL'}}
                  </td>
                </ng-container>


                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

                <tr mat-row *matRowDef="let row; columns: displayedColumns;" id="planToolTip"
                  matTooltipPosition="above"></tr>
              </table>
            </div>

          </div>

          <br>
          <!-- Transações incluídas nesta transferência-->
          <div fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="start stretch" >
            <div class="pagamento-da-venda" fxLayout="row" fxFlex>
              <b>Transações incluídas nesta transferência</b>
            </div>

            <mat-divider class="mb-3" style="margin: 10px;"></mat-divider>

            <div class="div-table">
              <mat-progress-bar mode="indeterminate" *ngIf="loadingTab"></mat-progress-bar>

              <table mat-table [dataSource]="transferTransactions" matSort (matSortChange)="handleSort($event)">

                <ng-container matColumnDef="date">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header id="transactionTableId"> Data do vencimento</th>
                  <td mat-cell *matCellDef="let row; let i = index;" [attr.id]="'disputesTdId_'+i">
                    {{ row.date | date: 'dd/MM/yyyy' }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="client">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header id="transactionTableId"> Cliente</th>
                  <td mat-cell *matCellDef="let row; let i = index;" [attr.id]="'disputesTdId_'+i"> {{
                    row.client
                    }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="value">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header id="transactionTableId"> Valor </th>
                  <td mat-cell *matCellDef="let row; let i = index;" [attr.id]="'disputesTdId_'+i">
                    {{row.value | CustomCurrency:'BRL'}}
                  </td>
                </ng-container>

                <ng-container matColumnDef="type">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header id="transactionTableId"> Tipo </th>
                  <td mat-cell *matCellDef="let row; let i = index;" [attr.id]="'disputesTdId_'+i">
                    {{row.type}}
                  </td>
                </ng-container>

                <ng-container matColumnDef="status">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header id="transactionTableId"> Status </th>
                  <td mat-cell *matCellDef="let row; let i = index;" [attr.id]="'disputesTdId_'+i"
                    [ngClass]="getStatusColor('PAY')">
                    {{row.status}}
                  </td>
                </ng-container>


                <tr mat-header-row *matHeaderRowDef="displayedTransactionColumns"></tr>

                <tr mat-row *matRowDef="let row; columns: displayedTransactionColumns;" id="planToolTip"
                  matTooltipPosition="above"></tr>
              </table>
              <mat-paginator [pageSize]="transferTransactionsSize" [length]="transferTransactionsLength" ></mat-paginator>
            </div>
          </div>
        </div>
      </card-box>
      <!-- card lateral end -->

    </div>
  </div>
</div>