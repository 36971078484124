<div class="content">

  <div fxLayout="column" fxLayoutAlign="center stretch" fxLayout.lt-lg="column" fxLayoutAlign.lt-lg="center stretch"
    fxLayoutGap="20px">

    <div fxLayout="column" fxFlex="40" fxFlex.lt-lg="100" class="card" fxLayoutGap="20px">
      <div class="title">
        <h4>Informações para pagamento</h4>
      </div>
      <!-- View -->
      <div fxLayout="column" *ngIf="paymentMethod == 'pix'">

        <div class="info-container" fxFlex fxLayout="row">
          <div>
            <table>
              <tr>
                <td>
                  <span fxFlex><strong>Valor</strong>: {{value | CustomCurrency:'BRL'}}</span>
                </td>
              </tr>
              <tr>
                <td>
                  <span fxFlex> <strong>Data de expiração</strong>: {{dateFormated | date: 'dd/MM/yyyy HH:mm'}}</span>
                </td>
              </tr>
              <tr>
                <td>
                  <img src="{{image}}" class="image-payment" alt="Pix" width="200px" />
                </td>
              </tr>
              <tr>
                <td>
                  <button mat-raised-button class="btn-search btn-copy" (click)="getText()"
                    style="width: 200px">{{text}}</button>
                </td>
              </tr>
            </table>
          </div>
          <span class="vertical-line"></span>
          <div class="list">
            <ol>
              <li>Abra o app do seu banco ou instituição financeira e entre no ambiente Pix</li>
              <li>Escolha a opção "Pagar com QR Code" e escaneie o código ao lado</li>
              <li>Confirme as informações e finalize a compra</li>
            </ol>
          </div>
        </div>
      </div>
      <div fxLayout="column" *ngIf="paymentMethod == 'bankslip'">
        <div class="info-container" fxFlex fxLayout="row">
          <div>
            <table>
              <tr>
                <td>
                  <span fxFlex><strong>Valor</strong>: {{value | CustomCurrency:'BRL'}}</span>
                </td>
              </tr>
              <tr>
                <td>
                  <span fxFlex> <strong>Data de vencimento</strong>: {{dateFormated | date: 'dd/MM/yyyy'}}</span>
                </td>
              </tr>
              <tr>
                <td>
                  <span fxFlex><strong>Linha digitável</strong>: {{code}}</span>
                </td>
              </tr>
              <tr>
                <td>
                  <img src="{{image}}" class="image-payment" alt="Boleto" width="500px" />
                </td>
              </tr>
            </table>
          </div>
          <span class="vertical-line-bankslip"></span>
          <div class="list">
            <ol>
              <li>A efetivação do pagamento pode levar até dois dias úteis.</li>
              <li>Após confirmado, você receberá uma notificação por e-mail.</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>