<div fxLayout="column" fxLayoutAlign="start stretch">

  <div class="pagamento-da-venda" fxLayout="row" fxFlex>
    <b>Metadados</b>
  </div>

  <mat-divider class="mt-2"></mat-divider>

  <div fxLayout="column" *ngIf="orderMetadata.length">
    <div fxFlex fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start start" *ngFor="let metadata of orderMetadata">
      <div fxLayout="row" fxFlex="250px">
        <span>{{ metadata.key }}</span>
      </div>
      <div fxLayout="row" fxLayoutGap="10px" fxFlex>
        <span>{{ metadata.value }}</span>
      </div>
    </div>
  </div>

  <span class="msgEmpty" *ngIf="!orderMetadata.length">Nenhum metadado para exibir</span>

</div>
