import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { MenuItem } from "primeng/api";
import { SaleCancelComponent } from 'src/app/components/sale-cancel/sale-cancel.component';
import { CobrancaStatusNew } from 'src/app/core/model/cobranca';
import { PageResponse } from 'src/app/core/model/page-response';
import { OrderResponse } from 'src/app/core/model/response/order.response';
import { SalesDetailsResponse } from 'src/app/core/model/response/sales-details-response';
import { TransactionStatus } from 'src/app/core/model/transaction';
import { AccountResponse, TransferOperationsResponse, TransferTransactionsResponse } from 'src/app/core/model/v1.model';
import { PagePermissions } from 'src/app/core/permissions/page-permissions';
import { AlertService } from 'src/app/core/services/alert.service';
import { AppService } from 'src/app/core/services/v0/app.service';
import { TransacaoService } from 'src/app/core/services/v0/transaction.service';
import { TransactionDetailFromSalesDetailComponent } from '../../sales-details/details/transactionDetailFromSalesDetail.component';

export interface PeriodicElement {
  dataPagamento: string;
  parcela: number;
  status: string;
  valorBruto: number;
  taxa: number;
  valorLiquido: number;
}

const ELEMENT_DATA: PeriodicElement[] = [
  { dataPagamento: '07/06/2021', parcela: 1, status: 'Pago', valorBruto: 1.0079, taxa: 1.0079, valorLiquido: 20.58 },
  { dataPagamento: '07/06/2021', parcela: 2, status: 'Pago', valorBruto: 1.0079, taxa: 1.0079, valorLiquido: 20.58 },
];

@Component({
  selector: 'app-transfer-details',
  templateUrl: './transfer-details.component.html',
  styleUrls: ['./transfer-details.component.scss']
})

export class TransferDetailsComponent implements OnInit {
  pageResponse: PageResponse;
  transferOperations = new MatTableDataSource<TransferOperationsResponse>();
  transferTransactions = new MatTableDataSource<TransferTransactionsResponse>();
  crumbs: MenuItem[];
  transaction: SalesDetailsResponse;
  order: OrderResponse;
  options: UntypedFormGroup;
  placeholderControl = new UntypedFormControl('Telefone');
  displayedColumns: string[] = ['date', 'description', 'amount', 'fee', 'due'];
  displayedTransactionColumns: string[] = ['date', 'client', 'value', 'type', 'status'];
  dataSource = ELEMENT_DATA;
  loading = true;
  listaStatus: CobrancaStatusNew[] = new Array();
  transactionStatusList: TransactionStatus[] = new Array();
  enableRefundButton: boolean = false;
  transactionDetailsPermissions = PagePermissions.TRANSACTION_DETAILS;
  cancelationPermissions = PagePermissions.CANCELATION_SALES_DETAIL;
  affiliateName: string;
  transfer: any;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  transferTransactionsLength: number;
  transferTransactionsSize: number;
  today: Date = new Date();

  constructor(
    private route: ActivatedRoute,
    private transactionService: TransacaoService,
    private alertService: AlertService,
    private permissionsService: NgxPermissionsService,
    fb: UntypedFormBuilder,
    private appService: AppService,
    public dialog: MatDialog,
  ) {
    this.options = fb.group({
      placeholder: this.placeholderControl
    });
  }

  ngOnInit() {

    this.pageResponse = {
      pageNumber: 0,
      totalPages: 0,
      pageSize: 10,
      totalElements: 0,
      content: null
    };

    this.initTransferTables()
    this.initCrumbs();

    //Busca status da cobranca
    this.appService.getChargeStatus().then(data => {
      this.listaStatus = data.cobrancaStatus;
    });

    //Busca status da transação
    this.appService.getTransactionStatus().then(data => {
      this.transactionStatusList = data.transactionStatus;
    });

    /* this.getById(); */
    this.loading = false;

    this.permissionsService.hasPermission(PagePermissions.CANCELATION_SALES_DETAIL).then(hasPermission => {
      this.enableRefundButton = hasPermission;
    });

  }

  ngAfterViewInit() {
    this.transferTransactions.paginator = this.paginator;
  }

  initTransferTables() {

    const transferOperationsSource = [{
      date: new Date(),
      description: "Deposito Automatico",
      amount: 143.71,
      fee: 5.0,
      due: 143.71
    },
    {
      date: new Date(),
      description: "Venda Credito Visa",
      amount: 150.00,
      fee: 6.29,
      due: 150.0
    }
    ];

    const transferTransactionsSource = [{
      date: new Date(),
      client: "Carlos Melo",
      value: 49.0,
      type: "Assinatura",
      status: "Aprovada"
    },
    {
      date: new Date(),
      client: "John Silva",
      value: 79.0,
      type: "Assinatura",
      status: "Aprovada"
    },
    {
      date: new Date(),
      client: "John Silva",
      value: 79.0,
      type: "Assinatura",
      status: "Aprovada"
    },
    {
      date: new Date(),
      client: "John Silva",
      value: 79.0,
      type: "Assinatura",
      status: "Aprovada"
    },
    {
      date: new Date(),
      client: "John Silva",
      value: 79.0,
      type: "Assinatura",
      status: "Aprovada"
    },
    {
      date: new Date(),
      client: "John Silva",
      value: 79.0,
      type: "Assinatura",
      status: "Aprovada"
    }
    ];

    this.transferOperations = new MatTableDataSource(transferOperationsSource);
    this.transferTransactions = new MatTableDataSource(transferTransactionsSource);
    this.transferTransactionsLength = transferTransactionsSource.length;
    this.transferTransactionsSize = 10;
  }


  showResult(result) {
    if (result != null) {
      this.pageResponse.content = result.content;
      this.pageResponse.pageNumber = result.pageable.pageNumber;
      this.pageResponse.totalElements = result.totalElements;
      this.pageResponse.totalPages = result.totalPages;
      this.transfer = this.pageResponse.content;
    } else {
      this.pageResponse.pageNumber = 0;
      this.pageResponse.totalElements = 0;
      this.pageResponse.totalPages = 0;
      this.transfer = new MatTableDataSource<AccountResponse>();
    }
  }

  /* getById() {
    this.loading = true;
    this.transactionService.getById(this.id).subscribe(
      result => {
        this.transaction = result;
      },
      error => {
        this.alertService.handleError(error);
      }
    )
    this.loading = false;
  } */

  initCrumbs() {
    this.crumbs = [
      { label: 'Financeiro', routerLink: ['/my-account'] },
      { label: '1', routerLink: ['/my-account/transfer-details' ]}
    ];
  }

  /*   getStatusColor(status) {
      for (let i = 0; i < this.listaStatus.length; i++) {
        if (status === this.listaStatus[i].value) {
          let aux = this.listaStatus[i].colorClass;
          return aux;
        }
      }
      return "green";
    }
   */
  getTransactionTextStatusColor(status) {
    for (let i = 0; i < this.transactionStatusList.length; i++) {
      if (status === this.transactionStatusList[i].value) {
        let aux = this.transactionStatusList[i].textColor;
        return aux;
      }
    }
    return "gray";
  }

  getTransactionStatusIcon(status) {
    for (let i = 0; i < this.transactionStatusList.length; i++) {
      if (status === this.transactionStatusList[i].value) {
        let aux = this.transactionStatusList[i].textColorClass;
        return aux;
      }
    }
    return "gray";
  }

  getStatusColor(status) {

    return "transaction-status transaction-" + status;
  }

  getStatusIcon(status) {
    switch (status) {
      case "PAY":
        return "fas fa-check-circle";
      case "CHARGEBACK":
        return "far fa-angry";
      case "CANCELED":
        return "fas fa-ban";
      case "REFUND":
        return "fas fa-backspace";
      case "PENDING":
        return "fas fa-exclamation-circle";
      case "OVERDUE":
        return "far fa-calendar-times";
      default:
        return "fas fa-bug";
    }
  }

  saleCancel() {
    const dialogRef = this.dialog.open(SaleCancelComponent, {
      width: '600px',
      height: '400px',
      data: this.transaction,
    });

    dialogRef.afterClosed().subscribe(result => {
      this.ngOnInit();
    });
  }

  detailTransaction(history, event) {
    this.permissionsService.hasPermission(this.transactionDetailsPermissions).then(hasPermission => {
      if (hasPermission) {
        const dialogRef = this.dialog.open(TransactionDetailFromSalesDetailComponent, {
          width: '380px',
          height: '380px',
          data: { transacao: history }
        });
        dialogRef.afterClosed().subscribe(result => {
        });
      }
    });

  }
}