export class Charge {
  id: number;
  status: string;
  value: number;
  refundedAmount: number;
  transactions: Transaction[];
  installments: number;
  customer: Customer;
  order: Order;
}

export class Order {
  id: number;
  orderReference: string;
  affiliate: Affiliate;
}

export class Customer {
  id: number;
  document: string;
}

export class Transaction {
  paymentMethod: string;
  id: number;
  value: number;
  paidValue: number;
  refundValue: number;
  type: string;
  status: string;
  transactionDate: Date;
  affiliate: Affiliate;
  establishment: Establishment;
  transactionReference: string;
  returnCode: string;
  returnMessage: string;
  nsuCapture: string;
  nsuNumber: string;
  authorizationNumber: string;
  tid: string;
  uniqueSequenceNumber: string;
  authenticationNumber: string;
  pan: string;
  returnAutCode: string;
  transactionKey: string;
  refundReason: string;
  yourReferenceId: string;
  card: CreditCard;
  installments: number;
}

export class CreditCard {
  holder: CardHolder;
  bin: string;
  lastFour: string;
  expiration: ExpirationDate;
  brand: string;
  token: string;
  id: number;
}

export class ExpirationDate {
  month: string;
  year: string;
}

export class CardHolder {
  name: string;
  document: string;
}

export class Establishment {
  id: number;
}

export class Affiliate {
  id: number;
  name: string;
  bussinessName: string;
}

export class Split {
  id: number;
  amount: number;
  affiliate: Affiliate;
}

export class Metadata {
  key: any;
  value: any;
}

export class OrderEvents {
  eventId: number;
  httpCode: number;
  eventResultCreated: Date;
  orderDate: Date;
  eventCreated: Date;
}
