import { Component, Input, OnInit } from '@angular/core';
import { Metadata } from 'src/app/core/model/v2.model';
import { AlertService } from 'src/app/core/services/alert.service';
import { OnOrderV2 } from 'src/app/core/services/v2/order.service.v2';

@Component({
  selector: 'app-metadata-list',
  templateUrl: './metadata-list.component.html',
  styleUrls: ['./metadata-list.component.scss']
})
export class MetadataListComponent implements OnInit {
  @Input() orderId: number;

  orderMetadata: Metadata[] = new Array();

  constructor(
    private onOrder: OnOrderV2,
    private alertService: AlertService,
  ) { }

  ngOnInit(): void {
    this.getOrderMetadata();
  }

  getOrderMetadata() {
    this.onOrder.getMetadataById(this.orderId).subscribe(
      result => {
        this.orderMetadata = Object.keys(result).map(key => ({
          key: key,
          value: result[key]
        }));
      },
      error => {
        this.alertService.handleError(error);
      }
    );
  }

}
