import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { Cobranca, CobrancaStatus } from 'src/app/core/model/cobranca';
import { PagePermissions } from 'src/app/core/permissions/page-permissions';

import { NgxPermissionsService } from 'ngx-permissions';
import { TransactionFilter } from 'src/app/core/common/filters/transaction.filter';
import { StatusHelper } from 'src/app/core/helper/status-helper';
import { StringUtils } from 'src/app/core/helper/string-utils';
import { PageResponse } from 'src/app/core/model/page-response';
import { Pageable } from 'src/app/core/model/pageable';
import { Transaction, TransactionName } from 'src/app/core/model/transaction';
import { AlertService } from 'src/app/core/services/alert.service';
import { AppService } from 'src/app/core/services/v0/app.service';
import { TransacaoService } from 'src/app/core/services/v0/transaction.service';
import { TransactionDetailComponent } from 'src/app/pages/transactions/transaction-detail/transaction-detail.component';
declare var $: any;

@Component({
  selector: 'app-client-details-summary',
  templateUrl: 'summary.component.html',
  styleUrls: ['summary.component.scss']
})
export class ClientDetailsSummaryComponent implements OnInit {
  checkedTransactions = [];
  loading = false;
  status: number = 0;
  type:string = ''; 

  aux = new Date();
  listaParcela: Array<Cobranca> = [];
  dataPedidoFormatado: string;
  printParcelas: Array<any>;
  printChargeBackSuccess: Array<string>;
  listaStatus: CobrancaStatus[];
  listTransactionType: TransactionName[];

  idSelectedCharge:number = 0;

  signatureCancelPermissions = PagePermissions.CUSTOMER_EDIT_SIGNATURE_CANCEL;
  refundPermissions = PagePermissions.CUSTOMER_EDIT_REFUND;
  chargebackPermissions = PagePermissions.CUSTOMER_EDIT_CHARGEBACK;
  chargeAndRefundPermissions = PagePermissions.CUSTOMER_EDIT_REFUND_CHARGEBACK;
  transactionDetailsPermissions = PagePermissions.TRANSACTION_DETAILS;
  transactionParcelPermissions = PagePermissions.TRANSACTION_PARCEL;  
  transactionPermissions = PagePermissions.TRANSACTION;
  createTransactionPermissions = PagePermissions.TRANSACTION_NEW;

  signatureTransactions = new MatTableDataSource<Cobranca>();
  currentParcelas:Cobranca[] = new Array();
  displayedColumns: string[] = [
    'business_name',
    'payment_gateway',
    'establishment_code',
    'transaction_date', 
    'lr',  
    'card_brand',
    'value',
    'status',
    'type',
    'actions'];


  pageResponse:PageResponse;
  pageable:Pageable;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  transactions = new MatTableDataSource<Transaction>();
  filters: TransactionFilter;
  seisPrimeirosDigitosCartao: string;
  quatroUltimosDigitosCartao: string;
  end = new Date(Date.now());
  begin = new Date(Date.now());
  rangeValid = true;
  check = [];

  document: string;
  name: string;
  @Input() client = {};
  viewFilter = false;


  constructor(
    private route: ActivatedRoute,
    private transactionService: TransacaoService,
    public dialog: MatDialog,
    private permissionsService: NgxPermissionsService ,
    private appService:AppService,
    private stringUtils:StringUtils,
    private alertService: AlertService,
    private statusHelper:StatusHelper,
    private router: Router
  ) {
  }


  id = this.route.snapshot.paramMap.get('id');
  idPedido = this.route.snapshot.paramMap.get('idPedido');

  ngOnInit() {

    this.pageResponse = {
      pageNumber:0,
      totalPages:0,
      pageSize:5,
      totalElements:0,
      content:null
    };

    this.begin.setDate(this.begin.getDate() - 30);


    this.filters = {
      startDate:moment(this.begin).format('YYYY-MM-DD'),
	    endDate:moment(this.end).format('YYYY-MM-DD'),
      status:0,
      name : '',
      document:'',
      type: '',
      customer: this.client['id'] ,
      cardBin:this.seisPrimeirosDigitosCartao + this.quatroUltimosDigitosCartao
    }

      setTimeout(() => {
        this.appService.getStatus().then(data => {
            this.listaStatus = data.transactionStatus;
            this.listTransactionType = data.transactionType;
        });

    }, 0);
      this.filters.status = 0;
      this.seisPrimeirosDigitosCartao = "";
      this.quatroUltimosDigitosCartao = "";
      this.filter();

    this.loading = true;
    this.transactionService.getStatus().then(data => {
      this.listaStatus = data.cobrancaStatus;      
    });    
  }
    
  filter() {
    this.permissionsService.hasPermission(this.transactionPermissions).then(hasPermission =>{
      if ( hasPermission ) {
        this.loading = true;
        this.filters.cardBin = this.seisPrimeirosDigitosCartao + this.quatroUltimosDigitosCartao;
        this.filters.status = this.status;
        this.filters.name = this.name;
        this.filters.type = this.type;
        this.filters.customer = this.client['id'];
        if((this.filters.cardBin.length !== 10) && (this.filters.cardBin !== '')) {
          this.alertService.error('Erro', 'Para pesquisar pelo cartão, você deve informar os 6 primeiros e também os quatro últimos dígitos do cartão.');
        } else {
          if (this.stringUtils.confirmDateRange(this.begin.toString(), this.end.toString())) {
            this.filters.startDate = moment(this.begin).format('YYYY-MM-DD');
            this.filters.endDate = moment(this.end).format('YYYY-MM-DD');
            this.pageable = {page: 0, size: 5};
            this.transactionService.filterTransaction(this.filters, this.pageable)
            .subscribe(result => {
              this.pageResponse = result.data;
              this.showResult();
            });
          }
        }
      } 
    }); 
    this.loading = false;   
   
  }

  showResult(){
    this.pageResponse.pageSize = 5;
    this.transactions = this.pageResponse.content;
    this.transactions.paginator = this.paginator;
    this.transactions.sort = this.sort;  
  }

  handlePage(event?:PageEvent){
    this.pageable.page = event.pageIndex;
    this.transactionService.filterTransaction(this.filters, this.pageable)
        .subscribe(result => {
          this.pageResponse = result.data;
          this.showResult();
        });
    return event;
  }

  public handleSort(event?:any){
    this.pageable = {page: 0, size: 5};
    this.pageable = Object.assign({}, this.pageable, {sort: `${event.active},${event.direction}`});
    this.transactionService.filterTransaction(this.filters, this.pageable)
        .subscribe(result => {
          this.pageResponse = result.data;
          this.showResult();
        });
  }

  getStatusColor(status) {
    return this.statusHelper.getTransacaoStatusColor(status);
  }

  detailTransaction(row , event) {
    this.permissionsService.hasPermission(this.transactionDetailsPermissions).then(hasPermission =>{
      if ( hasPermission ) {
        let element:Element = <Element> event.target;
        let valueView = element.getAttribute('data-not-view-transaction');
        if ( !valueView ) {
            let transaction = new Transaction();
            transaction.convertFromTransactionResponse(row);
            const dialogRef = this.dialog.open(TransactionDetailComponent, {
              width: '650px',
              height: '380px',
              data: { transacao: transaction }
            });
            dialogRef.afterClosed().subscribe(result => {
            });
        }  
      } else {
         this.alertService.errorPermission();
      }
    });
   
    
  }

  filterView(  ) {
    this.viewFilter = !this.viewFilter;    
    if (this.viewFilter) {
      $(".view-filter" ).css("display","none");
      $(".view-filter" ).removeClass("hide"); 
      $(".view-filter" ).slideDown();
    } else {       
       $(".view-filter" ).slideUp();
    }
  }

  newTransaction(){

    this.permissionsService.hasPermission(this.createTransactionPermissions).then(hasPermission =>{
        if ( hasPermission ) {
            this.router.navigate(['transactions/new']);
        } else {
            this.alertService.errorPermission();
        }
    }); 
  }

}

