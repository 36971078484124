import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { DateFormatPipe } from 'ngx-moment';
import { NgxPermissionsService } from 'ngx-permissions';
import { of } from 'rxjs';
import { RefundTransactionFilter } from 'src/app/core/common/filters/refund-transactions.filter';
import { Administradora } from 'src/app/core/model/administradora';
import { DataCard } from 'src/app/core/model/data-card';
import { RefundReason } from 'src/app/core/model/enum/refund-reason-type';
import { MotivoEstorno } from 'src/app/core/model/motivo-cancelamento';
import { PageResponse } from 'src/app/core/model/page-response';
import { Pageable } from 'src/app/core/model/pageable';
import { RefundTransaction } from 'src/app/core/model/refund-transaction';
import { AffiliateMinimalResponse, PlanResponse } from 'src/app/core/model/v1.model';
import { PagePermissions } from 'src/app/core/permissions/page-permissions';
import { AlertService } from 'src/app/core/services/alert.service';
import { AppService } from 'src/app/core/services/v0/app.service';
import { AffiliateServiceV1 } from 'src/app/core/services/v1/affiliate.service.v1';
import { PlanServiceV1 } from 'src/app/core/services/v1/plan.service.v1';
import { ReportServiceV1 } from 'src/app/core/services/v1/report.service.v1';
import { DateFormatter } from 'src/app/date-formatter';

@Component({
  selector: 'refund-transaction-report',
  templateUrl: './refund-transaction-report.component.html',
  styleUrls: ['./refund-transaction-report.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class RefundTransactionReportComponent implements OnInit {

  startDate: Date;
  endDate: Date;
  pageEvent: PageEvent;
  pageResponse: PageResponse;
  public pageable: Pageable;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  exportPermission = PagePermissions.REPORT_APPROVED_TRANSACTION_EXPORT;
  showExportBtn: boolean;
  filters: RefundTransactionFilter = new RefundTransactionFilter();
  expandedElement: RefundTransaction | null;
  transactions = new MatTableDataSource<RefundTransaction>();
  bandeiras: Administradora[];
  rangeValid: boolean = true;
  loading: boolean = false;

  planos: PlanResponse[];
  refundReasons: MotivoEstorno[] = new Array();
  reason: MotivoEstorno;

  loadingTab: boolean = true;
  affiliate: AffiliateMinimalResponse[];
  searchAffiliateForm: UntypedFormGroup;
  searchReasonForm: UntypedFormGroup;
  searchPlansForm: UntypedFormGroup;
  @ViewChild('allSelectedForAffiliate') private allSelectedForAffiliate: MatOption;
  @ViewChild('allSelectedForPlans') private allSelectedForPlans: MatOption;
  @ViewChild('allSelectedForReason') private allSelectedForReason: MatOption;


  displayedColumns: string[] = [
    'id',
    'customer.name',
    'card',
    'value',
    'refundDate',
    'refundReason',
    'user'];

  datasCard: DataCard[];

  constructor(
    private appService: AppService,
    private alertService: AlertService,
    private reportServiceV1: ReportServiceV1,
    private formBuilder: UntypedFormBuilder,
    private permissionsService: NgxPermissionsService,
    private planService: PlanServiceV1,
    private affiliateService: AffiliateServiceV1
  ) { }

  ngOnInit(): void {
    this.pageResponse = {
      pageNumber: 0,
      totalPages: 0,
      pageSize: 10,
      totalElements: 0,
      content: null
    };
    this.searchAffiliateForm = this.formBuilder.group({
      orderAffiliate: new UntypedFormControl('')
    });
    this.searchReasonForm = this.formBuilder.group({
      refundReason: new UntypedFormControl('')
    });
    this.searchPlansForm = this.formBuilder.group({
      orderPlans: new UntypedFormControl('')
    });
    this.affiliateService.getAll().subscribe(result => this.affiliate = result);
    this.refundReasons = this.appService.findAllMotivosEstorno();
    this.startDate = moment().startOf('month').toDate();
    this.endDate = new Date();
    this.permissionsService.hasPermission(this.exportPermission).then(hasPermission => {
      this.showExportBtn = hasPermission;
    });
    this.planService.getAllRecurrents().subscribe(result => this.planos = result);
    this.filter();
  }

  confirmDateRange() {
    let start = moment(this.filters.start);
    let end = moment(this.filters.end);
    let difference = end.diff(start, 'days');
    if (difference > 31) {
      this.rangeValid = false;
    } else {
      this.filters.start = this.filters.start.valueOf();
      this.filters.end = this.filters.end.valueOf();
      this.rangeValid = true;
    }
    return this.rangeValid;
  }

  actionLoading(status: boolean) {
    setTimeout(() => {
      this.loading = status;
      this.loadingTab = status;
    }, 500);

  }

  downloadCsv() {
    if (this.confirmDateRange()) {
      this.loading = true;
      this.reportServiceV1.downloadCsvReportRefunds(this.filters)
        .subscribe(response => {
          let binaryData = ["\ufeff", response];
          let downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(<any>binaryData, { type: "application/json" }));
          let dateFormatPipe: DateFormatPipe = new DateFormatPipe();
          let date = dateFormatPipe.transform(new Date(), "YYYY-MM-DD-HH-mm");
          downloadLink.setAttribute('download', `Relatorio_Estornos_${date}.csv`);
          document.body.appendChild(downloadLink);
          downloadLink.click();
          this.actionLoading(false);
        },
          error => {
            this.actionLoading(false);
            this.alertService.error("Erro", "Ocorreu um erro interno em nosso servidor. Se o erro persistir, por favor, contate o suporte.");
          });
    }
  }

  filter() {
    this.loading = true;
    this.pageable = { page: 0, size: 10 };
    this.search();
  }

  //paginação
  public handlePage(event?: PageEvent) {
    this.pageable.page = event.pageIndex;
    this.search();
    return event;
  }

  //ordenação
  public handleSort(event?: any) {
    this.pageable = { page: 0, size: 5 };
    this.pageable = Object.assign({}, this.pageable, { sort: `${event.active},${event.direction}` });
    this.search();
  }

  private search() {
    let formatter = new DateFormatter();
    this.filters.start = formatter.startOfDay(this.startDate);
    this.filters.end = formatter.endOfDay(this.endDate);
    this.reportServiceV1.reportEstornos(this.filters, this.pageable)
      .subscribe(result => {
        this.showResult(result);
      },
        error => {
          this.actionLoading(false);
          this.alertService.error("Erro", "Ocorreu um erro interno em nosso servidor. Se o erro persistir, por favor, contate o suporte.");
        });
  }

  showResult(result) {
    if (result != null) {
      this.pageResponse.pageNumber = result.pageable.pageNumber;
      this.pageResponse.totalElements = result.totalElements;
      this.pageResponse.totalPages = result.totalPages;
      this.pageResponse.content = result.content;
      this.transactions = this.pageResponse.content;
    } else {
      this.pageResponse.pageNumber = 0;
      this.pageResponse.totalElements = 0;
      this.pageResponse.totalPages = 0;
      this.transactions = this.pageResponse.content;

    }
    this.loading = false;
  }

  tosslePerOneForAffiliate(all) {
    if (this.allSelectedForAffiliate.selected) {
      this.allSelectedForAffiliate.deselect();
      return false;
    }
    if (this.searchAffiliateForm.controls.orderAffiliate.value.length == this.affiliate.length) {
      this.allSelectedForAffiliate.select();
    }
  }
  toggleAllSelectionForAffiliate() {
    if (this.allSelectedForAffiliate.selected) {
      this.searchAffiliateForm.controls.orderAffiliate
        .patchValue([0, ...this.affiliate.map(item => item.id)]);

    } else {
      this.searchAffiliateForm.controls.orderAffiliate.patchValue([]);
    }
  }

  tosslePerOneForReason(all) {
    if (this.allSelectedForReason.selected) {
      this.allSelectedForReason.deselect();
      return false;
    }
    if (this.searchReasonForm.controls.refundReason.value.length == this.refundReasons.length) {
      this.allSelectedForReason.select();
    }
  }
  toggleAllSelectionForReason() {
    if (this.allSelectedForReason.selected) {
      this.searchReasonForm.controls.refundReason
        .patchValue([...this.refundReasons.map(item => item), 0]);
    } else {
      this.searchReasonForm.controls.refundReason.patchValue([]);
    }
  }

  tosslePerOneForPlans(all) {
    if (this.allSelectedForPlans.selected) {
      this.allSelectedForPlans.deselect();
      return false;
    }
    if (this.searchPlansForm.controls.orderPlans.value.length == this.planos.length) {
      this.allSelectedForPlans.select();
    }
  }
  toggleAllSelectionForPlans() {
    if (this.allSelectedForPlans.selected) {
      this.searchPlansForm.controls.orderPlans
        .patchValue([0, ...this.planos.map(item => item.id)]);

    } else {
      this.searchPlansForm.controls.orderPlans.patchValue([]);
    }
  }

  compareId(id1: number, id2: number) {
    if (id2 === 0) {
      return id1 === 0;
    }
    return id1 && id2 && id1 === id2;
  }

}