import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'percentValue'})
export class PercentPipe implements PipeTransform {

  transform(value: string, args?: string[]): any {
    if (!value) { return value; }
    if(value.toString().indexOf(".") > -1){
      return `${(+value).toFixed(2)}%`;
    } else{
      return `${value}%`;
    }
    
  }
}