import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ClipboardService } from 'ngx-clipboard';
import { BankslipMinimalResponse, PixMinimalResponse } from 'src/app/core/model/v1.model';
import { TransactionServiceV1 } from 'src/app/core/services/v1/transaction.service.v1';
import { environment } from "src/environments/environment";





@Component({
  selector: 'billing-rule-transaction',
  templateUrl: './billing-rule-transaction.component.html',
  styleUrls: ['./billing-rule-transaction.component.scss']
})

export class BillingRuleTransaction implements OnInit {
  /*  QrCode
   Data de expiração
   pix copia e cola 
   valor
   plano  
   "00020101021226770014BR.GOV.BCB.PIX2555api.itau/pix/qr/v2/6492c652-1bcb-449f-8263-3848770ecbb25204000053039865802BR5925ZOOP TECNOLOGIA E MEIOS D6009SAO PAULO62070503***630483B0
   */

  emv: string;
  image: string;
  longDate: number;
  dateFormated: Date;
  idCompany: number;
  idTransaction: number;
  paymentMethod: string = '';
  uri: string;
  value: number;
  text: string;
  pixMinimal: PixMinimalResponse;
  bankslipMininal: BankslipMinimalResponse
  status: string;
  barcode: string = '';
  planName: string;


  constructor(
    private route: ActivatedRoute,
    private transactionServiceV1: TransactionServiceV1,
    private clipboardService: ClipboardService
  ) {
  }

  ngOnInit() {
    this.pixMinimal = new PixMinimalResponse();
    this.text = 'Copiar código pix';
    this.idCompany = parseInt(this.route.snapshot.paramMap.get('idCompany'));
    this.idTransaction = parseInt(this.route.snapshot.paramMap.get('idTransaction'));
    this.paymentMethod = this.route.snapshot.paramMap.get('paymentMethod');
    if (this.paymentMethod == "pix") {
      this.transactionServiceV1.getPixTransaction(this.idCompany, this.idTransaction)
        .subscribe(result => {
          this.pixMinimal = result;
          this.longDate = result.expirationDate;
          this.dateFormated = new Date(this.longDate);
          this.image = `${environment.apiUrl}/api/v1/image/qrcode?code=${this.pixMinimal.emv}`;
          this.status = result.status;
        })
    }
    if (this.paymentMethod == "bankslip") {
      this.transactionServiceV1.getBankslipTransaction(this.idCompany, this.idTransaction)
        .subscribe(result => {
          this.bankslipMininal = result;
          this.planName = result.planName;
          this.value = result.value;
          this.barcode = result.barcode;
          this.longDate = result.dueDate;
          this.dateFormated = new Date(this.longDate);
          this.image = `${environment.apiUrl}/api/v1/image/barcode?code=${this.bankslipMininal.barcode}`;
          this.status = result.status;
        })
    }

  }

  getText() {
    this.clipboardService.copyFromContent(this.pixMinimal.emv);
    this.text = 'Copiado!'

  }
}
