import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { firstValueFrom, Observable } from "rxjs";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class OnChargeV2 {
  private header: HttpHeaders;

  constructor(private http: HttpClient) {
    this.header = new HttpHeaders();
    this.header.set("Access-Control-Allow-Origin", "*");
    this.header.set("Access-Control-Allow-Headers", "Location");
  }

  public OnChargeV2(): OnChargeV2 {
    return new OnChargeV2(this.http);
  }

  public async getById(id: any): Promise<any> {
    return firstValueFrom(this.http.get<any>(`${environment.apiUrl}/api/v2/charges/${id}`, { headers: this.header }));
  }

  public refundCreditCard(id, request): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/v2/charges/${id}/credit-card/refund`, request, { headers: this.header });
  }

  public returnPix(id): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/v2/charges/${id}/pix/return`, { headers: this.header });
  }

  public captureCharge(id): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/v2/charges/${id}/credit-card/capture`, { headers: this.header });
  }

  public getSplitsById(id: any): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/api/v2/charges/${id}/splits`, { headers: this.header });
  }

  public cancelBoleto(id): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/v2/charges/${id}/boleto/cancel`, { headers: this.header });
  }

  public cancelPix(id): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/v2/charges/${id}/pix/cancel`, { headers: this.header });
  }
}
