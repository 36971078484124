import { Component, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Router } from "@angular/router";
import { MenuItem } from "primeng/api";
import { BempaggoConstants } from "src/app/core/helper/string-utils";
import { PlanFrequency, PlanResponse, PlanType, ProductResponse } from "src/app/core/model/v1.model";
import { AlertService } from "src/app/core/services/alert.service";
import { ImageUploadService } from "src/app/core/services/v1/image-upload.service";
import { PlanServiceV1 } from "src/app/core/services/v1/plan.service.v1";

@Component({
    selector: 'app-new-plan',
    templateUrl: './new-plan.component.html',
    styleUrls: ['./new-plan.component.scss']
})
export class NewPlanComponent implements OnInit {
    @ViewChild("planForm", {static: true}) form: NgForm;
    product: ProductResponse;
    products: ProductResponse[];
    plan: PlanResponse;
    frequencies: any[];
    types: any[];
    alphanumericPattern;
    chargeIndefinitely: boolean;
    crumbs: MenuItem[];
    installments: number[];
    croppedImage: Blob;

    constructor(
        private router: Router,
        private alertService: AlertService,
        private planService: PlanServiceV1,
        private imageUploadService: ImageUploadService
    ) { }

    ngOnInit() {
        this.chargeIndefinitely = false;
        this.plan = new PlanResponse();
        this.plan.type = PlanType.RECURRENT;
        this.alphanumericPattern = BempaggoConstants.PATTERN_ALPHANUMERIC;
        this.chargeIndefinitely = true;
        this.frequencies = Object.values(PlanFrequency).filter(x => typeof x === 'string');
        this.types = Object.values(PlanType).filter(x => typeof x === 'string');
        this.refreshInstallments();
        this.imageUploadService.imageCrop.subscribe(result => this.croppedImage = result);
    }

    uploadImage(planResponseId: number) {
        if (this.croppedImage) {
            this.planService.uploadImage(planResponseId, this.croppedImage).subscribe(
                result => {
                    this.goBack(result);
                },
                error => {
                    this.alertService.handleError(error);
                }
            );
        }
    }

    refreshInstallments() {
        this.installments = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
        if (this.plan.type && this.plan.type.toString() === 'RECURRENT') {
            if (this.plan.frequency) {
                let maxInstallments = this.planService.getMaxInstallmentsFromFrequency(this.plan.frequency);
                this.installments = [];
                for (let i = 2; i <= maxInstallments; i++) {
                    this.installments.push(i);
                }
            }
        }
        this.plan.maxInstallments = 1;
    }

    initCrumbs() {
        this.crumbs = [
            { label: 'Planos', routerLink: ['/plans'] },
            { label: this.product.name, routerLink: ['/plans', this.product.id] },
            { label: 'Novo Plano' },
        ];
    }

    create() {
        if (this.form.valid) {
            let request = this.form.value as PlanResponse; //trocar para PlanResponse?
            request.charges = this.chargeIndefinitely ? 0 : this.plan.charges;
            request.type = PlanType.RECURRENT;

            this.planService.create(request).subscribe(
                result => {
                    this.getPlanById(result.headers.get('Location'));
                }, error => {
                    this.alertService.handleError(error);
                }
            );
        }

    }

    private getPlanById(result: any) {
        this.planService.getByUri(result).subscribe(result => {
            this.uploadImage(result.paymentlink.id)
            this.goBack(result.id);
        });
    }

    goBack(id) {
        this.router.navigate(['plans', id]);
    }

    cancel() {
        this.router.navigate(['plans']);
    }
}