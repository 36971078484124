import { OnInit, Component } from "@angular/core";
import { AuthService } from "src/app/core/auth/auth.service";

@Component({
    selector: 'logout',
    templateUrl: './logout.component.html',
    styleUrls: ['./logout.component.scss']
  })

  export class LogoutComponent implements OnInit {

    constructor(
      public authService:AuthService
    ) {}

    ngOnInit(){
        this.authService.logout();
    }
  }