<mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>

<div class="container" *ngIf="!loading">

  <div fxLayout="column" fxLayoutGap="10px" style="margin-bottom: 10px;">
    <div fxLayout="row">

      <div fxFlex fxLayout="row" fxLayoutAlign="start center">
        <p-breadcrumb id="breadcrumb" [model]="crumbs"></p-breadcrumb>
      </div>

      <div fxFlex fxLayout="row" fxLayoutAlign="end center" *ngxPermissionsOnly="createImportPermissions">

      </div>

    </div>
  </div>

  <div fxLayout="column" class="card" fxLayoutGap="10px" fxLayoutAlign="space-between stretch">

    <form (ngSubmit)="upload()" #importForm="ngForm" name="importForm">
      <mat-horizontal-stepper [linear]="isLinear" #stepper>

        <!-- Download layout padrão -->
        <mat-step [stepControl]="firstFormGroup">
          <form [formGroup]="firstFormGroup">
            <ng-template matStepLabel>Baixe o layout</ng-template>
            <div fxLayout="column" fxLayoutGap="10px">
              <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px">
                <div class="bg-light" fxFlex fxLayout="column" fxLayoutAlign="space-evenly center" fxLayoutGap="0px">
                  <a class="mt-3" href="assets/layouts-import/bempaggo-layout.xlsx" download>

                    <h2><i class="layout fal fa-lg fa-file-download"
                        matTooltip="Baixar layout padrão de importação"></i>
                    </h2>
                  </a>
                  <span>Baixar layout padrão de importação</span>
                  <span><i class="fas fa-exclamation-triangle text-warning"></i> limite de 1000 registros por
                    arquivo</span>
                </div>
              </div>

              <div fxLayoutAlign="end end" class="mt-3" fxLayoutGap="10px">
                <button mat-button matStepperNext>Próximo</button>
              </div>
            </div>
          </form>
        </mat-step>

        <!-- Upload -->
        <mat-step [stepControl]="secondFormGroup">
          <form [formGroup]="secondFormGroup">
            <ng-template matStepLabel>Selecione o arquivo</ng-template>

            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px">

              <div fxFlex="35">
                <mat-form-field>
                  <input matInput placeholder="Nome do arquivo" [(ngModel)]="fileName" name="fileName" readonly
                    formControlName="secondCtrl" required>
                  <mat-error>Nenhum arquivo selecionado!</mat-error>
                </mat-form-field>
              </div>

              <div *ngxPermissionsOnly="createImportPermissions">
                <input hidden type="file" (change)="uploadFile($event.target.files)" accept=".xlsx" name="selectedFile"
                  [(ngModel)]="request.selectedFile" #fileInput [ngModelOptions]="{standalone: true}" />
                <button type="button" class="btn btn-info" md-button (click)="fileInput.click()">Selecionar
                  arquivo</button>
              </div>
            </div>
          </form>
          <div fxLayoutAlign="end end" class="mt-3" fxLayoutGap="10px">
            <button mat-button matStepperPrevious>Voltar</button>
            <button type="submit" *ngIf="!loading" class="btn btn-primary" md-button matStepperNext>Enviar
              arquivo</button>
          </div>

        </mat-step>

        <!-- Concluido -->
        <mat-step>
          <ng-template matStepLabel>Importação concluida</ng-template>
          <div fxLayout="column" fxLayoutAlign="center center" class="mt-3">
            <i class="far fa-check-circle text-success h1"></i>
            <div>
              <p>Arquivo enviado com sucesso. Importação iniciada. Para mais detalhes, acompanhe.</p>
            </div>
            <button class="btn btn-primary mt-3" md-button (click)="importList()">Acompanhar importação</button>
          </div>
        </mat-step>
      </mat-horizontal-stepper>
    </form>

  </div>
</div>
