import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../core/auth/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { User } from '../../core/model/user';
import { UserService } from '../../core/services/v0/user.service';
import { AlertService } from '../../core/services/alert.service';
import { Data } from '../../core/model/data';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  user: User = new User();
  loading = false;
  returnUrl: string;
  error = '';
  forgotPass = false;
  messagePass = false;


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private userService: UserService,
    private alertService: AlertService,
    public data: Data) { }

  ngOnInit() {
    // reset login status
    this.authService.logout();
    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    this.loading = false;
  }

  login() {
    this.loading = true;
    this.authService.login(this.user.username, this.user.password)
      .subscribe(
        data => {
          this.loading = false;
          this.router.navigate([this.returnUrl]);
        },
        error => {
          this.error = error;
          this.loading = false;
        });
  }


  forgotPassword() {
    this.forgotPass = !this.forgotPass;
  }

  sendNewPass() {
    if (this.user.email) {
      this.userService.resetPasswordByUsername(this.user.email)
        .subscribe(
          data => {
            this.loading = false;
          },
          error => {
            this.error = error;
            this.loading = false;
          });
          this.messagePass = true;
          this.forgotPass = false;
    } else {
      this.alertService.error('Erro', 'Informe seu usuário');
    }
  }


  removeMessage() {
    this.messagePass = false;
  }
}

