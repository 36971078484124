import { Component, OnInit, ViewChild } from "@angular/core";
import { PagePayResponse, PlanResponse } from "src/app/core/model/v1.model";
import { ProductServiceV1 } from "src/app/core/services/v1/product.service.v1";
import { AlertService } from "src/app/core/services/alert.service";
import { Router, ActivatedRoute } from "@angular/router";
import { PlanServiceV1 } from "src/app/core/services/v1/plan.service.v1";
import { PagePayServiceV1 } from "src/app/core/services/v1/pagepay.service.v1";
import { MenuItem } from "primeng/api";

@Component({
    selector: 'app-pagepay-details-v1',
    templateUrl: './pagepay-details-v1.component.html',
    styleUrls: ['./pagepay-details-v1.component.scss']
  })
  export class PagePayDetailsV1Component implements OnInit {

    plan:PlanResponse;
    pagepay:PagePayResponse;
    view:boolean;
    crumbs: MenuItem[];
    idPagePay: Number;

    constructor(
        private route: ActivatedRoute,
        private alertService: AlertService,
        private pagepayService: PagePayServiceV1,
        private planService: PlanServiceV1
    ){}

    ngOnInit(){
        this.pagepayService.editPagepay.subscribe(result => {this.view = result; this.initCrumbs()});
        this.view=true;
        //this.idPagePay = parseInt(this.route.snapshot.paramMap.get('id'));
        this.pagepayService.getById(parseInt(this.route.snapshot.paramMap.get('id'))).subscribe(
            result => {
                this.pagepay = result;
                this.getPlan();
            },
            error => {
                this.alertService.handleError(error);
            }
        );
    }

    private getPlan() {
        if (this.pagepay.plan != null) {
            this.planService.getByUri(this.pagepay.plan).subscribe(
                result => {
                    this.plan = result;
                    this.pagepay.maxInstallments = this.plan.maxInstallments;
                    this.initCrumbs();
                },
                error => {
                    this.alertService.handleError(error);
                }
            );
        }
    }

    public initCrumbs(){
        this.crumbs = [
            {label:'Link de Pagamento', routerLink: ['/paymentLinks']},
            {label:this.pagepay.name, routerLink: ['/paymentLinks', this.pagepay.id]}
        ];
    }

}