<div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between start" class="mb-2">
  <mat-toolbar role="toolbar" class="task-header w-75" *ngIf="signature">
    <div class="w-100" fxLayout="row" fxLayoutAlign="space-between start">
      <div class="w-auto d-flex">
        <a (click)="closeTransacoes()" class="back-list hide pr-3 text-black-50 pointer">
          <i class="fas fa-chevron-left"></i>
        </a>
        <b class="pr-3">#{{signature.id}}</b>
        <div class="limit-text"> {{signature.nome}}</div>
      </div>
      <select *ngIf="currentParcelas.length > 0" [(ngModel)]="selectInstallment"
        class="form-control w-auto hide back-list" (change)="openTransacoes(selectInstallment , $event , true)">
        <option *ngFor="let row of currentParcelas" [ngValue]="row">
          Parcela: {{row.numParcela}}
        </option>
      </select>
    </div>
  </mat-toolbar>
  <i (click)="onNoClick()" title="Fechar" class="fas fa-times pt-2 pointer"></i>
</div>


<div class="content d-flex">

  <div class="strech w-100 active">
    <span><i class="fas fa-list-ol"></i> Listagem de Parcelas</span>
    <table mat-table [dataSource]="signatureTransactions" matSort>

      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Parcela </th>
        <td mat-cell *matCellDef="let row"> {{row.installment}} </td>
      </ng-container>
      <ng-container matColumnDef="dtVencimentoFormatado">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Vencimento </th>
        <td mat-cell *matCellDef="let row" data-view-action="true"> {{row.dueDate | date: 'dd/MM/yyyy'}}
        </td>
      </ng-container>

      <ng-container matColumnDef="dtPagamentoFormatado">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Pagamento</th>
        <td mat-cell *matCellDef="let row" data-view-action="true"> {{row.paymentDate | date: 'dd/MM/yyyy'}}
        </td>
      </ng-container>

      <ng-container matColumnDef="valorParcela">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Valor Parcela</th>
        <td mat-cell *matCellDef="let row" data-view-action="true">
          {{(row.amount / 100) | CustomCurrency:'BRL'}} </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
        <td mat-cell *matCellDef="let row" data-view-action="true">
          {{row.status | translate}}
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row" class="center-text">
          <i class="fas fa-cog fa-2x text-black-50" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
            aria-expanded="false"></i>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a class="dropdown-item" (click)="chargeBack( row )" *ngIf="getAction(row) && false">
              <i class="fal fa-usd-circle mr-2"></i> ChargeBack
            </a>
            <a class="dropdown-item" (click)="openEstornoList( row )" *ngIf="getAction(row)">
              <i class="fal fa-undo-alt  mr-2"></i> Estornar
            </a>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

      <div *ngxPermissionsOnly="transactionDetailsPermissions; else dontHavePermission; then hasPermission"></div>
      <ng-template #dontHavePermission>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </ng-template>
      <ng-template #hasPermission>
        <tr [class.hover-row]="row.ultimaTransacaoDto != null" mat-row *matRowDef="let row; columns: displayedColumns;">
        </tr>
      </ng-template>
    </table>
  </div>


  <!-- <div id="trans-installments">
    <app-not-allowed [classAux]="'shadow-none border-0'" *ngxPermissionsExcept="transactionPermissions">
    </app-not-allowed>
    <div *ngxPermissionsOnly="transactionPermissions" fxLayout="row" fxLayoutAlign="space-between center">
      <div>
        <i class="fal fa-exchange-alt"></i> Listagem de Transações
      </div>
      <div *ngIf="selectInstallment" fxLayout="row" fxLayoutAlign="end center">
        <a class="dropdown-item pointer" (click)="chargeBack( selectInstallment )"
          *ngIf="getAction(selectInstallment) && false">
          <i class="fal fa-usd-circle mr-2"></i> ChargeBack
        </a>
      </div>
    </div>
    <div class="content h-100" fxLayout="row" fxLayoutAlign="center center" *ngIf="loadingTrans">
      <mat-spinner></mat-spinner>
    </div>
    <div class="content h-100" fxLayout="row" fxLayoutAlign="center center" *ngIf="!loadingTrans">
      <div *ngIf="currentTransactions.length > 0" class="strech-transc w-100 pb-4">
        <table mat-table [dataSource]="transactions" matSort>

          <ng-container matColumnDef="dtTransacaoFormatted">
            <th mat-header-cell *matHeaderCellDef> Data/Hora </th>
            <td mat-cell *matCellDef="let row"> {{row.transactionDate | date: 'dd/MM/yyyy HH:mm'}} </td>
          </ng-container>

          <ng-container matColumnDef="bandeira">
            <th mat-header-cell *matHeaderCellDef> Bandeira</th>
            <td mat-cell *matCellDef="let row">
              <div class="bandeira">
                <img src="assets/img/brands/{{row.cardBrand.toLowerCase()}}.png">
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="adquirente">
            <th mat-header-cell *matHeaderCellDef> Adquirente </th>
            <td mat-cell *matCellDef="let row"> <img
                src="assets/img/acquirers/{{row.paymentGateway?.toLowerCase()}}.svg" /> </td>
          </ng-container>

          <ng-container matColumnDef="valorParcela">
            <th mat-header-cell *matHeaderCellDef> Valor </th>
            <td mat-cell *matCellDef="let row">
              <span *ngIf="row.status == 2" class="dark-blue pull-left">+
                <b>{{row.value | CustomCurrency:'BRL'}}</b></span>
              <span *ngIf="row.status != 2" class="dark-red pull-left">{{row.value | CustomCurrency:'BRL'}} </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let row">
              <i class="fas fa-eye fa-2x text-black-50 pointer"
                matTooltip="Clique para visualizar os dados da transação"></i>
            </td>
          </ng-container>

          <ng-container matColumnDef="type" class="pull-right">
            <th mat-header-cell *matHeaderCellDef> Tipo </th>
            <td mat-cell *matCellDef="let row">
              <span>{{row.type | translate}}</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="status" class="pull-right">
            <th mat-header-cell *matHeaderCellDef> Status </th>
            <td mat-cell *matCellDef="let row">
              <span class="badge" [ngClass]="getStatusColorTransaction(row.status)">{{row.status | translate}}</span>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumnsTransaction"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumnsTransaction;" class="hover-row"
            matTooltipPosition="above" (click)="detailTransaction(row)">
          </tr>
        </table>
        <mat-paginator [length]="transactions?.data.length" [pageSize]="25"></mat-paginator>
      </div>
      <div [ngClass]="{'hide' : currentTransactions.length > 0}" class="no-data-message">
        <div>
          <span class="pointer" (click)="closeTransacoes()">Nenhuma transação encontrada.</span>
        </div>
        <div>
          <a class="pointer text-black-50 small" (click)="closeTransacoes()">Voltar</a>
        </div>
      </div>

    </div>
  </div> -->


</div>