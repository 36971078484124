import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { MenuItem } from 'primeng/api';
import { PagePermissions } from 'src/app/core/permissions/page-permissions';
import { PaymentGatewayService } from 'src/app/core/services/v0/payment-gateway.service';
import { EcsFilter } from '../../core/common/filters/ec.filter';
import { Ec } from '../../core/model/ec';
import { AlertService } from '../../core/services/alert.service';
import { CovenantService } from '../../core/services/v0/covenant.service';
import { EstablishmentPriority, PriorityLabelMapping } from 'src/app/core/model/v1.model';
import { CardBrand } from 'src/app/core/model/enum/card-brands';

@Component({
  selector: 'app-establishment',
  templateUrl: './establishment.component.html',
  styleUrls: ['./establishment.component.scss']
})
export class EstablishmentComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  crumbs: MenuItem[];
  loading: Boolean;
  ecsData = new MatTableDataSource<Ec>();
  filters = new EcsFilter();
  showTable = false;
  displayedColumns: string[] = ['cnpj', 'status', 'prioridade', 'LOOSE', 'ACCESSION', 'RECURRENT'];
  cardBrands: string[] = ['VISA', 'MASTERCARD', 'AMEX', 'ELO', 'AURA', 'JCB', 'DINERS', 'DISCOVER', 'HIPERCARD', 'SOROCRED', 'CABAL', 'CREDSYSTEM', 'BANESCARD', 'CREDZ'];
  establishmentCreatePermission = PagePermissions.CONVENANT_CREATE;
  editCovenantPermissions = PagePermissions.CONVENANT_EDIT;
  Priority = EstablishmentPriority; // Expor o enum para o template
  PriorityLabelMapping = PriorityLabelMapping; // Expor o mapeamento para o template

  constructor(
    private alertService: AlertService,
    private conveniosService: CovenantService,
    private router: Router,
    private permissionsService: NgxPermissionsService,
    public paymentGatewayService: PaymentGatewayService
  ) { }

  ngOnInit(): void {
    this.loading = true;
    this.initCrumbs();
    this.initFilters();
    this.getAllEcs();
    this.loading = false;
  }

  getPriorityLabel(priority: EstablishmentPriority): string {
    return this.PriorityLabelMapping[priority] || '';
  }

  public initCrumbs() {
    this.crumbs = [
      { label: 'Lista de Estabelecimentos' }
    ];
  }

  public initFilters() {
    this.filters.id = '';
    this.filters.idAdquirente = '';
    this.filters.nomeFantasia = '';
    this.filters.codEstabelecimento = '';
    this.filters.acao = '';
    this.filters.documento = '';
    this.filters.ecInativos = false;
    this.filters.bandeira = '';
  }

  getAllEcs() {
    this.conveniosService.getAllCovenants({
      ecInativos: this.filters.ecInativos,
      sellerId: this.filters.codEstabelecimento
    })
      .subscribe(result => {
        this.ecsData = new MatTableDataSource<Ec>(result);
        this.ecsData.data.length > 0 ? this.showTable = true : this.showTable = false;
        this.groupByTransactionType(result);
      });
  }

  groupByTransactionType(ecs: any[]): void {
    for (let ec of ecs) {
      ec.accessionSettings = ec.settings.filter(setting => setting.transactionType === 'ACCESSION');
      ec.recurrentSettings = ec.settings.filter(setting => setting.transactionType === 'RECURRENT');
      ec.looseSettings = ec.settings.filter(setting => setting.transactionType === 'LOOSE');
    }
  }

  establishmentDetail(establishment) {
    this.permissionsService.hasPermission(PagePermissions.CONVENANT_DETAILS).then(hasPermission => {
      if (hasPermission) {
        this.router.navigate(['settings/establishment', establishment.idEstabelecimento, establishment.idAdquirenteEstabelecimento]);
      } else {
        this.alertService.errorPermission();
      }
    });
  }

  establishmentCreate() {
    this.permissionsService.hasPermission(this.establishmentCreatePermission).then(hasPermission => {
      if (hasPermission) {
        this.router.navigate(['settings/establishment/new']);
      } else {
        this.alertService.errorPermission();
      }
    })
  }

  getCardBrandNameById(id: number): string {
    const cardBrandName = CardBrand[id];
    return cardBrandName ? cardBrandName : 'Unknown Card Brand';
  }
}
