import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Charge } from 'src/app/core/model/v2.model';
import { AlertService } from 'src/app/core/services/alert.service';
import { OnChargeV2 } from 'src/app/core/services/v2/charge.service.v2';

@Component({
  selector: 'app-cancel-charge',
  templateUrl: './cancel-charge.component.html',
  styleUrls: ['./cancel-charge.component.scss']
})
export class CancelChargeComponent implements OnInit {
  loading: boolean = false;

  constructor(
    private onChargeV2: OnChargeV2,
    private alertService: AlertService,
    public dialogRef: MatDialogRef<CancelChargeComponent>,
    @Inject(MAT_DIALOG_DATA) public charge: Charge,
  ) { }

  ngOnInit(): void {
  }

  cancelCharge() {
    this.loading = true;

    if (this.charge.transactions[0].paymentMethod === 'BOLETO') {
      this.cancelBoleto();
    } else if (this.charge.transactions[0].paymentMethod === 'PIX') {
      this.cancelPix();
    } else {
      this.alertService.error('Erro', 'Não é possível cancelar essa cobrança');
      this.loading = false;
    }
  }

  cancelBoleto() {
    this.onChargeV2.cancelBoleto(this.charge.id).subscribe(result => {
      this.alertService.success('Sucesso', 'Boleto cancelado com sucesso').then(() => this.dialogRef.close(true));
    },
      error => {
        this.alertService.error('Erro', error.error.message);
        this.loading = false;
      });
  }

  cancelPix() {
    this.onChargeV2.cancelPix(this.charge.id).subscribe(result => {
      this.alertService.success('Sucesso', 'Pix cancelado com sucesso').then(() => this.dialogRef.close(true));
    },
      error => {
        this.alertService.error('Erro', error.error.message);
        this.loading = false;
      });
  }

}
