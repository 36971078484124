import { TransactionResponse } from "./response/transaction.response";


export class Transaction {
    nomeAdquirente: string;
    bandeira: string;
    cpf: string;
    cpfCnpjCliente: string;
    cpfTitular: string;
    dtTransacaoFormatted: string;
    id: number;
    dtRegistro:string;
    dtTransacao:Date;
    dtRegistroFormatado: string;
    dataTransacaoFormatado: string;
    nomeCliente: string;
    numParcela: number;
    nomeProduto: string;
    nomeEmpresa: string;
    estabelecimento: string;
    numNsu: string;
    numAutorizacao: string;    
    idCliente: number;
    valor: string;
    status: number;
    statusStr: string;
    tid: string;
    tipoTransacao: string;
    valorParcela: number;
    valorEstorno: number;
    numeroCartao: string;
    cartaoNumeroUltimosDigitos: string;
    nomeTitular: string;
    idPedido?:number;
    lr?:string;
    returnMsg?:string;
    userReference: string;
    nsuNumber: string;
    nsuCapture: string;
    authorizationCode: string;

    
    //feito para resolver problema dos detalhes da transação - sim, contém gambi
    public convertFromTransactionResponse(response:TransactionResponse){
        this.nomeAdquirente = response.paymentGateway;
        this.bandeira = response.cardBrand;
        this.cpf = response.cardHolderDocument;
        this.cpfCnpjCliente = response.customerDocument;
        this.cpfTitular = response.cardHolderDocument;
        this.nomeCliente = response.customerName;
        this.nomeProduto = response.plan;
        this.nomeEmpresa = response.company;
        this.estabelecimento = response.establishment;
        this.numNsu = response.nsuNumber;
        this.numAutorizacao = response.authorizationNumber;
        this.valor = response.value.toString();
        this.statusStr = response.status;
        this.tid = response.tid;
        this.valorEstorno = response.chargebackValue;
        this.cartaoNumeroUltimosDigitos = response.cardBin+response.cardLast4;
        this.nomeTitular = response.cardHolderName;
        this.dtRegistro = response.registerDate;
        this.lr = response.lr;
        this.returnMsg = response.returnMsg;
        this.dtTransacao = response.transactionDate;
    }
}

    


export interface TransactionStatus {
    name: string;
    value: number;
    colorClass: string;
    textColorClass: string;
    textColor: string;
}

export interface TransactionName {
    name: string;
}