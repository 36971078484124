import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import * as moment from 'moment';
import { ApprovedTransactionFilter } from 'src/app/core/common/filters/approved-transactions.filter';

@Component({
  selector: 'report-approved-transactions-filter',
  templateUrl: './report-approved-transactions-filter.component.html'
})
export class ReportApprovedTransactionsFilterComponent implements OnInit {

  constructor(
    private formBuilder: UntypedFormBuilder,
  ) { }

  @Input() filters: ApprovedTransactionFilter
  @Output() filterClick: EventEmitter<any> = new EventEmitter();
  @Output() downloadClick: EventEmitter<any> = new EventEmitter();
  @Input() showExportBtn: boolean;

  rangeValid: boolean = true;
  loading: boolean = false;
  @ViewChild('allSelectedForAffiliate') private allSelectedForAffiliate: MatOption;

  ngOnInit(): void {
    this.filters.startDate = moment().startOf('month').toDate();
    this.filters.endDate = new Date();
    setTimeout(() => {
      this.filter();
    }, 0);

  }

  filter() {
    if (this.confirmDateRange()) {
      this.filterClick.emit();
    }
  }

  downloadCsv() {
    if (this.confirmDateRange()) {
      this.downloadClick.emit();
    }
  }
  confirmDateRange() {
    let start = moment(this.filters.startDate);
    let end = moment(this.filters.endDate);
    let difference = end.diff(start, 'days');
    if (difference > 31) {
      this.rangeValid = false;
    } else {
      this.rangeValid = true;
    }
    return this.rangeValid;
  }
  compareId(id1: number, id2: number) {
    if (id2 === 0) {
      return id1 === 0;
    }
    return id1 && id2 && id1 === id2;
  }

}

