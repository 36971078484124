import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { NgxPermissionsService } from 'ngx-permissions';
import { SaleCancelComponent } from 'src/app/components/sale-cancel/sale-cancel.component';
import { StatusHelper } from 'src/app/core/helper/status-helper';
import { Cobranca } from 'src/app/core/model/cobranca';
import { SalesDetailsResponse } from 'src/app/core/model/response/sales-details-response';
import { Charge } from 'src/app/core/model/v2.model';
import { PagePermissions } from 'src/app/core/permissions/page-permissions';
import { OrderService } from 'src/app/core/services/v0/order.service';
import { Transaction, TransactionStatus } from '../../../../core/model/transaction';
import { AlertService } from '../../../../core/services/alert.service';
import { TransacaoService } from '../../../../core/services/v0/transaction.service';
import { TransactionDetailComponent } from '../../../transactions/transaction-detail/transaction-detail.component';

export interface DialogData {
  signature: any;
}

declare var $: any;

@Component({
  selector: 'app-modal-intallments',
  templateUrl: './modal.intallments.component.html',
  styleUrls: ['./modal.intallments.component.scss']
})
export class ModalIntallmentsComponent implements OnInit {

  loading = false;
  listaEstorno: Array<Transaction> = [];
  valorRefundC: number;

  listaStatus: TransactionStatus[];
  transactions = new MatTableDataSource<Transaction>();
  currentTransactions: Transaction[] = new Array();


  displayedColumns: string[] = [
    'id',
    'dtVencimentoFormatado',
    'dtPagamentoFormatado',
    'valorParcela',
    'status'
  ];

  displayedColumnsTransaction: string[] = [
    'dtTransacaoFormatted',
    'bandeira',
    'adquirente',
    'valorParcela',
    'status',
    'type',
    'actions'
  ];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  signatureTransactions = new MatTableDataSource<Cobranca>();
  currentParcelas: Cobranca[] = new Array();
  signature: any;
  selectInstallment: any = false;

  transactionPermissions = PagePermissions.TRANSACTION;
  transactionDetailsPermissions = PagePermissions.TRANSACTION_DETAILS;
  editRefundPermissions = PagePermissions.EDIT_REFUND;

  loadingTrans = false;

  constructor(
    private alertService: AlertService,
    private transactionService: TransacaoService,
    private orderService: OrderService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ModalIntallmentsComponent>,
    private permissionsService: NgxPermissionsService,
    private statusHelper: StatusHelper,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit() {
    this.signature = this.data.signature;
    this.transactionService.getStatus().then(data => {
      this.listaStatus = data.cobrancaStatus;
      this.getSignatureData();
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  getSignatureData() {
    this.currentParcelas = [];
    this.orderService.getAllChargesByOrder(this.signature.id)
      .subscribe(result => {
        this.currentParcelas = result;
        this.signatureTransactions = new MatTableDataSource<Cobranca>(result);
        this.signatureTransactions.sort = this.sort;
        this.signatureTransactions.paginator = this.paginator;
        this.signatureTransactions.sortingDataAccessor = (item: any, property) => {
          switch (property) {
            case 'dtTransacaoFormatted': return moment(item.ultimaTransacaoDto.dataTransacaoFormatado, "DD/MM/YYYY").toDate().valueOf();
            case 'bandeira': return item.ultimaTransacaoDto.bandeira;
            case 'adquirente': return item.ultimaTransacaoDto.nomeAdquirente;
            case 'valorParcela': return (item.valor - item.valorEstornado);
            case 'status': return item.statusStr;
            default: return item[property];
          }
        };
      });
  }

  // Detalhar transacao
  detailTransaction(row) {
    this.permissionsService.hasPermission(this.transactionDetailsPermissions).then(hasPermission => {
      if (hasPermission) {
        let element: Element = <Element>event.target;
        let valueView = element.getAttribute('data-not-view-transaction');
        if (!valueView) {
          let transaction = new Transaction();
          transaction.convertFromTransactionResponse(row);
          const dialogRef = this.dialog.open(TransactionDetailComponent, {
            width: '680px',
            height: '400px',
            data: { transacao: transaction }
          });
          dialogRef.afterClosed().subscribe(result => {
          });
        }
      } else {
        this.alertService.errorPermission();
      }
    });
  }

  getStatusColor(status) {
    for (let i = 0; i < this.listaStatus.length; i++) {
      if (status === this.listaStatus[i].value) {
        let aux = this.listaStatus[i].colorClass;
        return aux;
      }
    }
    return "green";
  }

  getStatusColorTransaction(status) {
    return this.statusHelper.getTransacaoStatusColor(status);
  }


  closeTransacoes() {
    $("#trans-installments").removeClass("active");
    $(".strech").addClass("active");
    $(".back-list").addClass("hide");
  }

  openTransacoes(row, event: any = null, force = false) {

    let valueView: any = false;
    if (event != null) {
      let element: Element = <Element>event.target;
      valueView = element.getAttribute('data-view-action');
    }

    if (valueView || force) {

      this.selectInstallment = false;
      if (valueView) {
        $("#trans-installments").addClass("active");
        $(".back-list").removeClass("hide");
        $(".strech").removeClass("w-100");
        $(".strech").removeClass("active");
      }

      this.loadingTrans = true;
      this.currentTransactions = [];

      this.transactionService.getTransactionByParcela(row.id)
        .subscribe(result => {
          this.currentTransactions = result;
          this.transactions = new MatTableDataSource<Transaction>(result);
          this.transactions.sort = this.sort;
          this.transactions.paginator = this.paginator;
          this.transactions.sortingDataAccessor = (item: any, property) => {
            switch (property) {
              case 'dtTransacaoFormatted': return moment(item.dataTransacaoFormatado, "DD/MM/YYYY").toDate().valueOf();
              case 'bandeira': return item.bandeira;
              case 'adquirente': return item.nomeAdquirente;
              case 'valorParcela': return item.valor;
              case 'status': return item.statusStr;
              default: return item[property];
            }
          };
          setTimeout(() => {
            this.selectInstallment = row;
            this.loadingTrans = false;
          }, 400);
        }, error => {
          this.selectInstallment = false;
          this.loadingTrans = false;
        });
    }
  }

  openEstornoList(row, transaction = false) {
    this.permissionsService.hasPermission(this.editRefundPermissions).then(hasPermission => {
      if (hasPermission) {
        let notRefunded = false;

        let valorEstornado = typeof row.valorEstornado == "number" ? row.valorEstornado : 0;
        let valorChargeback = typeof row.valorChargeback == "number" ? row.valorChargeback : 0;

        if (!((row.status == 1 && valorEstornado == 0 && valorChargeback == 0) || (row.status == 6 && valorEstornado < row.valor))) {
          notRefunded = true;
        }

        if (notRefunded) {
          this.alertService.error('Erro', 'Verifique a(s) parcela(s) selecionadas. Apenas parcelas com transações aprovadas podem ser estornadas.');
        } else {
          let sale: SalesDetailsResponse = new SalesDetailsResponse();
          let charge: Charge = new Charge();
          charge.id = row.id;
          charge.value = row.valor * 100;
          sale.amount = row.valor;
          sale.id = row.id;
          let paymentMethod = "CREDIT_CARD";

          const dialogRef = this.dialog.open(SaleCancelComponent, {
            data: { charge: charge, paymentMethod: paymentMethod }
          });
          dialogRef.afterClosed().subscribe(result => {
            if (result) {
              this.getSignatureData();
              if (transaction) {
                this.closeTransacoes();
              }
            }
          });
        }
      } else {
        this.alertService.errorPermission();
      }
    });

  }

  getAction(row) {
    let valorEstornado = typeof row.valorEstornado == "number" ? row.valorEstornado : 0;
    let valorChargeback = typeof row.valorChargeback == "number" ? row.valorChargeback : 0;

    if ((row.status == 1 && valorEstornado == 0 && valorChargeback == 0) || (row.status == 6 && (valorEstornado + row.valor) == 0)) {
      return true;
    } else {
      return false;
    }
  }

}
