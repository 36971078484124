import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class TransactionServiceV1 {

    constructor(private http: HttpClient) {
    }

    billingRulePost(data, idCompany, idCharge): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}/api/v1/transactions/company/${idCompany}/charge/${idCharge}/pix`, data, { observe: 'response' });
    }
    getPixTransaction(idCompany, idTransaction): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/api/v1/transactions/company/${idCompany}/transaction/${idTransaction}/pix`, { responseType: 'json' });
    }

    getBankslipTransaction(idCompany, idTransaction): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/api/v1/transactions/company/${idCompany}/transaction/${idTransaction}/bankslip`, { responseType: 'json' });
    }

    getQRCode(code: string): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/api/v1/image/qrcode/${code}`, { responseType: 'blob' as 'json' });
    }

    getBarcode(code: string): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/api/v1/image/barcode/${code}`, { responseType: 'blob' as 'json' });
    }

    getByUri(uri: string): Observable<any> {
        return this.http.get<any>(uri);
    }

}