import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SalesDetailsResponse } from 'src/app/core/model/response/sales-details-response';
import { PagePayToSubscriptionResponse } from 'src/app/core/model/v1.model';


export interface DialogData {
  pagepay: PagePayToSubscriptionResponse;
}

@Component({
  selector: 'payment-page',
  templateUrl: './paymentPage.component.html',
  styleUrls: ['./paymentPage.component.scss']
})
export class PaymentPageComponent implements OnInit {

  pagepay: PagePayToSubscriptionResponse;

  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<SalesDetailsResponse>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) { }

  ngOnInit() {
    this.pagepay = this.data.pagepay;
  }

  clienteClick(idCliente:number) {
    if (idCliente != null && idCliente > 0) {
      this.dialogRef.close();
      this.router.navigate(["/transactions", idCliente]);
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
   
}
