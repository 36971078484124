<div *ngIf="isLoggedIn$ | async as isLoggedIn">

    <div class="navigation" fxLayout="row">
        <div class="user-message" [class.margin-large]="size=='out'" fxFlex="auto">
            <span class="username">{{user.nomeEmpresa}}</span>
        </div>

        <div fxFlex="50" class="divBtn">
            <span id="username" class="username">{{user.name}} </span>
            <button mat-icon-button name="appMenu" [matMenuTriggerFor]="appMenu" aria-placeholder="Usuário">
                <i class="fal fa-user-circle fa-2x"></i>
            </button>
        </div>
    </div>

    <!-- Menu user -->
    <mat-menu #appMenu="matMenu" class="menu-user" overlapTrigger="false">
        <button mat-menu-item [routerLink]="['/settings/user']"><i class="fal fa-key"></i> Alterar senha</button>
        <button mat-menu-item name="logoff" (click)="onLogout()"><i class="fal fa-sign-out-alt"></i> Sair</button>
    </mat-menu>

    <header class="page-header" id="pageTitle">
        {{headerText}}
        <!--<breadcrumb></breadcrumb>-->
        <script src="//code.jivosite.com/widget/WfcdbV4P1g" async></script>
    </header>

   <app-environment-label></app-environment-label>
</div>