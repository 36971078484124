import { Component, OnInit, ViewChild } from "@angular/core";
import { AlertService } from "src/app/core/services/alert.service";
import { ErrorHandleService } from "src/app/core/services/error-handle.service";
import * as moment from 'moment';
import { CustomerImportService } from "src/app/core/services/v0/customer-import.service";
import { Router } from "@angular/router";
import { MenuItem } from 'primeng/api';

@Component({
    selector: 'app-new-import',
    templateUrl: './new-import-wallet.component.html',
    styleUrls: ['./new-import-wallet.component.scss']
})

export class NewImportWalletComponent implements OnInit {
    @ViewChild("importForm") importForm;
    fileUploaded: any = null;
    loading: boolean = false;
    today = new Date(Date.now());
    scheduleDate = new Date(Date.now());
    request: any = new Object();
    fileName: string = "";
    crumbs: MenuItem[];
    headers = {
        name: "NAME",
        document: "DOCUMENT",
        cardNumber: "CARDNUMBER",
        cardBrand: "CARDBRAND",
        cardExpirationMonth: "CARDEXPMONTH",
        cardExpirationYear: "CARDEXPYEAR",
        cardSecurityCode: "CARDCODE",
        cardHolderName: "CARDHOLDERNAME",
        cardHolderDocument: "CARDHOLDERDOC",
        orderDate: "ORDERDATE",
        sku: "SKU",
        installment: "INSTALLMENT",
        duedate: "DUEDATE",
        birthDate: "BIRTHDATE",
        email: "EMAIL",
        areaCode: "AREACODE",
        phone: "PHONE",
        zipCode: "ZIPCODE",
        street: "STREET",
        streetNumber: "STREETNUMBER",
        addressLineTwo: "ADDRESSLINETWO",
        neighborhood: "NEIGHBORHOOD",
        cityName: "CITYNAME",
        stat: "STATE",
        rejectReason: "REJECTIONREASON"
    };

    constructor(
        private alertService: AlertService,
        private errorHandleService: ErrorHandleService,
        private customerImportService: CustomerImportService,
        private router: Router
    ) {
        this.request.metatags = "";
        this.request.selectedTag = "";
    }

    ngOnInit(): void {
        this.initCrumbs();
    }

    public initCrumbs() {
        this.crumbs = [
            { label: 'Lista de importações', routerLink: ['sales/import-customers'] },
            { label: 'Nova importação' }
        ];
    }

    upload(): void {
        if (this.importForm.valid) {
            if (this.fileUploaded != null) {
                this.alertService.loading();
                let filter = { importDateTime: (moment(this.scheduleDate).format('YYYY-MM-DD') + ' 00:00') };
                this.customerImportService.scheduleWalletImportExcel(this.fileUploaded, filter).subscribe(result => {
                    this.loading = false;
                    this.alertService.success("Sucesso!", "Importação agendada com sucesso!");
                    this.fileUploaded = null;
                    this.request.selectedFile = "";
                    this.fileName = "";
                    this.router.navigate(['sales/import-customers']);
                }, error => {
                    this.loading = false;
                    this.errorHandleService.alertError(error.error);
                }
                )
            } else {
                this.alertService.error('Erro', 'Nenhum arquivo selecionado!');
            }
        }
    }

    uploadFile(file: File) {
        this.fileUploaded = file;
        this.fileName = file[0].name;
    }

    cancel() {
        this.router.navigate(['sales/import-customers']);
    }
}
