<mat-card class="mt-3">
  <mat-card-header>
    <div mat-card-avatar *ngIf="product.paymentlink" style="background-image: url('{{ product.paymentlink.image }}');">
    </div>
    <mat-card-title>{{ product.name }}</mat-card-title>
    <mat-card-subtitle>

      <span class="badge bg-primary mr-1">{{product.trialDays + " dias de teste" | translate}}</span>
      <span class="badge bg-primary">{{"Em até " + product.maxInstallments + "x " | translate}}</span>
    </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <div class="d-flex justify-content-between">
      <div><span class="text-muted">Valor Adesão (apenas na primeira cobrança)</span></div>
      <div><span class="text-muted">{{product.accessionValue | CustomCurrency:'BRL' }}</span></div>
    </div>
    <div class="d-flex justify-content-between mb-2">
      <div><span class="text-muted mr-1">Valor Principal</span><span class="badge badge-info mr-1">{{product.frequency |
          translate}}</span></div>
      <div><span class="text-muted">{{product.value | CustomCurrency:'BRL' }}</span></div>
    </div>
    <div class="d-flex justify-content-between border-top pt-2">
      <div><span class="font-weight-bold">Total</span></div>
      <div><span class="font-weight-bold">{{product.accessionValue + product.value | CustomCurrency:'BRL' }}</span>
      </div>
    </div>
  </mat-card-content>
</mat-card>
