import { CardResponse } from "./response/card.response";

export class ProductRequest {
  name: string;
  description: string;
}

export class ProductResponse {
  id: number;
  name: string;
  description: string;
  registered: Date;
  image: string;
}

//Dispute
export class DisputeResponse {
  type: string;
  cardBin: string;
  cardLast4: string;
  descriptor: string;
  authorizationDate: string;
  alertDate: string;
  amount: number;
  refunded: Boolean;
  resolved: Boolean;
  status: String;
}

export class AccountResponse {
  name: string;
  code: string;
  status: string;
}

export class TransferOperationsResponse {
  date: Date;
  description: string;
  amount: number;
  fee: number;
  due: number;
}
export class TransferTransactionsResponse {
  date: Date;
  client: string;
  value: number;
  type: string;
  status: string;
}


export class AlertChargebackStatus {
  name: string;
  value: number;
  colorClass: string;
  textColorClass: string;
  textColor: string;
}

export class AlertChargebackType {
  name: string;
  value: number;
  colorClass: string;
  textColorClass: string;
  textColor: string;
}

export class DisputeFilter {
  startAlertDate: Date;
  endAlertDate: Date;
  startAuthorizationDate: Date;
  endAuthorizationDate: Date;
  descriptor: string;
  cardNumber: string;
  isRefunded: boolean;
  isConciliated: boolean;
  type: string[];
  status: string[];
}

//Assinaturas
export class SubscribersReponse {
  id: number;
  name: string;
  orderDate: Date;
  status: OrderStatus;
}

export class PlanResponse {
  id: number;
  name: string;
  type: PlanType;
  charges: number;
  frequency: PlanFrequency;
  maxInstallments: number;
  trialDays: number;
  chargeDays: number;
  sku: string;
  softDescriptor: string;
  value: number;
  accessionValue: number;
  floatValue: number;
  active: boolean;
  product: ProductResponse;
  paymentlink: PaymentLinkResponse
}

export class PaymentLinkResponse {
  id: number;
  name: string;
  image: string;
}

export class PlanRequest {
  name: string;
  sku: string;
  softDescriptor: string;
  type: PlanType;
  frequency: PlanFrequency;
  maxInstallments: number;
  value: number;
  charges: number;
}

export class PlanPutRequest {
  sku: string;
  softDescriptor: string;
}

export class PagePayRequest {
  name: string;
  description: string;
  addressRequired: boolean;
  birthdateRequired: boolean;
  sendEmail: boolean;
  color: PagePayColors.BLUE;
  expiration: Date;
  returnUrl: string;
  limit: number;
  idPlan: number;
  maxInstallments: number;
  amount: number;
  affiliateId: number;
}

export class PagePayPutRequest {
  name: string;
  description: string;
  amount: number
  maxInstallments: number;
  limit: number;
  expiration: Date;
  addressRequired: boolean;
  birthdateRequired: boolean;
  sendEmail: boolean;
  color: PagePayColors.BLUE;
  returnUrl: string;
  active: boolean;
  affiliateId: number;

}

export class PagePayResponse {
  id: number;
  name: string;
  returnUrl: string;
  uuid: string;
  sold: number;
  limit: number;
  expiration: Date;
  active: boolean;
  addressRequired: boolean;
  birthdateRequired: boolean;
  sendEmail: boolean;
  plan: string;
  color: PagePayColors;
  amount: number;
  description: string;
  affiliateMinimal: AffiliateMinimalResponse;
  affiliate: AffiliateResponse;
  maxInstallments: number;
  image: any;
}
export class PagePayToSubscriptionResponse {
  id: number;
  name: string;
  returnUrl: string;
  uuid: string;
  sold: number;
  limit: number;
  expiration: number;
  active: boolean;
  addressRequired: boolean;
  birthdateRequired: boolean;
  sendEmail: boolean;
  color: PagePayColors;
  companyMinimal: CompanyMinimalResponse;
  affiliateMinimal: AffiliateMinimalResponse;
  plan: string;
  description: string;
  amount: number;
  image: any;
  order: OrderResponseToPagePay
  maxInstallments: number;
  paymentMethods: PaymentMethodToSubscription[];
}

export class PaymentMethodToSubscription {
  description: string;
}

export class OrderResponseToPagePay {
  id: number;
  status: string;
  installments: number;
  value: number;
  customer: CustomerResponseToPagePay
}

export class CompanyMinimalResponse {
  id: number;
  name: string;
  businessName: string;
}

export class AffiliateResponse {
  id: number;
  name: string;
  businessName: string;
  document: string;
  email: string;
  address: AddressResponse;
  phone: PhoneResponse;
  image: string;
}

export class AffiliateMinimalResponse {
  id: number;
  name: string;
  businessName: string
}

export class AddressResponse {
  street: string;
  number: string;
  zipcode: string;
  neighborhood: string;
  lineTwo: string;
  city: string;
  state: string;
}
export class AddressResponseV1 {
  street: string;
  number: string;
  zipcode: string;
  neighborhood: string;
  lineTwo: string;
  city: string;
  state: string;
  id: number;
}

export class AddressRequest {
  cityName: string;
  state: string;
  zipCode: string;
  addressLineTwo: string;
  street: string;
  streetNumber: string;
  neighborhood: string;
  idCity: number;
  id: number;
}

export class PhoneResponse {
  code: string;
  number: string;
  country?: string;
}

export class Token {
  authorization: string;
}

export class CustomerRequest {
  name: string;
  document: string;
  email: string;
  birthdate: Date;
  phone: PhoneRequest;
}

export class CustomerResponse {
  id: number;
  name: string;
  document: string;
  email: string;
  birthdate: Date;
  phone: PhoneResponse;
}
export class CustomerResponseToPagePay {
  id: number;
  name: string;
  document: string;
  email: string;
  birthdate: Date;
  phone: PhoneResponse;
  address: string;
  bestAddress: string;
}

export class CardRequest {
  brand: number;
  number: string;
  month: string;
  year: string;
  code: string;
  holderName: string;
  holderDocument: string;
  main: boolean;
}

export class CardRequestV1 {
  brand: CardBrand;
  number: string;
  expiration: CardExpiration;
  cvv: string;
  holder: CardHolder;

  constructor() {
    this.expiration = new CardExpiration();
    this.holder = new CardHolder();
  }
}

export class CardHolder {
  name: string;
  document: string;
}

export class CardExpiration {
  month: string;
  year: string;
}

export class PhoneRequest {
  code: string;
  number: string;
  country?: string;
}

export class CardBrandResponse {
  id: number;
  name: string;
  active: boolean;
  enabledClass: string;
}

export class OrderResponse {
  id: number;
  status: OrderStatus;
  installments: number;
  value: number;
  date: Date;
  cancelation: Date;
  plan: string;
  customer: string;
  card: string;
  cancelationReason: string;
  lastTransaction: string;
  barcode: string;
  dueDate: string;
  image: string;
  emv: string;
  expirationDate: string;
}

export class CardIdRequest {
  idCard: number;
}

export class LastTransactionResponse {
  id: number;
  value: number;
  date: Date;
  lr: string;
  code: string;
  message: string;
  status: TransactionStatus;
  card: CardResponse;
}

export class CancelationReasonReportFilter {
  begin: Date;
  end: Date;
  products: number[];
  type: OrderType;
}

export class CancelationReasonReportResponse {
  quantity: number;
  value: number;
  items: CancelationReasonReportItemResponse[];
}

export class CancelationReasonReportItemResponse {
  reason: string;
  quantity: number;
  income: number;
  quantityPercent: number;
}

export class PaymentOptionsRequest {
  installments: number;
}

export class ViaCepResponse {
  cep: string;
  logradouro: string;
  complemento: string;
  bairro: string;
  localidade: string;
  uf: string;
  ibge: string;
  gia: string;
  ddd: string;
  siafi: string;
  erro: boolean;
}

export class PixMinimalResponse {
  emv: string;
  value: number;
  planName: string;
  status: string;
  expirationDate: number;
  idOrder: number;
}

export class BankslipMinimalResponse {
  value: number;
  planName: string;
  status: string;
  idOrder: number;
  barcode: string;
  dueDate: number;
}

export enum CheckoutFormStage {
  CUSTOMER_INFO = "CUSTOMER_INFO",
  ADDRESS = "ADDRESS",
  PAYMENT_DATA = "PAYMENT_DATA",
  SALE_SUMMARY = "SALE_SUMMARY",
  ORDER = "ORDER"
}

export enum CheckoutState {
  SUCCEEDED = "SUCCEEDED",
  PENDING = "PENDING",
  FAIL = "FAIL"
}

export enum OrderStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  OVERDUE = 'OVERDUE',
  PENDING = 'PENDING',
  CANCELED = 'CANCELED'
}

export enum ChargeOrderStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  OVERDUE = 'OVERDUE',
  PENDING = 'PENDING',
  CANCELED = 'CANCELED',
  CHARGEBACK = 'CHARGEBACK',
  COUNTERCHARGE = 'COUNTERCHARGE'
}

export enum ChargeStatus {
  ACCREDIT = 'ACCREDIT',
  SCHEDULE = 'SCHEDULE',
  AUTHORIZED = 'AUTHORIZED',
  CANCELED = 'CANCELED',
  CHARGEBACK = 'CHARGEBACK',
  REFUND = 'REFUND',
  COUNTERCHARGE = 'COUNTERCHARGE',
  FAIL = 'FAIL',
  IN_PROGRESS = 'IN_PROGRESS',
  MANUAL_DISCHARGE = 'MANUAL_DISCHARGE',
  PAY = 'PAY',
  PENDING = 'PENDING'
}

export enum CardBrand {
  VISA = 'VISA',
  MASTERCARD = 'MASTERCARD',
  ELO = 'ELO',
  AMEX = 'AMEX',
  DINERS = 'DINERS',
  AURA = 'AURA',
  DISCOVER = 'DISCOVER',
  JCB = 'JCB',
  HIPERCARD = 'HIPERCARD',
  SOROCRED = 'SOROCRED',
  BANESCARD = 'BANESCARD',
  CABAL = 'CABAL'
}

export enum TransactionStatus {
  IN_PROGRESS,
  APPROVED,
  REFUND,
  AUTHORIZED,
  MANUAL_DISCHARGE,
  MANUAL_REFUND,
  NOT_AUTHORIZED,
  NOT_APPROVED,
  CHARGEBACK,
  COUNTERCHARGE,
  FAIL,
  INVALID,
  AWAITING_PAYMENT,
  UNDER_PAYMENT,
  OVER_PAYMENT
}

export enum PlanType {
  RECURRENT,
  LOOSE
}

export enum TransactionType {
  RECURRENT,
  LOOSE,
  ACCESSION
}

export enum EstablishmentTransactionType {
  SINGLE = 'SINGLE',
  FIRST_RECURRING_CHARGE = 'FIRST_RECURRING_CHARGE',
  RECURRING = 'RECURRING'
}

export enum OrderType {
  RECURRENT = 'RECURRENT',
  LOOSE = 'LOOSE'
}

export enum PlanFrequency {
  WEEKLY = 'WEEKLY',
  BIWEEKLY = 'BIWEEKLY',
  MONTHLY = 'MONTHLY',
  BIMONTHLY = 'BIMONTHLY',
  QUARTERLY = 'QUARTERLY',
  SEMESTRAL = 'SEMESTRAL',
  YEARLY = 'YEARLY'
}

export enum PagePayColors {
  RED,
  GREEN,
  BLUE
}

export class EstablishmentCreateRequestV1 {
  acquirer: string;
  softDescriptor: string;
  priority: EstablishmentPriority;
  requireSecurityCode: boolean;
  active: boolean;
  version: string;
  credentials: Credentials[];
}

export enum EstablishmentPriority {
  MAIN = 1, // Principal
  OVERFLOW = 2 // Transbordo
}

export const PriorityLabelMapping: Record<EstablishmentPriority, string> = {
  [EstablishmentPriority.MAIN]: 'Principal',
  [EstablishmentPriority.OVERFLOW]: 'Transbordo'
};

export class EstablishmentUpdateRequestV1 {
  softDescriptor: string;
  priority: number;
  requireSecurityCode: boolean;
  active: boolean;
  credentials: Credentials[];
  routes: Route[];

  constructor() {
    this.credentials = new Array<Credentials>();
    this.routes = new Array<Route>();
  }
}

export class EstablishmentResponseV1 extends EstablishmentCreateRequestV1 {
  id: number;
  routes: Route[];
}

export class Route {
  brand: string;
  type: string;
}

export class Credentials {
  field: string;
  value: string;
  isReadOnly?: boolean;
}

export class EstablishmentFilterV1 {
  id: string;
  nomeFantasia: string;
  establishmentCode: string;
  acquireID: string;
  action: string;
  document: string;
  showEstablishmentInative: boolean;
  cardBrand: string;
}

export class AcquirerSetup {
  acquirer: string;
  configurations: Configuration[];
}

export class Configuration {
  isCreable: boolean;
  setup: Setup;
}

export class Setup {
  version: string;
  fields: Field[];
  methods: string[];
}

export class Field {
  field: string;
  isCreateable: boolean;
  isEditable: boolean;
}

export class PaymentRequestV1 {
  profileId: number;
  installments: number;
  type: ProfileType;
  constructor() { }
}

export enum ProfileType {
  CREDIT_CARD,
  PIX,
  BANK_SLIP,
  TRANSFER
}