<div class="container" fxLayout='column' *ngIf="!loading" fxLayoutGap="10px">
  <div fxLayout="column" fxLayoutGap="10px" style="margin-bottom: 10px;">
    <div fxLayout="row">
      <div fxFlex fxLayout="row" fxLayoutAlign="start center">
        <p-breadcrumb id="breadcrumb" [model]="crumbs"></p-breadcrumb>
      </div>
      <div fxFlex fxLayout="row" fxLayoutAlign="end center">
        <div>
          <button *ngxPermissionsOnly="editPermission" mat-raised-button class="btn-edit" (click)="editUser()"> Editar
          </button>
        </div>
      </div>
    </div>
  </div>
  <div fxLayout="column" fxLayout.lt-md="column" fxLayoutGap="10px">
    <card-box [title]="'Dados'" fxFlex="40">
      <div fxLayout='column'>
        <mat-list class="list-dados">
          <mat-list-item>
            <i mat-list-icon class="fal fa-user-tag"></i>
            <h4 mat-line>{{user.name}}</h4>
          </mat-list-item>
          <mat-list-item>
            <i mat-list-icon class="fal fa-envelope"></i>
            <h4 mat-line>{{user.email}}</h4>
          </mat-list-item>
          <mat-list-item>
            <i mat-list-icon class="fal fa-user-circle fa-2x"></i>
            <h4 mat-line>{{user.username}}</h4>
          </mat-list-item>
          <mat-list-item>
            <i mat-list-icon class="fal fa-building"></i>
            <h4 mat-line>{{user.company.name}}</h4>
          </mat-list-item>
        </mat-list>
      </div>
    </card-box>
    <card-box [title]="'Perfis'" fxFlex>
      <div fxLayout='column'>
        <mat-list class="list-dados">
          <mat-list-item *ngFor="let profile of user.profiles">
            <h4 mat-line>{{profile.name}}</h4>
            <p mat-line> {{profile.description}} </p>
          </mat-list-item>
        </mat-list>
      </div>
    </card-box>
  </div>
</div>
