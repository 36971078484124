import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Split } from 'src/app/core/model/v2.model';
import { AlertService } from 'src/app/core/services/alert.service';
import { OnChargeV2 } from 'src/app/core/services/v2/charge.service.v2';

@Component({
  selector: 'app-split-list',
  templateUrl: './split-list.component.html',
  styleUrls: ['./split-list.component.scss']
})
export class SplitListComponent implements OnInit {
  @Input() chargeId: number;
  @Input() chargeValue: number;

  chargeSplits = new MatTableDataSource<Split>();

  displayedColumns: string[] = ['affiliate', 'amount', 'percentage'];

  constructor(
    private onChage: OnChargeV2,
    private alertService: AlertService,
  ) { }

  ngOnInit(): void {
    this.getChargeSplitsById();
  }

  getChargeSplitsById() {
    this.onChage.getSplitsById(this.chargeId).subscribe(
      result => {
        this.chargeSplits = result;
      },
      error => {
        this.alertService.handleError(error);
      }
    );
  }
}
