import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class FileUtilsService {

    constructor() { }

    public convertToCSV(objArray, headers?: string) {
        let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
        let str = headers ? (headers != '' ? headers + '\r\n' : '') : '';

        for (let i = 0; i < array.length; i++) {
            let line = '';
            for (let index in array[i]) {
                if (line != '') line += ';'

                line += array[i][index];
            }

            str += line + '\r\n';
        }

        return str;
    }

    public downloadRejectionCSVFromArray(rejections: any[], headers: any, filename: string) {
        if (rejections.length > 0) {
            rejections.unshift(headers);
            let jsonObject: string = JSON.stringify(rejections);
            jsonObject.replace(",", ";")
            this.downloadCSVFromJSON(jsonObject, filename);
        }
    }

    public downloadCSVFromJSON(json: string, filename: string) {
        let csv = this.convertToCSV(json);
        let blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        this.downloadBlob(blob, filename);
    }

    public downloadExcelFromResult(result: any, filename: string) {
        let blob = new Blob([result], { type: 'text/xlsx;charset=utf-8;' });
        this.downloadBlob(blob, filename);
    }
    public downlodadCSVFromBlob(ba: Int8Array, filename: string) {
        let byteArray = new Uint8Array(ba);
        let blob = new Blob([ba], { type: 'text/csv;base64' });
        this.downloadBlob(ba, filename);
    }

    private downloadBlob(blob: any, filename: string) {
        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, filename);
        } else { // IE 9-
            let link = document.createElement("a");
            if (link.download !== undefined) {
                let url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", filename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }
}