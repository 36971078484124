<table mat-table [dataSource]="data" matSort (matSortChange)="handleSort($event)">

    <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header id="tableHeaderId"> Data</th>
        <td mat-cell *matCellDef="let row; let i = index;" [attr.id]="'disputesTdId_'+i">
            {{ row.createdAt | date: 'dd/MM/yyyy' }}
        </td>
    </ng-container>

    <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef mat-sort-header id="tableHeaderId"> Valor </th>
        <td mat-cell *matCellDef="let row; let i = index;" [attr.id]="'disputesTdId_'+i" class="amount-column">
            {{row.amount | CustomCurrency:'BRL'}}
        </td>
    </ng-container>

    <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header id="tableHeaderId"> Status </th>
        <td mat-cell *matCellDef="let row; let i = index;" [attr.id]="'disputesTdId_'+i"> {{
            row.status |
            translate}}
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="hover-row" matTooltipPosition="above"></tr>
</table>

<mat-paginator [length]="pageResponse.totalElements" [pageSize]="pageResponse.pageSize"
    (page)="pageEvent = handlePage($event)" aria-label="Select page">
</mat-paginator>