import { Component, OnInit, Inject } from '@angular/core';
import { TransactionStatus } from '../../../core/model/transaction';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from 'src/app/pages/transactions/transactions.component';
import { ActivatedRoute, Router } from '@angular/router';
import { TransacaoService } from '../../../core/services/v0/transaction.service';
import { PagePermissions } from 'src/app/core/permissions/page-permissions';
import { TranslateService } from '@ngx-translate/core';
import { SalesDetailsResponse } from 'src/app/core/model/response/sales-details-response';
import { AlertService } from 'src/app/core/services/alert.service';
import { Transaction } from 'src/app/core/model/v2.model';

@Component({
  selector: 'app-detail',
  templateUrl: './transactionDetailFromSalesDetail.component.html',
  styleUrls: ['./transactionDetailFromSalesDetail.component.scss']
})
export class TransactionDetailFromSalesDetailComponent implements OnInit {

  dadosTransacao: Transaction;
  listaStatus: TransactionStatus[];
  clientPermissions = PagePermissions.CUSTOMER_EDIT_DETAILS;
  idNew: number;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private transactionService: TransacaoService,
    public dialogRef: MatDialogRef<SalesDetailsResponse>,
    private translate: TranslateService,
    private alertService: AlertService,
    @Inject(MAT_DIALOG_DATA) public data: {transaction: Transaction},
  ) { }

  ngOnInit() {
;
    this.transactionService.getStatus().then(data => {
      this.listaStatus = data.transactionStatus;
    });

    this.dadosTransacao = this.data.transaction;
  }

  clienteClick(idCliente:number) {
    if (idCliente != null && idCliente > 0) {
      this.dialogRef.close();
      this.router.navigate(["/subscribers", idCliente]);
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  getStatusColor(status) {

    if(this.listaStatus) {
      for (let i=0; i < this.listaStatus.length; i++) {
        if(this.translate.instant(status) === this.listaStatus[i].value) {
          return this.listaStatus[i].textColorClass;
        }
      }
       return "";
     }

    }

}
