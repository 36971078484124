<div class="card shadow-none">
  <div fxLayout='row' fxLayout.lt-md="column" fxLayoutGap="20px">
    <div fxFlex="25">
      <mat-form-field>
        <input matInput [matDatepicker]="startDate" readonly (click)="startDate.open()" [(ngModel)]="filters.startDate"
          placeholder="Início" [max]="filters.endDate">
        <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
        <mat-datepicker #startDate></mat-datepicker>
      </mat-form-field>
    </div>
    <div fxFlex="25">
      <mat-form-field>
        <input matInput [matDatepicker]="endDate" readonly (click)="endDate.open()" [(ngModel)]="filters.endDate"
          placeholder="Fim" [min]="filters.startDate">
        <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
        <mat-datepicker #endDate></mat-datepicker>
      </mat-form-field>
    </div>

    <div fxFlex="20">
      <mat-form-field>
        <mat-select placeholder="Adquirente" [(ngModel)]="filters.idAdquirentes" multiple [compareWith]="compareId">
          <mat-option *ngFor="let adquirente of filteredAdquirentes" [value]="adquirente.id" (click)="filterAll()">
            {{adquirente.name.toUpperCase()}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div fxLayout='row' fxLayout.lt-md="column" fxLayoutGap="20px">

    <div fxFlex="20">
      <mat-form-field>
        <mat-select placeholder="Estabelecimento" [(ngModel)]="filters.idAdquirenteEstabelecimentos" multiple
          [compareWith]="compareId">
          <mat-option *ngFor="let ec of filteredEcs" [value]="ec.idAdquirenteEstabelecimento">{{ec.codEstabelecimento}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxLayout='row' fxLayout.lt-md="column" fxLayoutGap="20px"></div>
    <div fxFlex="20">
      <mat-form-field>
        <mat-select placeholder="Plano" [(ngModel)]="filters.idProdutos" multiple [compareWith]="compareId">
          <mat-option *ngFor="let plano of planos" [value]="plano.id">{{plano.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxFlex="20" *ngIf="showBandeiras">
      <mat-form-field>
        <mat-select placeholder="Bandeira" [(ngModel)]="filters.idAdministradoras" multiple [compareWith]="compareId">
          <mat-option *ngFor="let administradora of administradoras" [value]="administradora.id">{{administradora.nome}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="end">
    <div class="center-text">
      <button mat-raised-button class="btn-search" (click)="filter()">Pesquisar</button>
      <!-- <button mat-mini-fab *ngIf="showExportBtn" (click)="downloadCsv()" class="file-btn" matTooltip="Baixar CSV">
        <i class="fal fa-download"></i>
      </button> -->
    </div>
  </div>
  <div class="errors center-text" *ngIf="!rangeValid">
    <span>Favor selecionar um período máximo de 31 dias.</span>
  </div>
</div>