<mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>

<div class="container" *ngIf="!loading">

    <div fxLayout="column" fxLayoutGap="10px" style="margin-bottom: 10px;">
        <div fxLayout="row">

            <div fxFlex fxLayout="row" fxLayoutAlign="start center">
                <p-breadcrumb id="breadcrumb" [model]="crumbs"></p-breadcrumb>
            </div>

            <div fxFlex fxLayout="row" fxLayoutAlign="end center" *ngxPermissionsOnly="createImportPermissions">
                <button type="button" class="btn btn-primary" md-button (click)="fileInput.click()">Selecionar
                    arquivo</button>
            </div>

        </div>
    </div>

    <div fxLayout="column" class="card" fxLayoutGap="10px" fxLayoutAlign="space-between stretch">

        <form (ngSubmit)="upload()" #importForm="ngForm" name="importForm">

            <!-- primeira linha -->
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px">

                <!-- Formulário -->
                <div fxFlex fxLayout="column">

                    <input hidden type="file" (change)="uploadFile($event.target.files)" accept=".xlsx"
                        name="selectedFile" [(ngModel)]="request.selectedFile" #fileInput />

                    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px">
                        <mat-form-field>
                            <input matInput placeholder="Nome do arquivo" [(ngModel)]="fileName" name="fileName"
                                readonly>
                            <mat-error>Nenhum arquivo selecionado!</mat-error>
                        </mat-form-field>

                        <mat-form-field>
                            <input matInput [matDatepicker]="schdt" name="scheduleDate" readonly
                                [(ngModel)]="scheduleDate" placeholder="Data da Importação" [min]="today" [max]="today"
                                disabled>
                            <mat-datepicker-toggle matSuffix [for]="schdt"></mat-datepicker-toggle>
                            <mat-datepicker #schdt></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div>

                <!-- Download layout padrão -->
                <div class="bg-light" fxFlex fxLayout="column" fxLayoutAlign="space-evenly center" fxLayoutGap="0px">
                    <a href="assets/layouts-import/bempaggo-layout.xlsx" download>
                        <h2><i class="layout fal fa-lg fa-file-download"
                                matTooltip="Baixar layout padrão de importação"></i></h2>
                    </a>
                    <span>Baixar layout padrão de importação</span>
                    <h2>Atenção: Instruções para preenchimento do layout</h2>
                    <span>
                        * Data da venda: Data que foi originada a venda, essa data não tem influência em cobranças serve
                        somente para registro da venda.
                        * Data da cobrança: Da que o sistema irá efetuar a cobrança.
                        - Se a data informada for menor ou igual ao dia da importação o sistema irá realizar as
                        cobranças imediatamente após a importação.
                        - Se a data for superior ao dia da importação o sistema irá criar uma cobrança agendada para o
                        dia informado.
                        * ID do plano: ID do plano criado no Bempaggo
                        * Bandeira: (MASTERCARD, VISA, ELO, HIPERCARD, DINERS, AMEX)
                    </span>
                </div>
            </div>

            <!-- segunda linha -->
            <div fxLayout="row" fxLayoutAlign="end" style="margin-top: 10px;" fxLayoutGap="10px">
                <button type="button" *ngIf="!loading" class="btn btn-danger" md-button
                    (click)="cancel()">Cancelar</button>
                <button type="submit" *ngIf="!loading" class="btn btn-primary" md-button>Importar</button>
            </div>

        </form>
    </div>
</div>