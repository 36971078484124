import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Token } from "../../model/v1.model";

@Injectable({
    providedIn: 'root'
})
export class AuthServiceV1 {

    constructor(private http: HttpClient) {
    }

    public pagepayAuthorization(companyId:number, uuid:string): Observable<Token> {
        return this.http.get<Token>(`${environment.apiUrl}/api/v1/auth/companies/${companyId}/pagepays/${uuid}`);
    }
}