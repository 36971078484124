import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import * as moment from 'moment';
import { Administradora } from 'src/app/core/model/administradora';
import { Adquirente } from 'src/app/core/model/adquirente';
import { Ec } from 'src/app/core/model/ec';
import { Produto } from 'src/app/core/model/product';
import { AffiliateMinimalResponse, PlanResponse } from 'src/app/core/model/v1.model';
import { AppService } from 'src/app/core/services/v0/app.service';
import { CovenantService } from 'src/app/core/services/v0/covenant.service';
import { PaymentGatewayService } from 'src/app/core/services/v0/payment-gateway.service';
import { AffiliateServiceV1 } from 'src/app/core/services/v1/affiliate.service.v1';
import { PlanServiceV1 } from 'src/app/core/services/v1/plan.service.v1';

@Component({
  selector: 'report-filter',
  templateUrl: './report-filter.component.html'
})
export class ReportFilterComponent implements OnInit {

  constructor(
    private appService: AppService,
    private paymentGatewayService: PaymentGatewayService,
    private planService: PlanServiceV1,
    private affiliateService: AffiliateServiceV1,
    private formBuilder: UntypedFormBuilder,
    private conveniosService: CovenantService,
  ) { }

  @Input() filters: any
  @Input() showBandeiras: boolean;
  @Output() filterClick: EventEmitter<any> = new EventEmitter();
  @Output() downloadClick: EventEmitter<any> = new EventEmitter();
  @Input() showExportBtn: boolean;

  administradoras: Administradora[];
  rangeValid: boolean = true;
  loading: boolean = false;
  estabelecimentos: AffiliateMinimalResponse[];
  adquirentes: Adquirente[];
  ecs: Ec[];
  planos: PlanResponse[];

  filteredEstabelecimentos: AffiliateMinimalResponse[];
  filteredAdquirentes: Adquirente[];
  filteredEcs: Ec[];
  filteredProdutos: Produto[];
  filteredPlans: PlanResponse[];
  filteredAffiliates: AffiliateMinimalResponse[];

  affiliate: AffiliateMinimalResponse[];
  searchAffiliateForm: UntypedFormGroup;
  @ViewChild('allSelectedForAffiliate') private allSelectedForAffiliate: MatOption;

  ngOnInit(): void {
    this.appService.getAllAdministradoras().subscribe(result => this.administradoras = result);

    this.filters.startDate = moment().startOf('month').toDate();
    this.filters.endDate = new Date();
    this.searchAffiliateForm = this.formBuilder.group({
      orderAffiliate: new UntypedFormControl('')
    });

    this.conveniosService.getAllCovenants().subscribe(result => {
      this.ecs = result
      this.filteredEcs = result;
    });

    this.paymentGatewayService.getAll().subscribe(result => {
      this.adquirentes = result.data;
      this.filteredAdquirentes = result.data;
    });

    this.planService.getAllRecurrents().subscribe(result => {
      this.planos = result;
      this.filteredPlans = result;
    });
    this.affiliateService.getAll().subscribe(result => {
      this.estabelecimentos = result;
      this.filteredEstabelecimentos = result;
    });
    setTimeout(() => {
      this.filter();
    }, 0);

  }
  getEstabelecimentoById(idEstabelecimento: number) {
    for (let estabelecimento of this.estabelecimentos) {
      if (estabelecimento.id == idEstabelecimento) {
        return estabelecimento;
      }
    }
    return null;
  }
  filterAll() {
    this.filteredEcs = this.ecs.filter(ec => {
      if (this.filters.idEstabelecimentos && this.filters.idEstabelecimentos.length > 0) {
        for (let idEstabelecimento of this.filters.idEstabelecimentos) {
          if (idEstabelecimento == ec.idEstabelecimento) {
            return ec;
          }
        }
      } else {
        return ec;
      }
    });
    this.filteredAdquirentes = this.adquirentes.filter(adquirente => {
      if (this.filters.idEstabelecimentos && this.filters.idEstabelecimentos.length > 0) {
        for (let idEstabelecimento of this.filters.idEstabelecimentos) {
          let estabelecimento = this.getEstabelecimentoById(idEstabelecimento);
          if (estabelecimento) {
            for (let adquirente of this.estabelecimentos) {
              if (adquirente.id == adquirente.id) {
                return adquirente;
              }
            }
          }
        }
      } else {
        return adquirente;
      }
    });
    if (this.filters.idAdquirentes && this.filters.idAdquirentes.length > 0) {
      this.filteredEcs = this.filteredEcs.filter(ec => {
        if (this.filters.idAdquirentes && this.filters.idAdquirentes.length > 0) {
          for (let idAdquirente of this.filters.idAdquirentes) {
            if (idAdquirente == ec.idAdquirente) {
              return ec;
            }
          }
        } else {
          return ec;
        }
      });
    }


  }

  filter() {
    if (this.confirmDateRange()) {
      this.filterClick.emit();
    }
  }

  downloadCsv() {
    if (this.confirmDateRange()) {
      this.downloadClick.emit();
    }
  }
  confirmDateRange() {
    let start = moment(this.filters.startDate);
    let end = moment(this.filters.endDate);
    let difference = end.diff(start, 'days');
    if (difference > 31) {
      this.rangeValid = false;
    } else {
      this.filters.dateStart = this.filters.startDate.valueOf();
      this.filters.dateEnd = this.filters.endDate.valueOf();
      this.rangeValid = true;
    }
    return this.rangeValid;
  }
  compareId(id1: number, id2: number) {
    if (id2 === 0) {
      return id1 === 0;
    }
    return id1 && id2 && id1 === id2;
  }

  tosslePerOneForAffiliate(all) {
    if (this.allSelectedForAffiliate.selected) {
      this.allSelectedForAffiliate.deselect();
      return false;
    }
    if (this.searchAffiliateForm.controls.orderAffiliate.value.length == this.affiliate.length) {
      this.allSelectedForAffiliate.select();
    }
  }
  toggleAllSelectionForAffiliate() {
    if (this.allSelectedForAffiliate.selected) {
      this.searchAffiliateForm.controls.orderAffiliate
        .patchValue([0, ...this.affiliate.map(item => item.id)]);

    } else {
      this.searchAffiliateForm.controls.orderAffiliate.patchValue([]);
    }
  }

}

