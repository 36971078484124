import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";


@Injectable({
    providedIn: 'root'
})
export class PaymentGatewayService{
    constructor(private http: HttpClient) {}

    public getAll(){
        return this.http.get<any>(`${environment.apiUrl}/api/v1/payment-providers`);
    }
}