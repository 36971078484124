import { Component, Input, OnInit } from '@angular/core';
import { CreditCard } from 'src/app/core/model/v2.model';

export class Card2TwoCreditCardsLabel {
  card: CreditCard;
  installments: number;
}

@Component({
  selector: 'app-two-cards-label',
  templateUrl: './two-cards-label.component.html',
  styleUrls: ['./two-cards-label.component.scss']
})

export class TwoCardsLabelComponent implements OnInit {
  @Input() creditCards: Card2TwoCreditCardsLabel[];
  constructor() { }

  ngOnInit(): void {
  }

}
