<form name="customerForm" #customerForm="ngForm" fxLayout="column" fxLayoutGap="15px">
    <div fxLayout="{{options.layout}}" fxLayoutGap="15px">
        <mat-form-field class="cpf-field" fxFlex="25">
            <!-- cpf valido / vazio -->
            <!-- encontrar cliente pelo cpf -->
            <input matInput placeholder="CPF/CNPJ" mask="00.000.000/0000-00||000.000.000-00" minlength="11" #cpf="ngModel"
                (change)="getClienteByDocumento()" [(ngModel)]="customerRequest.document" name="document" required
                [disabled]="options.loading">
            <mat-error *ngIf="cpf.errors && cpf.errors.msg">{{cpf.errors.msg}}</mat-error>
        </mat-form-field>
        <mat-form-field fxFlex>
            <!-- maior que 4 -->
            <input matInput placeholder="Nome" [(ngModel)]="customerRequest.name" name="name" required
                [disabled]="options.loading">
            <mat-error>Nome é obrigatório</mat-error>
        </mat-form-field>
    </div>
    <div fxLayout="{{options.layout}}" fxLayoutGap="15px">
        <mat-form-field fxFlex="50">
            <input matInput placeholder="Email" email [(ngModel)]="customerRequest.email" name="email" required
                [disabled]="options.loading">
            <mat-error>Formato de e-mail inválido</mat-error>
        </mat-form-field>
        <mat-form-field fxFlex *ngIf="options.showNotRequired">
            <!-- verificar a validacao de telefone da tela de new transacao -->
            <input matInput [class.invalid-field]="telefoneInvalido" (focus)="telefoneInvalido = false"
                placeholder="Telefone" ngxPhoneMaskBr [(ngModel)]="inputTelefone" name="telefone" type="text"
                [disabled]="options.loading">
        </mat-form-field>
        <mat-form-field fxFlex *ngIf="options.showNotRequired">
            <input matInput placeholder="Aniversário" mask="00/00/0000" [(ngModel)]="customerRequest.birthDate"
                name="dataNascimentoFormatado" [disabled]="options.loading">
        </mat-form-field>
    </div>
    <div *ngIf="options.hasSubmit">
        <button mat-flat-button (click)="onSubmit()" [disabled]="options.loading">{{options.submitBtnTxt}}</button>
    </div>
</form>