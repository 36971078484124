import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import * as moment from 'moment';
import { MenuItem } from 'primeng/api';
import { CustomerImportFilter } from "src/app/core/common/filters/customer-import.filter";
import { CustomerImportStatusHelper } from "src/app/core/helper/customer-import-status.helper";
import { CustomerImport } from "src/app/core/model/customer-import";
import { PageResponse } from "src/app/core/model/page-response";
import { Pageable } from "src/app/core/model/pageable";
import { User } from "src/app/core/model/user";
import { PagePermissions } from "src/app/core/permissions/page-permissions";
import { AlertService } from "src/app/core/services/alert.service";
import { CustomerImportService } from "src/app/core/services/v0/customer-import.service";
import { FileUtilsService } from "src/app/core/services/file-utils.service";
import { UserService } from "src/app/core/services/v0/user.service";

const pageSizeInit = { page: 0, size: 10 };
@Component({
    selector: 'app-import',
    templateUrl: './import-wallet.component.html',
    styleUrls: ['./import-wallet.component.scss']
})

export class ImportWalletComponent implements OnInit {
    @ViewChild("importForm") importForm;
    users: User[];
    loading: boolean = false;
    request: any = new Object();
    filters: CustomerImportFilter = new CustomerImportFilter();
    fileName = "";
    idsMetatags = [];
    idsUsers = [];
    begin = new Date(Date.now());
    end = new Date(this.begin.getFullYear(), this.begin.getMonth(), this.begin.getDate() + 1);
    imports = new MatTableDataSource<CustomerImport>();
    pageResponse: PageResponse;
    pageable: Pageable;
    pageEvent: PageEvent;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    createImportPermissions = PagePermissions.IMPORT_WALLET;
    headers = {
        name: "Nome do cliente",
        document: "CPF do cliente",
        cardBrand: "Bandeira",
        cardNumber: "Num. cartao",
        cardExpirationMonth: "Mes validade",
        cardExpirationYear: "Ano validade",
        cardSecurityCode: "CVV",
        cardHolderName: "Nome do titular",
        cardHolderDocument: "CPF do titular",
        orderDate: "Data da venda",
        duedate: "Data da cobranca",
        sku: "ID do plano",
        rejectReason: "Motivo de rejeicao"
    };
    displayedColumns: string[] = [
        'id',
        'file_name',
        'total',
        'imported',
        'rejected',
        'status',
        'register_date',
        'actions'
    ];
    crumbs: MenuItem[];

    constructor(
        private alertService: AlertService,
        private customerImportService: CustomerImportService,
        private userService: UserService,
        private router: Router,
        private fileUtilService: FileUtilsService,
        private statusHelper: CustomerImportStatusHelper,
    ) {
        this.request.metatags = "";
        this.request.selectedTag = "";
    }

    ngOnInit(): void {
        this.initCrumbs();
        this.begin.setDate(1);
        this.pageResponse = {
            pageNumber: 0,
            totalPages: 0,
            pageSize: 10,
            totalElements: 0,
            content: null
        };
        this.pageable = pageSizeInit;
        this.filter();
    }

    public initCrumbs() {
        this.crumbs = [
            { label: 'Lista de importações' }
        ];
    }

    getUsers() {
        this.userService.findAll().subscribe(result => {
            if (result.success) {
                this.users = result.data;
            }
        })
    }

    newImport() {
        this.router.navigate(['sales/import']);
    }

    filter() {
        this.loading = true;
        this.pageable = pageSizeInit;
        this.filters.start = moment(this.begin).format('YYYY-MM-DD');
        this.filters.end = moment(this.end).format('YYYY-MM-DD');
        this.customerImportService.getFilter(this.filters, this.pageable)
            .subscribe(result => {
                this.loading = false;
                this.showResult(result);
            }, error => {
                this.loading = false;
                this.alertService.error("ERRO!", error.error);
            });
        return event;
    }

    getStatusColor(status: string) {
        return this.statusHelper.getCustomerImportStatusColor(status);
    }

    showResult(result) {
        if (result != null) {
            this.pageResponse.pageNumber = result.pageable.pageNumber;
            this.pageResponse.totalElements = result.totalElements;
            this.pageResponse.totalPages = result.totalPages;
            this.pageResponse.content = result.content;
            this.imports = this.pageResponse.content;

        } else {
            this.pageResponse.pageNumber = 0;
            this.pageResponse.totalElements = 0;
            this.pageResponse.totalPages = 0;
            this.imports = this.pageResponse.content;
        }
    }

    public handlePage(event?: PageEvent) {
        this.pageable.page = event.pageIndex;
        this.customerImportService.getFilter(this.filters, this.pageable)
            .subscribe(result => {
                this.showResult(result);
            }, error => {
                this.alertService.handleError(error);
            });
        return event;
    }
    public handleSort(event?: any) {
        this.pageable = pageSizeInit;
        this.pageable = Object.assign({}, this.pageable, { sort: `${event.active},${event.direction}` });
        this.customerImportService.getFilter(this.filters, this.pageable)
            .subscribe(result => {
                this.showResult(result);
            });
    }

    cancel(id: number) {
        this.alertService.confirm("Atenção!", "Tem certeza que deseja cancelar a importação?").then(confirm => {
            if (confirm) {
                this.alertService.loading();
                this.customerImportService.cancelImport(id).subscribe(
                    result => {
                        this.alertService.success("Sucesso!", "Importação cancelada com sucesso!");
                        this.filter();
                    },
                    error => {
                        this.alertService.handleError(error);
                    }
                );
            }
        });
    }

    //CSV Download methods
    downloadFile(id: number, filename: string) {
        this.customerImportService.getFileById(id)
            .subscribe(result => {
                this.fileUtilService.downlodadCSVFromBlob(result.data, filename);
            }, error => {
                this.alertService.handleError(error);
            }
            );
    }

    downloadRejections(id: number, filename: string) {
        this.alertService.loading();
        let name: string = filename.substring(0, filename.lastIndexOf(".csv")).concat("_rejections.csv");
        this.customerImportService.getRejectionsById(id)
            .subscribe(result => {
                this.alertService.closeAll();
                this.fileUtilService.downloadRejectionCSVFromArray(result.data, this.headers, name);
            }, error => {
                this.alertService.handleError(error);
            }
            );
    }



}
