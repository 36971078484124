<p-breadcrumb id="breadcrumb" [model]="crumbs"></p-breadcrumb>
<div class="container">
    <form class="card" fxLayout="column" fxLayoutGap="10px" #pagepayForm="ngForm" name="pagepayForm"
        (ngSubmit)="create()">

        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="15px">
            <div fxFlex="45" fxLayout="column" fxLayoutGap="5px">
                <app-image-upload></app-image-upload>
            </div>
            <div fxFlex fxLayout="column" id="divInfoPrincipal" fxLayoutGap="5px">
                <div fxFlex>
                    <mat-form-field>
                        <mat-select placeholder="Loja" [(ngModel)]="this.pagepay.affiliateId" name="affiliateId" required>
                            <mat-option *ngFor="let affiliate of affiliates" [value]="affiliate.id">
                                {{affiliate.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>

                </div>
                <div fxFlex>
                    <mat-form-field>
                        <input matInput placeholder="Nome" #nome name="name" id="name" [(ngModel)]="this.pagepay.name"
                            maxlength="80" alt="Infone um nome para o seu link de pagamento" required>
                        <mat-error id="nameError">Você precisa informar um nome</mat-error>
                        <mat-hint align="end">{{nome.value.length}} / 80</mat-hint>
                    </mat-form-field>
                </div>
                <div fxFlex>
                    <mat-form-field>
                        <mat-label>Descrição</mat-label>
                        <textarea matInput #descricao placeholder="Descrição" name="description" id="description"
                            [(ngModel)]="this.pagepay.description" maxlength="120" required></textarea>
                        <mat-error id="nameError">Você precisa informar uma breve descrição</mat-error>
                        <mat-hint align="end">{{descricao.value.length}} / 120</mat-hint>
                    </mat-form-field>
                </div>
                <div fxFlex>
                    <mat-form-field>
                        <input matInput placeholder="Valor" currencyMask
                            [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', allowNegative: false }"
                            maxlength="13" max="999999.99" id="amount" name="amount" [(ngModel)]="this.pagepay.amount"
                            size="40" required>
                        <mat-error id="valueError">Você precisa informar um valor</mat-error>
                    </mat-form-field>
                </div>
                <div fxFlex>
                    <mat-form-field>
                        <mat-select placeholder="Parcelamento" name="maxInstallments" id="maxInstallments"
                            [(ngModel)]="this.pagepay.maxInstallments" required>
                            <mat-option [value]="1">À vista (sem parcelamento)</mat-option>
                            <mat-option *ngFor=" let installment of installments" [value]="installment">Até
                                {{installment}} parcelas</mat-option>
                        </mat-select>
                        <mat-error id="maxInstallmentsError">Parcelamento é obrigatório</mat-error>
                    </mat-form-field>
                </div>
                <div fxFlex>
                    <div fxLayout="column" fxLayoutGap="0px">
                        <mat-form-field>
                            <input matInput placeholder="Redirecionamento do cliente" type="url" maxlength="512"
                                id="returnUrl" name="returnUrl" [(ngModel)]="this.pagepay.returnUrl">
                            <i class="fal fa-question-circle "
                                matTooltip="Em caso de pagamento aprovado, &#13;vamos direcioná-lo para onde você quiser."
                                matTooltipClass="tooltip-full"
                                matTooltipPosition="left"
                                matSuffix></i>
                            <mat-error id="returnUrlError">Você precisa informar um endereço valido</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div fxFlex>
                    <mat-slide-toggle name="hasNotExpiration" id="hasNotExpiration" [(ngModel)]="hasNotExpiration">Data
                        de
                        expiração</mat-slide-toggle>
                </div>
                <div fxFlex *ngIf="hasNotExpiration">
                    <mat-form-field>
                        <input matInput placeholder="Data de expiração" (focus)="myDatepicker.open()"
                            [matDatepicker]="myDatepicker" name="expiration" id="expiration"
                            [(ngModel)]="this.pagepay.expiration" size="60" required>
                        <mat-datepicker-toggle matSuffix [for]="myDatepicker"></mat-datepicker-toggle>
                        <mat-datepicker #myDatepicker></mat-datepicker>
                        <mat-error id="expirationError">Data de expiração é obrigatória</mat-error>
                    </mat-form-field>
                </div>
                <!--
                <div fxFlex>
                    <mat-slide-toggle name="hasNotSaleLimit" id="hasNotSaleLimit" [(ngModel)]="hasNotSaleLimit">Limitar
                        quantidade de vendas</mat-slide-toggle>
                </div>
                <div fxFlex>
                    <mat-form-field *ngIf="hasNotSaleLimit">
                        <input matInput placeholder="Quantidade máxima de vendas" type="number" min="1" max="9999999"
                            maxlength="6" size="20" name="limit" id="limit" ngDefaultControl=true
                            [(ngModel)]="this.pagepay.limit" required>
                        <mat-error id="limitError">Você precisa informar a quantidade total de vendas</mat-error>
                    </mat-form-field>
                </div>
                -->

                <div fxFlex>
                    <mat-slide-toggle name="birthdateRequired" id="birthdateRequired"
                        [(ngModel)]="this.pagepay.birthdateRequired">Solicitar data de nascimento</mat-slide-toggle>
                </div>
                <div fxFlex>
                    <mat-slide-toggle name="sendEmail" id="sendEmail" [(ngModel)]="this.pagepay.sendEmail">Notificar o
                        cliente automaticamente por e-mail</mat-slide-toggle>
                </div>
            </div>
        </div>

        <div fxLayout="row" fxLayoutAlign="end" class="actions-buttons">
            <button mat-raised-button class="btn-cancel" type="button" id="cancel"
                (click)="goCancel()">Cancelar</button>
            <button mat-raised-button class="btn-confirm" type="submit" id="save"
                [disabled]="!form.valid">Salvar</button>
        </div>

        <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="end">
        </div>
    </form>
</div>