<div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between start" class="mb-2">
  <mat-toolbar role="toolbar" class="task-header w-75">
  </mat-toolbar>
  <i (click)="onNoClick()" title="Fechar" class="fas fa-times pt-2 pointer"></i>
</div>
<div class="content">
  <div fxLayout='column' fxLayout.lt-md="column">
    <div class="box-data-card">
      <app-shared-link-fb [url]="pagepay.uuid"></app-shared-link-fb>
    </div>
  </div>
</div>