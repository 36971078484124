import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

export interface UserData {
  id: string;
  statusAdquirente: string;
  date: string;
  status: string;
  bandeira: string;
}
const STATUSADQUIRENTE: string[] = ['Autorizada', 'Não Autorizada', 'Falha na Comunicaçãos'];
const DATE: string[] = ['12/07/2018', '15/6/2018'];
const STATUS: string[] = ['Efetuada', 'Inválida'];
const BANDEIRA: string[] = ['Mastercard', 'Visa', 'Cielo'];


@Component({
  selector: 'app-denied',
  templateUrl: './denied.component.html',
  styleUrls: ['./denied.component.scss']
})
export class DeniedComponent implements OnInit {

  filtersOpen = false;
  displayedColumns: string[] = ['id', 'status', 'date', 'bandeira', 'statusAdquirente'];
   dataSource: MatTableDataSource<UserData>;
 
   @ViewChild(MatPaginator) paginator: MatPaginator;
   @ViewChild(MatSort) sort: MatSort;
   constructor() {
     const users = Array.from({ length: 100 }, (_, k) => createNewUser(k + 1));
     // Assign the data to the data source for the table to render
     this.dataSource = new MatTableDataSource(users);
   }
 
 
   ngOnInit() {
     this.dataSource.paginator = this.paginator;
     this.dataSource.sort = this.sort;
   }
 
   applyFilter(filterValue: string) {
     this.dataSource.filter = filterValue.trim().toLowerCase();
 
     if (this.dataSource.paginator) {
       this.dataSource.paginator.firstPage();
     }
   }

 }
 
 /** Builds and returns a new User. */
 function createNewUser(id: number): UserData {
  
   return {
     id: id.toString(),
     statusAdquirente: STATUSADQUIRENTE[Math.round(Math.random())],
     date: DATE[Math.round(Math.random() * (DATE.length - 1))],
     status: STATUS[Math.round(Math.random())],
     bandeira: BANDEIRA[Math.round(Math.random())]
   };
  }