import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, ValidatorFn, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { ValidationHelper } from 'src/app/core/helper/validation.helper';
import { Administradora } from 'src/app/core/model/administradora';
import { CardInformation } from 'src/app/core/model/card-infomartion';
import { Cidade } from 'src/app/core/model/cidade';
import { Client } from 'src/app/core/model/client';
import { ClientSendResource } from 'src/app/core/model/client-send-resource';
import { Endereco } from 'src/app/core/model/endereco';
import { Estado } from 'src/app/core/model/estado';
import { NewCustomerRequest } from 'src/app/core/model/request/new-customer-request';
import { CustomerResponse } from 'src/app/core/model/response/customer.response';
import { NewCustomerResponse } from 'src/app/core/model/response/new-customer-response';
import { TransactionSendResource } from 'src/app/core/model/transaction-send-resource';
import { AddressRequest, AddressResponseV1, AffiliateMinimalResponse, CardBrand, CardRequestV1, OrderType, PagePayToSubscriptionResponse, PhoneRequest, PlanResponse } from 'src/app/core/model/v1.model';
import { AlertService } from 'src/app/core/services/alert.service';
import { EnderecoService } from 'src/app/core/services/endereco.service';
import { AppService } from 'src/app/core/services/v0/app.service';
import { CustomerService } from 'src/app/core/services/v0/customer.service';
import { OnetimeService } from 'src/app/core/services/v0/onetime-transaction';
import { AffiliateServiceV1 } from 'src/app/core/services/v1/affiliate.service.v1';
import { OnCustomer as OnCustomerV1 } from 'src/app/core/services/v1/customer.service.v1';
import { PlanServiceV1 } from 'src/app/core/services/v1/plan.service.v1';
import { SubscriptionServiceV1 } from 'src/app/core/services/v1/subscription.service.v1';
import { PaymentPageComponent } from './pagepay-modal/paymentPage.component';
import { ManuallyKeyedTransaction } from './typed-sale-service';

export interface Status {
  value: string;
  viewValue: string;
  data;
}

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-new-transaction',
  templateUrl: './new-transaction.component.html',
  styleUrls: ['./new-transaction.component.scss']
})

export class NewTransactionComponent implements OnInit {
  @ViewChild(MatStepper, { static: true }) stepper: MatStepper;
  @Input() maxlength: string | number | null;
  @Input() minlength: string | number | null;
  public typeTransaction: OrderType = OrderType.RECURRENT;
  emailFormControl = new FormControl('', [Validators.required, Validators.email]);
  planFormControl = new FormControl('', [Validators.required]);
  matcher = new MyErrorStateMatcher();

  loose = {
    value: 0.00,
    dueDate: moment().format('DDMMYYYY'),
    referenceId: null,
    installments: 1,
    affiliateId: null,
    description: ''
  };

  step1Completed = false;
  step2Completed = false;
  cardInfo: CardInformation = new CardInformation();
  telefoneInvalido: boolean = false
  inputTelefone: string;
  loading = false;
  valueSubscription: number;
  valueAccession: number;
  show = false;
  newClient = true;
  newAddress = false;
  isLinear = false;
  estadoSelected: string;
  estadoSelectedV1 = new Estado();
  cidadeSelected: string;
  meses: string[] = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
  anos: string[] = [];
  icon = 'fal fa-search orange';
  toolTip = "Pesquisar Cpf";
  cepIcon = 'fal fa-search orange';
  cepTooltip = "Pesquisar Cep";
  clientData = new Client();
  clientDataV1 = new NewCustomerResponse();
  client = new Client();
  address = new Endereco();
  addressV1 = new AddressResponseV1();
  prod = new PlanResponse();
  listaProduto: PlanResponse[];
  administradoras: Administradora[];
  transacaoData = new TransactionSendResource();
  transacaoDataSend = new TransactionSendResource();
  clientDataSend = new ClientSendResource();
  clientDataSendV1 = new NewCustomerRequest();
  customerDataSend = new CustomerResponse();
  cidades = new Array<Cidade>();
  estados = new Array<Estado>();
  endereco: any;
  idEndereco: number;
  clientOk = false;
  productOk = false;
  cardOk = false;
  dataAtual = new Date(Date.now());
  camposVazios = new Array();
  textMenuCliente: any;
  textMenuCompra: any;
  textMenuPagamento: any;
  clienteId = 0;
  idAdministradora: number;
  paymentMethod: string;
  affiliates: AffiliateMinimalResponse[];
  imagem: any;
  searchAffiliateForm: FormGroup;
  searchAffiliateFormLoose: FormGroup;
  emv: string;
  expirationDate: Date;
  code: string;
  barcode: string;
  dueDateBankslip: number;
  installments: number;
  disable: boolean = false;
  paymentMethodNumber: number;
  customer: NewCustomerRequest;
  cardRequest: CardRequestV1;
  token: string;
  estadoV1: Estado;
  customerId: string;
  cardId: number;
  linkAddress: string;
  linkBestAddress: string;
  addressesList: AddressResponseV1[];
  bestAddress: AddressResponseV1;
  addressSelected: AddressRequest;
  cidadeV1 = new Cidade();
  uri: string;
  customerDocument: string;
  changeAddress: boolean = true;
  bestEndereco: any;
  addressSelectedResponse: AddressResponseV1;
  clientExists: string = "false";
  findClient: boolean = false;
  showFieldsClient: boolean;
  saleType: string = 'paymentLink';
  installmentsEnabled: boolean = false;
  maxInstallments: number;
  paymentMethodList: string[] = [];
  enableCardInstallments: boolean = false;
  paymentMethodPix: boolean = false;
  paymentMethodBansklip: boolean;
  paymentMethodCreditCard: boolean;
  showPaymentSummary: boolean;
  pagePay: PagePayToSubscriptionResponse;
  firstFormGroup: FormGroup;
  installmentType: string = 'CARD_INSTALLMENT';
  chargeId: number;
  installmentsLoose: number;
  get formArray(): AbstractControl | null { return this.firstFormGroup.get('formArray'); };
  control = new FormControl('', Validators.required)
  selectedProduct: PlanResponse;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private appService: AppService,
    private enderecoService: EnderecoService,
    private onCustomer: CustomerService,
    private onCustomerV1: OnCustomerV1,
    private subscriptionServiceV1: SubscriptionServiceV1,
    private onPlan: PlanServiceV1,
    private oneTimeService: OnetimeService,
    private validationHelper: ValidationHelper,
    private alertService: AlertService,
    private affiliateService: AffiliateServiceV1,
    public dialog: MatDialog,
  ) {
    this.typeTransaction = OrderType.RECURRENT;
  }

  ngOnInit() {
    this.paymentMethodBansklip = true;
    this.paymentMethodPix = true;
    this.paymentMethodCreditCard = true;
    this.transacaoData.dueDate = moment().format('DDMMYYYY')
    this.firstFormGroup = this.formBuilder.group(
      {
        formArray: this.formBuilder.array([
          //dados da compra
          this.formBuilder.group({
            typeTransaction: Validators.required,
            loja: Validators.required,
            plan: Validators.required,
            referenceId: Validators.nullValidator,
            valorPrincipal: Validators.required,
            description: Validators.nullValidator,
          }),
          //dados do cliente
          this.formBuilder.group({
            clientExists: Validators.required,
            cpf: ['', Validators.required],
            endereco: Validators.required,
            telefone: ['', Validators.nullValidator],
            aniversario: ['', Validators.nullValidator],
            email: ['', Validators.email],
            nomeCliente: Validators.required,
            cep: ['', Validators.nullValidator],
            logradouro: Validators.nullValidator,
            numero: Validators.nullValidator,
            complemento: Validators.nullValidator,
            bairro: Validators.nullValidator,
            estado: Validators.nullValidator,
            cidade: Validators.nullValidator,
          }),
          //dados do pagamento
          this.formBuilder.group({
            saleType: Validators.required,
            paymentMethodPix: Validators.nullValidator,
            paymentMethodBankslip: Validators.nullValidator,
            paymentMethodCreditCard: Validators.nullValidator,
            installmentsEnabled: Validators.required,
            installments: Validators.required,
            installmentType: Validators.required,
            administradora: Validators.required,
            cartao: ['', Validators.required],
            mes: Validators.required,
            ano: Validators.required,
            cvv: ['', Validators.nullValidator],
            nomeTitular: Validators.required,
            cpfTitular: ['', Validators.required],
          })
        ])
      });
    this.verifyStep();
    this.estadoV1 = new Estado();
    this.maxInstallments = 1;
    this.addressV1 = new AddressResponseV1();
    this.addressSelected = new AddressRequest();
    const localStorageObject = JSON.parse(localStorage.getItem('currentUser'));
    this.token = localStorageObject.accessToken;
    this.cardRequest = new CardRequestV1();
    this.clientData.listaEnderecos = new Array<Endereco>();
    let hoje = moment();
    for (let i = 0; i < 16; i++) {
      this.anos[i] = (hoje.year() + i).toString();
    }
    this.screen();
    this.getAllEstados();
    this.getProducts();
    this.affiliateService.getAll().subscribe(result => this.affiliates = result);
    this.searchAffiliateForm = this.formBuilder.group({
      orderAffiliate: new FormControl('')
    });
    this.searchAffiliateFormLoose = this.formBuilder.group({
      orderAffiliate: new FormControl('')
    });
    this.typeTransaction = OrderType.RECURRENT;
    this.installmentsEnabled = true;
    this.choiceType(true);
  }

  choiceType(isRecurent: boolean) {
    if (isRecurent) {
      this.formArray.get([0]).get("plan").validator = Validators.required;
      this.formArray.get([0]).get("valorPrincipal").clearValidators();
    } else {
      this.formArray.get([0]).get("plan").clearValidators();
      this.formArray.get([0]).get("valorPrincipal").validator = Validators.required;
    }
    this.formArray.get([0]).get("plan").updateValueAndValidity();
    this.formArray.get([0]).get("valorPrincipal").updateValueAndValidity();
  }

  isOneTimePayment(): Boolean {
    return this.typeTransaction == OrderType.LOOSE;
  }

  isRecurringPayment(): Boolean {
    return this.typeTransaction == OrderType.RECURRENT;
  }

  doTransaction() {
    this.getTransactionDataV1();
    new ManuallyKeyedTransaction(
      this,
      this.onCustomer,
      this.onCustomerV1,
      this.oneTimeService,
      this.subscriptionServiceV1,
      this.alertService)
      .doTransaction(this.clientDataSendV1.document, this.cardRequest, this.token);
  }

  getTransactionDataV1() {
    this.clientDataSendV1 = new NewCustomerRequest();
    this.transacaoDataSend = new TransactionSendResource();
    this.transacaoDataSend.idFormaPagamento = this.paymentMethodNumber;
    this.transacaoDataSend.idProduto = this.transacaoData.idProduto;
    this.loose.affiliateId = this.transacaoData.affiliateId;
    this.transacaoDataSend.qtdParcelas = this.prod.maxInstallments;
    this.transacaoDataSend.idCliente = this.clienteId !== 0 ? this.clienteId : null;
    this.clientDataSendV1.name = this.clientDataV1.name;
    this.clientDataSendV1.document = this.clientDataV1.document;
    this.clientDataSendV1.email = this.clientDataV1.email == '' ? null : this.clientDataV1.email;

    if (this.clientDataV1.birthdate) {
      const year = Number(this.clientDataV1.birthdate.substring(4, 8));
      const month = Number(this.clientDataV1.birthdate.substring(2, 4)) - 1;
      const day = Number(this.clientDataV1.birthdate.substring(0, 2));
      this.clientDataSendV1.birthdate = new Date(year, month, day);
    }

    if (this.inputTelefone) {
      this.clientDataSendV1.phone = new PhoneRequest();
      this.clientDataSendV1.phone.code = this.inputTelefone.substring(0, 2);
      this.clientDataSendV1.phone.number = this.inputTelefone.substring(2, this.inputTelefone.length);
    }
    this.cardRequest.cvv = this.cardInfo.cartaoCvv ? this.cardInfo.cartaoCvv : "";
    this.cardRequest.expiration.year = this.cardInfo.cartaoValidadeAno?.toString();
    this.cardRequest.expiration.month = this.cardInfo.cartaoValidadeMes?.toString();
  }

  selected(value: string) {
    this.paymentMethod = value;
  }

  updatePhone() {
    if (!this.clientDataV1.phone) {
      this.clientDataV1.phone = new PhoneRequest();
    }
    this.clientDataV1.phone.code = this.inputTelefone.substring(0, 2);
    this.clientDataV1.phone.number = this.inputTelefone.substring(2, this.inputTelefone.length);
  }

  getProducts() {
    this.onPlan.getAllRecurrents()
      .subscribe(result => {
        this.listaProduto = result;
      });
  }

  getSelectProduct() {
    this.onPlan.getById(this.prod.id)
      .subscribe(result => {
        this.selectedProduct = result;
      });
  }

  selecionaProduto(produto) {
    this.prod = produto;
    this.step2Completed = true;
    this.getSelectProduct();
  }

  selectAddressV1(endereco) {
    if (endereco === 0) {
      this.addressSelected = new AddressRequest();
      this.addressSelectedResponse = new AddressResponseV1;
      this.addressSelected.zipCode = '';
      this.newAddress = true;
      this.estadoSelected = '';
      this.cidadeSelected = '';
      if (this.addressSelected.zipCode != '') {
        this.addressSelectedResponse = this.conversor(this.addressSelected)
        this.addressesList.push(this.addressSelectedResponse);
        this.onCustomer.createAddress(this.linkAddress, this.addressSelected)
          .subscribe(() => {
            this.onCustomer.getAddress(this.linkAddress)
              .subscribe(result => {
                this.addressesList = result;

              });
          });
      }
    }
    else {
      this.addressSelectedResponse = this.conversor(this.addressSelected)
      this.addressSelectedResponse = this.addressesList.filter(info => {
        return info == endereco.id;
      })[0];
      this.newAddress = false;
    }
  }

  conversor(addressRequest: AddressRequest) {
    let addressResponse = new AddressResponseV1;
    addressResponse.number = addressRequest.streetNumber;
    addressResponse.zipcode = addressRequest.zipCode;
    addressResponse.lineTwo = addressRequest.addressLineTwo;
    addressResponse.city = addressRequest.cityName;
    addressResponse.neighborhood = addressRequest.neighborhood;
    addressResponse.state = addressRequest.state
    addressResponse.street = addressRequest.street
    addressResponse.id = addressRequest.id
    return addressResponse
  }

  consultaCep() {
    this.enderecoService.getAddressByCep(this.addressSelected.zipCode)
      .subscribe(result => {
        this.cepIcon = 'fas fa-check-circle green';
        this.cepTooltip = 'Cep encontrado';
        this.addressSelected.zipCode = result.cep;
        this.addressSelected.street = result.logradouro;
        this.estadoSelectedV1 = result.estado_info.codigo_ibge;
        this.addressSelected.state = result.estado;
        this.selectCidades(this.estadoSelectedV1, () => {
          this.cidadeSelected = result.cidade_info.codigo_ibge;
          this.setCidadeV1(this.findCidade());
        });
      });
  }

  getAllEstados() {
    this.enderecoService.getAllEstados().then(result => {
      this.estados = result;
    });
  }

  findCidade(): Cidade {
    for (let cidade of this.cidades) {
      if (cidade.codIbge === this.cidadeSelected) {
        return cidade;
      }
    }
    return null;
  }

  selectCidades(codIbge, callback?: Function) {
    this.enderecoService.getCidadesByEstado(codIbge)
      .subscribe(result => {
        this.cidades = result;
        if (callback) {
          callback();
        }
      });
  }

  setCidade(cidade) {
    this.address.idCidade = cidade.id;
    this.address.nomeCidade = cidade.nome;
  }

  setCidadeV1(cidade) {
    this.cidadeV1.id = cidade.id;
    this.cidadeV1.nome = cidade.nome;
    this.addressSelected.cityName = cidade.nome;
    this.addressSelected.idCity = cidade.id;
  }

  getClienteByDocumentoV1(event: Event) {
    let document = this.clientDataV1.document;
    if (document && document.length >= 11) {
      this.getCustomerByDocument(document);
    }
    event.stopPropagation();
  }

  private getCustomerByDocument(document: string) {
    this.onCustomerV1.getByDocument(document)
      .subscribe(result => {
        this.findClient = true;
        this.customerId = result.id;
        this.verifyClient();
        this.icon = 'fas fa-check-circle green';
        this.toolTip = 'Cliente encontrado';
        this.clientDataV1 = result;

        if (result.birthdate != null) {
          let birthdate = new Date(result.birthdate);
          this.clientDataV1.birthdate = moment.utc(birthdate).format('DD/MM/YYYY');
        }

        this.linkAddress = result.addresses;
        this.linkBestAddress = result.bestAddress;
        this.onCustomer.getAddress(this.linkAddress)
          .subscribe(result => {
            this.addressesList = result;
            this.onCustomer.getAddress(this.linkBestAddress)
              .subscribe(result => {
                this.bestAddress = this.addressesList.filter(address => address.id == result.id)[0];
              });
          });
        this.newClient = false;
        if (this.clientDataV1.phone != null && this.clientDataV1.phone.code && this.clientDataV1.phone.number) {
          this.inputTelefone = `${this.clientDataV1.phone.code}${this.clientDataV1.phone.number}`;
        } else {
          this.inputTelefone = "";
        }
      },
        error => {
          this.findClient = false;
          this.verifyClient();
          this.newClient = true;
          this.icon = 'fas fa-plus-circle blue';
          this.toolTip = 'Novo Cliente';
          this.clienteId = null;
          this.clientDataV1 = new NewCustomerResponse();
          this.clientDataV1.document = document;
          this.inputTelefone = "";
        });
  }

  navigateToTransactions(id) {
    this.router.navigate([`../sales/sales-details/${id}`]);
  }

  openPagePayModal(pagepay) {
    const dialogRef = this.dialog.open(PaymentPageComponent, {
      width: '400px',
      height: '400px',
      data: { pagepay: pagepay }
    });
    dialogRef.afterClosed().subscribe(() => {
    });
  }

  verifyCreditCardTransaction(data) {
    if (data.status === 201) {
      if (data.data.status !== "APPROVED" && this.prod.trialDays > 0) {
        this.alertService.success('Sucesso', 'Transação agendada com sucesso.').then(success => {
          if (success) {
            this.navigateToTransactions(this.chargeId);
          }
        });
      } else if (data.data.status === "APPROVED" || data.data.status === "AUTHORIZED") {
        this.alertService.success('Sucesso', data.message).then(success => {
          if (success) {
            this.navigateToTransactions(this.chargeId);
          }
        });
      } else {
        this.alertService.error('Erro', 'Não foi possível efetuar a transação. <br/>' + data.message);
      }
    } else {
      this.alertService.error('Erro', 'Não foi possível efetuar a transação. <br/>' + data.message);
    }
    this.loading = false;
  }

  verifyInstallments() {
    if (this.loose.installments > 12) {
      this.loose.installments = 12;
    }
    if (this.loose.installments < 1) {
      this.loose.installments = 1;
    }
  }

  getDateFormat(date) {
    return moment(date, 'DDMMYYYY').format('DD/MM/YYYY');
  }

  // Mouseover que mostra onde fica o cvv no cartao
  open() {
    this.show = true;
  }
  close() {
    this.show = false;
  }

  // Limpar dados de cartão
  cleanForm() {
    this.clientData.cartaoNumero = "";
    this.clientData.nomeTitular = "";
    this.clientData.cpfCnpjTitular = "";
    this.newClient = true;
  }

  validaCartao(card: CardInformation) {
    let mesAtual = this.dataAtual.getMonth() + 1;
    let notExpired = card.cartaoValidadeAno == this.dataAtual.getFullYear() && card.cartaoValidadeMes >= mesAtual;
    return card.cartaoValidadeAno > this.dataAtual.getFullYear() || notExpired ? true : false;
  }

  isInvalidFirstStep() {
    if (!this.clientData.nome ? this.camposVazios.push('<br/> - Nome') : this.camposVazios.push()) { }
    if (!this.clientData.cpfCnpjCliente ? this.camposVazios.push('<br/> - Cpf') : this.camposVazios.push()) { }
    if ((this.clientData.cpfCnpjCliente && !this.validationHelper.validaCpf(this.clientData.cpfCnpjCliente)) ? this.camposVazios.push('<br/> - Cpf Inválido') : this.camposVazios.push()) { }
    if ((this.clientData.email && !this.validaEmail(this.clientData.email)) ? this.camposVazios.push('<br/> - E-mail inválido') : this.camposVazios.push()) { }
    if (this.camposVazios.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  isInvalidFirstStepV1() {
    if (!this.clientDataV1.name ? this.camposVazios.push('<br/> - Nome') : this.camposVazios.push()) { }
    if (!this.clientDataV1.document ? this.camposVazios.push('<br/> - Cpf') : this.camposVazios.push()) { }
    if ((this.clientDataV1.document && !this.validationHelper.validaCpf(this.clientDataV1.document)) ? this.camposVazios.push('<br/> - Cpf Inválido') : this.camposVazios.push()) { }
    if ((this.clientDataV1.email && !this.validaEmail(this.clientDataV1.email)) ? this.camposVazios.push('<br/> - E-mail inválido') : this.camposVazios.push()) { }
    if (this.camposVazios.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  isInvalidSecondStep() {
    if (!this.transacaoDataSend.idProduto && this.typeTransaction == OrderType.RECURRENT ? this.camposVazios.push('<br/> - Produto') : this.camposVazios.push()) { }
    if (this.camposVazios.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  isInvalidThirdStep() {
    if (this.paymentMethod == 'credit_card') {
      if (!this.clientData.nomeTitular ? this.camposVazios.push('<br/>- Nome Titular ') : this.camposVazios) { }
      if (!this.idAdministradora ? this.camposVazios.push('<br/>- Bandeira ') : this.camposVazios) { }
      if (!this.clientData.cpfCnpjTitular ? this.camposVazios.push('<br/>- Cpf Titular ') : this.camposVazios) { }
      if ((this.clientData.cpfCnpjTitular && !this.validationHelper.validaCpf(this.clientData.cpfCnpjTitular)) ? this.camposVazios.push('<br/>- Cpf Titular Inválido ') : this.camposVazios) { }
      if (!this.cardInfo.cartaoNumero ? this.camposVazios.push('<br/>- Número do cartão ') : this.camposVazios.push()) { }
      if (!((this.cardInfo.cartaoValidadeMes && this.cardInfo.cartaoValidadeAno)) ? this.camposVazios.push('<br/>- Validade do Cartão') : this.camposVazios.push()) { }
      if (!this.clientData.nomeTitular === null ? this.camposVazios.push('<br/>- Nome do titular do cartão ') : this.camposVazios.push()) { }
      if (this.cardInfo.cartaoNumero && this.cardInfo.cartaoNumero.length < 16) {
        this.camposVazios.push('<br/>- Número do cartão inválido.');
      } else if (!this.validaCartao(this.cardInfo)) {
        this.camposVazios.push('<br/>- Data de validade do cartão é menor que a data atual.');
      }
      if (this.camposVazios.length > 0) {
        return true;
      }
    }
    else
      if (this.paymentMethod == 'pix' || this.paymentMethod == 'boleto') {
        return false;
      }
      else {
        return true;
      }
  }

  validaEmail(email) {
    let regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    if (regexp.test(email)) {
      return true;
    } else {
      return false;
    }
  }

  isInvalidThirdStepV1() {
    if (this.paymentMethod == 'credit_card') {
      if (!this.cardRequest.holder.name ? this.camposVazios.push('<br/>- Nome Titular ') : this.camposVazios) { }
      if (!this.cardRequest.brand ? this.camposVazios.push('<br/>- Bandeira ') : this.camposVazios) { }
      if (!this.cardRequest.holder.document ? this.camposVazios.push('<br/>- Cpf Titular ') : this.camposVazios) { }
      if ((this.cardRequest.holder.document && !this.validationHelper.validaCpf(this.cardRequest.holder.document)) ? this.camposVazios.push('<br/>- Cpf Titular Inválido ') : this.camposVazios) { }
      if (!this.cardRequest.number ? this.camposVazios.push('<br/>- Número do cartão ') : this.camposVazios.push()) { }
      if (!((this.cardRequest.expiration.year && this.cardRequest.expiration.year)) ? this.camposVazios.push('<br/>- Validade do Cartão') : this.camposVazios.push()) { }
      if (!this.cardRequest.holder.name === null ? this.camposVazios.push('<br/>- Nome do titular do cartão ') : this.camposVazios.push()) { }
      if (this.cardRequest.number && this.cardRequest.number.length < 16) {
        this.camposVazios.push('<br/>- Número do cartão inválido.');
      } else if (!this.validaCartao(this.cardInfo)) {
        this.camposVazios.push('<br/>- Data de validade do cartão é menor que a data atual.');
      }
      if (this.camposVazios.length > 0) {
        return true;
      }
    }
    else
      if (this.paymentMethod == 'pix' || this.paymentMethod == 'boleto') {
        return false;
      }
      else {
        return true;
      }
  }

  screen() {
    let x = screen.width;
    if (x < 500) {
      this.textMenuCliente = '';
      this.textMenuCompra = '';
      this.textMenuPagamento = '';
    } else {
      this.textMenuCliente = 'Dados do Cliente';
      this.textMenuCompra = 'Dados de Compra';
      this.textMenuPagamento = 'Pagamento';
    }
  }

  validateFirstStep() {
    if (this.isInvalidFirstStep()) {
      this.alertService.error('Erro', 'Um ou mais campos apresentaram problema.' + this.camposVazios);
    }
  }

  verifyClient() {
    this.showFieldsClient = this.findClient || this.clientExists == 'false';
  }

  verifyMaxInstallments() {
    if (this.typeTransaction == OrderType.LOOSE) {
      this.maxInstallments > 12 ? this.maxInstallments = 12 : this.maxInstallments;
    }
    if (this.typeTransaction == OrderType.RECURRENT) {
      this.installments > this.prod.maxInstallments ? this.installments = this.prod.maxInstallments : this.installments;
    }
  }

  verifyCardInstallments() {
    if (this.typeTransaction == OrderType.RECURRENT) {
      return this.caseRecurrentWhatIsPaymentType();
    } else if (this.typeTransaction == OrderType.LOOSE) {
      return this.caseLooseWhatIsPaymentType();
    } else {
      return false;
    }
  }

  caseLooseWhatIsPaymentType() {
    if (!this.hasInstallmentsEnabled()) {
      return false;
    }
    return ((this.saleType == 'paymentLink' && this.maxInstallments > 1 && this.paymentMethodCreditCard)
      || (this.saleType == 'typedSale' && this.installmentsLoose > 1));
  }
  caseRecurrentWhatIsPaymentType() {
    if (!this.hasPlanInstallments()) {
      return false;
    }
    return ((this.saleType == 'paymentLink' && this.paymentMethodCreditCard)
      || (this.saleType == 'typedSale' && this.installmentsEnabled));
  }
  hasPlanInstallments() {
    return this.prod.maxInstallments > 1;
  }
  hasInstallmentsEnabled() {
    return this.installmentsEnabled == true;
  }


  verifyStep() {
    if (this.stepper.selectedIndex == 2 || this.showPaymentSummary) {
      this.showPaymentSummary = true;
      return true;
    }
  }

  selectedPaymentMethod() {
    if (this.paymentMethodList.length > 0) {
      this.paymentMethodList.splice(0, this.paymentMethodList.length)
    }
    if (this.stepper.selectedIndex == 2 || this.showPaymentSummary) {
      this.showPaymentSummary = true;
      if (this.paymentMethodBansklip) {
        this.paymentMethodList.push('BANK_SLIP');
      }
      if (this.paymentMethodPix) {
        this.paymentMethodList.push('PIX');
      }
      if (this.paymentMethodCreditCard) {
        this.paymentMethodList.push('CREDIT_CARD');
      }
      return true;
    }
  }
  checkCardBrand() {
    if (this.cardRequest.number && this.cardRequest.number.length > 8) {
      let bandeira = this.getBrandByCardBin(this.cardRequest.number);
      this.cardRequest.brand = bandeira;
      return bandeira!.toLowerCase();
    } else {
      this.cardRequest.brand = null;
    }
  }

  verifyDate() {
    const today = moment().format('DDMMYYYY');
    if (this.typeTransaction == OrderType.RECURRENT) {
      this.transacaoData.dueDate > today ? this.transacaoData.dueDate : this.transacaoData.dueDate = today;
    }
    if (this.typeTransaction == OrderType.LOOSE) {
      this.loose.dueDate > today ? this.loose.dueDate : this.loose.dueDate = today;
    }
  }

  private getBrandByCardBin(cardNumber: string): CardBrand | null {
    const JCB_REGEX = "^(?:2131|1800|35\\d{3})\\d{11}$";
    const DINERS_REGEX = "^3(?:0[0-5]|[68][0-9])[0-9]{11}$";
    const AMEX_REGEX = "^3[47][0-9]{13}$";
    const ELO_REGEX = "^((40117[8-9])|(43127[0-9])|(438935)|(451416)|(45763[1-2])|(5041[8-9][0-9])|(5067[0-6][0-9])|(50677[0-8])|(5090[0-9][0-9])|(6277[3-9][0-9])|(6363[0-6][0-9])|(636368)|(6504[0-9][0-9])|(6505[0-4][0-9])|(65054[0-9])|(65055[0-9]))\\d{10}$";
    const VISA_REGEX = "^4[0-9]{12}(?:[0-9]{3})?$";
    const AURA_REGEX = "^(503616)$";
    const MASTERCARD_REGEX = "^5[1-5][0-9]{14}$";
    const BANESCARD_REGEX = "^(6034)[0-9]{12}$";
    const CABAL_REGEX = "^60420[1-9][0-9]{10}$";
    const HIPERCARD_REGEX = "^(606282\\d{10}(\\d{3})?)|(3841\\d{15})$";
    const DISCOVER_REGEX = "^6(?:011|5[0-9]{2})[0-9]{12}$";

    if (cardNumber.match(JCB_REGEX)) {
      return CardBrand.JCB;
    } else if (cardNumber.match(DINERS_REGEX)) {
      return CardBrand.DINERS;
    } else if (cardNumber.match(AMEX_REGEX)) {
      return CardBrand.AMEX;
    } else if (cardNumber.match(ELO_REGEX)) {
      return CardBrand.ELO;
    } else if (cardNumber.match(VISA_REGEX)) {
      return CardBrand.VISA;
    } else if (cardNumber.match(AURA_REGEX)) {
      return CardBrand.AURA;
    } else if (cardNumber.match(MASTERCARD_REGEX)) {
      return CardBrand.MASTERCARD;
    } else if (cardNumber.match(BANESCARD_REGEX)) {
      return CardBrand.BANESCARD;
    } else if (cardNumber.match(CABAL_REGEX)) {
      return CardBrand.CABAL;
    } else if (cardNumber.match(HIPERCARD_REGEX)) {
      return CardBrand.HIPERCARD;
    } else if (cardNumber.match(DISCOVER_REGEX)) {
      return CardBrand.DISCOVER;
    }

    return null;
  }
  
  getInstallmentOptions(): number[] {
    const maxInstallments = this.prod?.maxInstallments || 12; // Use o valor máximo disponível ou um padrão de 12
    return Array.from({ length: maxInstallments }, (_, i) => i + 1);
  }


}
