import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Pageable } from "../../model/pageable";

@Injectable({
  providedIn: 'root'
})
export class AccountServiceV2 {

  private header: HttpHeaders;

  constructor(private http: HttpClient) {
    this.header = new HttpHeaders();
    this.header.set("Access-Control-Allow-Origin", "*");
    this.header.set("Access-Control-Allow-Headers", "Location");
  }

  public getAccounts(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/api/v2/accounts`, { headers: this.header });
  }

  public getBalances(sellerId: any): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/api/v2/accounts/${sellerId}/balances`, { headers: this.header });
  }

  public getReceivables(sellerId: any, pageable: Pageable): Observable<any> {
    let httpParams = this.createHttpParams(pageable);
    return this.http.get<any>(`${environment.apiUrl}/api/v2/accounts/${sellerId}/receivables`, { headers: this.header, params: httpParams });
  }

  public getTransfers(sellerId: any, pageable: Pageable): Observable<any> {
    let httpParams = this.createHttpParams(pageable);
    return this.http.get<any>(`${environment.apiUrl}/api/v2/accounts/${sellerId}/transfers`, { headers: this.header, params: httpParams });
  }

  public getFutureTransfers(sellerId: any, pageable: Pageable): Observable<any> {
    let httpParams = this.createHttpParams(pageable);
    return this.http.get<any>(`${environment.apiUrl}/api/v2/accounts/${sellerId}/future-transfers`, { headers: this.header, params: httpParams });
  }

  private createHttpParams(pageable: Pageable): HttpParams {
    let httpParams = new HttpParams();
    if (pageable) {
      httpParams = httpParams.append('size', pageable.size.toString());
      httpParams = httpParams.append('page', pageable.page.toString());
    }
    return httpParams;
  }
}
