<div class="content container">

  <div fxLayout="column" fxLayoutAlign="center stretch" fxLayout.lt-lg="column" fxLayoutAlign.lt-lg="center stretch"
    fxLayoutGap="20px">
    <div class="card">
      <div class="title">
        <h4>Pagamento da assinatura</h4>
      </div>
      <div class="info-container" fxFlex fxLayout="row" >
        <div>
          <table>
            <tr>
              <td>
                <span fxFlex><strong>Nome do plano</strong>: {{planName}}</span>
              </td>
            </tr>
            <tr>
              <td>
                <span fxFlex><strong>Valor</strong>: {{value | CustomCurrency:'BRL'}}</span>
              </td>
            </tr>
            <tr>
              <td>
                <span *ngIf="!approved" fxFlex> <strong>Data de expiração</strong>: {{dateFormated | date: 'dd/MM/yyyy HH:mm'}}</span>
              </td>
            </tr>
            <tr>
              <td>
                <img *ngIf="!approved" src="{{image}}" class="image-payment" alt="Pix" width="200px" />
              </td>
            </tr>
            <tr>
              <td>
                <img *ngIf="approved" src="..\..\assets\img\qrcode-checked.png" class="image-payment" alt="Pago" />
              </td>
            </tr>
            <tr>
              <td>
                <button *ngIf="!approved" mat-raised-button class="btn-search btn-copy" (click)="getText()"
                  style="width: 200px">{{text}}</button>
              </td>
            </tr>
            <tr>
              <td>
                <span fxFlex class="status" *ngIf="approved" widht="200px">Pago!</span>
              </td>
            </tr>
          </table>
        </div>
        <span *ngIf="!approved" class="vertical-line"></span>
        <div *ngIf="!approved" class="list">
          <ol>
            <li>Abra o app do seu banco ou instituição financeira e entre no ambiente Pix</li>
            <li>Escolha a opção "Pagar com QR Code" e escaneie o código ao lado</li>
            <li>Confirme as informações e finalize a compra</li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</div>