import { Pipe } from "@angular/core";
import { Dds } from "../../model/dds";

// tslint:disable-next-line:use-pipe-transform-interface
@Pipe({
    name: "dds",
    pure: false
})
export class DdsPipe {
    transform(value: Dds, field: string): any {
       return Dds [ value ];
    }
}
