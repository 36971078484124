import { RoleName } from "./role-name";

export const PagePermissions = {
	ADMIN: [
		RoleName[RoleName.ADMIN],
		RoleName[RoleName.USER_ADMIN],
	],
	HOME: [
		RoleName[RoleName.SHOW_DASHBOARD],
		RoleName[RoleName.ADMIN],
		RoleName[RoleName.USER_ADMIN],
	],
	TRANSACTION_NEW: [
		RoleName[RoleName.TRANSACTION_NEW],
		RoleName[RoleName.ADMIN],
		RoleName[RoleName.USER_ADMIN],
	],
	TRANSACTION: [
		RoleName[RoleName.USER_REFUND],
		RoleName[RoleName.TRANSACTION_QUERY],
		RoleName[RoleName.TRANSACTION_QUERY_EXPORT],
		RoleName[RoleName.TRANSACTION_DETAILS],
		RoleName[RoleName.ADMIN],
		RoleName[RoleName.USER_ADMIN],
	],
	TRANSACTION_DETAILS: [
		RoleName[RoleName.USER_REFUND],
		RoleName[RoleName.TRANSACTION_DETAILS],
		RoleName[RoleName.ADMIN],
		RoleName[RoleName.USER_ADMIN],
	],
	PLAN: [
		RoleName[RoleName.PLAN_NEW],
		RoleName[RoleName.PLAN_QUERY],
		RoleName[RoleName.PLAN_DETAILS],
		RoleName[RoleName.PLAN_EDIT],
		RoleName[RoleName.PLAN_DELETE],
		RoleName[RoleName.ADMIN],
		RoleName[RoleName.USER_ADMIN],
	],
	PLAN_DETAILS: [
		RoleName[RoleName.PLAN_DETAILS],
		RoleName[RoleName.PLAN_EDIT],
		RoleName[RoleName.ADMIN],
		RoleName[RoleName.USER_ADMIN],
	],
	PLAN_CREATE: [
		RoleName[RoleName.PLAN_NEW],
		RoleName[RoleName.ADMIN],
		RoleName[RoleName.USER_ADMIN],
	],
	PLAN_EDIT: [
		RoleName[RoleName.PLAN_EDIT],
		RoleName[RoleName.ADMIN],
		RoleName[RoleName.USER_ADMIN],
	],
	CUSTOMER: [
		RoleName[RoleName.CUSTOMER_QUERY],
		RoleName[RoleName.CUSTOMER_DETAILS],
		RoleName[RoleName.CUSTOMER_DELETE],
		RoleName[RoleName.CUSTOMER_EDIT],
		RoleName[RoleName.CUSTOMER_EDIT_CHARGEBACK],
		RoleName[RoleName.CUSTOMER_EDIT_PAYMENT_DELETE],
		RoleName[RoleName.CUSTOMER_EDIT_PAYMENT_EDIT],
		RoleName[RoleName.CUSTOMER_EDIT_PAYMENT_NEW],
		RoleName[RoleName.CUSTOMER_EDIT_PAYMENT_VIEW],
		RoleName[RoleName.CUSTOMER_EDIT_REFUND],
		RoleName[RoleName.CUSTOMER_EDIT_SIGNATURE_CANCEL],
		RoleName[RoleName.CUSTOMER_NEW],
		RoleName[RoleName.ADMIN],
		RoleName[RoleName.USER_ADMIN],
	],
	CUSTOMER_EDIT_DETAILS: [
		RoleName[RoleName.CUSTOMER_DETAILS],
		RoleName[RoleName.CUSTOMER_DELETE],
		RoleName[RoleName.CUSTOMER_EDIT],
		RoleName[RoleName.CUSTOMER_EDIT_CHARGEBACK],
		RoleName[RoleName.CUSTOMER_EDIT_PAYMENT_DELETE],
		RoleName[RoleName.CUSTOMER_EDIT_PAYMENT_EDIT],
		RoleName[RoleName.CUSTOMER_EDIT_PAYMENT_NEW],
		RoleName[RoleName.CUSTOMER_EDIT_PAYMENT_VIEW],
		RoleName[RoleName.CUSTOMER_EDIT_REFUND],
		RoleName[RoleName.CUSTOMER_EDIT_SIGNATURE_CANCEL],
		RoleName[RoleName.CUSTOMER_NEW],
		RoleName[RoleName.ADMIN],
		RoleName[RoleName.USER_ADMIN],
		RoleName[RoleName.TRANSACTION_DETAILS],
	],
	CUSTOMER_EDIT: [
		RoleName[RoleName.CUSTOMER_EDIT],
		RoleName[RoleName.ADMIN],
		RoleName[RoleName.USER_ADMIN],
	],
	CUSTOMER_EDIT_SIGNATURE_CANCEL: [
		RoleName[RoleName.CUSTOMER_EDIT_SIGNATURE_CANCEL],
		RoleName[RoleName.CUSTOMER_EDIT],
		RoleName[RoleName.ADMIN],
		RoleName[RoleName.USER_ADMIN]
	],
	SIGNATURE_CANCEL: [
		RoleName[RoleName.CUSTOMER_EDIT_SIGNATURE_CANCEL],
		RoleName[RoleName.ADMIN],
		RoleName[RoleName.USER_ADMIN]
	],
	CUSTOMER_EDIT_CHARGEBACK: [
		RoleName[RoleName.CUSTOMER_EDIT_CHARGEBACK],
		RoleName[RoleName.CUSTOMER_EDIT],
		RoleName[RoleName.ADMIN],
		RoleName[RoleName.USER_ADMIN]
	],
	CUSTOMER_EDIT_REFUND: [
		RoleName[RoleName.CUSTOMER_EDIT_REFUND],
		RoleName[RoleName.CUSTOMER_EDIT],
		RoleName[RoleName.ADMIN],
		RoleName[RoleName.USER_ADMIN]
	],
	EDIT_REFUND: [
		RoleName[RoleName.CUSTOMER_EDIT_REFUND],
		RoleName[RoleName.ADMIN],
		RoleName[RoleName.USER_ADMIN]
	],
	CUSTOMER_EDIT_REFUND_CHARGEBACK: [
		RoleName[RoleName.CUSTOMER_EDIT_CHARGEBACK],
		RoleName[RoleName.CUSTOMER_EDIT_REFUND],
		RoleName[RoleName.CUSTOMER_EDIT],
		RoleName[RoleName.ADMIN],
		RoleName[RoleName.USER_ADMIN]
	],
	CONVENANT: [
		RoleName[RoleName.CONVENANT_QUERY],
		RoleName[RoleName.CONVENANT_DETAILS],
		RoleName[RoleName.CONVENANT_EDIT],
		RoleName[RoleName.ADMIN],
		RoleName[RoleName.USER_ADMIN]
	],
	CONVENANT_DETAILS: [
		RoleName[RoleName.CONVENANT_DETAILS],
		RoleName[RoleName.CONVENANT_EDIT],
		RoleName[RoleName.ADMIN],
		RoleName[RoleName.USER_ADMIN]
	],
	CONVENANT_EDIT: [
		RoleName[RoleName.CONVENANT_EDIT],
		RoleName[RoleName.ADMIN],
		RoleName[RoleName.USER_ADMIN]
	],
	CONVENANT_CREATE: [
		RoleName[RoleName.CONVENANT_CREATE],
		RoleName[RoleName.ADMIN],
		RoleName[RoleName.USER_ADMIN]
	],
	REPORT_APPROVED_TRANSACTION: [
		RoleName[RoleName.REPORT_APPROVED_TRANSACTION],
		RoleName[RoleName.REPORT_APPROVED_TRANSACTION_EXPORT],
		RoleName[RoleName.ADMIN],
		RoleName[RoleName.USER_ADMIN]
	],
	REPORT_APPROVED_TRANSACTION_EXPORT: [
		RoleName[RoleName.REPORT_APPROVED_TRANSACTION_EXPORT],
		RoleName[RoleName.ADMIN],
		RoleName[RoleName.USER_ADMIN]
	],
	REPORT_ACQUIRER_MESSAGE: [
		RoleName[RoleName.REPORT_ACQUIRER_MESSAGE],
		RoleName[RoleName.REPORT_ACQUIRER_MESSAGE_EXPORT],
		RoleName[RoleName.ADMIN],
		RoleName[RoleName.USER_ADMIN]
	],
	REPORT_ACQUIRER_MESSAGE_EXPORT: [
		RoleName[RoleName.REPORT_ACQUIRER_MESSAGE_EXPORT],
		RoleName[RoleName.ADMIN],
		RoleName[RoleName.USER_ADMIN]
	],
	REPORT_NET_REVENUE: [
		RoleName[RoleName.REPORT_NET_REVENUE],
		RoleName[RoleName.REPORT_NET_REVENUE_EXPORT],
		RoleName[RoleName.ADMIN],
		RoleName[RoleName.USER_ADMIN]
	],
	REPORT_CHURN: [
		RoleName[RoleName.REPORT_CHURN],
		RoleName[RoleName.REPORT_CHURN_EXPORT],
		RoleName[RoleName.ADMIN],
		RoleName[RoleName.USER_ADMIN]
	],
	REPORT_CHURN_EXPORT: [
		RoleName[RoleName.REPORT_CHURN_EXPORT],
		RoleName[RoleName.ADMIN],
		RoleName[RoleName.USER_ADMIN]
	],
	REPORT_ORDER: [
		RoleName[RoleName.REPORT_ORDER],
		RoleName[RoleName.ADMIN],
		RoleName[RoleName.USER_ADMIN]
	],
	REPORT_SUBSCRIPTION: [
		RoleName[RoleName.REPORT_SUBSCRIPTION],
		RoleName[RoleName.ADMIN],
		RoleName[RoleName.USER_ADMIN]
	],
	REPORT_SUBSCRIPTION_EXPORT: [
		RoleName[RoleName.REPORT_SUBSCRIPTION_EXPORT],
		RoleName[RoleName.ADMIN],
		RoleName[RoleName.USER_ADMIN]
	],
	REPORT_CHARGE_EXPORT: [
		RoleName[RoleName.REPORT_CHARGE_EXPORT],
		RoleName[RoleName.ADMIN],
		RoleName[RoleName.USER_ADMIN]
	],
	REPORT_CHARGE: [
		RoleName[RoleName.REPORT_CHARGE],
		RoleName[RoleName.ADMIN],
		RoleName[RoleName.USER_ADMIN]
	],
	REPORT_TRANSACTION: [
		RoleName[RoleName.REPORT_TRANSACTION],
		RoleName[RoleName.ADMIN],
		RoleName[RoleName.USER_ADMIN]
	],
	REPORT_TRANSACTION_EXPORT: [
		RoleName[RoleName.REPORT_TRANSACTION_EXPORT],
		RoleName[RoleName.ADMIN],
		RoleName[RoleName.USER_ADMIN]
	],
	PERFIL_CREATE: [
		RoleName[RoleName.PERFIL_NEW],
		RoleName[RoleName.ADMIN],
		RoleName[RoleName.USER_ADMIN],
	],
	PERFIL_EDIT: [
		RoleName[RoleName.PERFIL_EDIT],
		RoleName[RoleName.ADMIN],
		RoleName[RoleName.USER_ADMIN],
	],
	PAGE_PERMISSIONS: [
		RoleName[RoleName.PERFIL_NEW],
		RoleName[RoleName.PERFIL_QUERY],
		RoleName[RoleName.PERFIL_EDIT],
		RoleName[RoleName.ADMIN],
		RoleName[RoleName.USER_ADMIN]
	],
	USER_PERMISSIONS: [
		RoleName[RoleName.USER_QUERY],
		RoleName[RoleName.USER_NEW],
		RoleName[RoleName.USER_EDIT],
		RoleName[RoleName.ADMIN],
		RoleName[RoleName.USER_ADMIN]
	],
	USER_EDIT: [
		RoleName[RoleName.USER_EDIT],
		RoleName[RoleName.ADMIN],
		RoleName[RoleName.USER_ADMIN]
	],
	USER_NEW: [
		RoleName[RoleName.USER_NEW],
		RoleName[RoleName.ADMIN],
		RoleName[RoleName.USER_ADMIN]
	],
	PAGE_PAY_CREATE: [
		RoleName[RoleName.PAGE_PAY_NEW],
		RoleName[RoleName.ADMIN],
		RoleName[RoleName.USER_ADMIN]
	],
	PAGE_PAY_EDIT: [
		RoleName[RoleName.PAGE_PAY_EDIT],
		RoleName[RoleName.ADMIN],
		RoleName[RoleName.USER_ADMIN]
	],
	PAGE_PAY: [
		RoleName[RoleName.PAGE_PAY_QUERY],
		RoleName[RoleName.PAGE_PAY_NEW],
		RoleName[RoleName.PAGE_PAY_EDIT],
		RoleName[RoleName.ADMIN],
		RoleName[RoleName.USER_ADMIN]
	],
	IMPORT_WALLET: [
		RoleName[RoleName.IMPORT_WALLET],
		RoleName[RoleName.ADMIN],
		RoleName[RoleName.USER_ADMIN]
	],
	WHITE_LIST_NEW: [
		RoleName[RoleName.WHITELIST_NEW],
		RoleName[RoleName.ADMIN],
		RoleName[RoleName.USER_ADMIN]
	],
	WHITE_LIST: [
		RoleName[RoleName.WHITELIST_NEW],
		RoleName[RoleName.WHITELIST_QUERY],
		RoleName[RoleName.ADMIN],
		RoleName[RoleName.USER_ADMIN]
	],
	CARD: [
		RoleName[RoleName.CARD_NEW],
		RoleName[RoleName.CARD_EDIT],
		RoleName[RoleName.ADMIN],
		RoleName[RoleName.USER_ADMIN]
	],
	CARD_NEW: [
		RoleName[RoleName.CARD_NEW],
		RoleName[RoleName.ADMIN],
		RoleName[RoleName.USER_ADMIN]
	],
	CARD_EDIT: [
		RoleName[RoleName.CARD_EDIT],
		RoleName[RoleName.ADMIN],
		RoleName[RoleName.USER_ADMIN]
	],
	CARD_QUERY: [
		RoleName[RoleName.CARD_QUERY],
		RoleName[RoleName.ADMIN],
		RoleName[RoleName.USER_ADMIN]
	],
	TRANSACTION_PARCEL: [
		RoleName[RoleName.TRANSACTION_QUERY],
		RoleName[RoleName.ADMIN],
		RoleName[RoleName.USER_ADMIN]
	],
	SUBSCRIPTIONS_QUERY: [
		RoleName[RoleName.SUBSCRIPTIONS_QUERY],
		RoleName[RoleName.ADMIN],
		RoleName[RoleName.USER_ADMIN]
	],
	WALLET_IMPORT_QUERY: [
		RoleName[RoleName.WALLET_IMPORT_QUERY],
		RoleName[RoleName.ADMIN],
		RoleName[RoleName.USER_ADMIN]
	],
	CANCEL_IMPORT_ORDERS: [
		RoleName[RoleName.CANCEL_IMPORT_ORDERS],
		RoleName[RoleName.ADMIN],
		RoleName[RoleName.USER_ADMIN]
	],
	AFFILIATE_QUERY: [
		RoleName[RoleName.AFFILIATE_CREATE],
		RoleName[RoleName.AFFILIATE_EDIT],
		RoleName[RoleName.AFFILIATE_QUERY],
		RoleName[RoleName.USER_ADMIN],
		RoleName[RoleName.ADMIN]
	],
	AFFILIATE_EDIT: [
		RoleName[RoleName.AFFILIATE_EDIT],
		RoleName[RoleName.USER_ADMIN],
		RoleName[RoleName.ADMIN]
	],
	AFFILIATE_CREATE: [
		RoleName[RoleName.AFFILIATE_CREATE],
		RoleName[RoleName.USER_ADMIN],
		RoleName[RoleName.ADMIN]
	],
	CANCELATION_REASON_QUERY: [
		RoleName[RoleName.CANCELATION_REASON_QUERY],
		RoleName[RoleName.CANCELATION_REASON_EDIT],
		RoleName[RoleName.CANCELATION_REASON_CREATE],
		RoleName[RoleName.USER_ADMIN],
		RoleName[RoleName.ADMIN]
	],
	CANCELATION_REASON_CREATE: [
		RoleName[RoleName.CANCELATION_REASON_CREATE],
		RoleName[RoleName.ADMIN],
		RoleName[RoleName.USER_ADMIN]
	],
	CANCELATION_REASON_EDIT: [
		RoleName[RoleName.CANCELATION_REASON_EDIT],
		RoleName[RoleName.ADMIN],
		RoleName[RoleName.USER_ADMIN]
	],
	CANCELATION_SALES_DETAIL: [
		RoleName[RoleName.CUSTOMER_EDIT_CHARGEBACK],
		RoleName[RoleName.CUSTOMER_EDIT],
		RoleName[RoleName.ADMIN],
		RoleName[RoleName.USER_ADMIN],
		RoleName[RoleName.TRANSACTION_DETAILS],
		RoleName[RoleName.USER_REFUND],
	]
}
