<div class="container mb-4">

  <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>

  <div fxLayout="column" fxLayoutGap="10px" style="margin-bottom: 10px;">
    <div fxLayout="row">
      <div fxFlex fxLayout="row" fxLayoutAlign="start center">
        <p-breadcrumb id="breadcrumb" [model]="crumbs"></p-breadcrumb>
      </div>
      <div fxFlex fxLayout="row" fxLayoutAlign="end center">
        <!-- <button type="button" class="btn btn-primary" name="newLink" [routerLink]="['new']">+ Novo</button> -->
      </div>
    </div>
  </div>

  <!-- Filter begin -->
  <div class="card mb-0 shadow-none" fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="10">
    <div fxLayout='column' fxFlex fxLayoutAlign="start stretch" fxLayoutAlign.lt-md="space-around stretch"
      fxLayoutGap="10">
      <!-- Primeira linha de filtros -->
      <div fxLayout='row' fxLayoutAlign="start stretch" fxLayout.lt-md="column"
        fxLayoutAlign.lt-md="space-around stretch" fxLayoutGap="10">
        <div fxLayout='column' fxLayoutGap="10" fxFlex="30">
          <mat-slide-toggle [(ngModel)]="enableDueDate">Data do vencimento</mat-slide-toggle>
          <div fxLayout='row' fxLayoutGap="5" *ngIf="enableDueDate">
            <mat-form-field fxFlex="50">
              <input matInput [matDatepicker]="startDueDateFF" readonly (click)="startDueDateFF.open()"
                (dateChange)="limitDate()" [(ngModel)]="startDueDate" placeholder="Início:" [max]="endDueDate">
              <mat-datepicker-toggle matSuffix [for]="startDueDateFF"></mat-datepicker-toggle>
              <mat-datepicker #startDueDateFF></mat-datepicker>
            </mat-form-field>
            <mat-form-field fxFlex="50">
              <input matInput [matDatepicker]="endDueDateFF" readonly (click)="endDueDateFF.open()"
                (dateChange)="limitDate()" [(ngModel)]="endDueDate" placeholder="Fim:" [min]="startDueDate"
                [max]="limitDueDate">
              <mat-datepicker-toggle matSuffix [for]="endDueDateFF"></mat-datepicker-toggle>
              <mat-datepicker #endDueDateFF></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="errors center-text" *ngIf="!dueDateValid">
            <span>Favor selecionar um período máximo de 90 dias</span>
          </div>
        </div>
        <div fxLayout='column' fxLayoutGap="10" fxFlex="30">
          <mat-slide-toggle [(ngModel)]="enablePaymentDate">Data do pagamento</mat-slide-toggle>
          <div fxLayout='row' fxLayoutGap="5" *ngIf="enablePaymentDate">
            <mat-form-field fxFlex="50">
              <input matInput [matDatepicker]="startPaymentDateFF" readonly (click)="startPaymentDateFF.open()"
                (dateChange)="limitDate()" [(ngModel)]="startPaymentDate" placeholder="Início:" [max]="endPaymentDate">
              <mat-datepicker-toggle matSuffix [for]="startPaymentDateFF"></mat-datepicker-toggle>
              <mat-datepicker #startPaymentDateFF></mat-datepicker>
            </mat-form-field>
            <mat-form-field fxFlex="50">
              <input matInput [matDatepicker]="endPaymentDateFF" readonly (click)="endPaymentDateFF.open()"
                (dateChange)="limitDate()" [(ngModel)]="endPaymentDate" placeholder="Fim:" [min]="startPaymentDate"
                [max]="limitPaymentDate">
              <mat-datepicker-toggle matSuffix [for]="endPaymentDateFF"></mat-datepicker-toggle>
              <mat-datepicker #endPaymentDateFF></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="errors center-text" *ngIf="!PaymentDateValid">
            <span>Favor selecionar um período máximo de 90 dias</span>
          </div>
        </div>
      </div>
      <!-- Segunda linha de filtros -->
      <div fxLayout='row' fxLayoutAlign="space-between stretch" fxLayout.lt-md="column"
        fxLayoutAlign.lt-md="space-around stretch" fxLayoutGap="10">
        <div fxFlex="40">
          <mat-form-field fxFlex>
            <input matInput [(ngModel)]="filters.searchField" placeholder="Busque por nome, CPF ou e-mail">
          </mat-form-field>
        </div>
        <div fxFlex="65">
          <mat-form-field fxFlex>
            <input matInput [(ngModel)]="filters.transactionSearch"
              placeholder="Busque por TID, NSU, Cod. da transação, Cod. de ref. do pedido ou n. de autorização">
          </mat-form-field>
        </div>
      </div>
      <!-- Terceira linha de filtros -->
      <div fxLayout='row' fxLayoutAlign="space-between stretch" fxLayout.lt-md="column"
        fxLayoutAlign.lt-md="space-around stretch" fxLayoutGap="10">
        <div fxFlex>
          <mat-form-field fxFlex>
            <mat-select placeholder="Status" #select multiple [(ngModel)]="filters.status">
              <mat-option #allSelected (click)="toggleAllSelection()" value="">Todos</mat-option>
              <mat-divider></mat-divider>
              <mat-option (click)="optionClick()" *ngFor="let status of listaStatus" [value]="status">
                {{status | translate}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div fxFlex>
          <form [formGroup]="searchAffiliateForm">
            <mat-form-field fxFlex>
              <mat-select class="opcoesFiltro" placeholder="Loja" formControlName="orderAffiliate"
                [compareWith]="compareId" multiple [(ngModel)]="filters.affiliateIDs">
                <mat-option #allSelectedForAffiliate (click)="toggleAllSelectionForAffiliate()" [value]="0">Todos
                </mat-option>
                <mat-option *ngFor="let affiliate of affiliate" [value]="affiliate.id"
                  (click)="tosslePerOneForAffiliate(allSelectedForAffiliate.viewValue)">
                  {{affiliate.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </form>
        </div>
        <div fxFlex>
          <mat-form-field fxFlex>
            <mat-select placeholder="Método de pagamento" id="paymentMethod" [(ngModel)]="filters.paymentMethod">
              <mat-option value="">Todos</mat-option>
              <mat-option value="1">Cartão de crédito</mat-option>
              <mat-option value="2">Pix</mat-option>
              <mat-option value="3">Boleto</mat-option>
              <mat-option value="4">Transferência</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <!-- Quarta linha de filtros -->
      <div fxLayout='row' fxLayoutAlign="start stretch" fxLayout.lt-md="column"
        fxLayoutAlign.lt-md="space-around stretch" fxLayoutGap="10">
        <div fxLayout='column' fxLayoutGap="10" fxFlex>
          <mat-slide-toggle [(ngModel)]="enableMetadata" (click)="cleanMetadata()">Metadados</mat-slide-toggle>
          <div fxLayout='row' fxLayoutGap="5" *ngIf="enableMetadata">
            <mat-form-field fxFlex>
              <input matInput maxlength="250" [(ngModel)]="filters.metadataValue" placeholder="Valor"
                id="metadataValue">
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="end center">
      <button mat-raised-button class="btn-search" (click)="filterParam()">
        Pesquisar
      </button>
    </div>
  </div>
  <!-- Filter end -->

  <!-- Datasource -->
  <div class="shadow-none" [ngClass]="{'hide' : pageResponse.totalElements == 0}" style="margin-top: 10px;">
    <div class="div-table">

      <div class="export">
        <a class="export-link" *ngIf="showExportBtn" (click)="exportToExcel()">
          <span class="export-label">Exportar</span>
          <i class="fa fa-arrow-circle-down" aria-hidden="true"></i>
        </a>
      </div>

      <table mat-table [dataSource]="transactions" matSort (matSortChange)="handleSort($event)">
        <ng-container matColumnDef="orderDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Data do vencimento </th>
          <td mat-cell *matCellDef="let row"> {{row.dueDate | date: 'dd/MM/yyyy'}} </td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>Status</th>
          <td mat-cell *matCellDef="let row">
            <span class="badge" [ngClass]="getStatusColor(row.lastTransaction.status)">{{row.lastTransaction.status |
              translate}}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="client">
          <th mat-header-cell *matHeaderCellDef>Cliente</th>
          <td mat-cell *matCellDef="let row">{{row.customer.name | translate}}</td>
        </ng-container>
        <ng-container matColumnDef="installments">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Modalidade </th>
          <td mat-cell *matCellDef="let row">
            <div *ngIf="row.lastTransaction.paymentMethod == 1 && !has2Cards(row)">
              <img style="height: 22px; padding-right: 3px;" src="assets/img/credit-card.png"> Cartão de crédito
            </div>
            <div *ngIf="row.lastTransaction.paymentMethod==2" class="bandeira">
              <img style="height: 22px; padding-right: 3px;" src="assets/img/pagamentos/simbolo-pix.svg"> PIX
            </div>
            <div *ngIf="row.lastTransaction.paymentMethod==3" class="bandeira">
              <img style="height: 22px; padding-right: 3px;" src="assets/img/pagamentos/itau-boleto.svg"> Boleto
            </div>
            <div *ngIf="row.lastTransaction.paymentMethod==4" class="bandeira">
              <img style="height: 22px; padding-right: 3px;" src="assets/img/pagamentos/setas.svg"> Transferência
            </div>
            <div *ngIf="has2Cards(row)" class="bandeira">
              <img style="height: 22px; padding-right: 3px;" src="assets/img/2cards.png"> 2 Cartões de crédito
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="value">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Valor </th>
          <td mat-cell *matCellDef="let row"> {{ row.value | CustomCurrency:'BRL' }} </td>
        </ng-container>
        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo </th>
          <td mat-cell *matCellDef="let row"> {{row.order.type == "RECURRENT" ? 'Assinatura' : 'Venda'}}</td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <div *ngxPermissionsOnly="transactionDetailPermission; else dontHavePermission; then hasPermission"></div>
        <ng-template #dontHavePermission>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </ng-template>
        <ng-template #hasPermission>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="hover-row" matTooltipPosition="above"
            matTooltip="Clique para visualizar os detalhes" [routerLink]="['/sales/sales-details/', row.id]"></tr>
        </ng-template>
      </table>
    </div>
    <mat-paginator [length]="pageResponse.totalElements" [pageSize]="pageResponse.pageSize"
      (page)="pageEvent = handlePage($event)" aria-label="Select page">
    </mat-paginator>
  </div>
  <div [ngClass]="{'hide' : pageResponse.totalElements > 0}" class="no-data-message">
    <span>Nenhuma venda encontrada</span>
  </div>
</div>
