import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Router } from "@angular/router";
import { BempaggoConstants } from "src/app/core/helper/string-utils";
import { PlanPutRequest, PlanResponse } from "src/app/core/model/v1.model";
import { AlertService } from "src/app/core/services/alert.service";
import { ImageUploadService } from "src/app/core/services/v1/image-upload.service";
import { PlanServiceV1 } from "src/app/core/services/v1/plan.service.v1";

@Component({
  selector: 'app-plan-edit',
  templateUrl: './plan-edit.component.html',
  styleUrls: ['./plan-edit.component.scss']
})
export class PlanEditComponent implements OnInit {

  @ViewChild("planForm", {static: true}) form: NgForm;
  @Input()
  plan: PlanResponse;
  alphanumericPattern;
  name:string;
  sku:string;
  description:string;
  softDescriptor:string;
  croppedImage: Blob;

  constructor(
    private router: Router,
    private alertService: AlertService,
    private planService: PlanServiceV1,    
    private imageUploadService: ImageUploadService
  ) { }

  ngOnInit() {
    this.name = this.plan.name;
    this.sku = this.plan.sku;
    this.softDescriptor = this.plan.softDescriptor;
    this.alphanumericPattern = BempaggoConstants.PATTERN_ALPHANUMERIC;
    this.imageUploadService.imageCrop.subscribe(result => this.croppedImage = result);
  }

  goBack() {
    this.planService.editPlan.emit(true);
  }

  update() {
    if (this.form.valid) {
      let request = this.form.value as PlanPutRequest;
      this.planService.update(this.plan, request).subscribe(
        result => {
          this.plan.name = this.name;
          this.plan.sku = this.sku;
          this.plan.softDescriptor = this.softDescriptor;
          this.uploadImage(this.plan.paymentlink.id);
          this.goBack();
        }, error => {
          this.alertService.handleError(error);
        }
      );
    }
  }

  uploadImage(planResponseId: number) {
    if (this.croppedImage) {
        this.planService.uploadImage(planResponseId, this.croppedImage).subscribe(
            () => {
                this.goBack();
            },
            error => {
                this.alertService.handleError(error);
            }
        );
    }
}

}