
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { CustomerImportService } from "../services/v0/customer-import.service";

@Injectable({
    providedIn: 'root'
})
export class CustomerImportStatusHelper {
    status:any[] = [];

    constructor(private customerImportService:CustomerImportService , private translate: TranslateService){
        this.customerImportService.getStatus().then(data => {
            this.status = data.status;
          });
    }

    getCustomerImportStatusColor(status):string {
        for (let i=0; i < this.status.length; i++) {
          if(this.translate.instant(status) === this.status[i].name) {
            return this.status[i].colorClass;
          }
        }
         return "bg-gray";
       }
}
