import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from "src/environments/environment";
import { tap } from "rxjs/operators";
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { AlertService } from '../services/alert.service';
 
@Injectable({
    providedIn: 'root'
})
export class JwtInterceptor implements HttpInterceptor {
    isLoggedIn$: BehaviorSubject<boolean>;

    constructor(private router:Router, private auth:AuthService, private alertService:AlertService) {
        this.isLoggedIn$ = this.auth.getLoggedIn;
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (currentUser && currentUser.accessToken && request.url.indexOf(environment.apiUrl) > -1) {
            request = request.clone({
                setHeaders: { 
                    Authorization: `Bearer ${currentUser.accessToken}`
                }
            });
        }
 
        return next.handle(request).pipe(tap((event:HttpEvent<any>) => {
            // 
        }, 
        (error:any) => {
            if (error.status === 401 && this.isLoggedIn$.value) {
                this.alertService.error("SESSÃO EXPIRADA", "A sua sessão expirou! Por favor faça o login novamente!").then(()=>{
                    this.auth.logout();
                });
                //this.router.navigate(["/login"]);
            }
        }));
    }
}