import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { ApprovedTransactionFilter, TransactionByReasonFilter } from '../../common/filters/approved-transactions.filter';
import { RefundTransactionFilter } from '../../common/filters/refund-transactions.filter';
import { TransactionsReportFilter } from '../../common/filters/transactions-report.filter';
import { cancelationReasonOrderReportFiltertoHttpParams, filtertoHttpParams, getParamsApprovedTransactions, getParamsTransactionReport } from "../../helper/http.helper";
import { AcquirerTransactionCodeDto } from '../../model/acquirer-transaction-code-dto';
import { Pageable } from '../../model/pageable';
import { CancelationReasonReportFilter, CancelationReasonReportResponse } from "../../model/v1.model";

@Injectable({
    providedIn: 'root'
})
export class ReportServiceV1 {

    private readonly baseUrl = `${environment.apiUrl}/api/v1/reports`;

    constructor(private http: HttpClient) {
    }

    reportAprovadas(filter: ApprovedTransactionFilter): Observable<any> {
        let params = {
            dateStart: filter.startDate.getTime(),
            dateEnd: filter.endDate.getTime(),
        }
        return this.http.get<any>(`${this.baseUrl}/approved-transactions`, { params: params });
    }

    public getCancelationReasonReport(filter: CancelationReasonReportFilter): Observable<CancelationReasonReportResponse> {
        return this.http.get<CancelationReasonReportResponse>(`${this.baseUrl}/orders/cancelation/reason`, cancelationReasonOrderReportFiltertoHttpParams(filter));
    }
  
    public getTransactionReport(filter: TransactionsReportFilter, pageable: Pageable) {
        let params = getParamsTransactionReport(filter);
        params = params.set('size', pageable.size.toString());
        params = params.set('page', pageable.page.toString());
        return this.http.get<any>(`${this.baseUrl}/transactions`, { params: params });
    }

    public getTransactionReportCsv(filter: TransactionsReportFilter) {
        let params = getParamsTransactionReport(filter);
        return this.http.get<any>(`${this.baseUrl}/transactions/csv`, { params: params, responseType: 'blob' as 'json' });
    }

    public reportStatusAdquirente(filter: TransactionByReasonFilter): Observable<AcquirerTransactionCodeDto[]> {
        return this.http.get<any>(`${this.baseUrl}/transactions-by-reason`, getParamsApprovedTransactions(filter));
    }

    public getRelatorioLiquido(): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/revenue`);
    }

    public downloadCsvReportRefunds(filters: RefundTransactionFilter) {
        return this.http.get(`${this.baseUrl}/refunds/csv`, { ...filtertoHttpParams(filters), responseType: 'blob' as 'json' });
    }

    public reportEstornos(filter: RefundTransactionFilter, pageable: Pageable): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/refunds`, filtertoHttpParams(filter, pageable));
    }
}
