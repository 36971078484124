import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OnWebhookV2 {
  private header: HttpHeaders;

  constructor(private http: HttpClient) {
    this.header = new HttpHeaders();
    this.header.set("Access-Control-Allow-Origin", "*");
    this.header.set("Access-Control-Allow-Headers", "Location");
  }

  public WebhookService(): OnWebhookV2 {
    return new OnWebhookV2(this.http);
  }

  public resendWebhook(id: number): any {
    return this.http.post<any>(`${environment.apiUrl}/api/v2/events/${id}`, { headers: this.header });
  }
}
