<mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
<div class="container" *ngIf="!loading">
  <div fxLayout="column" fxLayoutGap="10px" style="margin-bottom: 10px;">
    <div fxLayout="row">
      <div fxFlex fxLayout="row" fxLayoutAlign="start center">
        <p-breadcrumb id="breadcrumb" [model]="crumbs"></p-breadcrumb>
      </div>
      <div fxFlex fxLayout="row" fxLayoutAlign="end center">
        <button *ngxPermissionsOnly="establishmentCreatePermission" mat-raised-button class="btn-edit"
          name="newCovenant" type="button" (click)="establishmentCreate()">+ Novo
          Estabelecimento</button>
      </div>
    </div>
  </div>
  <!-- Filter begin -->
  <div name="filter" class="card mb-0 shadow-none" fxLayout="row" fxLayout.lt-md="column"
    fxLayoutAlign="space-between stretch">
    <div fxFlex fxLayout='column' fxLayoutAlign="start stretch">
      <div name="filterLine2" fxLayout='row' fxLayoutAlign="start stretch" fxLayout.lt-md="column"
        fxLayoutAlign.lt-md="space-around stretch" fxLayoutGap="10px">
        <div fxFlex>
          <!-- Affiliate -->
          <!-- <div fxFlex fxLayout="column">
            <div fxLayout="row">
              <mat-form-field>
                <mat-select placeholder="Loja" [(ngModel)]="transacaoData.affiliateId" formControlName="loja" required>
                  <mat-option *ngFor="let affiliate of affiliates" [value]="affiliate.id">
                    {{affiliate.name}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div> -->

        </div>
        <div fxFlex class="check-type-ec">
          <mat-checkbox [(ngModel)]="filters.ecInativos" [value]="true">Exibir Todos</mat-checkbox>
        </div>
      </div>
    </div>
    <div name="btnSearch" fxLayout="row" fxLayoutAlign="end center">
      <button mat-raised-button class="btn-search" nome="searchButton" (click)="getAllEcs()">Pesquisar</button>
    </div>
  </div>

  <!-- DataTable begin -->
  <div class="shadow-none" *ngIf="showTable" style="margin-top: 10px;">
    <div class="div-table">
      <table mat-table name="covenantTable" [dataSource]="ecsData" matSort>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef> Status </th>
          <td mat-cell *matCellDef="let row">
            <span *ngIf="row.ativo" class="badge bg-green">Ativo </span>
            <span *ngIf="!row.ativo" class="badge bg-gray">Inativo </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="cnpj">
          <th mat-header-cell *matHeaderCellDef> Filial </th>
          <td mat-cell *matCellDef="let row">
            <div fxLayout='row' fxLayoutGap="10px">
              <img class="adquirente" src="assets/img/acquirers/{{row.idAdquirente}}.svg" />
              <div fxLayout='column'>
                <span class="ec-name">{{row.nomeFantasia}}</span>
                <span>{{row.codEstabelecimento}}</span>
              </div>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="ec">
          <th mat-header-cell *matHeaderCellDef>Afiliação (EC)</th>
          <td mat-cell *matCellDef="let row" class="clickable" (click)="establishmentDetail(row)">
            {{row.codEstabelecimento}}</td>
        </ng-container>

        <ng-container matColumnDef="prioridade">
          <th mat-header-cell *matHeaderCellDef> Prioridade </th>
          <td mat-cell *matCellDef="let row">
            {{ getPriorityLabel(row.prioridade) }}
          </td>
        </ng-container>

        <ng-container matColumnDef="LOOSE">
          <th mat-header-cell *matHeaderCellDef style="width: 160px;"> Avulsas </th>
          <td mat-cell *matCellDef="let row" style="width: 160px;">
            <div fxLayout="row" fxLayoutAlign="start center">
              <div class="row g-0">
                <div class="col-6 p-2" *ngFor="let cardBrand of row.looseSettings">
                  <img name="imgAcquirer" style="max-width: 20px;"
                    src="assets/img/brands/{{getCardBrandNameById(cardBrand.cardBrandId).toLowerCase()}}.png" />
                </div>
              </div>

            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="ACCESSION">
          <th mat-header-cell *matHeaderCellDef style="width: 160px;"> Novas assinaturas </th>
          <td mat-cell *matCellDef="let row" style="width: 160px;">
            <div fxLayout="row" fxLayoutAlign="start center">
              <div class="row">
                <div class="col-6 p-2" *ngFor="let cardBrand of row.accessionSettings">
                  <img name="imgAcquirer" style="max-width: 20px;"
                    src="assets/img/brands/{{getCardBrandNameById(cardBrand.cardBrandId).toLowerCase()}}.png" />
                </div>
              </div>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="RECURRENT">
          <th mat-header-cell *matHeaderCellDef style="width: 160px;"> Recorrência <th>
          <td mat-cell *matCellDef="let row" style="width: 160px;">
            <div class="row g-0">
              <div class="col-6 p-2" *ngFor="let cardBrand of row.recurrentSettings">
                <div fxLayout="row" fxLayoutAlign="start center">
                  <img name="imgAcquirer" style="max-width: 20px;"
                    src="assets/img/brands/{{getCardBrandNameById(cardBrand.cardBrandId).toLowerCase()}}.png" />
                </div>
              </div>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns;"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="example-element-row" class="clickable"
          (click)="establishmentDetail(row)"></tr>

      </table>
    </div>
  </div>
  <div [ngClass]="{'hide' : ecsData.data && ecsData.data.length}" class="no-data-message">
    <span>Nenhum Estabelecimento cadastrado</span>
  </div>
</div>
