import { Directive, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { PageResponse } from 'src/app/core/model/page-response';
import { Pageable } from 'src/app/core/model/pageable';
import { AccountServiceV2 } from 'src/app/core/services/v2/account.service.v2';

@Directive()
export abstract class BaseTableComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @Input() sellerId: any;
  data: MatTableDataSource<any>;
  pageEvent: PageEvent;
  pageResponse: PageResponse;
  displayedColumns: string[];
  public pageable: Pageable;
  
  constructor(protected accountService: AccountServiceV2) { }

  abstract loadData(sellerId: any): void;

  ngOnInit(): void {
    this.pageResponse = {
      pageNumber: 0,
      totalPages: 0,
      pageSize: 20,
      totalElements: 0,
      content: null
    };
    this.pageable = { page: 0, size: 20 };
    this.loadData(this.sellerId);
  }

  public handlePage(event?: PageEvent) {
    this.pageable.page = event.pageIndex;
    this.loadData(this.sellerId);
    return event;
  }

  public handleSort(event?: any) {
    this.pageable = { page: 0, size: 5 };
    this.pageable = Object.assign({}, this.pageable, { sort: `${event.active},${event.direction}` });
    this.loadData(this.sellerId);
  }
}
