export class SalesDetailsResponse {
    id : number;
    amount : number;
    installments : number;
    status : string;
    type : string;
    payment : any;
    history : any;
    paymentDate: Date;
    pagepay: any;
}