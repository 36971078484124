import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { CardBrandResponse, PlanResponse } from "../../model/v1.model";

@Injectable({
  providedIn: 'root'
})
export class CardServiceV1 {

  constructor(private http: HttpClient) {
  }

  public getBrandsWithoutPlan(token: string): Observable<CardBrandResponse[]> {
    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };

    return this.http.get<CardBrandResponse[]>(`${environment.apiUrl}/api/v1/cards/brands/loose`, options);
  }

  public getBrandsByPlanWithToken(plan: PlanResponse, token: string): Observable<CardBrandResponse[]> {
    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    if (plan.type.toString() == "RECURRENT") {
      return this.http.get<CardBrandResponse[]>(`${environment.apiUrl}/api/v1/cards/brands/accession`, options);
    } else {
      return this.http.get<CardBrandResponse[]>(`${environment.apiUrl}/api/v1/cards/brands/loose`, options);
    }
  }

  public validCardWithToken(number: string, token: string): Observable<Boolean> {
    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.get<Boolean>(`${environment.apiUrl}/api/v1/cards/number/${number}/valid`, options);
  }

  public getCard(number: string, token: string): Observable<Boolean> {
    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.get<Boolean>(`${environment.apiUrl}/api/v1/cards/number/${number}/valid`, options);
  }


}