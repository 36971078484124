import { Component, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, NgForm } from "@angular/forms";
import { Router } from "@angular/router";
import { MenuItem } from "primeng/api";
import { BempaggoConstants } from "src/app/core/helper/string-utils";
import { AffiliateMinimalResponse, PagePayRequest, PagePayResponse, PlanResponse } from "src/app/core/model/v1.model";
import { AlertService } from "src/app/core/services/alert.service";
import { AffiliateServiceV1 } from "src/app/core/services/v1/affiliate.service.v1";
import { ImageUploadService } from "src/app/core/services/v1/image-upload.service";
import { PagePayServiceV1 } from "src/app/core/services/v1/pagepay.service.v1";

@Component({
    selector: 'app-new-pagepay',
    templateUrl: './new-pagepay.component.html',
    styleUrls: ['./new-pagepay.component.scss']
})

export class NewPagepayComponent implements OnInit {
    @ViewChild("pagepayForm", {static: true}) form: NgForm;
    plans: PlanResponse[];
    pagepay: PagePayRequest
    colors: any[];
    crumbs: MenuItem[];
    saleType: number;
    hasNotSaleLimit: boolean;
    hasNotExpiration: boolean;
    installments: number[];
    croppedImage: Blob;
    affiliates: AffiliateMinimalResponse[];
    searchAffiliateForm: UntypedFormGroup;

    constructor(
        private router: Router,
        private alertService: AlertService,
        private pagepayService: PagePayServiceV1,
        private imageUploadService: ImageUploadService,
        private affiliateService: AffiliateServiceV1,
        private formBuilder: UntypedFormBuilder,
    ) { }

    ngOnInit() {
        this.saleType = 1;
        this.hasNotSaleLimit = false;
        this.hasNotExpiration = false;
        this.pagepay = new PagePayRequest();
        this.pagepay.addressRequired = true;
        this.pagepay.birthdateRequired = false;
        this.pagepay.sendEmail = false;
        this.installments = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
        this.imageUploadService.imageCrop.subscribe(result => this.croppedImage = result);
        this.pagepay.maxInstallments = 1;
        this.initCrumbs();
        this.affiliateService.getAll().subscribe(result => this.affiliates = result);
        this.searchAffiliateForm = this.formBuilder.group({
        orderAffiliate: new UntypedFormControl('')
    });
    }

    public initCrumbs() {
        this.crumbs = [
            { label: 'Link de Pagamento', routerLink: ['/paymentLinks'] },
            { label: 'Novo Link de Pagamento' }
        ];
    }

    goForward(response: PagePayResponse) {
        this.router.navigate(['paymentLinks', response.id]);
    }

    goCancel() {
        this.router.navigate(['paymentLinks']);
    }

    uploadImage(pagepayResponse: PagePayResponse) {
        if (this.croppedImage) {
            this.pagepayService.uploadImage(pagepayResponse.id, this.croppedImage).subscribe(
                result => {
                    this.goForward(result);
                },
                error => {
                    this.alertService.handleError(error);
                }
            );
        }
    }

    create() {
        if (this.form.valid) {
            let request = this.form.value as PagePayRequest;
            request.addressRequired = true;
            request.limit = BempaggoConstants.JAVA_MAX_INT;
            this.pagepayService.createWithoutPlan(request).subscribe(
                result => {
                    this.pagepayService.getByUri(result.headers.get('Location')).subscribe(
                        result => {
                            this.uploadImage(result);
                            this.goForward(result);
                        },
                        error => {
                            this.alertService.handleError(error);
                        }
                    );
                }, error => {
                    this.alertService.handleError(error);
                }
            );
        }
    }
}