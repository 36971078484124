import * as moment from 'moment';
export interface DataCard {
    display?: string;
    text: string;
    value:any; 
    icon?: string; 
    bgcard?: string;
    mask?: boolean;
    order?:number;
    total?:number|boolean;
    isMyAccount?:boolean;
    textMinor?: string;
}
export enum DataCardEnum {
    DataByDay = "dia",
    DataByMonth = "mês"
}
export class DataCardValue {
    public type: DataCardEnum;
    textValue: string;
    textQuantity: string;
    iconValue: string;
    iconQuantity: string;
    bgcardQuantity: string;
    bgcardValue: string;
    
    constructor(dataCardType: DataCardEnum) {
        this.type = dataCardType;
        //this.textValue = `Valor total no ${this.type}`;
        //this.textQuantity = `Vendas no ${this.type}`;
        this.iconQuantity = "fal fa-sort-amount-up fa-2x";
        this.iconValue = "fal fa-hand-holding-usd fa-2x";
        this.bgcardQuantity = "card-count";
        this.bgcardValue = "card-value";
        
    }

    public getDateStart(): number {
        let start = moment({ hour:0, minute:0, seconds:0 });
        switch (this.type) {
            case DataCardEnum.DataByMonth:
            start.startOf('month');
            break;
        }
        return start.valueOf();
    }   

    public getDateEnd(): number {
        let end = moment({ hour:23, minute:59, seconds: 59 });
        return end.valueOf();
    }   
}
export const dataCardOptions: Array<DataCardValue> = [ 
    new DataCardValue(DataCardEnum.DataByDay), 
    new DataCardValue(DataCardEnum.DataByMonth),
];

export enum DataCardColors{
    iconQuantity = "fal fa-sort-amount-up fa-2x",
    iconValue = "fal fa-hand-holding-usd fa-2x",
    iconValueDown = "fal fa-frown",
    iconValueUp = "fal fa-smile",
    iconDolar = "fal fa-dollar-sign",
    iconCalendar="fal fa-calendar",
    iconExclamation="fas fa-exclamation",
    bgcardValueDown = "card-value-down",
    bgcardValueUp = "card-value-up",
    bgcardQuantity = "card-count",
    bgcardValue = "card-value",
    bgCardValueTotal = "card-value-total"
    
}