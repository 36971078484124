<mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>

<div class="container" *ngIf="!loading">

  <div fxLayout='column' fxLayoutGap="10px">

    <div fxLayout="row" *ngIf="charge">

      <div fxFlex fxLayout="row" fxLayoutAlign="start center">
        <p-breadcrumb id="breadcrumb" [model]="crumbs"></p-breadcrumb>
      </div>

      <div fxFlex fxLayoutGap="10px" fxLayout="row" fxLayoutAlign="end center">

        <div *ngIf="enableRefundButton">
          <button *ngIf="charge.status === 'PAY' && (paymentMethodCharge == 'CREDIT_CARD_SPLIT' || 'CREDIT_CARD')"
            type="button" class="btn btn-danger" id="btnRefund" (click)="refundCharge()">Estornar cobrança</button>
        </div>

        <button type="button" class="btn btn-info" id="btnCapture" (click)="captureCharge()"
          *ngIf="charge.status == 'AUTHORIZED'">Capturar cobrança
        </button>

        <button type="button" class="btn btn-danger" id="btnCancel" (click)="cancelCharge()"
          *ngIf="canCancelCharge">Cancelar {{paymentMethodCharge |
          translate}}
        </button>

      </div>

    </div>

    <!-- Card superior com informações gerais da venda -->
    <card-box name="card-superior">

      <div class="info" fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px" fxLayoutAlign="start center"
        *ngIf="charge">
        <div fxLayout="column" fxFlex="20" fxLayoutAlign="start start">
          <span class="resume-field-title"><i class="fas fa-wallet"></i> Valor </span>
          <div class="resume-field-value">
            <div class="money-label  undefined">
              <span class="amount mr-2">{{charge.value/100 | CustomCurrency:'BRL'}}</span>
              <span class="badge" [ngClass]="getStatusColor(charge.status)">{{charge.status | translate}} <i
                  [ngClass]="getStatusIcon(charge.status)">
                </i></span>
            </div>
          </div>
        </div>
        <div fxLayout="column" fxFlex="20" fxLayoutAlign="start start" *ngIf="transaction?.customer">
          <span><i class="fas fa-user"></i> Cliente</span>
          <div><a href="/#/subscribers/{{transaction?.customer.id}}">{{transaction?.customer.name}} <i
                class="fas fa-external-link-alt"></i></a></div>
        </div>
        <div fxLayout="column" fxFlex="40" fxLayoutAlign="start start" *ngIf="charge.order">
          <span><i class="fas fa-shopping-bag"></i> Loja</span>
          <span>{{charge?.order.affiliate?.name}}</span>
        </div>
      </div>

      <mat-divider class="my-3"></mat-divider>

      <div class="sub-info" fxLayout.xs="column" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">

        <div fxLayout="column" fxFlex="20" fxLayoutAlign="start start">
          <span><i class="fas fa-wallet"></i> Modalidade</span>
          <div>
            <img style="height: 17px;" *ngIf="paymentMethodCharge === 'CREDIT_CARD_SPLIT'" src="assets/img/2cards.png">
            <img style="height: 17px;" *ngIf="paymentMethodCharge === 'CREDIT_CARD'" src="assets/img/credit-card.png">
            <i class="fa fa-barcode pr-1" *ngIf="paymentMethodCharge === 'BOLETO'"></i>
            <img style="height: 15px;" *ngIf="paymentMethodCharge === 'PIX'"
              src="assets/img/pagamentos/simbolo-pix.svg">
            <span class="txt">{{paymentMethodCharge | translate}}</span>
          </div>
        </div>

        <div fxLayout="column" fxFlex="20" fxLayoutAlign="start start">
          <span><i class="fas fa-shopping-bag"></i> Tipo</span>
          <div><span class="txt">
              {{charge.transactions[0].type | translate}}
            </span>
          </div>
        </div>

        <div fxLayout="column" fxFlex="20" fxLayoutAlign="start start" *ngIf="charge?.order?.orderReference != null">
          <span><i class="fas fa-arrow-alt-circle-right"></i> Código de referência do pedido</span>
          <div fxLayout="row">
            <span class="txt">
              {{charge?.order?.orderReference | translate}}
            </span>
            <app-copy-to-clipboard [value]="charge?.order?.orderReference"></app-copy-to-clipboard>
          </div>
        </div>
      </div>
    </card-box>

    <!-- Card inferior -->
    <div fxLayout.gt-sm="row" class="mb-3" fxFlex fxLayout="column" fxLayoutGap="10px"
      fxLayoutAlign="space-between stretch">

      <!-- timeline -->
      <card-box class="history">
        <app-transaction-history [transactionList]="charge.transactions"></app-transaction-history>
      </card-box>
      <!-- timeline end -->

      <!-- card lateral begin -->
      <card-box fxFlex name="card-inferior">

        <div fxLayout="column" fxLayoutGap="40px" fxLayoutAlign="start stretch">

          <!-- Dados do pagamento -->
          <div fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="start stretch">
            <div class="pagamento-da-venda" fxLayout="row" fxFlex>
              <b>Dados de pagamento</b>
            </div>
            <mat-divider class="mb-3" style="margin: 10px;"></mat-divider>

            <div fxFlex fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start start">
              <div fxLayout="row" fxFlex="250px">
                <span><i class="fas fa-donate"></i> Valor </span>
              </div>
              <div fxLayout="row" fxLayoutGap="10px" fxFlex>
                <span>{{charge.value/100 | CustomCurrency: 'BRL' }}</span>
              </div>
            </div>
            <div fxFlex fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start start">
              <div fxLayout="row" fxFlex="250px">
                <span><i class="fas fa-arrow-alt-circle-right"></i> Status </span>
              </div>
              <div fxLayout="row" fxFlex>
                <span class="badge" [ngClass]="getStatusColor(charge.status)">{{charge?.status | translate}}
                  <i [ngClass]="getStatusIcon(charge?.status)">
                  </i></span>
              </div>
            </div>

            <div fxFlex fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start start" *ngIf="charge.status === 'PAY'">

              <div fxLayout="row" fxFlex="250px">
                <span><i class="fas fa-donate"></i> Valor pago</span>
              </div>

              <div fxLayout="column" fxFlex>

                <div *ngIf="paymentMethodCharge === 'BOLETO' || 'PIX'">
                  <span>{{ charge.value/100 | CustomCurrency:'BRL'}}</span>
                </div>

                <div *ngFor="let transactionV2 of transactionsV2">

                  <div *ngIf="transactionV2.paymentMethod != 'BOLETO' || 'PIX'">
                    <img style="height: 17px;" src="assets/img/brands/{{transactionV2.card.brand.toLowerCase()}}.png">
                    <span *ngIf="transactionV2.type != 'REFUND'">
                      {{transactionV2.paidValue/100 | CustomCurrency:'BRL'}} em {{ transactionV2.installments +'x' }}
                    </span>
                  </div>

                </div>

              </div>

            </div>

            <div fxFlex fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start start">
              <div fxLayout="row" fxFlex="250px">
                <span><i class="far fa-calendar-alt"></i> Data da cobrança</span>
              </div>
              <div fxLayout="row" fxFlex>
                <span>{{transaction?.order.orderDate | date:
                  'dd/MM/yyyy HH:mm'}}</span>
              </div>
            </div>
            <div hidden fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start start"
              *ngIf="transaction?.payment.paymentMethod  == 'BANK_SLIP'">
              <div fxLayout="row" fxFlex="250px">
                <span><i class="far fa-calendar-alt"></i> Data do vencimento</span>
              </div>
              <div fxLayout="row" fxFlex>
                <span>{{transaction?.payment.dueDate | date: 'dd/MM/yyyy' }}</span>
              </div>
            </div>
            <div fxFlex fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start start" *ngIf="transaction?.paymentDate">
              <div fxLayout="row" fxFlex="250px">
                <span><i class="far fa-calendar-alt"></i> Data do pagamento</span>
              </div>
              <div fxLayout="row" fxFlex>
                <span> {{transaction?.paymentDate | date: 'dd/MM/yyyy HH:mm' :'GMT-03:00'}}</span>
              </div>
            </div>
            <div fxFlex fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start start" *ngIf="transaction?.adquirente">
              <div fxLayout="row" fxFlex="250px">
                <span><i class="fas fa-university"></i> Provedor</span>
              </div>
              <div fxLayout="row" fxFlex>
                <span><img *ngIf="transaction?.payment.cardBrand"
                    src="assets/img/brands/{{transaction?.payment.cardBrand.toLowerCase()}}.png"></span>
              </div>
            </div>
          </div>

          <!-- Forma de pagamento -->
          <div fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="start stretch">
            <div class="pagamento-da-venda" fxLayout="row" fxFlex>
              <b>Forma de pagamento</b>
            </div>
            <mat-divider class="mb-3" style="margin: 10px;"></mat-divider>
            <div fxFlex fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="10px" fxLayoutAlign="start start"
              *ngIf="paymentMethodCharge  == 'CREDIT_CARD' || 'CREDIT_CARD_SPLIT'">
              <div *ngFor="let creditCard of cardsFromCreditCardComponent">
                <app-credit-card [creditCard]="creditCard"></app-credit-card>
              </div>
            </div>
            <div fxFlex fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="10px" fxLayoutAlign="start start"
              *ngIf="paymentMethodCharge  == 'PIX'">
              <div>
                <img style="width: 80px;" src="assets/img/pix.png">
              </div>
              <div fxLayout="column" fxFlex fxLayoutGap="10px" *ngIf="charge?.transactions[0]?.emv">
                <div>
                  <span><i class="fas fa-arrow-alt-circle-right"></i> Código PIX</span>
                  <div fxLayout="row">
                    <span class="sub-info" *ngIf="charge.status !== 'CANCELED'">
                      {{charge.transactions[0]?.emv}}
                    </span>
                    <span class="sub-info" *ngIf="charge.status === 'CANCELED'">
                      <del>{{charge.transactions[0]?.emv}}</del>
                    </span>
                    <app-copy-to-clipboard [value]="charge.transactions[0]?.emv"
                      *ngIf="charge.status !== 'CANCELED'"></app-copy-to-clipboard>
                  </div>
                </div>
                <div fxLayout="column" *ngIf="charge.transactions[0]?.expirationDate">
                  <span class="symbol"><i class="far fa-calendar-alt"></i> Valido até</span>
                  <span class="sub-info">
                    {{charge.transactions[0]?.expirationDate | date: 'dd/MM/yyyy HH:mm:ss'}}
                    <span class="badge badge-pill badge-danger"
                      *ngIf="isExpired(charge.transactions[0]?.expirationDate)">Expirado</span>
                  </span>
                </div>
              </div>
            </div>
            <div fxFlex fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="10px" fxLayoutAlign="start start"
              *ngIf="paymentMethodCharge  == 'BOLETO'">
              <div>
                <img style="width: 80px;" src="assets/img/boleto.png">
              </div>
              <div fxLayout="column" fxFlex fxLayoutGap="10px">
                <div *ngIf="charge.transactions[0]?.digitableLine">
                  <span><i class="fas fa-arrow-alt-circle-right"></i> Linha digitável</span>
                  <div fxLayout="row">
                    <span class="sub-info" *ngIf="charge.status !== 'CANCELED'">
                      {{charge.transactions[0]?.digitableLine}}
                    </span>
                    <span class="sub-info" *ngIf="charge.status === 'CANCELED'">
                      <del>{{charge.transactions[0]?.digitableLine}}</del>
                    </span>
                    <app-copy-to-clipboard [value]="charge.transactions[0]?.digitableLine"
                      *ngIf="charge.status !== 'CANCELED'"></app-copy-to-clipboard>
                  </div>
                </div>
                <div fxLayout="column" *ngIf="charge.transactions[0]?.dueDate">
                  <span class="symbol"><i class="far fa-calendar-alt"></i> Vencimento</span>
                  <span class="sub-info">{{charge.transactions[0]?.dueDate | date: 'dd/MM/yyyy'}}</span>
                </div>
              </div>
            </div>
          </div>

          <!-- Dados da assinatura -->
          <div fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="start stretch" *ngIf="transaction?.order.plan">

            <div class="pagamento-da-venda" fxLayout="row" fxFlex>
              <b>{{transaction?.order.plan ? 'Assinatura' : 'Itens' }}</b>
            </div>

            <mat-divider class="mb-3" style="margin: 10px;"></mat-divider>

            <div fxFlex fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start start">
              <div fxLayout="row" fxFlex>
                <span><i class="fas fa-bookmark"></i> Nome</span>
              </div>
              <div fxLayout="row" fxFlex>
                <span><i class="far fa-calendar-alt"></i> Data da compra</span>
              </div>
              <div fxLayout="row" fxFlex>
                <span><i class="fas fa-donate"></i> Valor</span>
              </div>
              <div fxLayout="row" fxFlex>
                <span><i class="fas fa-arrow-alt-circle-right"></i> Status</span>
              </div>
            </div>

            <div fxFlex fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start start">
              <div fxLayout="row" fxFlex>
                <span *ngIf="transaction?.order.plan">
                  <a href="/#/plans/{{transaction?.order.plan.id}}">{{transaction?.order.plan.name}}
                    <i class="fas fa-external-link-alt"></i></a></span>
                <span *ngIf="!transaction?.order.plan">Venda</span>
              </div>
              <div fxLayout="row" fxFlex>
                <span>{{transaction?.order.orderDate | date: 'dd/MM/yyyy HH:mm'}}</span>
              </div>
              <div fxLayout="row" fxFlex>
                <span *ngIf="transaction?.order.plan">{{transaction?.order.plan.amout | CustomCurrency:'BRL'}}</span>
                <span *ngIf="!transaction?.order.plan">{{transaction?.order.value | CustomCurrency:'BRL'}}</span>
              </div>
              <div fxLayout="row" fxFlex>
                <span class="badge" [ngClass]="getStatusColor(transaction?.status)">{{transaction?.order.status |
                  translate}}</span>
              </div>
            </div>
          </div>

          <!-- Dados Split -->
          <app-split-list [chargeId]="charge.id" [chargeValue]="charge.value"></app-split-list>

          <!-- Metadados -->
          <app-metadata-list [orderId]="charge.order.id"></app-metadata-list>

          <!-- Webhooks Events -->
          <app-event-list [orderId]="charge.order.id"></app-event-list>

        </div>
      </card-box>
    </div>
  </div>
</div>