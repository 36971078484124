import { PaymentRequestV1 } from "../v1.model";
import { PaymentRequest } from "./payment.request";

export class SubscriptionRequest {
    idPlan:number;
    idCustomer:number;
    referenceId:string;
    payment:PaymentRequest;

    constructor(){
        this.payment  = new PaymentRequest();
    }
}

export class SubscriptionRequestV1 {
    planId:number;
    customerId:number;
    referenceId:string;
    payment:PaymentRequestV1;
    affiliateId: number;

    constructor(){
        this.payment  = new PaymentRequestV1();
    }
}
