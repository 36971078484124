<div class="container" *ngIf="filter">
    <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="10px">
        <div class="view-filter mb-0">
            <div class="card" fxLayout='row' fxLayout.lt-md="column">
                <div class="filter-trans" fxLayout="column" fxLayoutAlign="space-between stretch" style="width: 100%;">

                    <div fxLayout='row' fxLayoutAlign=" stretch" fxLayout.lt-md="column"
                        fxLayoutAlign.lt-md="space-around stretch">
                        <div class="elementosFiltro" fxFlex="14">
                            <mat-form-field>
                                <mat-select placeholder="Tipo de Pedido" id="type" [(ngModel)]="filter.type">
                                    <mat-option value="">Todos</mat-option>
                                    <mat-option *ngFor="let type of types" [value]="type">{{type | translate}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div class="elementosFiltro" fxFlex="13">
                            <mat-form-field>
                                <input matInput [matDatepicker]="startDate" id="begin" readonly
                                    (click)="startDate.open()" [(ngModel)]="filter.begin" placeholder="Data Inicial"
                                    [max]="filter.end">
                                <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
                                <mat-datepicker #startDate></mat-datepicker>
                            </mat-form-field>
                        </div>

                        <div class="elementosFiltro" fxFlex="13">
                            <mat-form-field>
                                <input matInput [matDatepicker]="endDate" id="end" name="end" readonly
                                    (click)="endDate.open()" [(ngModel)]="filter.end" placeholder="Data Final"
                                    [min]="filter.begin">
                                <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
                                <mat-datepicker #endDate></mat-datepicker>
                            </mat-form-field>
                        </div>

                        <div fxFlex="16" class="center-text">
                            <button mat-raised-button class="btn-search" id="search"
                                (click)="search()">Pesquisar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>



        <div fxLayout="row" fxLayoutGap="10px" class="report-data-card" *ngIf="response">
            <data-card id="cardQuantity" [data]="quantityDataCard" fxFlex="20"></data-card>
            <data-card id="cardValue" [data]="valueDataCard" fxFlex="20"></data-card>
        </div>



        <div *ngIf="response" [ngClass]="{'hide' : quantityDataCard.value == 0}">
            <table mat-table [dataSource]="response.items" matSort id="reportTable">
                <ng-container matColumnDef="reason">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header id="tableHeaderReason"> Motivo </th>
                    <td mat-cell *matCellDef="let row; let i = index;" [attr.id]="'reportTdReason_'+i"> {{ row.reason }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="quantity">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header id="tableHeaderQuantity"> Quantidade </th>
                    <td mat-cell *matCellDef="let row; let i = index;" [attr.id]="'reportTdQuantity_'+i"> {{
                        row.quantity }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="income">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header id="tableHeaderIncome"> Receita </th>
                    <td mat-cell *matCellDef="let row; let i = index;" [attr.id]="'reportTdIncome_'+i">
                        {{ row.income | CustomCurrency:'BRL'}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="percent">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header id="tableHeaderPercent"> Volume (%) </th>
                    <td mat-cell *matCellDef="let row; let i = index;" [attr.id]="'reportTdPercent_'+i"> {{
                        row.quantityPercent != 'NaN' ? (row.quantityPercent | number : '0.0-2' ): '0' }}% </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                </tr>
            </table>

        </div>



    </div>


</div>