<div class="container-pai">
    <div class="credit-card-flags">
        <h3>Formas de Pagamento:</h3>
        <div class="cards">
            <img src="../../../assets/img/brands/visa.png" alt="Visa">
            <img src="../../../assets/img/brands/mastercard.png" alt="MasterCard">
            <img src="../../../assets/img/brands/amex.png" alt="American Express">
            <img src="../../../assets/img/brands/diners.png" alt="Diners Club">
            <img src="../../../assets/img/brands/elo.png" alt="Elo">
            <img src="../../../assets/img/brands/hipercard.png" alt="Hipercard">
            <!-- Adicione outras bandeiras conforme necessário -->
        </div>
        <div>
            <img src="../../../assets/img/pix.png" alt="Pix" style="max-width:150px">
            <img src="../../../assets/img/acquirers/nupay.png" alt="NuPay" style="max-width:150px;margin-left: 20px;">
        </div>
    </div>
    <div class="empty-accounts-container">
        <h2>Nenhum Estabelecimento Encontrado</h2>
        <p>Atualmente, não há estabelecimentos listados em sua conta.</p>
        <a mat-raised-button color="primary" target="_blank" href="https://bempaggo.atlassian.net/servicedesk/customer/portal/3/create/51" class="button-contrate-agora">Contrate Agora</a>
    </div>
</div>