<div class="container" fxLayout="column" fxLayoutGap="10px">
    <div fxFlex.gt-sm="50" fxFlex="100" fxLayoutAlign="end">
        <mat-form-field fxFlex.gt-sm="35" fxFlex="100">
            <mat-select placeholder="Estabelecimento" #seller [(ngModel)]="sellerId">
                <mat-option (click)="optionClick(account.code)" *ngFor="let account of accounts" [value]="account.code">
                    {{account.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    
    <!-- Cards -->
    <div fxLayout='row' fxFlexAlign="stretch" fxLayout.lt-md="column" fxLayoutGap="10px">
        <data-card *ngFor="let index of [0,1,2]" [ngStyle.gt-sm]="{'margin-right': ( index <= 3 ? '10px': '0px' )  }"
            [data]="datasCard[index]" fxFlex="33"></data-card>
    </div>

    <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>

    <div class="shadow-none" style="margin-top: 10px;" *ngIf="!loading && sellerId">
        <div class="div-table">
            <mat-tab-group (selectedTabChange)="onTabChanged($event)">
                <mat-tab label="Entradas">
                    <app-receivables-table #receivablesTable [sellerId]="sellerId" [loading]="loadingTab"></app-receivables-table>
                </mat-tab>
                <mat-tab label="Saídas"> 
                    <!-- <app-transfers-table #transfersTable [sellerId]="sellerId" [loading]="loadingTab"></app-transfers-table> -->
                </mat-tab>
                <mat-tab label="Lançamentos Futuros"> 
                    <!-- <app-future-transfers-table #futureTransfersTable [sellerId]="sellerId" [loading]="loadingTab"></app-future-transfers-table> -->
                </mat-tab>
            </mat-tab-group>
            <mat-progress-bar mode="indeterminate" *ngIf="loadingTab"></mat-progress-bar>
        </div>
    </div>

</div>