import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { FileUtilsService } from './file-utils.service';

@Injectable({
    providedIn: 'root'
})
export class AlertService {

  constructor(
    public fileUtilsService: FileUtilsService,
    private router: Router
  ) { }

  closeAll() {
    Swal.close();
  }

  loading(text = 'Processando...') {
    Swal.fire({
      title: text,
      onBeforeOpen: () => {
        Swal.showLoading()
      },
      heightAuto: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      stopKeydownPropagation: false,
      allowOutsideClick: false
    })
  }

  timeMessage(title: string, message: string, time = 50000, showConfirmButton = false, callback?: Function) {
    let timerInterval: any = false;
    Swal.fire({
      type: 'success',
      heightAuto: false,
      title: title,
      timer: time,
      html:
        '<h2> ' + message + ' </h2> ' +
        '<h5>Em <strong></strong> segundos você será fechado</h5>',
      showConfirmButton: showConfirmButton,
      allowEscapeKey: false,
      allowEnterKey: false,
      stopKeydownPropagation: false,
      allowOutsideClick: false,
      onBeforeOpen: () => {
        timerInterval = setInterval(() => {
          let seconds = ((Swal.getTimerLeft() % 60000) / 1000).toFixed(0);
          if (seconds === '0') {
            if (typeof callback == 'function') {
              callback();
            }
            clearInterval(timerInterval);
            Swal.close();
          } else {
            Swal.getContent().querySelector('strong').textContent = seconds.toString();
          }
        }, 100)
      },
      onClose: () => {
        if (typeof callback == 'function') {
          callback();
        }
        clearInterval(timerInterval);
      }
    });
  }

  success(title: string, message: string, timer?: number): Promise<any> {
    return Swal.fire({
      title: title,
      html: message,
      type: "success",
      timer: timer ? timer : 0
    });
  }

  error(title: string, message: string): Promise<any> {
    return Swal.fire(title, message, "error");
  }

  warning(title: string, message: string): Promise<any> {
    return Swal.fire(title, message, "warning");
  }

  errorPermission(): Promise<any> {
    return Swal.fire('Acesso Negado ', 'Você não possui <b>permissão para acessar</b> este conteúdo.<br> Para acessa-lo, entre em contato com o administrador do sistema.', "error");
  }

  confirm(title: string, message: string): Promise<boolean> {
    return Swal.fire({
      title: title,
      html: message,
      type: "warning",
      showCancelButton: true,
      cancelButtonText: 'Cancelar'
    }).then(result => {
      return result.value;
    });
  }

  confirmYesNo(title: string, callback: Function, confirmButtonText = 'Sim', cancelButtonText = 'Não', text = '') {
    Swal.fire({
      title: title,
      text: text,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: confirmButtonText,
      cancelButtonText: cancelButtonText,
    }).then((result) => {
      if (result.value) {
        if (typeof callback == 'function') {
          callback();
        } else {
          console.error("Callback is not function");
        }
      }
    })
  }

  alertDownloadCSVFromArray(title: string, message: string, confirmText: string, cancelText: string, rejections: any[], headers: any, fileName: string): Promise<any> {
    return Swal.fire({
      title: title,
      html: message,
      type: "warning",
      showCancelButton: true,
      confirmButtonText: confirmText,
      cancelButtonText: cancelText,
      reverseButtons: true,
      showLoaderOnConfirm: true,
      allowEscapeKey: false,
      allowEnterKey: false,
      allowOutsideClick: false,
      confirmButtonColor: '#dc3545',
      preConfirm: () => {
        this.fileUtilsService.downloadRejectionCSVFromArray(rejections, headers, fileName);
        return false;
      }
    });
  }

  handleError(respError) {
    if (respError['error'] == null) {
      this.error("Erro", "Ocorreu um erro interno em nosso servidor. Se o erro persistir, por favor, contate o suporte.");
    } else {
      if (respError.error.errors != null && typeof respError['error']['errors'] != 'undefined') {
        let msg = '';
        respError['error']['errors'].forEach(element => {
          msg += element['message'] + '<br/>';
        });
        this.error("Erro", msg);
      } else {
        let error = respError.error;
        if (typeof error['error'] == 'string') {
          this.error("Erro", error['error']);
        } else if (typeof error['message'] == 'string') {
          this.error("Erro", error['message']);
        } else {
          this.error("Erro", error);
        }
      }
    }
  }

  handleErrorCheckOut(respError) {
    if (respError.status == 404 || respError.status == 422) {
      this.router.navigate(['not-found']);
    } else {
      this.handleError(respError);
    }

  }

}
