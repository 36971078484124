<div class="content" fxLayout='column' fxLayoutGap="10px">
  <div fxLayout='row' fxLayout.lt-md="row">
    <card-box fxFlex="100">
      <div class="chart-container mt-4" style="position: relative; width: 98%; height: 100%;">
        <div class="content h-100 mt-4 pb-5" fxLayout="row" fxLayoutAlign="center center" *ngIf="loadingTab">
          <mat-spinner></mat-spinner>
        </div>
        <mat-progress-bar mode="indeterminate" *ngIf="loading && !loadingTab"></mat-progress-bar>
        <div class="row" fxLayout="column" *ngIf="!loadingTab">

          <div class="view-filter" display="flex" fxLayout='column' fxLayout.lt-md="column">
            <div fxFlex="100" class="filter-trans card-body bg-light pb-4 pt-3" fxLayout='row' fxLayoutAlign="start"
              fxLayout.lt-lg="column" fxLayoutAlign.lt-lg="space-around stretch">
              <div fxLayout='row' fxLayout.lt-md="column" fxLayoutGap="20px">
                <div>
                  <mat-form-field>
                    <input matInput [matDatepicker]="startDate" readonly (click)="startDate.open()"
                      [(ngModel)]="filters.start" placeholder="Início" [max]="filters.end">
                    <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
                    <mat-datepicker #startDate></mat-datepicker>
                  </mat-form-field>
                </div>
                <div>
                  <mat-form-field>
                    <input matInput [matDatepicker]="endDate" readonly (click)="endDate.open()"
                      [(ngModel)]="filters.end" placeholder="Fim" [min]="filters.start">
                    <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
                    <mat-datepicker #endDate></mat-datepicker>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div fxFlex="100" class="filter-trans card-body bg-light pb-4 pt-3" fxLayout='row'
              fxLayoutAlign="start stretch" fxLayout.lt-lg="column" fxLayoutAlign.lt-lg="space-around">
              <div fxLayout='row' fxLayout.lt-md="column" fxLayoutGap="10px">
                <div fxFlex>
                  <form [formGroup]="searchPlansForm">
                    <mat-form-field>
                      <mat-select class="opcoesFiltro" placeholder="Planos" [(ngModel)]="filters.planIDs" multiple
                        [compareWith]="compareId" formControlName="orderPlans">
                        <mat-option #allSelectedForPlans (click)="toggleAllSelectionForPlans()" [value]="0">Todos
                        </mat-option>
                        <mat-option *ngFor="let plano of planos" [value]="plano.id"
                          (click)="tosslePerOneForPlans(allSelectedForPlans.viewValue)">
                          {{plano.name}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </form>
                </div>

                <div fxFlex>
                  <form [formGroup]="searchTypesForm">
                    <mat-form-field>
                      <mat-select class="opcoesFiltro" placeholder="Tipo do pedido" formControlName="orderTypes"
                        [(ngModel)]="filters.type" [compareWith]="compareId" multiple>
                        <mat-option #allSelectedForTypes (click)="toggleAllSelectionForTypes()" [value]="0">Todos
                        </mat-option>
                        <mat-option *ngFor="let aType of types" [value]="aType"
                          (click)="tosslePerOneForTypes(allSelectedForTypes.viewValue)">
                          {{aType | translate}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </form>
                </div>
                <div fxFlex>
                  <form [formGroup]="searchStatusForm">
                    <mat-form-field>
                      <mat-select class="opcoesFiltro" placeholder="Status do pedido" formControlName="orderStatus"
                        [(ngModel)]="filters.status" [compareWith]="compareId" multiple>
                        <mat-option #allSelectedForStatus (click)="toggleAllSelectionForStatus()" [value]="0">Todos
                        </mat-option>
                        <mat-option *ngFor="let aStatus of status" [value]="aStatus"
                          (click)="tosslePerOneForStatus(allSelectedForStatus.viewValue)">
                          {{aStatus | translate}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </form>
                </div>
              </div>


              <div class="pesquisar" fxLayout="row" fxLayoutAlign="end">
                <div class="center-text">
                  <button mat-raised-button class="btn-search" fxLayoutAlign="end" (click)="filter()">Pesquisar</button>
                </div>
              </div>
            </div>
          </div>

          <div class="errors center-text" *ngIf="!rangeValid">
            <span>Favor selecionar um período máximo de 31 dias.</span>
          </div>
        </div>


      </div>
    </card-box>
  </div>
  <div class="tab-content">
    <div [ngClass]="{'hide' : pageResponse.totalElements == 0}">
      <div class="export">
        <a class="export-link" (click)="downloadCsv()">
          <span class="export-label">Exportar</span>
          <i class="fa fa-arrow-circle-down" aria-hidden="true"></i>
        </a>
      </div>
      <div class="div-table">
        <table mat-table [dataSource]="subscriptions" multiTemplateDataRows matSort style="width: 100%;">
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
            <td mat-cell *matCellDef="let row"> {{row.id}} </td>
          </ng-container>
          <ng-container matColumnDef="plan">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Plano</th>
            <td mat-cell *matCellDef="let row"> {{row.plan?.name}} </td>
          </ng-container>
          <ng-container matColumnDef="customer">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Assinante</th>
            <td mat-cell *matCellDef="let row"> {{row.customer.name}} </td>
          </ng-container>
          <ng-container matColumnDef="registerDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Data de inscrição</th>
            <td mat-cell *matCellDef="let row"> {{row.registerDate | date: 'dd/MM/yyyy'}} </td>
          </ng-container>
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
            <td mat-cell *matCellDef="let row"> {{row.status | translate}} </td>
          </ng-container>
          <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Tipo</th>
            <td mat-cell *matCellDef="let row"> {{row.type | translate}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="expandedElement = row">
          </tr>

        </table>
      </div>

      <mat-paginator [length]="pageResponse.totalElements" [pageSize]="pageResponse.pageSize"
        (page)="pageEvent = handlePage($event)" aria-label="Select page">
      </mat-paginator>
    </div>



    <div [ngClass]="{'hide' : pageResponse.totalElements > 0}" class="no-data-message">
      <span>Nenhuma assinatura para exibir</span>
    </div>
  </div>


</div>