import { Component, OnInit, ViewChild } from "@angular/core";
import { ProductResponse, PlanResponse, PagePayResponse } from "src/app/core/model/v1.model";
import { ProductServiceV1 } from "src/app/core/services/v1/product.service.v1";
import { AlertService } from "src/app/core/services/alert.service";
import { Router, ActivatedRoute } from "@angular/router";
import { PlanServiceV1 } from "src/app/core/services/v1/plan.service.v1";
import { PagePayServiceV1 } from "src/app/core/services/v1/pagepay.service.v1";
import { MenuItem } from "primeng/api";
import { BempaggoConstants } from "src/app/core/helper/string-utils";

@Component({
    selector: 'app-plan-details-v1',
    templateUrl: './plan-details-v1.component.html',
    styleUrls: ['./plan-details-v1.component.scss']
})
export class PlanDetailsV1Component implements OnInit {

    companyId: number;
    shareUrl: string;
    plan: PlanResponse;
    view: boolean;
    //pagepays: PagePayResponse[];
    displayedColumns: string[] =
        ['id',
            'name',
            'expiration',
            'sold',
            'limit',
            'status'
        ];
    crumbs: MenuItem[];
    javaMaxInt: number = BempaggoConstants.JAVA_MAX_INT;

    constructor(
        private route: ActivatedRoute,
        private alertService: AlertService,
        private planService: PlanServiceV1,
        private pagepayService: PagePayServiceV1
    ) { }

    ngOnInit() {
        this.planService.editPlan.subscribe(result => { this.view = result; this.initCrumbs() });
        this.view = true;
        this.planService.getById(parseInt(this.route.snapshot.paramMap.get('id'))).subscribe(
            result => {
                this.plan = result;
                this.initCrumbs();
            },
            error => {
                this.alertService.handleError(error);
            }
        );
    }

    public initCrumbs() {
        this.crumbs = [
            { label: 'Assinaturas', routerLink: ['/plans'] },
            { label: this.plan.name, routerLink: ['/plans', this.plan.id] },
        ];
    }

    activeSlideChange(pagepay: PagePayResponse) {
        if (pagepay.active) {
            this.pagepayService.active(pagepay).subscribe(
                result => { },
                error => {
                    pagepay.active = !pagepay.active;
                    this.alertService.handleError(error)
                }
            );
        } else {
            this.pagepayService.inactive(pagepay).subscribe(
                result => { },
                error => {
                    pagepay.active = !pagepay.active;
                    this.alertService.handleError(error)
                }
            );
        }
    }

}