import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { DateFormatPipe } from 'ngx-moment';
import { NgxPermissionsService } from 'ngx-permissions';
import { ChargeReportFilter } from 'src/app/core/common/filters/charge-report.filter';
import { DateUtils } from 'src/app/core/helper/date-utils';
import { Administradora } from 'src/app/core/model/administradora';
import { ChargeReports } from 'src/app/core/model/charges-report';
import { DataCard } from 'src/app/core/model/data-card';
import { PageResponse } from 'src/app/core/model/page-response';
import { Pageable } from 'src/app/core/model/pageable';
import { AffiliateMinimalResponse, TransactionStatus } from 'src/app/core/model/v1.model';
import { PagePermissions } from 'src/app/core/permissions/page-permissions';
import { AlertService } from 'src/app/core/services/alert.service';
import { AffiliateServiceV1 } from 'src/app/core/services/v1/affiliate.service.v1';
import { ChargeServiceV1 } from 'src/app/core/services/v1/charge.service.v1';


@Component({
  selector: 'charges-report',
  templateUrl: './charges.component.html',
  styleUrls: ['./charges.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ChargesComponent implements OnInit {

  pageEvent: PageEvent;
  pageResponse: PageResponse;
  public pageable: Pageable;
  status: any[];
  searchStatusForm: UntypedFormGroup;

  @ViewChild('allSelectedForStatus') private allSelectedForStatus: MatOption;


  dateFormatPipe: DateFormatPipe = new DateFormatPipe();
  dateUtils: DateUtils = new DateUtils();
  enableDueDate: boolean = false;
  enablePaymentDate: boolean = false;
  enableLastChargeDate: boolean = false;
  exportPermission = PagePermissions.REPORT_CHARGE_EXPORT;
  showExportBtn: boolean;
  filters: ChargeReportFilter = new ChargeReportFilter();
  expandedElement: ChargeReports | null;
  transactions = new MatTableDataSource<ChargeReports>();
  bandeiras: Administradora[];
  rangeValid: boolean = true;
  loading: boolean = false;
  dueDateValid: boolean;
  paymentDateValid: boolean;
  lastChargeDateValid: boolean;
  affiliate: AffiliateMinimalResponse[];
  searchAffiliateForm: UntypedFormGroup;
  @ViewChild('allSelectedForAffiliate') private allSelectedForAffiliate: MatOption;



  displayedColumns: string[] = [
    'chargeId',
    'transactionDate',
    'name',
    'amount',
    'dueDate',
    'status'
  ];

  datasCard: DataCard[];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private alertService: AlertService,
    private chargeService: ChargeServiceV1,
    private permissionsService: NgxPermissionsService,
    private affiliateService: AffiliateServiceV1) { }

  ngOnInit(): void {
    this.pageResponse = {
      pageNumber: 0,
      totalPages: 0,
      pageSize: 10,
      totalElements: 0,
      content: null
    };
    this.status = Object.values(TransactionStatus).filter(x => typeof x === 'string');
    this.searchStatusForm = this.formBuilder.group({
      chargeStatus: new UntypedFormControl('')
    });
    this.searchAffiliateForm = this.formBuilder.group({
      orderAffiliate: new UntypedFormControl('')
    });
    this.affiliateService.getAll().subscribe(result => this.affiliate = result);
    this.filters.endLastChargeDate = new Date();
    this.filters.endDueDate = new Date();
    this.filters.endPaymentDate = new Date();
    this.filters.startLastChargeDate = new Date(this.filters.endLastChargeDate.getFullYear(), this.filters.endLastChargeDate.getMonth() - 1, this.filters.endLastChargeDate.getDate());
    this.filters.startDueDate = new Date(this.filters.endDueDate.getFullYear(), this.filters.endDueDate.getMonth() - 1, this.filters.endDueDate.getDate());
    this.filters.startPaymentDate = new Date(this.filters.endPaymentDate.getFullYear(), this.filters.endPaymentDate.getMonth() - 1, this.filters.endPaymentDate.getDate());
    this.permissionsService.hasPermission(this.exportPermission).then(hasPermission => {
      this.showExportBtn = hasPermission;
    });
    this.filter();
  }

  confirmDateRange() {
    let start = moment(this.filters.startLastChargeDate);
    let end = moment(this.filters.endLastChargeDate);
    let difference = end.diff(start, 'days');
    if (difference > 31) {
      this.rangeValid = false;
    } else {
      this.filters.startLastChargeDate = this.filters.startLastChargeDate;
      this.filters.endLastChargeDate = this.filters.endLastChargeDate;
      this.rangeValid = true;
    }
    return this.rangeValid;
  }


  downloadCsv() {
    if (this.confirmDateRange()) {
      this.loading = true;
      this.chargeService.downloadCsvReportCharges(this.filters)
        .subscribe(response => {
          let binaryData = ["\ufeff", response];
          let downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(<any>binaryData, { type: "application/json" }));
          let dateFormatPipe: DateFormatPipe = new DateFormatPipe();
          let date = dateFormatPipe.transform(new Date(), "YYYY-MM-DD-HH-mm");
          downloadLink.setAttribute('download', `Relatorio_Cobranças_${date}.csv`);
          document.body.appendChild(downloadLink);
          downloadLink.click();
          this.loading = false;
        },
          error => {
            this.loading = false;
            this.alertService.error("Erro", "Ocorreu um erro interno em nosso servidor. Se o erro persistir, por favor, contate o suporte.");
          });
    }
  }

  filter() {
    this.pageable = { page: 0, size: 10 };
    
    if (this.validateDate()) {
      this.loading = true;
      this.search();
    } else {
      this.loading = false;
    }
  }

  //paginação
  public handlePage(event?: PageEvent) {
    this.pageable.page = event.pageIndex;
    this.search();
    return event;
  }

  //ordenação
  public handleSort(event?: any) {
    this.pageable = { page: 0, size: 5 };
    this.pageable = Object.assign({}, this.pageable, { sort: `${event.active},${event.direction}` });
    this.search();
  }

  private search() {
    this.chargeService.reportCharge(this.filters, this.pageable)
      .subscribe(result => {
        this.showResult(result);
      },
        error => {
          this.loading = false;
          this.alertService.error("Erro", "Ocorreu um erro interno em nosso servidor. Se o erro persistir, por favor, contate o suporte.");
        });
  }



  showResult(result) {
    if (result != null) {
      this.pageResponse.pageNumber = result.pageable.pageNumber;
      this.pageResponse.totalElements = result.totalElements;
      this.pageResponse.totalPages = result.totalPages;
      this.pageResponse.content = result.content;
      this.transactions = this.pageResponse.content;
    } else {
      this.pageResponse.pageNumber = 0;
      this.pageResponse.totalElements = 0;
      this.pageResponse.totalPages = 0;
      this.transactions = this.pageResponse.content;
    }
    this.loading = false;
  }

  tosslePerOneForStatus(all) {
    if (this.allSelectedForStatus.selected) {
      this.allSelectedForStatus.deselect();
      return false;
    }
    if (this.searchStatusForm.controls.chargeStatus.value.length == this.status.length) {
      this.allSelectedForStatus.select();
    }
  }
  toggleAllSelectionForStatus() {
    if (this.allSelectedForStatus.selected) {
      this.searchStatusForm.controls.chargeStatus
        .patchValue([...this.status.map(item => item), 0]);
    } else {
      this.searchStatusForm.controls.chargeStatus.patchValue([]);
    }
  }

  validateDate(): boolean {
    if (this.enableLastChargeDate && (!this.dateUtils.compareEndToBeginMonth(this.filters.endLastChargeDate, this.filters.startLastChargeDate, 2))) {
      this.lastChargeDateValid = false;
    } else {
      this.lastChargeDateValid = true;
    }
    if (this.enableDueDate && (!this.dateUtils.compareEndToBeginMonth(this.filters.endDueDate, this.filters.startDueDate, 2))) {
      this.dueDateValid = false;
    } else {
      this.dueDateValid = true;
    }
    if (this.enablePaymentDate && (!this.dateUtils.compareEndToBeginMonth(this.filters.endPaymentDate, this.filters.startPaymentDate, 2))) {
      this.paymentDateValid = false;
    } else {
      this.paymentDateValid = true;
    }
    if (!this.enablePaymentDate) {
      this.filters.endPaymentDate = null;
      this.filters.startPaymentDate = null;
    }
    if (!this.enableDueDate) {
      this.filters.endDueDate = null;
      this.filters.startDueDate = null;
    }
    if (!this.enableLastChargeDate) {
      this.filters.endLastChargeDate = null;
      this.filters.startLastChargeDate = null;
    }
    return this.lastChargeDateValid && this.dueDateValid && this.paymentDateValid;
  }

  getStatusColor(status) {

    return "transaction-status transaction-" + status;
  }

  tosslePerOneForAffiliate(all) {
    if (this.allSelectedForAffiliate.selected) {
      this.allSelectedForAffiliate.deselect();
      return false;
    }
    if (this.searchAffiliateForm.controls.orderAffiliate.value.length == this.affiliate.length) {
      this.allSelectedForAffiliate.select();
    }
  }
  toggleAllSelectionForAffiliate() {
    if (this.allSelectedForAffiliate.selected) {
      this.searchAffiliateForm.controls.orderAffiliate
        .patchValue([0, ...this.affiliate.map(item => item.id)]);

    } else {
      this.searchAffiliateForm.controls.orderAffiliate.patchValue([]);
    }
  }

  compareId(id1: number, id2: number) {
    if (id2 === 0) {
      return id1 === 0;
    }
    return id1 && id2 && id1 === id2;
  }

  isEnableLastChargeDate() {
    return this.enableLastChargeDate;
  }

  isEnableDueDate() {
    return this.enableDueDate;
  }

  isEnablePaymentDate() {
    return this.enablePaymentDate;
  }
}