import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OnOrderV2 {
  private header: HttpHeaders;

  constructor(private http: HttpClient) {
    this.header = new HttpHeaders();
    this.header.set("Access-Control-Allow-Origin", "*");
    this.header.set("Access-Control-Allow-Headers", "Location");
  }

  public OrderServiceV2(): OnOrderV2 {
    return new OnOrderV2(this.http);
  }

  public getMetadataById(id: any): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/api/v2/orders/${id}/metadata`, { headers: this.header });
  }

  //Refazer o método abaixo após a correção do endpoint pelo time de back-end
  public getEventsById(id: any): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/api/v2/events/order/${id}`, { headers: this.header });
  }
}
