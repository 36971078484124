<div class="container">
    <div class="card" autosize>
      <mat-grid-list cols="10"  rowHeight="53px" autosize><mat-grid-tile [colspan]="2">
          <mat-form-field>
            <mat-select placeholder="Status Transação">
              <mat-option value="1">Efetuada</mat-option>
              <mat-option value="2">Estornada</mat-option>
              <mat-option value="3">Em andamento</mat-option>
              <mat-option value="90">Falha</mat-option>
              <mat-option value="99">Inválida</mat-option>
            </mat-select>
          </mat-form-field>
        </mat-grid-tile>
          <mat-grid-tile [colspan]="2">
              <mat-form-field>
                  <mat-select placeholder="Adquirente">
                    <mat-option value="1">Mastercard</mat-option>
                    <mat-option value="2">Visa</mat-option>
                    <mat-option value="3">Todas</mat-option>
                  </mat-select>
                </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="2">
              <mat-form-field>
                <mat-select placeholder="Status Adquirente">
                  <mat-option value="1">Autorizada</mat-option>
                  <mat-option value="2">Não autorizada</mat-option>
                  <mat-option value="90">Falha na Comunicação</mat-option>
                </mat-select>
              </mat-form-field>
            </mat-grid-tile>
        <mat-grid-tile [colspan]="1">
          <mat-form-field>
            <input matInput [matDatepicker]="startDate" placeholder="Início">
            <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
            <mat-datepicker #startDate></mat-datepicker>
          </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="1">
          <mat-form-field>
            <input matInput [matDatepicker]="endDate" placeholder="Fim">
            <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
            <mat-datepicker #endDate></mat-datepicker>
          </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="2">
            <button mat-raised-button class="btn-search">Pesquisar</button>
        </mat-grid-tile>
      </mat-grid-list>
    </div>
    
      <div class="shadow-padrao">
          <table mat-table [dataSource]="dataSource" matSort autosize>
            <!-- ID Column -->
            <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
              <td mat-cell *matCellDef="let row"> {{row.id}} </td>
            </ng-container>
      
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                <td mat-cell *matCellDef="let row"> {{row.status}} </td>
              </ng-container>

            <ng-container matColumnDef="date">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Data/Hora </th>
              <td mat-cell *matCellDef="let row"> {{row.date}} </td>
            </ng-container>
      
            <ng-container matColumnDef="bandeira">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Bandeira </th>
                <td mat-cell *matCellDef="let row"> {{row.bandeira}} </td>
              </ng-container>
      
            <ng-container matColumnDef="statusAdquirente">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Retorno Adquirente </th>
              <td mat-cell *matCellDef="let row"> {{row.statusAdquirente}} </td>
            </ng-container>
      
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;">
            </tr>
          </table>
      
          <mat-paginator [pageSizeOptions]="[10]"></mat-paginator>
        </div>
    </div>