<div class="card shadow-none">
  <div fxLayout='row' fxLayout.lt-md="column" fxLayoutGap="20px">
    <div fxFlex="25">
      <mat-form-field>
        <input matInput [matDatepicker]="startDate" readonly (click)="startDate.open()" [(ngModel)]="filters.startDate"
          placeholder="Início" [max]="filters.endDate">
        <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
        <mat-datepicker #startDate></mat-datepicker>
      </mat-form-field>
    </div>
    <div fxFlex="25">
      <mat-form-field>
        <input matInput [matDatepicker]="endDate" readonly (click)="endDate.open()" [(ngModel)]="filters.endDate"
          placeholder="Fim" [min]="filters.startDate">
        <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
        <mat-datepicker #endDate></mat-datepicker>
      </mat-form-field>
    </div>
  </div>

  <div fxLayout="row" fxLayoutAlign="end">
    <div class="center-text">
      <button mat-raised-button class="btn-search" (click)="filter()">Pesquisar</button>
    </div>
  </div>
  <div class="errors center-text" *ngIf="!rangeValid">
    <span>Favor selecionar um período máximo de 31 dias.</span>
  </div>
</div>