import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { toHttpParams } from "../../helper/http.helper";

@Injectable({
    providedIn: 'root'
})
export class ChargebackServiceV1 {

    constructor(private http: HttpClient) { }

    getMetricAlerts(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/api/v1/chargebacks/alerts/metrics/all`, { params: toHttpParams({}) });
    }

}