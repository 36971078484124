import { Injectable } from "@angular/core";
import { ErrorResponse } from "../model/error-response";
import { AlertService } from "./alert.service";

@Injectable({
  providedIn: 'root'
})
export class ErrorHandleService {

  constructor(private alertService: AlertService) {  }

  public alertError(error:ErrorResponse){
    let alertMessage = "";
    switch (error.code){
        case 422:
        case 400: 
        {
            if (error.errors != null && error.errors.length > 0){
                for(let i = 0; error.errors.length > i; i++){
                    alertMessage = `${alertMessage}${error.errors[i].message}\n`;
                }
            } else {
                alertMessage = error.message;
            }
            break;
        }
        default:
        {
            alertMessage = error.message;
        }
    }

    this.alertService.error("Erro", alertMessage);
  }

}