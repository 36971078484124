<div class="content" fxLayout='column' fxLayoutGap="10px">
    <!-- <div *ngxPermissionsOnly="['TRANSACTION_QUERY', 'TRANSACTION_QUERY_EXPORT']" class="card-dash" fxLayout='row'
        fxLayout.lt-md="column" fxLayoutGap="10px">
        <data-card class="pointer" fxFlexAlign="start start" *ngFor="let data of datasCard1" fxFlex [data]="data" fxFlex
            (click)="goToTransactions(data)"></data-card>
        <data-card class="pointer" fxFlexAlign="start end" *ngFor="let data of datasCard2" fxFlex [data]="data" fxFlex
            (click)="goToTransactions(data)"></data-card>
    </div> -->
    <div *ngxPermissionsExcept="['TRANSACTION_QUERY', 'TRANSACTION_QUERY_EXPORT']" class="card-dash" fxLayout='row'
        fxLayout.lt-md="column" fxLayoutGap="10px">
        <data-card fxFlexAlign="start start" *ngFor="let data of salesDataArray" fxFlex [data]="data" fxFlex>
        </data-card>
    </div>

    <div class="card-dash" fxLayout='row' fxLayout.lt-md="column" fxLayoutGap="10px">
        <card-box [title]="'Assinaturas Ativas'" [icon]="'fal fa-chart-line'" fxFlex>
            <div fxLayout='row' fxLayoutAlign="center end" style="height: 100px">
                <mini-data-card *ngFor="let data of datasAssinaturas" [data]="data" fxFlex></mini-data-card>
            </div>
        </card-box>
        <card-box [title]="'Recorrências (mês atual)'" [icon]="'fal fa-sync-alt'" fxFlex>
            <div fxLayout='row' fxLayoutAlign="center end" style="height: 100px">
                <mini-data-card *ngFor="let data of datasRecorrente" [data]="data" fxFlex></mini-data-card>
            </div>
        </card-box>
    </div>

    <div *ngIf="chart" id="chart2" fxLayout='row' fxLayout.lt-md="row">
        <card-box [title]="'Novas Vendas (Últimos 30 dias)'" fxFlex>
            <div class="chart-container" style="position: relative; width: 98%; height: 300px;">
                <canvas id="canvas">{{chart}}</canvas>
            </div>
        </card-box>
    </div>
</div>