import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { of } from 'rxjs';
import { MotivoEstorno } from 'src/app/core/model/motivo-cancelamento';
import { RefundRequest } from 'src/app/core/model/request/refund.request';
import { Charge } from 'src/app/core/model/v2.model';
import { AlertService } from 'src/app/core/services/alert.service';
import { AppService } from 'src/app/core/services/v0/app.service';
import { OnChargeV2 } from 'src/app/core/services/v2/charge.service.v2';

@Component({
  selector: 'app-sale-cancel',
  templateUrl: './sale-cancel.component.html',
  styleUrls: ['./sale-cancel.component.scss']
})

export class SaleCancelComponent implements OnInit {
  loading: boolean = false;
  returnPixEnabled: boolean = true;
  motivos: MotivoEstorno[] = new Array();

  motivo: MotivoEstorno;
  estornoFatura: boolean;

  constructor(
    private appService: AppService,
    private onChargeV2: OnChargeV2,
    private alertService: AlertService,
    public dialogRef: MatDialogRef<SaleCancelComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { charge: Charge, paymentMethod: string },
  ) { }

  ngOnInit(): void {
    this.estornoFatura = true;
    this.motivos = this.appService.findAllMotivosEstorno();
  }

  submit(): void {

    if (this.data.paymentMethod === 'PIX' && this.returnPixEnabled) {
      this.returnPix();
    } else if (this.data.paymentMethod === 'CREDIT_CARD' || this.data.paymentMethod === 'CREDIT_CARD_SPLIT') {
      if (this.motivo != null) {
        this.loading = true;
        let refundRequest = new RefundRequest();
        refundRequest.value = this.data.charge.value;
        refundRequest.reason = this.motivo.reason;
        this.refundV2(refundRequest);
      } else {
        this.alertService.error("Verifique os campos", "O motivo do estorno não foi selecionado");
      }
    } else {
      this.alertService.error('Erro', 'Não é possível estornar essa cobrança');
      this.loading = false;
    }
  }

  private refundV2(request: RefundRequest) {
    this.onChargeV2.refundCreditCard(this.data.charge.id, request).subscribe(_result => {
      this.alertService.success('Sucesso', 'Estorno realizado com sucesso.').then(() => this.dialogRef.close(true));
    },
      error => {
        this.alertService.error('Erro', error?.error?.message);
        this.loading = false;
      });
  }

  private returnPix() {
    this.onChargeV2.returnPix(this.data.charge.id).subscribe(_result => {
      this.alertService.success('Sucesso', 'Estorno realizado com sucesso.').then(() => this.dialogRef.close(true));
    },
      error => {
        this.alertService.error('Erro', error?.error?.message);
        this.loading = false;
      });
  }
}
