import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { DataShare } from "src/app/core/helper/provider/data-share";
import { User } from "src/app/core/model/user";
import { PagePermissions } from "src/app/core/permissions/page-permissions";
import { UserService } from "src/app/core/services/v0/user.service";
import { MenuItem } from 'primeng/api';
@Component({
  selector: 'app-user-query',
  templateUrl: './user-query.component.html',
  styleUrls: ['./user-query.component.scss']
})
export class UserQueryComponent implements OnInit {
  crumbs: MenuItem[];
  usersData: MatTableDataSource<User> = new MatTableDataSource<User>();
  displayedColumns: string[] = ['id', 'nome', 'email', 'username', 'status'];
  createUserPermissions = PagePermissions.USER_NEW;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private userService: UserService, private router: Router, private dataShare: DataShare) { }

  ngOnInit(): void {
    this.userService.findAll().subscribe(result => {
      if (result) {
        this.usersData = new MatTableDataSource<User>(result);
        this.usersData.paginator = this.paginator;
        this.usersData.sort = this.sort;
      }
    });
    this.initCrumbs();
  }

  public initCrumbs() {
    this.crumbs = [
      { label: 'Usuários' },
    ];
  }

  goToUserPage(userId: number) {
    this.router.navigate(['settings/users/details/', userId]);
  }

  newUser() {
    this.dataShare.data = new User();
    this.dataShare.data.perfis = [];
    this.router.navigate(['settings/users/new']);
  }
}
