import { PhoneResponse } from "../v1.model";

export class NewCustomerResponse{
    id: number;
    name: string;
    document: string;
    email: string;
    birthdate: string;
    phone: PhoneResponse;
    addresses: string;
    bestAdress: string;
}