<div class="content" fxLayout='column' fxLayoutGap="10px">
    <div fxLayout='row' fxLayout.lt-md="row">
        <card-box fxFlex>
            <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
            <div class="chart-container mt-4" style="position: relative; width: 90%; height: 100%;">
                <div class="card-charges md-0 shadow-none" fxLayout='column' fxFlex fxLayoutAlign="start stretch"
                    fxLayout.lt-md="column" fxLayoutAlign.lt-md="space-around stretch" fxLayoutGap="10px">

                    <!-- Primeira linha de filtros -->
                    <div fxLayout='row' fxLayoutAlign="start stretch" fxLayout.lt-md="column"
                        fxLayoutAlign.lt-md="space-around stretch" fxLayoutGap="10px">
                        <div fxLayout='column' fxLayoutGap="10px">
                            <mat-slide-toggle [(ngModel)]="enableLastChargeDate">Data da última cobrança</mat-slide-toggle>
                            <div fxLayout='row' fxLayoutGap="4px">
                                <mat-form-field  class="input-date">
                                    <input matInput [matDatepicker]="startLastChargeDate" readonly class="input-date"
                                        (click)="startLastChargeDate.open()" [(ngModel)]="filters.startLastChargeDate"
                                        placeholder="Início:" [max]="filters.endLastChargeDate" disabled="{{!isEnableLastChargeDate()}}">
                                    <mat-datepicker-toggle matSuffix [for]="startLastChargeDate">
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #startLastChargeDate></mat-datepicker>
                                </mat-form-field>
                                <mat-form-field  class="input-date">
                                    <input matInput [matDatepicker]="endLastChargeDate" readonly  class="input-date"
                                        (click)="endLastChargeDate.open()" [(ngModel)]="filters.endLastChargeDate"
                                        placeholder="Fim:" [min]="filters.startLastChargeDate" disabled="{{!isEnableLastChargeDate()}}">
                                    <mat-datepicker-toggle matSuffix [for]="endLastChargeDate">
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #endLastChargeDate></mat-datepicker>
                                </mat-form-field>
                            </div>
                            <div class="errors center-text" *ngIf="!lastChargeDateValid">
                                <span>Favor selecionar um período máximo de um mês</span>
                            </div>

                        </div>
                        <div fxLayout='column' fxLayoutGap="10px">
                            <mat-slide-toggle [(ngModel)]="enableDueDate">Data do vencimento</mat-slide-toggle>
                            <div fxLayout='row' fxLayoutGap="4px">
                                <mat-form-field  class="input-date">
                                    <input matInput [matDatepicker]="startDueDate" readonly  class="input-date"
                                        (click)="startDueDate.open()" [(ngModel)]="filters.startDueDate"
                                        placeholder="Início:" [max]="filters.endLastChargeDate" disabled="{{!isEnableDueDate()}}">
                                    <mat-datepicker-toggle matSuffix [for]="startDueDate">
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #startDueDate></mat-datepicker>
                                </mat-form-field>
                                <mat-form-field  class="input-date">
                                    <input matInput [matDatepicker]="endDueDate" readonly  class="input-date"
                                        (click)="endDueDate.open()" [(ngModel)]="filters.endDueDate"
                                        placeholder="Fim:" [min]="filters.startDueDate" disabled="{{!isEnableDueDate()}}">
                                    <mat-datepicker-toggle matSuffix [for]="endDueDate">
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #endDueDate></mat-datepicker>
                                </mat-form-field>
                            </div>
                            <div class="errors center-text" *ngIf="!dueDateValid">
                                <span>Favor selecionar um período máximo de um mês</span>
                            </div>

                        </div>

                        <div fxLayout='column' fxLayoutGap="10px">
                            <mat-slide-toggle [(ngModel)]="enablePaymentDate">Data do pagamento
                            </mat-slide-toggle>
                            <div fxLayout='row' fxLayoutGap="4px">
                                <mat-form-field class="input-date">
                                    <input matInput [matDatepicker]="startPaymentDate" readonly   style="width:150px"
                                        (click)="startPaymentDate.open()" [(ngModel)]="filters.startPaymentDate"
                                        placeholder="Início:" [max]="filters.endPaymentDate"  disabled="{{!isEnablePaymentDate()}}">
                                    <mat-datepicker-toggle matSuffix [for]="startPaymentDate" style="width:150px">
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #startPaymentDate></mat-datepicker>
                                </mat-form-field>

                                <mat-form-field class="input-date">
                                    <input matInput [matDatepicker]="endPaymentDate" readonly class="input-date"
                                        (click)="endPaymentDate.open()" [(ngModel)]="filters.endPaymentDate"
                                        placeholder="Fim:" [min]="filters.startPaymentDate" disabled="{{!isEnablePaymentDate()}}">
                                    <mat-datepicker-toggle matSuffix [for]="endPaymentDate">
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #endPaymentDate></mat-datepicker>
                                </mat-form-field>
                            </div>
                            <div class="errors center-text" *ngIf="!paymentDateValid">
                                <span>Favor selecionar um período máximo de um mês</span>
                            </div>

                        </div>

                    </div>

                    <!-- Segunda linha de filtros -->
                    <div fxLayout='row' fxLayoutAlign="start stretch" fxLayout.lt-md="column"
                        fxLayoutAlign.lt-md="space-around stretch" fxLayoutGap="10px">
                        <div fxFlex>
                            <mat-form-field>
                                <input matInput [(ngModel)]="filters.searchField" placeholder="Nome ou documento"
                                    id="searchField">
                            </mat-form-field>
                        </div>
                        <div fxFlex>
                            <mat-form-field>
                                <input matInput [(ngModel)]="filters.transactionSearch"
                                    placeholder="Busque por TID, NSU, número do pedido ou número de autorização">
                            </mat-form-field>
                        </div>
                        


                    </div>
                    <div fxLayout='row' fxLayoutAlign="start stretch" fxLayout.lt-md="column"
                        fxLayoutAlign.lt-md="space-around stretch" fxLayoutGap="10px">
                        <div fxFlex>
                            <form [formGroup]="searchStatusForm">
                                <mat-form-field>
                                    <mat-select class="opcoesFiltro" placeholder="Status da cobrança"
                                        formControlName="chargeStatus" [(ngModel)]="filters.status"
                                        [compareWith]="compareId" multiple>
                                        <mat-option #allSelectedForStatus (click)="toggleAllSelectionForStatus()"
                                            [value]="0">Todos
                                        </mat-option>
                                        <mat-option *ngFor="let aStatus of status" [value]="aStatus"
                                            (click)="tosslePerOneForStatus(allSelectedForStatus.viewValue)">
                                            {{aStatus | translate}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </form>
                        </div>
                        <div fxFlex>
                            <form [formGroup]="searchAffiliateForm">
                                <mat-form-field>
                                    <mat-select class="opcoesFiltro" placeholder="Loja" formControlName="orderAffiliate"
                                        [compareWith]="compareId" multiple [(ngModel)]="filters.affiliateIDs">
                                        <mat-option #allSelectedForAffiliate (click)="toggleAllSelectionForAffiliate()"
                                            [value]="0">Todos </mat-option>
                                        <mat-option *ngFor="let affiliate of affiliate" [value]="affiliate.id"
                                            (click)="tosslePerOneForAffiliate(allSelectedForAffiliate.viewValue)">
                                            {{affiliate.name}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </form>
                        </div>
                        
                        <div fxLayout="row" fxLayoutAlign="end flex-end">
                            <button mat-raised-button class="btn-search" fxLayoutAlign="end"
                                (click)="filter()">Pesquisar</button>
                        </div>
                    </div>

                </div>

            </div>
        </card-box>
    </div>
    <div class="tab-content">
        <div [ngClass]="{'hide' : pageResponse.totalElements == 0}">
            <div class="export">
                <a class="export-link" (click)="downloadCsv()">
                    <span class="export-label">Exportar</span>
                    <i class="fa fa-arrow-circle-down" aria-hidden="true"></i>
                </a>
            </div>
            <table mat-table [dataSource]="transactions" matSort (matSortChange)="handleSort($event)">
                <ng-container matColumnDef="chargeId">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
                    <td mat-cell *matCellDef="let row"> {{row.id}} </td>
                </ng-container>

                <ng-container matColumnDef="transactionDate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Data da última cobrança </th>
                    <td mat-cell *matCellDef="let row"> {{row.lastTransaction.transactionDate | date:
                        'dd/MM/yyyy'}} </td>
                </ng-container>


                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef>Cliente</th>
                    <td mat-cell *matCellDef="let row">{{row.customer.name}}</td>
                </ng-container>

                <ng-container matColumnDef="amount">
                    <th mat-header-cell *matHeaderCellDef>Valor</th>
                    <td mat-cell *matCellDef="let row">{{row.value | CustomCurrency:'BRL'}}</td>
                </ng-container>

                <ng-container matColumnDef="dueDate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Data do vencimento</th>
                    <td mat-cell *matCellDef="let row"> {{row.dueDate | date: 'dd/MM/yyyy HH:mm'}} </td>
                </ng-container>

                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                    <td mat-cell *matCellDef="let row">
                        <span [ngClass]="getStatusColor(row.status)">{{row.status |
                            translate}}</span>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="example-element-row"
                    [class.example-expanded-row]="expandedElement === row" (click)="expandedElement = row">
                </tr>

            </table>
            <mat-paginator [length]="pageResponse.totalElements" [pageSize]="pageResponse.pageSize"
                (page)="pageEvent = handlePage($event)" aria-label="Select page">
            </mat-paginator>
        </div>
        <div [ngClass]="{'hide' : pageResponse.totalElements > 0}" class="no-data-message">
            <span>Nenhuma cobrança para exibir</span>
        </div>
    </div>
</div>