<div class="container">
  <div fxLayout='row' class="mb-3" fxLayout.lt-md="column" fxLayoutGap="10">
    <mat-card>
      <form fxLayout="column" #dadosPagamentoForm="ngForm" class="form-payment" [formGroup]="firstFormGroup">

        <!-- Dados da Compra -->
        <mat-vertical-stepper formArrayName="formArray" linear>

          <mat-step label="Dados da compra" formGroupName="0" [stepControl]="formArray?.get([0])">
            <div>
              <div div fxLayout="column" fxLayoutAlign="space-evenly stretch">
                <div class="pt-2 pb-2">
                  <mat-radio-group [(ngModel)]="typeTransaction" formControlName="typeTransaction"
                    #radioGroup="matRadioGroup" (click)="choiceType(typeTransaction!='RECURRENT')">
                    <mat-radio-button value="RECURRENT">Assinatura</mat-radio-button>
                    <mat-radio-button value="LOOSE">Venda Avulsa</mat-radio-button>
                  </mat-radio-group>
                </div>
                <!-- Affiliate -->
                <div fxFlex fxLayout="column">
                  <div fxLayout="row">
                    <mat-form-field>
                      <mat-select placeholder="Loja" [(ngModel)]="transacaoData.affiliateId" formControlName="loja"
                        required>
                        <mat-option *ngFor="let affiliate of affiliates" [value]="affiliate.id">
                          {{affiliate.name}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <div fxLayout='row' fxLayout.lt-md="column" *ngIf="isRecurringPayment()">
                  <div fxFlex fxLayout="column">
                    <div fxLayout="row">
                      <mat-form-field>
                        <mat-select placeholder="Selecione o Produto" [(ngModel)]="transacaoData.idProduto"
                          formControlName="plan">
                          <mat-option *ngFor="let produto of listaProduto" [value]="produto.id"
                            (click)="selecionaProduto(produto)" id="produto_{{produto.id}}">{{produto.name}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <div fxLayout='column' *ngIf="isOneTimePayment()">
                  <div fxFlex>
                    <mat-form-field>
                      <input matInput placeholder="Valor" [(ngModel)]="loose.value" formControlName="valorPrincipal"
                        currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', allowNegative: false }"
                        maxlength="13" min="0.01" max="999999.99" required>
                    </mat-form-field>
                  </div>
                  <div fxFlex="50">
                    <mat-form-field>
                      <input matInput placeholder="Código de Referência" [(ngModel)]="loose.referenceId"
                        formControlName="referenceId">
                    </mat-form-field>
                  </div>
                  <div fxFlex="row">
                    <mat-form-field>
                      <textarea matInput placeholder="Descrição" [(ngModel)]="transacaoData.description"
                        formControlName="description"></textarea>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
            <div class="adjust-div-buttons">
              <div class="pull-right btn-confirm">
                <button mat-button matStepperNext type="button">Avançar</button>
              </div>
              <div class="bp-form-line"></div>
            </div>
          </mat-step>

          <!-- Dados do cliente -->
          <mat-step label="Dados do cliente" formGroupName="1" [stepControl]="formArray?.get([1])">
            <div fxFlex fxLayout="column">
              <div fxLayout="row" fxFlexAlign="start center">
                <mat-form-field>
                  <input matInput placeholder="CPF ou CNPJ" mask="00.000.000/0000-00||000.000.000-00"
                    [(ngModel)]="clientDataV1.document" (change)="getClienteByDocumentoV1($event)" required
                    formControlName="cpf">
                  <a matSuffix (click)="getClienteByDocumentoV1($event)"><i class="{{ icon }}"
                      matTooltip="{{ toolTip }}"></i></a>
                </mat-form-field>
              </div>
              <div *ngIf="showFieldsClient">
                <div fxLayout='row' fxLayout.lt-md="column">
                  <div fxFlex="65">
                    <mat-form-field>
                      <mat-label>Nome</mat-label>
                      <input matInput placeholder="Nome" [(ngModel)]="clientDataV1.name" required
                        formControlName="nomeCliente" placeholder="Maria Silva">
                    </mat-form-field>
                  </div>
                  <div fxFlex>
                    <mat-form-field>
                      <mat-label>Data de nascimento</mat-label>
                      <input matInput placeholder="Data de Nascimento" mask="00/00/0000"
                        [(ngModel)]="clientDataV1.birthdate" formControlName="aniversario" placeholder="00/00/0000">
                    </mat-form-field>
                  </div>
                </div>
                <div fxLayout='row' fxLayout.lt-md="column">
                  <div fxFlex="65">
                    <mat-form-field class="example-full-width">
                      <mat-label>E-mail</mat-label>
                      <input type="email" matInput formControlName="email" [formControl]="emailFormControl"
                        placeholder="nome@email.com" [(ngModel)]="clientDataV1.email">
                      <mat-error *ngIf="emailFormControl.hasError('email')">
                        Por favor insira um endereço de e-mail válido
                      </mat-error>
                      <mat-error *ngIf="emailFormControl.hasError('required')">
                        Email é <strong>obrigatório</strong>
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div fxFlex>
                    <mat-form-field>
                      <mat-label>Telefone</mat-label>
                      <input matInput [class.invalid-field]="telefoneInvalido" (focus)="telefoneInvalido = false"
                        mask="(00) 0 0000-0000||(00) 0000-0000" placeholder="(11) 99999-9999" ngxPhoneMaskBr
                        [(ngModel)]="inputTelefone" formControlName="telefone" type="text" (input)="updatePhone()">
                    </mat-form-field>
                  </div>
                </div>
                <div fxLayout='row'>
                  <div fxFlex>
                    <mat-form-field class="endereco" *ngIf="changeAddress == true">
                      <mat-select placeholder="Endereço" [(value)]="bestAddress" formControlName="endereco" required>
                        <mat-option (click)="selectAddressV1(0)" value="0">Novo Endereço</mat-option>
                        <mat-option *ngFor="let endereco of addressesList" [value]="endereco"
                          (click)="selectAddressV1(endereco)">
                          {{endereco.street}}, {{endereco.number}} - {{endereco.city}} - {{ endereco.lineTwo }}
                          | {{ endereco.zipcode }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div *ngIf="newAddress" id="clientAddress">
                <div fxLayout='row' fxLayout.lt-md="column">
                  <div fxFlex="60" fxLayout='row' class="get-client-div">
                    <mat-form-field>
                      <input matInput placeholder="CEP" (blur)="consultaCep()" mask="00.000-000"
                        [(ngModel)]="addressSelected.zipCode" formControlName="cep">
                    </mat-form-field>
                    <a (click)="consultaCep()">
                      <i class="{{ cepIcon }}" matTooltip="{{ cepTooltip }}"></i>
                    </a>
                  </div>
                </div>
                <div fxLayout='row' fxLayout.lt-md="column">
                  <div fxFlex="75">
                    <mat-form-field>
                      <input matInput placeholder="Endereço" [(ngModel)]="addressSelected.street"
                        formControlName="logradouro">
                    </mat-form-field>
                  </div>
                  <div fxFlex="20">
                    <mat-form-field>
                      <input matInput placeholder="Nº" maxlength="6" [(ngModel)]="addressSelected.streetNumber"
                        formControlName="numero">
                    </mat-form-field>
                  </div>
                </div>
                <div fxLayout='row' fxLayout.lt-md="column">
                  <div fxFlex="48.5">
                    <mat-form-field>
                      <input matInput placeholder="Complemento" [(ngModel)]="addressSelected.addressLineTwo"
                        formControlName="complemento">
                    </mat-form-field>
                  </div>
                  <div fxFlex="48.5">
                    <mat-form-field>
                      <input matInput placeholder="Bairro" [(ngModel)]="addressSelected.neighborhood"
                        formControlName="bairro">
                    </mat-form-field>
                  </div>
                </div>
                <div fxLayout='row' fxLayout.lt-md="column">
                  <div fxFlex="32.5">
                    <mat-form-field>
                      <mat-select placeholder="Estado" [(ngModel)]="addressSelected.state" formControlName="estado">
                        <mat-option *ngFor="let estado of estados" [value]="estado.uf"
                          (click)="selectCidades(estado.codIbge)">
                          {{estado.uf}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div fxFlex="65">
                    <mat-form-field>
                      <mat-select placeholder="Cidade" [(ngModel)]="cidadeSelected" formControlName="cidade">
                        <mat-option *ngFor="let cidade of cidades" [value]="cidade.codIbge"
                          (change)="setCidade(cidade)">
                          <span>{{cidade.nome}}</span>
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="adjust-div-buttons">
                <div class="pull-right btn-confirm">
                  <button mat-button matStepperPrevious type="button">Voltar</button>
                  <button mat-button matStepperNext type="button">Avançar</button>
                </div>
                <div class="bp-form-line"></div>
              </div>
            </div>
          </mat-step>

          <!-- Dados do pagamento -->
          <mat-step (click)="saleType='paymentLink'" label="Dados do pagamento" formGroupName="2"
            [stepControl]="formArray?.get([2])">
            <div fxFlex fxLayout="column">
              <div padding="10px" fxFlex fxLayout="column" margin="10px">
                <div fxLayout="column">
                  <div fxLayout="row">
                    <div>
                      <div fxLayout='row' fxLayout.lt-md="column">
                        <div fxFlex>
                          <mat-form-field>
                            <mat-label>Número do cartão</mat-label>
                            <input matInput
                              mask="0000 0000 0000||0000 0000 0000 0||0000 0000 0000 00||0000 0000 0000 000||0000 0000 0000 0000||0000 0000 0000 0000 0||0000 0000 0000 0000 00||0000 0000 0000 0000 000"
                              type="text" formControlName="cartao" placeholder="Número do cartão"
                              (ngModelChange)="checkCardBrand()" [(ngModel)]="cardRequest.number" [disabled]="loading"
                              required />
                            <mat-icon *ngIf="checkCardBrand()" matSuffix>
                              <img src="assets/img/brands/{{checkCardBrand()}}.png"
                                style="max-width: 40px; height: auto; vertical-align: middle; margin-left: -20px;" />
                            </mat-icon>
                            <mat-error>
                              Informe um número de cartão válido!
                            </mat-error>
                          </mat-form-field>

                        </div>
                      </div>
                      <div fxLayout='row' class="form-flex" fxLayout.lt-md="column">
                        <div fxFlex>
                          <mat-form-field>
                            <mat-select placeholder="MM" [(ngModel)]="cardInfo.cartaoValidadeMes" required
                              [disabled]="loading" formControlName="mes">
                              <mat-option *ngFor=" let mes of meses " [value]="mes" id="mes_{{mes}}">{{mes}}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                        <div fxFlex>
                          <mat-form-field>
                            <mat-select placeholder="AAAA" [(ngModel)]="cardInfo.cartaoValidadeAno" required
                              [disabled]="loading" formControlName="ano">
                              <mat-option *ngFor=" let ano of anos " [value]="ano" id="ano_{{ano}}">{{ano}}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                        <div fxFlex>
                          <mat-form-field>
                            <input matInput placeholder="Código de Segurança" mask="000||0000"
                              [dropSpecialCharacters]="false" [(ngModel)]="cardInfo.cartaoCvv" [disabled]="loading"
                              formControlName="cvv">
                          </mat-form-field>
                        </div>
                      </div>
                      <div fxLayout='row' fxLayout.lt-md="column">
                        <div fxFlex="60">
                          <mat-form-field>
                            <input matInput placeholder="Nome Titular" maxlength="45"
                              [(ngModel)]="cardRequest.holder.name" required [disabled]="loading"
                              formControlName="nomeTitular">
                          </mat-form-field>
                        </div>
                        <div fxFlex>
                          <mat-form-field>
                            <input matInput placeholder="CPF Titular" mask="000.000.000-00" minlength="11"
                              maxlength="14" [(ngModel)]="cardRequest.holder.document" [disabled]="loading"
                              formControlName="cpfTitular" required>
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!--Installments -->
                <div fxFlex="30" fxLayout="column">
                  <div class="installments-slide installments-qtd"
                    *ngIf="installmentsEnabled && (isOneTimePayment() || isRecurringPayment()) && (saleType == 'paymentLink' || saleType == 'typedSale')">
                    <span>Quantidade de parcelas</span>
                    <mat-form-field class="maxInstallments">
                      <mat-select [(ngModel)]="installments" (selectionChange)="verifyMaxInstallments()"
                        formControlName="installments" required>
                        <mat-option *ngFor="let installment of getInstallmentOptions()" [value]="installment">
                          {{ installment }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="installments-slide"
                    *ngIf="isRecurringPayment() && prod.maxInstallments > 1 && saleType =='typedSale'">
                    <span>Deseja parcelar?</span>
                    <mat-slide-toggle [(ngModel)]="installmentsEnabled" formControlName="installmentsEnabled">
                    </mat-slide-toggle>
                  </div>
                </div>

              </div>
            </div>
            <div class="adjust-div-buttons">
              <div class="pull-right btn-confirm">
                <button mat-button matStepperPrevious type="button">Voltar</button>
                <button id="botao-confirmar" mat-button [disabled]="loading"
                  (click)="doTransaction()">Confirmar</button>
              </div>
            </div>
          </mat-step>
        </mat-vertical-stepper>
      </form>
    </mat-card>

    <!--Resumo da venda-->
    <div class="summary-container" fxFlex fxLayout="column" fxLayoutAlign="start">
      <mat-card fxFlex fxLayout="column" class="card-summary">
        <span>Resumo</span>
        <div fxFlex fxLayout="row" fxLayoutAlign="start">
          <mat-list class="payment-values" fxFlex="100">
            <div *ngIf="isRecurringPayment()" class="payment-values">
              <mat-divider></mat-divider>
              <app-product-card *ngIf="selectedProduct" [product]="selectedProduct"></app-product-card>
            </div>
            <div *ngIf="isOneTimePayment()" class="payment-values">
              <mat-divider></mat-divider>
              <h3 mat-subheader style="padding:0"><strong>Dados da compra</strong></h3>
              <mat-list-item> Valor <span>{{loose.value | CustomCurrency:'BRL' }} </span></mat-list-item>
              <mat-list-item> Data de vencimento <span>{{loose.dueDate | mask: '00/00/0000'}} </span></mat-list-item>
              <mat-list-item *ngIf="loose.referenceId"> Código de referência <span>{{loose.referenceId}}
                </span></mat-list-item>
              <mat-list-item *ngIf="loose.description"> Descrição <span>{{loose.description}} </span></mat-list-item>
            </div>
            <div *ngIf="clientDataV1.name" class="payment-values">
              <mat-divider></mat-divider>
              <h3 mat-subheader style="padding:0"><strong>Dados do cliente</strong></h3>
              <mat-list-item *ngIf="clientDataV1.name"> Nome do cliente <span>{{clientDataV1.name}} </span>
              </mat-list-item>
              <mat-list-item *ngIf="clientDataV1.email"> Email do cliente <span>{{clientDataV1.email}} </span>
              </mat-list-item>
              <mat-list-item *ngIf="clientDataV1.phone"> Telefone do cliente <span>{{ (clientDataV1.phone.code +
                  clientDataV1.phone.number) | mask: '(00) 0 0000-0000||(00) 0000-0000' }} </span>
              </mat-list-item>
            </div>
            <div class="payment-values" *ngIf="verifyStep()" hidden>
              <mat-divider></mat-divider>
              <h3 mat-subheader style="padding:0"><strong>Dados da compra</strong></h3>
              <mat-list-item> Tipo de venda <span>{{saleType | translate}} </span></mat-list-item>
              <div class="payment-values" *ngIf="saleType == 'paymentLink'">
                <mat-list-item> Pix <span> {{paymentMethodPix ? 'Disponível' : 'Indisponível'}} </span></mat-list-item>
                <mat-list-item> Boleto <span> {{paymentMethodBansklip ? 'Disponível' : 'Indisponível'}} </span>
                </mat-list-item>
                <mat-list-item> Cartão de crédito <span> {{paymentMethodCreditCard ? 'Disponível' :
                    'Indisponível'}}</span></mat-list-item>
              </div>
            </div>
          </mat-list>
        </div>
      </mat-card>

    </div>
  </div>
</div>