import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { NgxPermissionsService } from 'ngx-permissions';
import { ApprovedTransactionFilter } from 'src/app/core/common/filters/approved-transactions.filter';
import { CustomCurrencyPipe } from 'src/app/core/helper/pipe/custom-currency.pipe';
import { Administradora } from 'src/app/core/model/administradora';
import { ApprovedTransaction } from 'src/app/core/model/approved-transaction';
import { DataCard, DataCardColors } from 'src/app/core/model/data-card';
import { PagePermissions } from 'src/app/core/permissions/page-permissions';
import { AlertService } from 'src/app/core/services/alert.service';
import { ReportServiceV1 } from 'src/app/core/services/v1/report.service.v1';

@Component({
  selector: 'approved-transaction-report',
  templateUrl: './approved-transaction-report.component.html',
  styleUrls: ['./approved-transaction-report.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ApprovedTransactionReportComponent implements OnInit {

  exportPermission = PagePermissions.REPORT_APPROVED_TRANSACTION_EXPORT;
  showExportBtn: boolean;
  filters: ApprovedTransactionFilter = new ApprovedTransactionFilter();
  expandedElement: ApprovedTransaction | null;
  transactions = new MatTableDataSource<ApprovedTransaction>();
  bandeiras: Administradora[];
  rangeValid: boolean = true;
  loading: boolean = false;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = [
    'empresa',
    'nomeAdquirente',
    'estabelecimento',
    'total',
    'aprovadas',
    'aprovacao',
    'receita'];

  datasCard: DataCard[];

  constructor(private currencyPipe: CustomCurrencyPipe,
    private reportService: ReportServiceV1,
    private permissionsService: NgxPermissionsService) { }

  ngOnInit(): void {
    this.filters.startDate = moment().startOf('month').toDate();
    this.filters.endDate = new Date();
    this.permissionsService.hasPermission(this.exportPermission).then(hasPermission => {
      this.showExportBtn = hasPermission;
    });
  }

  getTotalResult(result: ApprovedTransaction[]) {
    let total = 0;
    let totalReceita = 0;
    let totalAprovadas = 0;
    let estornoTotal = 0;
    let estornoValor = 0;
    let recusadasTotal = 0;
    let recusadasValor = 0;
    for (let row of result) {
      totalReceita += row.receita;
      total += row.total;
      totalAprovadas += row.aprovadas;
      estornoTotal += row.estornoTotal;
      estornoValor += row.estornoValor;
      recusadasTotal += row.recusadasTotal;
      recusadasValor += row.recusadasValor;
    }

    let totalReceitaDataCard: DataCard = { text: 'Faturamento', value: this.currencyPipe.transform(totalReceita/100, 'BRL'), icon: DataCardColors.iconDolar, bgcard: DataCardColors.bgCardValueTotal, mask: true, total: totalAprovadas };
    let totalAprovadasDataCard: DataCard = { text: 'Aprovadas', value: totalAprovadas, icon: DataCardColors.iconValueUp, bgcard: DataCardColors.bgcardValueUp, mask: false, total: totalAprovadas };
    let totalDataCard: DataCard = { text: 'Total de transações', value: total, icon: DataCardColors.iconQuantity, bgcard: DataCardColors.bgcardQuantity, mask: false, total: totalAprovadas };
    // let estornoDataCard: DataCard = { text: 'Estornos', value: this.currencyPipe.transform(estornoValor.toString(), 'BRL'), icon: "fal fa-sync fa-2x", bgcard: "card-estorno", mask: true, total: estornoTotal };
    // let recusadasDataCard: DataCard = { text: 'Não aprovadas', value: this.currencyPipe.transform(recusadasValor.toString(), 'BRL'), icon: DataCardColors.iconValueDown, bgcard: DataCardColors.bgcardValueDown, mask: true, total: recusadasTotal };
    this.datasCard = [totalDataCard, totalAprovadasDataCard, totalReceitaDataCard]
  }

  filter() {
    try {
      this.loading = true;
      const result = this.reportService.reportAprovadas(this.filters).subscribe(
        result => {
          this.transactions = new MatTableDataSource<ApprovedTransaction>(result);
          this.transactions.sort = this.sort;
          this.getTotalResult(result);
        });
    } finally {
      this.loading = false;
    }
  }

}
