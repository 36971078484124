import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { lastValueFrom } from 'rxjs';
import { EstablishmentResponseV0 } from 'src/app/core/model/v0.model';
import { AcquirerSetup, EstablishmentResponseV1, EstablishmentTransactionType } from 'src/app/core/model/v1.model';
import { PagePermissions } from 'src/app/core/permissions/page-permissions';
import { AlertService } from 'src/app/core/services/alert.service';
import { OnEstablishmentV1 } from 'src/app/core/services/v1/establishment.service.v1';

export interface PaymentRoutes {
  brand: string;
  loose: boolean;
  accession: boolean;
  recurrent: boolean;
}

@Component({
  selector: 'app-establishment-details',
  templateUrl: './establishment-details.component.html',
  styleUrls: ['./establishment-details.component.scss']
})
export class EstablishmentDetailsComponent implements OnInit {
  mode: FormMode;
  loading: Boolean;
  crumbs: MenuItem[];
  editCovenantPermissions = PagePermissions.CONVENANT_EDIT;
  establishment: EstablishmentResponseV1 = new EstablishmentResponseV1();
  idAffiliate = null;

  dadosEc: EstablishmentResponseV0;

  //Table
  displayedColumns: string[] = ['brand', 'loose', 'accession', 'recurrent'];
  paymentRoutes: PaymentRoutes[] = [];
  dataSource = [];
  acquirerList: AcquirerSetup[] = [];
  methods: string[] = [];

  constructor(
    private alertService: AlertService,
    private onEstablishmentV1: OnEstablishmentV1,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  async ngOnInit() {
    this.loading = true;
    this.initCrumbs();
    this.getAcquirers();
    await this.loadEstablishment();
    this.loading = false;
  }

  public initCrumbs(): void {
    this.crumbs = [
      { label: 'Lista de Estabelecimentos', routerLink: ['/settings/establishments'] }
    ];
  }

  private async loadEstablishment() {
    let establishmentId = null;
    this.route.params.subscribe(async parametros => {
      establishmentId = parametros['id'] ? parametros['id'] : null;
      this.idAffiliate = parametros['idAffiliate'] ? parametros['idAffiliate'] : null;
      if (establishmentId && this.idAffiliate) {
        //V1
        await this.getEstablishmentById(this.idAffiliate, establishmentId);
        this.modeChange("VIEW");
      } else {
        this.modeChange("NEW");
      }
    });
  }

  async getEstablishmentById(idAffiliate: number, establishmentId: number) {
    try {
      const result = await lastValueFrom(this.onEstablishmentV1.getById(idAffiliate, establishmentId));
      this.establishment = result;
      await this.initPaymentRoutes();
      this.getMethods();
    } catch (error) {
      console.error('Ocorreu um erro ao buscar o estabelecimento:', error);
      this.alertService.handleError('Ocorreu um erro ao buscar o estabelecimento: ' + error);
    }
  }

  async initPaymentRoutes() {
    const brandList: string[] = await this.getBrands();
    for (let i = 0; i < brandList.length; i++) {
      const brand = brandList[i];
      let loose = false;
      let accession = false;
      let recurrent = false;
      for (let x = 0; x < this.establishment.routes.length; x++) {
        const route = this.establishment.routes[x];
        if (route.brand === brand) {
          if (route.type === EstablishmentTransactionType.SINGLE.toString()) {
            loose = true;
          } else if (route.type === EstablishmentTransactionType.FIRST_RECURRING_CHARGE.toString()) {
            accession = true;
          } else if (route.type === EstablishmentTransactionType.RECURRING.toString()) {
            recurrent = true;
          }
        }
      }
      this.paymentRoutes.push({ brand, loose, accession, recurrent });
    }
    this.dataSource = this.paymentRoutes;
  }

  getMethods(): void {
    for (const acquirer of this.acquirerList) {
      if (acquirer.acquirer === this.establishment.acquirer) {
        acquirer.configurations.forEach(configuration => {
          configuration.setup.methods.forEach(method => {
            this.methods.push(method);
          });
        }
        );
      }
    }
  }

  verifyMethod(method: string): boolean {
    if (this.methods == null) {
      return false;
    } else {
      return this.methods.includes(method);
    }
  }

  private async getAcquirers() {
    await this.onEstablishmentV1.onEstablishmentV1().getSetups().subscribe(result => {
      for (const acquirer of result) {
        for (const configuration of acquirer.configurations) {
          if (configuration.isCreable) {
            this.acquirerList.push(acquirer);
            break;
          }
        }
      }
    });
  }

  async getBrands(): Promise<string[]> {
    try {
      const result = await lastValueFrom(this.onEstablishmentV1.getBrands());
      return result;
    } catch (error) {
      console.error('Ocorreu um erro ao buscar as bandeiras:', error);
      this.alertService.handleError('Ocorreu um erro ao buscar as bandeiras: ' + error);
      throw error;
    }
  }

  modeChange(mode: string) {
    switch (mode) {
      case 'EDIT':
        this.initCrumbs();
        this.crumbs.push({ label: this.establishment.id.toString(), routerLink: ['/settings/establishment', this.idAffiliate, this.establishment.id] });
        this.crumbs.push({ label: 'Editar' });
        this.mode = FormMode.EDIT;
        break;
      case 'VIEW':
        this.initCrumbs();
        this.crumbs.push({ label: this.establishment.id.toString(), routerLink: ['/settings/establishment', this.idAffiliate, this.establishment.id] });
        this.mode = FormMode.VIEW;
        break;
      case 'NEW':
        this.crumbs.push({ label: 'Novo Estabelecimento' });
        this.mode = FormMode.NEW;
        break;
      default:
        break;
    }
  }

  backToEstablishmentsList(): void {
    this.router.navigate(['/settings/establishments']);
  }
}

export enum FormMode {
  NEW = 'NEW',
  EDIT = 'EDIT',
  VIEW = 'VIEW'
}
