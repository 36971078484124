import { Injectable, TemplateRef } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { AuthService } from './auth.service';
import { NgxPermissionsService, NgxPermissionsConfigurationService } from 'ngx-permissions';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    constructor(private router: Router, private authService: AuthService, private permissionsService: NgxPermissionsService, private ngxPermissionsConfigurationService: NgxPermissionsConfigurationService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (localStorage.getItem('currentUser')) {
            this.authService.setLoggedIn();
            let currentUser = JSON.parse(localStorage.getItem('currentUser'));

            let userRoles = currentUser.roles;

            let pageRoles = route && route.data["roles"] && route.data["roles"].length > 0 ? route.data["roles"].map(role => role) : null;

            this.ngxPermissionsConfigurationService.addPermissionStrategy('disable', (templateRef: TemplateRef<any>) => {
                templateRef.elementRef.nativeElement.nextSibling.setAttribute('disabled', true)
            });
            this.ngxPermissionsConfigurationService.addPermissionStrategy('enable', (templateRef: TemplateRef<any>) => {
                templateRef.elementRef.nativeElement.nextSibling.setAttribute('disabled', false)
            });
            this.permissionsService.flushPermissions();
            this.permissionsService.loadPermissions(userRoles);

            if (pageRoles != null && userRoles) {
                let hasPermission = pageRoles.some(v => userRoles.indexOf(v) !== -1);

                if (hasPermission) {
                    return true;
                } else {
                    this.router.navigate(['/notallowed']);
                    return false;
                }
            }
            // logged in so return true
            return true;
        }

        // not logged in so redirect to login page
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        return false;
    }

    // canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    //     return this.authService.isLoggedIn
    //       .pipe(take(1), map((isLoggedIn: boolean) => {
    //           if (!isLoggedIn){
    //             this.router.navigate(['/login']);
    //             return false;
    //           }
    //           return true;
    //         }));
    //   }
}