<div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between start" class="mb-2" >
  <mat-toolbar role="toolbar" class="task-header w-75">              
  </mat-toolbar>
  <i (click)="onNoClick()" title="Fechar" class="fas fa-times pt-2 pointer"></i>
</div>
<div class="content">
  <div fxLayout='row' fxLayout.lt-md="column">
    <div fxFlex="55">
      <div fxLayout='column' fxLayout.lt-md="column">
        <div class="box-data-card">
          <span class="text-title">
            <i class="fal fa-database"></i> Dados da Transação</span>
        </div>
        <div class="secondary-box" fxLayout='row'>
          <span fxFlex="80" class="primary-text">
            <!--{{dadosTransacao.nomeEmpresa}}
            <br>--> {{dadosTransacao.estabelecimento}}
          </span>
          <span fxFlex="10">
              <img src="assets/img/acquirers/{{dadosTransacao.nomeAdquirente?.toLowerCase()}}.svg"/>
          </span>
        </div>
        <div class="secondary-box" fxLayout='column'>
          <p>
            <span class="first-info">TID
              <b>#{{dadosTransacao.tid}}</b>
            </span>
            <span class="first-info pull-right">{{dadosTransacao.dtTransacao | date: 'dd/MM/yyyy HH:mm'}}</span>
          </p>
          <p fxLayout='row'>
            <span fxFlex="35">
              <a class="type-data">NSU: </a>
              {{dadosTransacao.numNsu}}
            </span>
            <span fxFlex="35">
              <a class="type-data">Nº AUT: </a>
              {{dadosTransacao.numAutorizacao}}
            </span>
            <span fxFlex="30">
                <a class="primary-text">
                  {{dadosTransacao.statusStr | translate}} <span><i [ngClass]="getStatusColor(dadosTransacao.statusStr)"></i></span>
                </a>
            </span>           
          </p>
          <p fxLayout='row'>
            <span class="first-info w-50">Código de Retorno:
              <b>{{dadosTransacao.lr}}</b>
            </span>   
          </p> 
          <p fxLayout='row'>
            <span class="first-inf">Descrição do Retorno:
              <b>{{dadosTransacao.returnMsg | translate}}</b>
            </span>        
          </p>    
        </div>
       
        <div class="secondary-box" fxLayout='column'>
            <div *ngxPermissionsOnly="clientPermissions; else dontHavePermission; then hasPermission"></div>
            <ng-template #dontHavePermission>
                <span>{{dadosTransacao.nomeCliente}}</span> 
            </ng-template>
            <ng-template #hasPermission>
                <span class="client-link">
                  <a (click)="clienteClick(dadosTransacao.idCliente)" [ngClass]="{'clickable':dadosTransacao.idCliente}">{{dadosTransacao.nomeCliente}}</a>
                </span>
            </ng-template>
          <div fxLayout='row'>
          <span fxFlex="65" class="primary-text value-product">{{dadosTransacao.nomeProduto}}</span>
          <span fxFlex="35" class="pull-right value-product"> <b>{{dadosTransacao.valor | CustomCurrency:'BRL' }}</b></span>
        </div>
        </div>
      </div>
    </div>
    <div fxFlex="45">
      <div class="box-data-card">
        <span class="text-title">
          <i class="fal fa-info-circle"></i> Cartão de Crédito</span>
      </div>
      <div class="credit-card" fxLayout='row' fxLayout.lt-md="column">
        <div fxFlex="70">
          <div fxLayout='column' fxLayout.lt-md="column">
            <i fxFlex class="far fa-credit-card fa-lg"></i>
          </div>
          <div fxLayout='column' fxLayout.lt-md="column">
            <div class="box-data-card">
              <span class="secondary-text">
                <i>NÚMERO</i>
              </span>
            </div>
            <span class="primary-text">{{dadosTransacao.cartaoNumeroUltimosDigitos | creditCardMask}}</span>
            <div class="box-data-card">
              <span class="secondary-text">
                <i>PORTADOR</i>
              </span>
            </div>
            <span class="primary-text">{{dadosTransacao.nomeTitular}}</span>
            <span class="primary-text">CPF: {{dadosTransacao.cpfTitular | mask: '000.000.000-00'}}</span>
          </div>
        </div>
        <div fxFlex="30" class="flag-side">
          <div fxLayout='column' fxLayout.lt-md="column" class="pull-right">
            <img src="assets/img/brands/{{dadosTransacao.bandeira | lowercase}}.png">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


