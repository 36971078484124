<div fxLayout="row" style="margin-bottom: 10px;">
    <div fxFlex fxLayout="row" fxLayoutAlign="start center">
        <p-breadcrumb id="breadcrumb" [model]="crumbs"></p-breadcrumb>
      </div>
      <div fxFlex fxLayout="row" fxLayoutAlign="end center" *ngIf="pagepay && this.view">
        <button type="button" class="btn btn-dark" id="editPagePay"
          (click)="this.pagepayService.editPagepay.emit(false)">Editar</button>
      </div>
</div>
<div class="content" fxLayout='column' fxLayoutGap="10px">
    <div fxLayout='column' fxLayout.lt-md="column" fxLayoutGap="10px">
        <card-box fxFlex="100">
            <app-pagepay-edit *ngIf="pagepay && !this.view" [pagepay]="this.pagepay"></app-pagepay-edit>
            <app-pagepay-view *ngIf="pagepay && this.view" [pagepay]="this.pagepay"></app-pagepay-view>
        </card-box>
        <card-box *ngIf="this.view && pagepay?.uuid">
            <app-shared-link-fb [url]="this.pagepay.uuid"></app-shared-link-fb>
        </card-box>
    </div>
</div>