import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-payment-routes-history',
  templateUrl: './payment-routes-history.component.html',
  styleUrls: ['./payment-routes-history.component.scss'],
})

export class PaymentRoutesHistoryComponent implements OnInit {
  folders: Section[] = [
    {
      name: 'Maria',
      updated: new Date('1/05/23'),
    },
    {
      name: 'Josefina',
      updated: new Date('1/17/16'),
    },
    {
      name: 'Lena',
      updated: new Date('1/28/16'),
    },
  ];

  constructor() { }

  ngOnInit(): void {
  }

}

export interface Section {
  name: string;
  updated: Date;
}
