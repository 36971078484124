import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';

@Component({
  selector: 'app-brands-list',
  templateUrl: './brands-list.component.html',
  styleUrls: ['./brands-list.component.scss']
})
export class BrandsListComponent implements OnInit {
  @ViewChild(MatAccordion) accordion: MatAccordion;
  @Input() brandsList = [];
  @Input() establishmentList = [];
  @Input()editMode: boolean;
  paymantRoute: paymentRoute = new Object() as paymentRoute;

  constructor(
  ) { }

  ngOnInit(): void {
  }
}

export interface paymentRoute {
  type: string;
  establishmentId1: number;
  establishmentId2: number;
  establishmentId3: number;
}
