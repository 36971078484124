<div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px" fxLayoutAlign="start start">
  <div>
    <p><b>Histórico</b></p>
    <ul class="timeline">
      <li *ngFor="let history of transactionList">
        <p><b><i [ngClass]="getTransactionStatusIcon(history?.status)">
            </i><span [ngClass]="getTransactionTextStatusColor(history?.status)"> {{history?.status |
              translate}}</span></b></p>
        <p><i class="far fa-calendar-alt"></i> {{history?.transactionDate | date:
          'dd/MM/yyyy HH:mm'}}</p>
        <p>
          <span class="text-justify" *ngIf="history?.returnMessage">{{history?.returnCode}} -
            {{history?.returnMessage | translate}}
          </span>
        </p>
        <p fxLayout="row" *ngIf="history?.yourReferenceId">Código da transação: {{history?.yourReferenceId}}
          <app-copy-to-clipboard [value]="history?.yourReferenceId"></app-copy-to-clipboard>
        </p>
        <p><b>{{history?.value/100 | CustomCurrency:'BRL'}}</b></p>
        <button type="button" class="btn-detail" (click)="detailTransaction(history, event)">
          <i class="fa fa-info" aria-hidden="true"></i>
        </button>
      </li>
    </ul>
  </div>
</div>
