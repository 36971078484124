<p-breadcrumb id="breadcrumb" [model]="crumbs"></p-breadcrumb>
<div class="content" fxLayout='column' fxLayoutGap="10px">
  <div fxLayout='column' fxLayout.lt-md="column" fxLayoutGap="10px">
    <card-box fxFlex="100">
      <app-plan-edit *ngIf="plan && !this.view" [plan]="this.plan"></app-plan-edit>
      <app-plan-view *ngIf="plan && this.view" [plan]="this.plan"></app-plan-view>
    </card-box>
    
    <card-box *ngIf="this.view">
      <app-shared-link-fb *ngIf="this.plan?.paymentlink" [url]="this.plan?.paymentlink.uuid"></app-shared-link-fb>
    </card-box>

    <app-subscribers-list *ngIf="plan && this.view" [plan]="this.plan"></app-subscribers-list>
  </div>
</div>