export class TransactionsReportFilter {
    start?: Date;
    end?: Date;
    affiliateIDs?: number[];
    transactionSearch?: string;
    bin?: string;
    lastFour?: string;
    document?: string;
    status?: any[];
    paymentMethod: number;
}