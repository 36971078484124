export class ClientsFilter {
    name: string;
    document: string;
    cardBin: string;
    cardLast4:string;
    email:string;
    referenceId:string;
    startRegisterDate: number;
    endRegisterDate:number;

}
