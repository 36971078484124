import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MenuItem } from 'primeng/api';
import { AlertService } from 'src/app/core/services/alert.service';
import { EstablishmentPriority, EstablishmentResponseV1, EstablishmentTransactionType, EstablishmentUpdateRequestV1 } from 'src/app/core/model/v1.model';
import { OnEstablishmentV1 } from 'src/app/core/services/v1/establishment.service.v1';
import { PaymentRoutes } from '../establishment-details/establishment-details.component';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-establishment-edit',
  templateUrl: './establishment-edit.component.html',
  styleUrls: ['./establishment-edit.component.scss']
})
export class EstablishmentEditComponent implements OnInit {
  @ViewChild("editEcForm") editEcForm: NgForm;
  @Input() idAffiliate: number;
  @Input() establishment: EstablishmentResponseV1;
  @Input() paymentRoutes: PaymentRoutes[];
  @Input() methods: string[];
  @Output() modeChange = new EventEmitter<string>();
  crumbs: MenuItem[];
  Priority = EstablishmentPriority; // Expor o enum para o template

  constructor(
    private onEstablishmentV1: OnEstablishmentV1,
    private alertService: AlertService,
  ) { }

  ngOnInit(): void {
  }

  cancel() {
    this.modeChange.emit("VIEW");
  }

  verifyMethod(method: string): boolean {
    if (this.methods == null) {
      return false;
    } else {
      return this.methods.includes(method);
    }
  }

  async update() {
    if (this.editEcForm.valid) {
      const establishmentUpdate: EstablishmentUpdateRequestV1 = new EstablishmentUpdateRequestV1();
      establishmentUpdate.active = this.establishment.active;
      establishmentUpdate.priority = this.establishment.priority;
      establishmentUpdate.requireSecurityCode = this.establishment.requireSecurityCode;
      establishmentUpdate.softDescriptor = this.establishment.softDescriptor;
      for (const credential of this.establishment.credentials) {
        if (!credential.isReadOnly) {
          establishmentUpdate.credentials.push(credential);
        }
      }

      for (const key in this.paymentRoutes) {
        if (this.paymentRoutes.hasOwnProperty(key)) {
          const { brand, loose, accession, recurrent } = this.paymentRoutes[key];

          if (loose) {
            establishmentUpdate.routes.push({ brand, type: EstablishmentTransactionType.SINGLE.toString() });
          }
          if (accession) {
            establishmentUpdate.routes.push({ brand, type: EstablishmentTransactionType.FIRST_RECURRING_CHARGE.toString() });
          }
          if (recurrent) {
            establishmentUpdate.routes.push({ brand, type: EstablishmentTransactionType.RECURRING.toString() });
          }
        }
      }

      this.alertService.loading('Salvando...');
      try {
        await lastValueFrom(this.onEstablishmentV1.onEstablishmentV1().update(this.idAffiliate, this.establishment.id, establishmentUpdate));
        this.alertService.timeMessage('', 'Salvo com sucesso', 2000, true);
        this.modeChange.emit("VIEW");
      } catch (error) {
        this.alertService.handleError(error);
      }
    }
  }
}
