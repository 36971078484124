import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from "@angular/core";
import { Client } from "src/app/core/model/client";
import * as lodash from 'lodash';


@Component({
    selector: 'app-not-found',
    templateUrl: './not-found.component.html',
    styleUrls: ['./not-found.component.scss']
  })

  export class NotFoundComponent implements OnInit {

    ngOnInit(): void {
    }
  }