import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/core/services/alert.service';
import { CustomerImportService } from 'src/app/core/services/v0/customer-import.service';
import { ErrorHandleService } from 'src/app/core/services/error-handle.service';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'customer-import-wizard.component',
  templateUrl: 'customer-import-wizard.component.html',
  styleUrls: ['customer-import-wizard.component.scss'],
})
export class CustomerImportWizardComponent implements OnInit {
  @ViewChild("importForm") importForm;
  isLinear = true;
  firstFormGroup: UntypedFormGroup;
  secondFormGroup: UntypedFormGroup;
  fileUploaded: any = null;
  fileName: string = "";
  request: any = new Object();
  loading: boolean;
  crumbs: MenuItem[];

  constructor(private _formBuilder: UntypedFormBuilder,
    private alertService: AlertService,
    private errorHandleService: ErrorHandleService,
    private customerImportService: CustomerImportService,
    private router: Router
  ) { }

  ngOnInit() {
    this.initCrumbs();
    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ['']
    });
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ['', Validators.required]
    });
  }

  public initCrumbs() {
    this.crumbs = [
        { label: 'Lista de importações', routerLink: ['/sales/import-customers'] },
        { label: 'Nova importação' }
    ];
}

  uploadFile(file: File) {
    this.fileUploaded = file;
    this.fileName = file[0].name;
  }

  upload(): void {
    if (this.importForm.valid) {
      if (this.fileUploaded != null) {
        //this.alertService.loading();
        let filter = {};
        this.customerImportService.scheduleWalletImportExcel(this.fileUploaded, filter).subscribe(result => {
          //this.loading = false;
          //this.alertService.success("Sucesso!", "Arquivo importado com sucesso!");
          this.fileUploaded = null;
          this.request.selectedFile = "";
          this.fileName = "";

        }, error => {
          this.loading = false;
          this.errorHandleService.alertError(error.error);
        }
        )
      } else {

      }
    }
  }

  importList() {
    this.router.navigate(['sales/import-customers']);
}

}
