import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from 'src/app/core/auth/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-shared-link-fb',
  templateUrl: './shared-link-fb.component.html',
  styleUrls: ['./shared-link-fb.component.scss']
})
export class SharedLinkFbComponent implements OnInit {

  @Input() type: 'facebook' | 'twitter' | 'whatsapp' | 'twitter' | 'linkedin';
  navUrl: string;
  @Input()
  url: string;

  constructor(private authService: AuthService) {
  }

  ngOnInit() {
    this.url = this.getUrl(this.url);
  }

  copyInputMessage(inputElement) {
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
  }


  public facebook() {
    let searchParams = new URLSearchParams();
    searchParams.set('app_id', '719240138883714');
    searchParams.set('href', this.url);
    searchParams.set('display', 'popup');
    this.navUrl = 'https://www.facebook.com/dialog/share?' + searchParams;
    this.click(this.navUrl);
  }

  public twitter() {
    let searchParams = new URLSearchParams();
    searchParams.set('url', this.url);
    this.navUrl = 'https://twitter.com/share?' + searchParams;
    this.click(this.navUrl);
  }

  public whatsapp() {
    let searchParams = new URLSearchParams();
    searchParams.set('text', this.url);
    this.navUrl = 'https://api.whatsapp.com/send?' + searchParams;
    this.click(this.navUrl);
  }

  public linkedin() {
    let searchParams = new URLSearchParams();
    searchParams.set('url', this.url);
    searchParams.set('mini', 'true');
    this.navUrl = 'https://www.linkedin.com/shareArticle?' + searchParams;
    this.click(this.navUrl);
  }

  public click(url: string) {
    return window.open(url, "_blank");
  }

  public getUrl(uuid: string) {
    let companyId: number = this.authService.getCurrentUser().idEmpresa;
    return `${environment.checkoutUrl}/${uuid}`;
  }
}
