import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { DataFinder } from "../../helper/data-finder";
import { Administradora } from "../../model/administradora";
import { RefundReason } from '../../model/enum/refund-reason-type';
import { MotivoEstorno } from '../../model/motivo-cancelamento';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  constructor(private http: HttpClient, private dataFinder: DataFinder) { }

  public getAllAdministradoras(token?: string): Observable<any> {
    if (token) {
      let header = new HttpHeaders();
      header = header.set("Authorization", "Bearer " + token);
      return this.http.get<Administradora[]>(`${environment.apiUrl}/api/v1/card-brands`, { headers: header });
    }
    return this.http.get<Administradora[]>(`${environment.apiUrl}/api/v1/card-brands`);
  }

  public findAllMotivosEstorno(): MotivoEstorno[] {
    const refundReasons = Object.values(RefundReason).map(reason => {
      let description: string;

      switch (reason) {
        case RefundReason.IMPROPER_CHARGE:
          description = 'Cobrança Indevida';
          break;
        case RefundReason.DUPLICATE_CHARGE:
          description = 'Cobrança Duplicada';
          break;
        case RefundReason.COSTUMER_WITHDRAWAL:
          description = 'Desistência do Cliente';
          break;
        case RefundReason.OTHERS:
          description = 'Outros';
          break;
        default:
          description = 'Outros';
      }
      return { reason, description } as unknown as MotivoEstorno;
    });

    refundReasons.sort((a, b) => (a.description < b.description) ? -1 : 1);
    return refundReasons;
  }

  public getStatus(): Promise<any> {
    return this.dataFinder.getTransactionStatusJson();
  }
  public getTransactionStatus(): Promise<any> {
    return this.dataFinder.getTransactionStatus();
  }
  public getChargeStatus(): Promise<any> {
    return this.dataFinder.getChargeStatusJson();
  }
  public getAlertStatus(): Promise<any> {
    return this.dataFinder.getAlertStatus();
  }
  public getAlertType(): Promise<any> {
    return this.dataFinder.getAlertType();
  }
}