import { Component, OnInit } from '@angular/core';
import { Route } from '@angular/router';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  crumbs: MenuItem[];
  loading: Boolean;
  router: Route
  constructor() { }

  ngOnInit(): void {
    this.loading = true;
    this.initCrumbs();
    this.loading = false;
  }

  public initCrumbs() {
    this.crumbs = [
      { label: 'Configurações' },
    ];
  }
}
