<div class="container">
  <div fxLayout="column" fxLayoutGap="10px" style="margin-bottom: 10px;">
    <div fxLayout="row">
      <div fxFlex fxLayout="row" fxLayoutAlign="start center">
        <p-breadcrumb id="breadcrumb" [model]="crumbs"></p-breadcrumb>
      </div>
      <div fxFlex fxLayout="row" fxLayoutAlign="end center">
        <div>
          <button mat-raised-button class="btn-create" (click)="newUser()" *ngxPermissionsOnly="createUserPermissions">
            Novo
            Usuário </button>
        </div>
      </div>
    </div>
  </div>
  <div fxLayout="column" fxLayoutGap="10px">

    <table mat-table [dataSource]="usersData" matSort class="mat-elevation-z6">
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> # </th>
        <td mat-cell *matCellDef="let row"> {{row.id}} </td>
      </ng-container>
      <ng-container matColumnDef="nome">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Nome </th>
        <td mat-cell *matCellDef="let row"> <span class="camel-case">{{row.name}}</span> </td>
      </ng-container>
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> E-mail </th>
        <td mat-cell *matCellDef="let row"> {{row.email}} </td>
      </ng-container>
      <ng-container matColumnDef="username">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Usuário </th>
        <td mat-cell *matCellDef="let row"> {{row.username}} </td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
        <td mat-cell *matCellDef="let row">
          <!-- <span class="badge bg-green">{{row.status}}</span> -->
          <div *ngIf="row.status == 'ACTIVE'">
            <span class="badge bg-green">Ativo</span>
          </div>
          <div *ngIf="row.status != 'ACTIVE'">
            <span class="badge bg-gray">Inativo</span>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns;"></tr>

      <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="hover-row" matTooltipPosition="above"
        matTooltip="Clique para visualizar os dados do usuário" (click)="goToUserPage(row.id)"></tr>
    </table>

    <mat-paginator [length]="usersData.data.length" [pageSizeOptions]="[10]"></mat-paginator>
  </div>
</div>