import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { TransactionFilter } from "../../common/filters/transaction.filter";
import { DataFinder } from "../../helper/data-finder";
import { toHttpParams, transactionFiltertoHttpParams } from "../../helper/http.helper";
import { Pageable } from "../../model/pageable";

@Injectable({
    providedIn: 'root'
})
export class TransacaoService {

    constructor(private http: HttpClient, private dataFinder: DataFinder) {
    }

    public getStatus(): Promise<any> {
        return this.dataFinder.getTransactionStatusJson();
    }

    public filterTransaction(transactionFilter: TransactionFilter, pageable: Pageable) {
        return this.http.get<any>(`${environment.apiUrl}/api/transaction/filter`, transactionFiltertoHttpParams(transactionFilter, pageable));
    }

    public getTransactionByParcela(cobrancaId: number): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/api/v1/transactions/charges/${cobrancaId}`);
    }

    getByUri(uri: string): Observable<any> {
        return this.http.get<any>(uri);
    }

}