import { Component, OnInit } from '@angular/core';
import { PagePermissions } from "src/app/core/permissions/page-permissions";
import { TokenService } from 'src/app/core/services/v1/token.service';
import { AlertService } from 'src/app/core/services/alert.service';
import { ActivatedRoute } from '@angular/router';
import { MenuItem } from 'primeng/api';


@Component({
  selector: 'app-token',
  templateUrl: './token.component.html',
  styleUrls: ['./token.component.scss']
})
export class TokenComponent implements OnInit {
  tokenAdmin = PagePermissions.ADMIN
  generatedToken: string
  hasToken: boolean = false
  showToken: boolean = false
  copyMsg: boolean = false
  loading: Boolean = false;
  crumbs: MenuItem[];

  constructor(

    private tokenService: TokenService,
    private alertService: AlertService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.initCrumbs();
    this.alreadyHasToken()
  }

  public initCrumbs() {
    this.crumbs = [
      { label: 'Configurações', routerLink: '/settings' },
      { label: 'Token API' }
    ];
  }

  alreadyHasToken() {
    this.loading = true;
    this.tokenService.getToken().subscribe(
      result => {
        this.hasToken = (result.data.token && result.data.token.length > 0)
      }
    )
    this.loading = false;
  }

  showMsgCopy() {
    this.copyMsg = true;
  }

  generateToken() {
    this.alertService.loading();
    this.tokenService.createToken().subscribe(
      result => {
        this.showToken = true
        this.generatedToken = result.data
        this.hasToken = true
        this.alertService.success("Sucesso", "Token gerado com sucesso!", 3000)
      },
      error => {
        this.alertService.handleError(error)
      }
    )
  }
}
