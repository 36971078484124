import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { getParamsDisputed } from "../../helper/http.helper";
import { Pageable } from "../../model/pageable";
import { DisputeFilter, DisputeResponse } from "../../model/v1.model";

@Injectable({
    providedIn: 'root'
})
export class DisputeService {

    constructor(private http: HttpClient) {
    }

    
    public getAll(filter: DisputeFilter, pageable: Pageable): Observable<any> {
        let params = getParamsDisputed(filter);
        params = params.set('size', pageable.size.toString());
        params = params.set('page', pageable.page.toString());
        return this.http.get<DisputeResponse[]>(`${environment.apiUrl}/api/v1/chargebacks/alerts/list`, {params: params});
    }

    public downloadCsvDisputed(filters: DisputeFilter) {
        let params = getParamsDisputed(filters);
        return this.http.get(`${environment.apiUrl}/api/v1/chargebacks/alerts/list/csv`, {params: params, responseType: 'blob' as 'json' });
      }
}
