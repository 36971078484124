<div class="container">
  <div class="card" autosize>
    <mat-grid-list cols="10" rowHeight="53px" autosize>
      <mat-grid-tile [colspan]="4">
        <mat-form-field>
          <mat-select placeholder="Status" [(ngModel)]="filters.status">
            <mat-option value="0">Todos</mat-option>
            <mat-option value="1">Em andamento</mat-option>
            <mat-option value="2">Aprovada</mat-option>
            <mat-option value="3">Estornada</mat-option>
            <mat-option value="4">Falha</mat-option>
            <mat-option value="5">Inválida</mat-option>
          </mat-select>
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="2">
        <mat-form-field>
          <input matInput [matDatepicker]="startDate" [(ngModel)]="begin" placeholder="Início">
          <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
          <mat-datepicker #startDate></mat-datepicker>
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="2">
        <mat-form-field>
          <input matInput [matDatepicker]="endDate" [(ngModel)]="end" placeholder="Fim">
          <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
          <mat-datepicker #endDate></mat-datepicker>
        </mat-form-field>
      </mat-grid-tile>
      <!-- <mat-grid-tile [colspan]="1">
        <a (click)="toggleFilters()" title="Mais Filtros">
          <i class="fa fa-plus-circle fa-lg" *ngIf="!filtersOpen"></i>
          <i class="fa fa-minus-circle fa-lg" *ngIf="filtersOpen"></i>
        </a>
      </mat-grid-tile>
      <div *ngIf="filtersOpen">

        - ADICAO DE NOVOS FILTROS, CASO NECESSARIO - 

      </div> -->
      <div *ngIf="!filtersOpen">
        <mat-grid-tile [colspan]="1">
          <button mat-raised-button class="btn-search" (click)="filter()">Pesquisar</button>
        </mat-grid-tile>
      </div>
      <div *ngIf="filtersOpen">
        <mat-grid-tile [colspan]="5"></mat-grid-tile>
        <mat-grid-tile [colspan]="1">
          <button mat-raised-button class="btn-search">Pesquisar</button>
        </mat-grid-tile>
      </div>
    </mat-grid-list>
    <div class="errors center-text" *ngIf="!rangeValid">
      <span>Favor selecionar um período máximo de 120 dias.</span>
    </div>
  </div>

  <div class="shadow-padrao">
    <table mat-table [dataSource]="transactions" matSort autosize>
      <!-- ID Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
        <td mat-cell *matCellDef="let row"> {{row.id}} </td>
      </ng-container>

      <ng-container matColumnDef="dtRegistroFormatado">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Data/Hora </th>
        <td mat-cell *matCellDef="let row"> {{row.dtRegistroFormatado}} </td>
      </ng-container>

      <ng-container matColumnDef="estabelecimento">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> EC </th>
          <td mat-cell *matCellDef="let row"> {{row.estabelecimento}} </td>
      </ng-container>

      <ng-container matColumnDef="nomeCliente">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Nome </th>
          <td mat-cell *matCellDef="let row"> {{row.nomeCliente}} </td>
        </ng-container>
  
      <ng-container matColumnDef="bandeira">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Bandeira </th>
          <td mat-cell *matCellDef="let row"> {{row.bandeira}} </td>
      </ng-container>
        
        <ng-container matColumnDef="statusStr">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
            <td mat-cell *matCellDef="let row"> {{row.status}} </td>
          </ng-container>

      <ng-container matColumnDef="valor">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Valor </th>
        <td mat-cell *matCellDef="let row"> {{row.valor}} </td>
      </ng-container>

      <ng-container matColumnDef="codRetorno">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Código Retorno </th>
        <td mat-cell *matCellDef="let row"> {{row.codRetorno}} </td>
      </ng-container>
      

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;">
      </tr>
    </table>

    <mat-paginator [pageSizeOptions]="[10]" [length]="transactions.data.length"></mat-paginator>
  </div>
</div>