<div fxLayout="column" fxLayoutAlign="start stretch">
  <div class="pagamento-da-venda" fxLayout="row" fxLayoutAlign="space-between stretch" fxFlex>
    <b>Webhook</b>
  </div>
  <mat-divider class="mt-2"></mat-divider>
  <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
  <mat-table [dataSource]="orderEvents" *ngIf="orderEvents.length">
    <ng-container matColumnDef="event">
      <mat-header-cell *matHeaderCellDef> Evento </mat-header-cell>
      <mat-cell *matCellDef="let event"> Mudança de status </mat-cell>
    </ng-container>
    <ng-container matColumnDef="date">
      <mat-header-cell *matHeaderCellDef> Data </mat-header-cell>
      <mat-cell *matCellDef="let event"> {{event.eventCreated | date: 'dd/MM/yyyy HH:mm:ss'}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef> Status Webhooks</mat-header-cell>
      <mat-cell *matCellDef="let event">
        <span class="badge badge badge-success" *ngIf="event.httpCode == 200">{{event.httpCode}} OK</span>
        <span class="badge badge-pill badge-danger" *ngIf="event.httpCode != 200">{{event.httpCode}} Falha</span>
        <span class="pl-1">em {{event.eventResultCreated | date: 'dd/MM/yyyy HH:mm:ss'}}</span>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef fxFlex="10"> Ações </mat-header-cell>
      <mat-cell *matCellDef="let event" fxFlex="10">
        <button mat-icon-button [matMenuTriggerFor]="menu">
          <mat-icon fontIcon="more_vert"></mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="retryWebhook(event.eventId)">
            <mat-icon>refresh</mat-icon>
            <span>Reenviar</span>
          </button>
        </mat-menu>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumnsEvents"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumnsEvents;"></mat-row>
  </mat-table>
  <span class="msgEmpty" *ngIf="!orderEvents.length">Nenhum evento para exibir</span>
</div>
