import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { AffiliateMinimalResponse, AffiliateResponse } from "../../model/v1.model";
import { OnEstablishment } from './establishment-service-v1';

@Injectable({
    providedIn: 'root'
})
export class AffiliateServiceV1 {

    constructor(private http: HttpClient) { }

    public onEstablishment(affiliateId: number): OnEstablishment {
        return new OnEstablishment(this.http, affiliateId);
    }

    public getAll(): Observable<AffiliateMinimalResponse[]> {
        return this.http.get<AffiliateMinimalResponse[]>(`${environment.apiUrl}/api/v1/affiliates`);
    }

    public getById(affiliateId: number): Observable<any> {
      return this.http.get<any>(`${environment.apiUrl}/api/v1/affiliates/${affiliateId}`);
    }

    public getByUri(uri: string): Observable<AffiliateResponse> {
        return this.http.get<AffiliateResponse>(uri);
    }

    public getByUriWithToken(uri: string, token: string): Observable<AffiliateResponse> {
        let options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            })
        };
        return this.http.get<AffiliateResponse>(uri, options);
    }

    public uploadImage(id: number, request: Blob) {
        let formData: FormData = new FormData();
        formData.append('file', request);
        return this.http.post<any>(`${environment.apiUrl}/api/v1/affiliates/${id}/image`, formData);
    }
}
