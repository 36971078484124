import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { NgxPermissionsService } from 'ngx-permissions';
import { TransactionByReasonFilter } from 'src/app/core/common/filters/approved-transactions.filter';
import { CustomCurrencyPipe } from 'src/app/core/helper/pipe/custom-currency.pipe';
import { StatusHelper } from 'src/app/core/helper/status-helper';
import { AcquirerTransactionCodeDto } from 'src/app/core/model/acquirer-transaction-code-dto';
import { Administradora } from 'src/app/core/model/administradora';
import { Adquirente } from 'src/app/core/model/adquirente';
import { DataCard, DataCardColors } from 'src/app/core/model/data-card';
import { Ec } from 'src/app/core/model/ec';
import { Estabelecimento } from 'src/app/core/model/estabelecimento';
import { AffiliateMinimalResponse, PlanResponse, TransactionType } from 'src/app/core/model/v1.model';
import { PagePermissions } from 'src/app/core/permissions/page-permissions';
import { AlertService } from 'src/app/core/services/alert.service';
import { CovenantService } from 'src/app/core/services/v0/covenant.service';
import { PaymentGatewayService } from 'src/app/core/services/v0/payment-gateway.service';
import { AffiliateServiceV1 } from 'src/app/core/services/v1/affiliate.service.v1';
import { PlanServiceV1 } from 'src/app/core/services/v1/plan.service.v1';
import { ReportServiceV1 } from 'src/app/core/services/v1/report.service.v1';
declare var $: any;

@Component({
  selector: 'acquirer-message-transaction-report',
  templateUrl: './acquirer-message-transaction-report.component.html',
  styleUrls: ['./acquirer-message-transaction-report.component.scss']
})
export class AcquirerMessageTransactionReportComponent implements OnInit {

  exportPermission = PagePermissions.REPORT_ACQUIRER_MESSAGE_EXPORT;
  showExportBtn: boolean;
  filters: TransactionByReasonFilter = new TransactionByReasonFilter();
  transactions = new MatTableDataSource<AcquirerTransactionCodeDto>();
  bandeiras: Administradora[];
  rangeValid: boolean = true;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = [
    'nomeAdquirente',
    'codRetorno',
    'msgRetorno',
    'statusStr',
    'quantidade',
    'percentual',
    'valorMedio',
    'valorTotal'];

  estabelecimentos: Estabelecimento[];
  adquirentes: Adquirente[];
  ecs: Ec[];
  planos: PlanResponse[];

  filteredEstabelecimentos: Estabelecimento[];
  filteredAdquirentes: Adquirente[];
  establishmentSelected: Ec;
  datasCard: DataCard[];
  loading: boolean = true;
  loadingTab: boolean = true;
  types: any[];
  searchUserForm: UntypedFormGroup;
  @ViewChild('allSelected') private allSelected: MatOption;
  affiliate: AffiliateMinimalResponse[];
  searchAffiliateForm: UntypedFormGroup;
  @ViewChild('allSelectedForAffiliate') private allSelectedForAffiliate: MatOption;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private currencyPipe: CustomCurrencyPipe,
    private alertService: AlertService,
    private permissionsService: NgxPermissionsService,
    private reportServiceV1: ReportServiceV1,
    private statusHelper: StatusHelper,
    private conveniosService: CovenantService
  ) { }

  ngOnInit(): void {
    this.searchUserForm = this.formBuilder.group({
      userType: new UntypedFormControl('')
    });
    this.types = Object.values(TransactionType).filter(x => typeof x === 'string');
    this.filters.start = moment().startOf('month').toDate();
    this.filters.end = new Date();

    this.conveniosService.getAllCovenants().subscribe(result => {
      this.ecs = result
      this.establishmentSelected = this.ecs[0];
      this.filter();
    });

    this.searchAffiliateForm = this.formBuilder.group({
      orderAffiliate: new UntypedFormControl('')
    });
    this.permissionsService.hasPermission(this.exportPermission).then(hasPermission => {
      this.showExportBtn = hasPermission;
    });
  }

  loadData() {
    this.loadingTab = true;
    this.filter();
  }

  getTotalResult(result: AcquirerTransactionCodeDto[]) {
    let totalQtdTransacoes = 0;
    let totalTicketMedio = 0;
    /** Total em quantidade */
    let totalAprovadasQtde = 0;
    let totalRecusadasQtde = 0;
    /*** Total em REAL */
    let totalAprovadas = 0;
    let totalRecusadas = 0;
    let total = 0;

    for (let row of result) {

      if (row.statusNumber != 3 && row.statusNumber != 7 && row.statusNumber != 10 && row.statusNumber != 12) {
        totalQtdTransacoes += row.quantidade;
        totalTicketMedio += row.valorMedio;
        total += row.valorTotal;
      }

      if (row.statusNumber == 2) {
        totalAprovadas += row.valorTotal;
        totalAprovadasQtde += row.quantidade;
      } else if (row.statusNumber == 8 || row.statusNumber == 9) { // NOT_AUTHORIZED = 8 + NOT_APPROVED + 9;
        totalRecusadas = totalRecusadas + row.valorTotal;
        totalRecusadasQtde += row.quantidade;
      }
    }

    if (totalTicketMedio > 0) totalTicketMedio = Math.round(totalTicketMedio / result.length);

    let totalQtdDataCard: DataCard = { text: 'Total Transações', value: totalQtdTransacoes, icon: DataCardColors.iconQuantity, bgcard: DataCardColors.bgcardQuantity, mask: false, total: false };
    let totalAprovadasDataCard: DataCard = { text: 'Aprovadas', value: this.currencyPipe.transform(totalAprovadas.toString(), 'BRL'), icon: DataCardColors.iconValueUp, bgcard: DataCardColors.bgcardValueUp, mask: true, total: totalAprovadasQtde };
    let totalRecusadasDataCard: DataCard = { text: 'Recusadas', value: this.currencyPipe.transform(totalRecusadas.toString(), 'BRL'), icon: DataCardColors.iconValueDown, bgcard: DataCardColors.bgcardValueDown, mask: true, total: totalRecusadasQtde };
    this.datasCard = [totalQtdDataCard, totalAprovadasDataCard, totalRecusadasDataCard]
  }
  getEstabelecimentoById(idEstabelecimento: number) {
    for (let estabelecimento of this.estabelecimentos) {
      if (estabelecimento.idEstabelecimento == idEstabelecimento) {
        return estabelecimento;
      }
    }
    return null;
  }

  getStatusColor(status) {
    return this.statusHelper.getTransacaoStatusColor(status);
  }

  confirmDateRange() {
    let start = moment(this.filters.start);
    let end = moment(this.filters.end);
    let difference = end.diff(start, 'days');
    if (difference >= 31) {
      this.rangeValid = false;
    } else {
      this.filters.start = this.filters.start;
      this.filters.end = this.filters.end;
      this.rangeValid = true;
    }
    return this.rangeValid;
  }

  filter() {
    if (this.confirmDateRange()) {
      this.loading = true;
      this.filters.establishmentId = this.establishmentSelected.idAdquirenteEstabelecimento;
      this.reportServiceV1.reportStatusAdquirente(this.filters)
        .subscribe(result => {
          this.transactions = new MatTableDataSource<AcquirerTransactionCodeDto>(result);
          this.transactions.sort = this.sort;
          this.actionLoading(false);
          this.getTotalResult(result);
        },
          error => {
            this.actionLoading(false);
            this.alertService.error("Erro", "Ocorreu um erro interno em nosso servidor. Se o erro persistir, por favor, contate o suporte.");
          });
    }
  }

  actionLoading(status: boolean) {
    setTimeout(() => {
      this.loading = status;
      this.loadingTab = status;
    }, 500);

  }

  compareId(id1: number, id2: number) {
    if (id2 === 0) {
      return id1 === 0;
    }
    return id1 && id2 && id1 === id2;
  }

  tosslePerOne(all) {
    if (this.allSelected.selected) {
      this.allSelected.deselect();
      return false;
    }
    if (this.searchUserForm.controls.userType.value.length == this.types.length)
      this.allSelected.select();
  }
  toggleAllSelection() {
    if (this.allSelected.selected) {
      this.searchUserForm.controls.userType
        .patchValue([...this.types.map(item => item), 0]);
    } else {
      this.searchUserForm.controls.userType.patchValue([]);
    }
  }

  tosslePerOneForAffiliate(all) {
    if (this.allSelectedForAffiliate.selected) {
      this.allSelectedForAffiliate.deselect();
      return false;
    }
    if (this.searchAffiliateForm.controls.orderAffiliate.value.length == this.affiliate.length) {
      this.allSelectedForAffiliate.select();
    }
  }
  toggleAllSelectionForAffiliate() {
    if (this.allSelectedForAffiliate.selected) {
      this.searchAffiliateForm.controls.orderAffiliate
        .patchValue([0, ...this.affiliate.map(item => item.id)]);

    } else {
      this.searchAffiliateForm.controls.orderAffiliate.patchValue([]);
    }
  }

}


