import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { MenuItem } from "primeng/api";
import { Client } from 'src/app/core/model/client';
import { PagePermissions } from 'src/app/core/permissions/page-permissions';
import { AlertService } from 'src/app/core/services/alert.service';
import { TransacaoService } from '../../../core/services/v0/transaction.service';
import { ClientDetailsSummaryComponent } from './summary/summary.component';
import { CustomerService } from 'src/app/core/services/v0/customer.service';

@Component({
  selector: 'app-client-details',
  templateUrl: './client-details.component.html',
  styleUrls: ['./client-details.component.scss']
})

export class ClientDetailsComponent implements OnInit {
  signatureCancelPermissions = PagePermissions.CUSTOMER_EDIT_SIGNATURE_CANCEL;
  refundPermissions = PagePermissions.CUSTOMER_EDIT_REFUND;
  chargebackPermissions = PagePermissions.CUSTOMER_EDIT_CHARGEBACK;
  chargeAndRefundPermissions = PagePermissions.CUSTOMER_EDIT_REFUND_CHARGEBACK;
  transactionDetailsPermissions = PagePermissions.TRANSACTION_DETAILS;
  transactionParcelPermissions = PagePermissions.TRANSACTION_PARCEL;
  transactionPermissions = PagePermissions.TRANSACTION;
  clientEditPermissions = PagePermissions.CUSTOMER_EDIT;

  @ViewChild('summary') summary: ClientDetailsSummaryComponent;

  viewTab = {
    summary: true,
    signature: true,
    card: false,
    loose: true
  };

  loading = true;
  listaStatus = [];
  client: Client;
  crumbs: MenuItem[];

  constructor(
    private route: ActivatedRoute,
    private clientService: CustomerService,
    private transactionService: TransacaoService,
    public dialog: MatDialog,
    private alertService: AlertService,
    private permissionsService: NgxPermissionsService
  ) {
  }

  id = this.route.snapshot.paramMap.get('id');
  idPedido = this.route.snapshot.paramMap.get('idPedido');

  ngOnInit() {
    this.initCrumbs();
    this.transactionService.getStatus().then(data => {
      this.listaStatus = data.cobrancaStatus;
      this.getClientData();
    });
    this.loading = false;
  }

  initCrumbs() {
    this.crumbs = [
      { label: 'Clientes', routerLink: ['/subscribers'] },
      { label: this.route.snapshot.paramMap.get('id') }
    ];
  }

  hasViewAba(index, filter = true) {
    this.viewTab[index] = true;
    if (index == 'summary' && filter) {
      this.summary.filter();
    }
  }

  getClientData(sumarry = true) {
    this.clientService.getById(this.id).subscribe(result => {
        this.client = result;
        if (sumarry) {
          this.hasViewAba('summary', false);
        }
      });
  }

  filter() {
  }
}

