<mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
<div class="content" *ngIf="!loading">

  <div fxLayout="column" fxLayoutGap="10px" style="margin-bottom: 10px;">
    <div fxLayout="column" fxLayout.gt-xs="row">
      <div fxFlex fxLayout="row" fxLayoutAlign="start center">
        <p-breadcrumb id="breadcrumb" [model]="crumbs"></p-breadcrumb>
      </div>
      <div fxFlex fxLayout="row" fxLayoutAlign="end center">
        <div *ngIf="mode == 'VIEW'">
          <button *ngxPermissionsOnly="editCovenantPermissions" mat-raised-button name="editButton" class="btn-edit"
            (click)="modeChange('EDIT')">Editar</button>
        </div>
      </div>
    </div>
  </div>

  <div>
    <mat-card class="mb-3" fxFlex fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="start start" fxLayoutGap="10px">
      <div fxFlex>
        <mat-card-title *ngIf="dadosEc && mode != 'NEW'">
          <!-- <img name="imgAcquirer" style="max-width: 100px;"
            src="assets/img/acquirers/semmargem/{{dadosEc.paymentGateway.id}}.svg" /> -->
        </mat-card-title>
        <mat-card-title *ngIf="dadosEc && mode != 'NEW'">{{dadosEc.affiliate?.businessName}}</mat-card-title>
        <mat-card-subtitle *ngIf="dadosEc && mode != 'NEW'">
          {{dadosEc?.affiliate?.document | mask: '00.000.000/0000-00'}}
          <mat-card-title fxLayout="row wrap">
            <span [hidden]="mode != 'VIEW'"
              [class]="establishment.active ? 'badge badge-success mr-1' : 'badge badge-danger mr-1'">{{establishment.active
              ? "Ativo" : "Inativo"}}</span>
            <span class="badge badge-info mr-1" [hidden]="mode != 'VIEW'">{{"Prioridade " +
              establishment.priority}}</span>
            <span class="badge badge-info mr-1" [hidden]="mode != 'VIEW'" *ngIf="verifyMethod('CREDIT_CARD')">
              {{ establishment.requireSecurityCode ? "CVV Obrigatório": "CVV Opcional" }}</span>
            <span class="badge badge-info mr-1" [hidden]="mode == 'NEW'">{{ ("establishment.version." +
              establishment.version) |
              translate }}</span>
          </mat-card-title>
        </mat-card-subtitle>
        <mat-card-content fxLayout="row wrap" fxLayoutGap *ngIf="mode != 'NEW'">
          <div>
            <mat-form-field *ngIf="mode == 'VIEW'" appearance="fill">
              <mat-label>Conexão</mat-label>
              <input matInput disabled value="{{ 'establishment.acquirer.' + establishment.acquirer | translate }}">
            </mat-form-field>
          </div>
          <div *ngFor="let field of establishment.credentials">
            <mat-form-field *ngIf="mode != 'NEW' && field != null" [hidden]="mode == 'EDIT' && !field.isReadOnly"
              appearance="fill">
              <mat-label>{{("establishment.field."+field.field) | translate}}</mat-label>
              <input matInput disabled value="{{field.value}}">
            </mat-form-field>
          </div>
          <div>
            <mat-form-field *ngIf="mode == 'VIEW'" [hidden]="!verifyMethod('CREDIT_CARD')" appearance="fill">
              <mat-label>Soft Descriptor</mat-label>
              <input matInput disabled value="{{establishment.softDescriptor}}">
            </mat-form-field>
          </div>
        </mat-card-content>
        <mat-divider *ngIf="mode == 'EDIT'"></mat-divider>
        <mat-card-content class="w-50 pt-3" *ngIf="mode == 'EDIT'">
          <app-establishment-edit (modeChange)="modeChange($event)" [establishment]="establishment"
            [idAffiliate]="idAffiliate" [paymentRoutes]="paymentRoutes" [methods]="methods"></app-establishment-edit>
        </mat-card-content>
        <mat-card-content *ngIf="mode == 'NEW'">
          <app-establishment-create [mode]="NEW"></app-establishment-create>
        </mat-card-content>
        <mat-divider *ngIf="mode == 'VIEW'"></mat-divider>
        <mat-card-actions *ngIf="mode == 'VIEW'" class="pl-2">
          <button mat-button (click)="backToEstablishmentsList()">Voltar</button>
        </mat-card-actions>
        <mat-card-footer>
        </mat-card-footer>
      </div>


      <table fxFlex="40" mat-table [dataSource]="dataSource" class="border-left"
        *ngIf="mode != 'NEW' && verifyMethod('CREDIT_CARD')">

        <ng-container matColumnDef="brand">
          <th mat-header-cell *matHeaderCellDef class="text-center"> Bandeira </th>
          <td mat-cell *matCellDef="let route" class="text-center">
            <img name="imgAcquirer" style="max-width: 35px;"
              src="assets/img/brands/{{route.brand.toLowerCase()}}.png" />
          </td>
        </ng-container>

        <ng-container matColumnDef="loose">
          <th mat-header-cell *matHeaderCellDef class="text-center"> Avulsas </th>
          <td mat-cell *matCellDef="let route" class="text-center">
            <mat-checkbox matInput [(ngModel)]="route.loose" [disabled]="mode == 'VIEW'"></mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="accession">
          <th mat-header-cell *matHeaderCellDef class="text-center"> Novas assinaturas </th>
          <td mat-cell *matCellDef="let route" class="text-center">
            <mat-checkbox matInput [(ngModel)]="route.accession" [disabled]="mode == 'VIEW'"></mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="recurrent">
          <th mat-header-cell *matHeaderCellDef class="text-center"> Recorrência </th>
          <td mat-cell *matCellDef="let route" class="text-center">
            <mat-checkbox matInput [(ngModel)]="route.recurrent" [disabled]="mode == 'VIEW'"></mat-checkbox>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;">
        </tr>
      </table>

    </mat-card>
  </div>
</div>
