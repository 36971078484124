import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class PerfilService {

  constructor(private http: HttpClient) { }
  
  findAll(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/api/v1/profiles`);
  }
  

}
