import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, NgForm } from "@angular/forms";
import { Router } from "@angular/router";
import { BempaggoConstants } from "src/app/core/helper/string-utils";
import { AffiliateMinimalResponse, PagePayPutRequest, PagePayResponse } from "src/app/core/model/v1.model";
import { AlertService } from "src/app/core/services/alert.service";
import { AffiliateServiceV1 } from "src/app/core/services/v1/affiliate.service.v1";
import { ImageUploadService } from "src/app/core/services/v1/image-upload.service";
import { PagePayServiceV1 } from "src/app/core/services/v1/pagepay.service.v1";

@Component({
    selector: 'app-pagepay-edit',
    templateUrl: './pagepay-edit.component.html',
    styleUrls: ['./pagepay-edit.component.scss']
})
export class PagePayEditComponent implements OnInit {
    @ViewChild("pagepayForm", {static: true}) form: NgForm;
    @Input()
    pagepay: PagePayResponse;
    colors: any[];
    name: string;
    description: string;
    amount: number;
    addressRequired: boolean;
    birthdateRequired: boolean;
    sendEmail: boolean;
    expiration: Date;
    returnUrl: string;
    limit: number;
    hasNotSaleLimit: boolean;
    hasNotExpiration: boolean;
    active: boolean;
    maxInstallments: number;
    installments: number[];
    croppedImage: Blob;
    affiliates: AffiliateMinimalResponse[];
    searchAffiliateForm: UntypedFormGroup;

    constructor(
        private router: Router,
        public pagepayService: PagePayServiceV1,
        private alertService: AlertService,
        private imageUploadService: ImageUploadService,
        private affiliateService: AffiliateServiceV1,
        private formBuilder: UntypedFormBuilder,
    ) { }

    ngOnInit() {
        this.installments = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
        //this.hasNotSaleLimit = (this.pagepay.limit == BempaggoConstants.JAVA_MAX_INT);
        this.hasNotExpiration = this.pagepay.expiration ? true : false;
        this.name = this.pagepay.name;
        this.description = this.pagepay.description;
        this.amount = this.pagepay.amount;
        this.addressRequired = this.pagepay.addressRequired;
        this.birthdateRequired = this.pagepay.birthdateRequired;
        this.sendEmail = this.pagepay.sendEmail;
        this.expiration = this.pagepay.expiration ? new Date(this.pagepay.expiration) : null;
        this.returnUrl = this.pagepay.returnUrl;
        this.limit = this.pagepay.limit == BempaggoConstants.JAVA_MAX_INT ? null : this.pagepay.limit;
        this.active = this.pagepay.active;
        this.maxInstallments = this.pagepay.maxInstallments;
        this.imageUploadService.imageCrop.subscribe(result => this.croppedImage = result);
        this.affiliateService.getAll().subscribe(result => this.affiliates = result);
        this.searchAffiliateForm = this.formBuilder.group({
        orderAffiliate: new UntypedFormControl('')
    });
    }

    update() {
        if (this.form.valid) {
            let request = this.form.value as PagePayPutRequest;
            request.addressRequired = true;
            request.limit = BempaggoConstants.JAVA_MAX_INT;
            this.pagepayService.update(this.pagepay, request).subscribe(
                result => {
                    this.pagepay.name = this.name;
                    this.pagepay.description = this.description;
                    this.pagepay.amount = this.amount;
                    this.pagepay.addressRequired = this.addressRequired;
                    this.pagepay.birthdateRequired = this.birthdateRequired;
                    this.pagepay.sendEmail = this.sendEmail;
                    this.pagepay.expiration = this.hasNotExpiration ? this.expiration : null;
                    this.pagepay.returnUrl = this.returnUrl;
                    this.pagepay.limit = this.limit;
                    this.pagepay.active = this.active;
                    this.upload();
                    this.goBack();
                }, error => {
                    this.alertService.handleError(error);
                    this.pagepayService.editPagepay.emit(false);
                }
            );
        }
    }

    private upload() {
        if (this.croppedImage) {
            this.pagepayService.uploadImage(this.pagepay.id, this.croppedImage).subscribe(
                error => {
                    this.alertService.handleError(error);
                }
            );
        }
    }

    goBack() {
        this.pagepayService.editPagepay.emit(true);
    }
}

