import { Component, OnInit, Input} from "@angular/core";

@Component({
    selector: 'app-not-allowed',
    templateUrl: './not-allowed.component.html',
    styleUrls: ['./not-allowed.component.scss']
  })

  export class NotAllowedComponent implements OnInit {

    @Input() classAux = '';

    ngOnInit(): void {
    }
  }