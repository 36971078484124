<div style="position: relative; width: 98%; height: 100%;" id="editProduct">
    <form fxLayout="column" fxLayoutGap="10px" #planForm="ngForm" name="planForm" (ngSubmit)="update()">
        
        <div class="shadow-none border-0">
            <div class="card-body bg-light">
                <div fxLayout="column">
                    <app-image-upload [imageSrc]="plan.paymentlink ? plan.paymentlink.image : null"></app-image-upload>
                    <div>
                        <mat-form-field>
                            <input matInput placeholder="Nome" maxlength="40" id="name" name="name"
                                [(ngModel)]="this.name" required>
                            <mat-error id="nameError">
                                Nome é obrigatório
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div fxLayout="row" fxLayoutGap="5px">
                        <mat-form-field>
                            <input matInput placeholder="SKU" maxlength="40" id="sku" name="sku" #innputSku="ngModel"
                                [(ngModel)]="this.sku" [plan]="this.plan" skuUnique>
                            <i class="fal fa-question-circle" matTooltip="Código identificador único do plano."
                                matSuffix></i>
                            <mat-error id="skuError"
                                *ngIf="innputSku.invalid && (innputSku.dirty || innputSku.touched)">
                                SKU indisponível
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field>
                            <input matInput placeholder="Identificação na Fatura" maxlength="13"
                                [patterns]="alphanumericPattern" mask="aaaaaaaaaaaaa" id="softDescriptor"
                                name="softDescriptor" [(ngModel)]="softDescriptor">
                            <i matSuffix class="fal fa-question-circle"
                                matTooltip="No caso da forma de pagamento ser cartão de crédito, informe qual a identificação deverá ser mostrada na fatura."></i>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="end">
            <div fxLayout="row" fxLayoutGap="10px">
                <button type="button" class="btn btn-danger" id="cancel" (click)="goBack()">Cancelar</button>
                <button type="button" class="btn btn-info" id="save" type="submit"
                    [disabled]="!this.form.valid">Salvar</button>
            </div>
        </div>
    </form>
</div>