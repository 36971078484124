<div class="content" fxLayout='column' fxLayoutGap="10px">
  <div fxLayout='row' fxLayout.lt-md="row">
    <card-box fxFlex="100">
      <div class="chart-container mt-4" style="position: relative; width: 98%; height: 100%;">
        <div class="content h-100 mt-4 pb-5" fxLayout="row" fxLayoutAlign="center center" *ngIf="loadingTab">
          <mat-spinner></mat-spinner>
        </div>
        <mat-progress-bar mode="indeterminate" *ngIf="loading && !loadingTab"></mat-progress-bar>

        <div class="row" fxLayout="column" *ngIf="!loadingTab">
          <div class="view-filter" display="flex" fxLayout='row wrap' fxLayout.lt-md="column">
            <div fxFlex="100" class="filter-trans card-body bg-light pb-4 pt-3" fxLayout='row'
              fxLayoutAlign="space-between center" fxLayoutGap="20px">
              <div fxFlex fxLayout='column' fxLayoutGap="20px">
                <div fxLayout='row' fxLayout.lt-md="column" fxLayoutGap="10px">
                  <div fxFlex>
                    <mat-form-field>
                      <input matInput [matDatepicker]="startDate" readonly (click)="startDate.open()"
                        [(ngModel)]="filters.start" placeholder="Início" [max]="filters.end">
                      <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
                      <mat-datepicker #startDate></mat-datepicker>
                    </mat-form-field>
                  </div>

                  <div fxFlex>
                    <mat-form-field>
                      <input matInput [matDatepicker]="endDate" readonly (click)="endDate.open()"
                        [(ngModel)]="filters.end" placeholder="Fim" [min]="filters.start">
                      <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
                      <mat-datepicker #endDate></mat-datepicker>
                    </mat-form-field>
                  </div>
                </div>

                <div fxLayout='row' fxLayout.lt-md="column" fxLayoutGap="10px">
                  <div fxFlex>
                    <mat-form-field>
                      <mat-select class="opcoesFiltro" placeholder="Estabelecimento"
                        [(ngModel)]="establishmentSelected" [compareWith]="compareId">
                        <mat-option *ngFor="let ec of ecs" [value]="ec">
                          {{ec.nomeAdquirente}} - {{ec.codEstabelecimento}} - {{ec.nomeFantasia}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>

              <button mat-raised-button class="btn-search" fxLayoutAlign="end" (click)="filter()">Pesquisar</button>

            </div>

          </div>

          <div class="errors center-text" *ngIf="!rangeValid">
            <span>Favor selecionar um período máximo de 31 dias.</span>
          </div>
        </div>

        <div class="tab-content">
          <div class="tab-pane fade show active" id="nav-resumo" *ngIf="!loading">


            <div fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between none" fxLayout="column"
              class="report-data-card mt-4" *ngIf="datasCard">
              <div fxFlex="100">
                <data-card *ngFor="let index of [0,1,2]"
                  [ngStyle.gt-sm]="{'margin-right': ( index <= 3 ? '10px': '0px' )  }" [data]="datasCard[index]"
                  fxFlex="33"></data-card>
              </div>

              <div fxFlex>
                <data-card *ngFor="let index of [3,4,5]" [data]="datasCard[index]"
                  [ngStyle.gt-sm]="{'margin-right': ( index < 5 ? '10px': '0px' )  }" fxFlex="33"></data-card>
              </div>
            </div>

            <div [ngClass]="{'hide' : !transactions.data || !transactions.data.length}">
              <div class="div-table">
                <table mat-table [dataSource]="transactions" matSort style="width: 100%;">

                  <ng-container matColumnDef="nomeAdquirente">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Adquirente </th>

                    <td mat-cell *matCellDef="let row"> <img
                        src="assets/img/acquirers/{{row.nomeAdquirente?.toLowerCase()}}.svg" />

                    </td>
                  </ng-container>

                  <ng-container matColumnDef="codRetorno">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Código </th>
                    <td mat-cell *matCellDef="let row"> {{row.codRetorno}}</td>
                  </ng-container>

                  <ng-container matColumnDef="msgRetorno">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Descrição </th>
                    <td mat-cell *matCellDef="let row"> {{row.msgRetorno}} </td>
                  </ng-container>
                  <ng-container matColumnDef="statusStr">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
                    <td mat-cell *matCellDef="let row">
                      <span class="badge" [ngClass]="getStatusColor(row.statusStr)">{{row.statusStr}}</span>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="quantidade">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Quantidade </th>
                    <td mat-cell *matCellDef="let row"> {{row.quantidade}} </td>
                  </ng-container>

                  <ng-container matColumnDef="percentual">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Percentual </th>
                    <td mat-cell *matCellDef="let row"> {{row.percentual | percentValue}} </td>
                  </ng-container>

                  <ng-container matColumnDef="valorMedio">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Ticket Médio </th>
                    <td mat-cell *matCellDef="let row"> {{row.valorMedio | CustomCurrency:'BRL'}} </td>
                  </ng-container>

                  <ng-container matColumnDef="valorTotal">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Total </th>
                    <td mat-cell *matCellDef="let row"><span [class.green]="row.codRetorno == '00'">{{row.valorTotal |
                        CustomCurrency:'BRL'}} </span></td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                  </tr>
                </table>
              </div>

            </div>

            <div [ngClass]="{'hide' : transactions.data && transactions.data.length}" class="no-data-message">
              <span>Nenhuma transação encontrada.</span>
            </div>


          </div>
        </div>
      </div>
    </card-box>
  </div>


</div>

<div class="container inner-container" *ngIf="false">
  <report-filter [filters]="filters" (filterClick)="filter()" (downloadClick)="downloadCsv()"
    [showExportBtn]="showExportBtn"></report-filter>
  <div fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between none" fxLayout="column" class="report-data-card"
    *ngIf="datasCard">
    <div fxFlex>
      <data-card *ngFor="let index of [0,1,2]" [ngStyle.gt-sm]="{'margin-right': ( index <= 2 ? '10px': '0px' )  }"
        [data]="datasCard[index]" fxFlex="33"></data-card>
    </div>

    <div fxFlex>
      <data-card *ngFor="let index of [3,4,5]" [data]="datasCard[index]"
        [ngStyle.gt-sm]="{'margin-right': ( index < 5 ? '10px': '0px' )  }" fxFlex="33"></data-card>
    </div>
  </div>


  <div class="shadow-padrao" [ngClass]="{'hide' : !transactions.data || !transactions.data.length}">
    <table mat-table [dataSource]="transactions" matSort style="width: 100%;">

      <ng-container matColumnDef="nomeAdquirente">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Adquirente </th>
        <td mat-cell *matCellDef="let row"> <img src="assets/img/acquirers/{{row.nomeAdquirente?.toLowerCase()}}.svg" />
        </td>
      </ng-container>

      <ng-container matColumnDef="codRetorno">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Código </th>
        <td mat-cell *matCellDef="let row"> {{row.codRetorno}}</td>
      </ng-container>

      <ng-container matColumnDef="msgRetorno">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Descrição </th>
        <td mat-cell *matCellDef="let row"> {{row.msgRetorno}} </td>
      </ng-container>
      <ng-container matColumnDef="statusStr">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
        <td mat-cell *matCellDef="let row">
          <span class="badge" [ngClass]="getStatusColor(row.statusStr)">{{row.statusStr}}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="quantidade">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Quantidade </th>
        <td mat-cell *matCellDef="let row"> {{row.quantidade}} </td>
      </ng-container>

      <ng-container matColumnDef="percentual">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Percentual </th>
        <td mat-cell *matCellDef="let row"> {{row.percentual | percentValue}} </td>
      </ng-container>

      <ng-container matColumnDef="valorMedio">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Ticket Médio </th>
        <td mat-cell *matCellDef="let row"> {{row.valorMedio | CustomCurrency:'BRL'}} </td>
      </ng-container>

      <ng-container matColumnDef="valorTotal">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Total </th>
        <td mat-cell *matCellDef="let row"><span [class.green]="row.codRetorno == '00'">{{row.valorTotal |
            CustomCurrency:'BRL'}} </span></td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;">
      </tr>
    </table>
    <div class="export">
      <a class="export-link" *ngIf="showExportBtn" (click)="downloadCsv()">
        <span class="export-label">Exportar</span>
        <i class="fa fa-arrow-circle-down" aria-hidden="true"></i>
      </a>
    </div>

  </div>
  <div [ngClass]="{'hide' : transactions.data && transactions.data.length}" class="no-data-message">
    <span>Nenhuma transação encontrada.</span>
  </div>
</div>
