import { Component, Input, OnInit } from '@angular/core';
import { CardDash } from 'src/app/core/model/card-dash';

@Component({
  selector: 'app-card-dash',
  templateUrl: './card-dash.component.html',
  styleUrls: ['./card-dash.component.scss']
})
export class CardDashComponent implements OnInit {
  @Input() data: CardDash;

  constructor() { }

  ngOnInit() {
  }

}
