export class Endereco {
    cep: string;
    complemento: string;
    entrega: string;
    idCidade: number;
    logradouro: string;
    nomeCidade: string;
    uf:string;
    numero: string;
    principal: boolean;
    cidadeIbge:string;
	estadoIbge:string;
}