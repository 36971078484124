<mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
<section class="content-login" fxLayout='row' fxLayout.lt-md="column">
  <div fxFlex="50" class="left-half">
    <div>
      <div class="slogan">
        <h1>Simplifique.</h1>
        <h2>utilize o Bempaggo para efetuar suas transações e aumente ainda mais seus ganhos
        </h2>
      </div>
    </div>
  </div>
  <div fxFlex="50" class="right-half" *ngIf="!forgotPass">
    <div>
      <div *ngIf="!messagePass" class="space"></div>
      <div class="badge alert-blue" fxLayout='row' fxLayout.lt-md="row" (click)="removeMessage()" *ngIf="messagePass">
        <span class="text-left" fxFlex="90">
          Se o seu usuário ou endereço de e-mail existir em nosso sistema, você receberá uma senha temporária em seu
          endereço
          de e-mail em alguns minutos.
        </span>
        <span class="text-right" fxFlex="10">x</span>
      </div>
      <div class="container-login container">
        <div class="img-responsive pb-3">
          <img src="assets/img/logo.png" alt="">
        </div>
        <form name="form" (ngSubmit)="f.form.valid && login()" #f="ngForm" novalidate>
          <mat-form-field class="input-res w-100">
            <input type="user" name="user" matInput [(ngModel)]="user.username" #username="ngModel"
              placeholder="Usuário" required>
          </mat-form-field>
          <mat-form-field class="input-res w-100">
            <input type="password" name="password" matInput [(ngModel)]="user.password" #password="ngModel"
              placeholder="Senha" required>
          </mat-form-field>
          <div class="pull-right" id="forgot-password" (click)="forgotPassword()">
            <a>Esqueci minha senha</a>
          </div>
          <div id="btn-align" class="btn-confirm">
            <button mat-button class="">Login</button>
          </div>
          <div class="errors" *ngIf="error">
            <span>Usuário ou Senha incorreto</span>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div fxFlex class="right-half" *ngIf="forgotPass">
    <div *ngIf="!messagePass" class="space"></div>
    <div class="container-login">
      <div class="img-responsive">
        <img src="assets/img/logo.png" alt="">
      </div>
      <form name="form" (ngSubmit)="sendNewPass()" #f="ngForm" novalidate>
        <mat-form-field class="input-res">
          <input type="user" name="user" matInput [(ngModel)]="user.email" #username="ngModel" placeholder="Usuário"
            required>
        </mat-form-field>
        <div fxLayout='row' fxLayout.lt-md="column">
          <div fxFlex="50" class="cancel">
            <button mat-raised-button (click)="forgotPassword()">Cancelar</button>
          </div>
          <div fxFlex="50" class="new-pass">
            <button mat-raised-button>Recuperar Senha</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</section>
