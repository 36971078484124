<div class="card">

  <div fxLayout="row" fxLayoutAlign="space-between end">

    <div class="card-chip"></div>

    <div class="box-value-installment" fxLayout="column" fxLayoutGap="0px" fxLayoutAlign="end end">
      <span class="value" *ngIf="creditCard.value > 0">{{creditCard.value/100 | CustomCurrency:'BRL'}}</span>
      <span class="installment">em {{ creditCard.installments + 'x' }}</span>
    </div>
  </div>

  <div class="card-number">
    {{creditCard.card.bin.slice(0,4)}} {{creditCard.card.bin.slice(4)}}** **** {{creditCard.card.lastFour}}
  </div>

  <div fxLayout="row" fxLayoutAlign="space-between end">
    <div fxLayout="column">
      <div class="card-expiration">Válido até
        <span>{{creditCard.card.expiration.month}}/{{creditCard.card.expiration.year.slice(2)}}</span>
      </div>
      <div class="card-owner">{{creditCard.card.holder.name.toUpperCase()}}</div>
    </div>
    <div>
      <img class="card-logo" src="assets/img/brands/{{creditCard.card.brand.toLowerCase()}}.png"
        [alt]="creditCard.card.brand">
    </div>
  </div>

</div>
