export const environment = {
  production: false,
  developer: false,
  sandbox: true,
  local: false,
  apiUrl: 'https://api-sandbox.bempaggo.io',
  recaptcha: {
    siteKey: '6LcmOP0UAAAAAOSAmCwIJQbhdWWdU7mYYIHL9_s3',
  },
  checkoutUrl: 'https://pay-sandbox.bempaggo.io'
};
