import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ClipboardService } from 'ngx-clipboard';
import { SalesDetailsResponse } from 'src/app/core/model/response/sales-details-response';
import { PagePermissions } from 'src/app/core/permissions/page-permissions';
import { AlertService } from 'src/app/core/services/alert.service';


export interface DialogData {
  newPassword: string;
}

@Component({
  selector: 'app-detail',
  templateUrl: './newPassword.component.html',
  styleUrls: ['./newPassword.component.scss']
})
export class NewPasswordComponent implements OnInit {

  clientPermissions = PagePermissions.CUSTOMER_EDIT_DETAILS;
  idNew: number;
  newPassword: string;
  copiado: boolean = false;
  copyMsg: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public dialogRef: MatDialogRef<SalesDetailsResponse>,
    private translate: TranslateService,
    private alertService: AlertService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private clipboardService: ClipboardService
  ) { }

  ngOnInit() {
    this.newPassword = this.data.newPassword;
  }

  clienteClick(idCliente: number) {
    if (idCliente != null && idCliente > 0) {
      this.dialogRef.close();
      this.router.navigate(["/subscribers", idCliente]);
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  getText() {
    this.clipboardService.copyFromContent(this.newPassword);
    this.copiado = true;
  }

  showMsgCopy() {
    this.copyMsg = true;
  }

}
