import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxPermissionsService } from "ngx-permissions";
import { DataShare } from "src/app/core/helper/provider/data-share";
import { User } from "src/app/core/model/user";
import { PagePermissions } from "src/app/core/permissions/page-permissions";
import { UserService } from "src/app/core/services/v0/user.service";
import { NewPasswordComponent } from "./newPassword/newPassword.component";
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss']
})
export class UserDetailsComponent implements OnInit {
  loading: boolean = true;
  id = this.route.snapshot.paramMap.get('id');
  user: User = new User();
  editPermission = PagePermissions.USER_EDIT;
  crumbs: MenuItem[];

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private dataShare: DataShare,
    private router: Router,
    private permissionsService: NgxPermissionsService,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.initCrumbs();
    this.userService.findById(parseInt(this.id)).subscribe(result => {
      if (result) {
        this.user = result;
        this.loading = false;
      }
    });
  }

  public initCrumbs() {
    this.crumbs = [
      { label: 'Usuários', routerLink: ['/settings/users'] },
      { label: this.id }
    ];
  }

  detailTransaction(newPassword) {
    this.permissionsService.hasPermission(this.editPermission).then(hasPermission => {
      if (hasPermission) {
        const dialogRef = this.dialog.open(NewPasswordComponent, {
          width: '400px',
          data: { newPassword: newPassword }
        });
        dialogRef.afterClosed().subscribe(() => {
        });
      }
    });
  }
  
  editUser() {
    this.dataShare.data = this.user;
    this.router.navigate(['/settings/users/edit/', this.user.id]);
  }
}
