<div fxLayout="row" fxLayout.lt-md='column'>
  <div fxFlex>
    <mat-form-field>
      <mat-select placeholder="Selecione o Motivo do Cancelamento" [(ngModel)]="motivo">
        <mat-option *ngFor="let motivo of motivos"  [value]="motivo.id">{{motivo.reason}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>

<div fxLayout="row" fxLayout.lt-md='column'>
  <div fxFlex="50">
    <button mat-raised-button class="btn-go-back" (click)="onNoClick()">Voltar</button>
  </div>
  <div fxFlex="50" class="text-right">
    <button mat-raised-button class="btn-cancel" (click)="cancelaAssinatura()">Efetuar cancelamento</button>
  </div>
</div>