import { CurrencyPipe, HashLocationStrategy, LocationStrategy, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import localePt from '@angular/common/locales/pt';
import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { ClipboardModule } from 'ngx-clipboard';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxMaskModule } from 'ngx-mask';
import { MomentModule } from 'ngx-moment';
import { NgxPermissionsModule } from 'ngx-permissions';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { InputMaskModule } from 'primeng/inputmask';
import { InputTextModule } from 'primeng/inputtext';
import { SelectButtonModule } from 'primeng/selectbutton';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './base/footer/footer.component';
import { HeaderComponent } from './base/header/header.component';
import { ShowEnvironmentComponent } from './base/show-environment/show-environment.component';
import { AdministradoraSelectComponent } from './components/administradora-select/administradora-select.component';
import { CancelChargeComponent } from './components/cancel-charge/cancel-charge.component';
import { CaptureChargeComponent } from './components/capture-charge/capture-charge.component';
import { CardBoxComponent } from './components/card-box/card-box.component';
import { CardDashComponent } from './components/card-dash/card-dash.component';
import { ClientFormComponent } from './components/client-form/client-form.component';
import { CopyToClipboardComponent } from './components/copy-to-clipboard/copy-to-clipboard.component';
import { CreditCardComponent } from './components/credit-card/credit-card.component';
import { DataCardComponent } from './components/data-card.component/data-card.component';
import { MiniDataCardComponent } from './components/data-card.component/mini-data-card/mini-data-card.component';
import { EnvironmentLabelComponent } from './components/environment-label/environment-label.component';
import { ImageUploadComponent } from './components/image-upload/image-upload.component';
import { PaymentFormComponent } from './components/payment-form/payment-form.component';
import { ProductCardComponent } from './components/product-card/product-card.component';
import { ReportApprovedTransactionsFilterComponent } from './components/report-approved-transactions-filter/report-approved-transactions-filter.component';
import { ReportFilterComponent } from './components/report-filter/report-filter.component';
import { SaleCancelComponent } from './components/sale-cancel/sale-cancel.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { TwoCardsLabelComponent } from './components/two-cards-label/two-cards-label.component';
import { AuthGuard } from './core/auth/auth.guard';
import { AuthService } from './core/auth/auth.service';
import { CustomerImportStatusHelper } from './core/helper/customer-import-status.helper';
import { DataFinder } from './core/helper/data-finder';
import { CardNumberValidator } from './core/helper/directive/card-number-validator.directive';
import { ClickStopPropagation } from './core/helper/directive/click-stop-propagation.directive';
import { CpfValidator } from './core/helper/directive/cpf-validator.directive';
import { PhoneMaskDirective } from './core/helper/directive/phone-mask.directive';
import { SkuUnique } from './core/helper/directive/sku-unique.directive';
import { JwtInterceptor } from './core/helper/jwt.interceptor';
import { OrderStatusHelper } from './core/helper/order-status.helper';
import { CardHidePipe } from './core/helper/pipe/card-hide.pipe';
import { CustomCurrencyPipe } from './core/helper/pipe/custom-currency.pipe';
import { DdsPipe } from './core/helper/pipe/dds.pipe';
import { PercentPipe } from './core/helper/pipe/percent.pipe';
import { DataShare } from './core/helper/provider/data-share';
import { StatusHelper } from './core/helper/status-helper';
import { StringUtils } from './core/helper/string-utils';
import { ValidationHelper } from './core/helper/validation.helper';
import { Data } from './core/model/data';
import { AngularMaterialModule } from './core/modules/angular-material.module';
import { AlertService } from './core/services/alert.service';
import { EnderecoService } from './core/services/endereco.service';
import { ErrorHandleService } from './core/services/error-handle.service';
import { ErrorsHandler } from './core/services/errors-handler.service';
import { FileUtilsService } from './core/services/file-utils.service';
import { CustomerImportService } from './core/services/v0/customer-import.service';
import { OrderService } from './core/services/v0/order.service';
import { PaymentGatewayService } from './core/services/v0/payment-gateway.service';
import { PerfilService } from './core/services/v0/perfil.services';
import { TransacaoService } from './core/services/v0/transaction.service';
import { UserService } from './core/services/v0/user.service';
import { AffiliateServiceV1 } from './core/services/v1/affiliate.service.v1';
import { AuthServiceV1 } from './core/services/v1/auth.service.v1';
import { CardServiceV1 } from './core/services/v1/card.service.v1';
import { OnCustomer } from './core/services/v1/customer.service.v1';
import { ImageUploadService } from './core/services/v1/image-upload.service';
import { OrderServiceV1 } from './core/services/v1/order.service.v1';
import { PlanServiceV1 } from './core/services/v1/plan.service.v1';
import { ProductServiceV1 } from './core/services/v1/product.service.v1';
import { SubscriptionServiceV1 } from './core/services/v1/subscription.service.v1';
import { BillingRulePaymentPage } from './pages/billing-rule-payment-page/billing-rule-payment-page.component';
import { BillingRuleTransaction } from './pages/billing-rule-payment-page/billing-rule-transaction/billing-rule-transaction.component';
import { ClientDetailsComponent } from './pages/clients/client-details/client-details.component';
import { ModalCancelamentoComponent } from './pages/clients/client-details/modal-cancelamento/modal-cancelamento.component';
import { ModalIntallmentsComponent } from './pages/clients/client-details/modal-intallments/modal.intallments.component';
import { ClientDetailsSignatureComponent } from './pages/clients/client-details/signature/signature.component';
import { ClientDetailsSummaryComponent } from './pages/clients/client-details/summary/summary.component';
import { ClientsComponent } from './pages/clients/clients.component';
import { DisputedListComponent } from './pages/disputed/disputed-list/disputed-list.component';
import { DisputedComponent } from './pages/disputed/disputed.component';
import { EstablishmentCreateComponent } from './pages/establishment/establishment-create/establishment-create.component';
import { EstablishmentDetailsComponent } from './pages/establishment/establishment-details/establishment-details.component';
import { EstablishmentEditComponent } from './pages/establishment/establishment-edit/establishment-edit.component';
import { EstablishmentComponent } from './pages/establishment/establishment.component';
import { HomeComponent } from './pages/home/home.component';
import { BatchTransactionsComponent } from './pages/import/batch-transactions/batch-transactions.component';
import { CustomerImportWizardComponent } from './pages/import/customer-import-wizard/customer-import-wizard.component';
import { ImportWalletComponent } from './pages/import/import-wallet.component';
import { NewImportWalletComponent } from './pages/import/new-import/new-import-wallet.component';
import { LoginComponent } from './pages/login/login.component';
import { LogoutComponent } from './pages/logout/logout.component';
import { EmptyAccountsComponent } from './pages/my-account/empty-accounts-component/empty-accounts-component.component';
import { FutureTransfersTableComponent } from './pages/my-account/future-transfers-table/future-transfers-table.component';
import { MyAccountComponent } from './pages/my-account/my-account.component';
import { ReceivablesTableComponent } from './pages/my-account/receivables-table/receivables-table.component';
import { TransferDetailsComponent } from './pages/my-account/transfer-details/transfer-details.component';
import { TransfersTableComponent } from './pages/my-account/transfers-table/transfers-table.component';
import { NotAllowedComponent } from './pages/not-allowed/not-allowed.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { NewPagepayComponent } from './pages/pagepay/new-pagepay/new-pagepay.component';
import { PagePayDetailsV1Component } from './pages/pagepay/pagepay-details/pagepay-details-v1.component';
import { PagePayEditComponent } from './pages/pagepay/pagepay-edit/pagepay-edit.component';
import { PagepayListComponent } from './pages/pagepay/pagepay-list/pagepay-list.component';
import { PagePayViewComponent } from './pages/pagepay/pagepay-view/pagepay-view.component';
import { SharedLinkFbComponent } from './pages/pagepay/shared/shared-link-fb/shared-link-fb.component';
import { NewPlanComponent } from './pages/plans/new-plan/new-plan.component';
import { PlanEditComponent } from './pages/plans/plan-edit/plan-edit.component';
import { PlansListComponent } from './pages/plans/plan-list.component';
import { PlanViewComponent } from './pages/plans/plan-view/plan-view.component';
import { PlanDetailsV1Component } from './pages/plans/plans-details/plan-details-v1.component';
import { SubscribersListComponent } from './pages/plans/subscribers-list/subscribers-list.component';
import { CancelationReasonOrderReportComponent } from './pages/reports/cancelation-reason/cancelation-reason-order-report.component';
import { ChargesComponent } from './pages/reports/charges/charges.component';
import { DeniedComponent } from './pages/reports/denied/denied.component';
import { ReportsComponent } from './pages/reports/reports.component';
import { SubscriptionReportComponent } from './pages/reports/subscription/subscription-report.component';
import { AcquirerMessageTransactionReportComponent } from './pages/reports/transaction/acquirer-message/acquirer-message-transaction-report';
import { ApprovedTransactionReportComponent } from './pages/reports/transaction/approved/approved-transaction-report.component';
import { LiquidoReportComponent } from './pages/reports/transaction/liquido/liquido-report.component';
import { RefundTransactionReportComponent } from './pages/reports/transaction/refund/refund-transaction-report.component';
import { TransactionsReportComponent } from './pages/reports/transaction/transactions-report/transactions-report.component';
import { TransactionDetailFromSalesDetailComponent } from './pages/sales-details/details/transactionDetailFromSalesDetail.component';
import { EventListComponent } from './pages/sales-details/event-list/event-list.component';
import { MetadataListComponent } from './pages/sales-details/metadata-list/metadata-list.component';
import { SalesDetailsComponent } from './pages/sales-details/sales-details.component';
import { SplitListComponent } from './pages/sales-details/split-list/split-list.component';
import { TransactionHistoryComponent } from './pages/sales-details/transaction-history/transaction-history.component';
import { BrandsListComponent } from './pages/settings/payment-routes/brands-list/brands-list.component';
import { PaymentRoutesHistoryComponent } from './pages/settings/payment-routes/payment-routes-history/payment-routes-history.component';
import { PaymentRoutesComponent } from './pages/settings/payment-routes/payment-routes.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { TokenComponent } from './pages/token/token.component';
import { NewTransactionComponent } from './pages/transactions/new-transaction/new-transaction.component';
import { PaymentPageComponent } from './pages/transactions/new-transaction/pagepay-modal/paymentPage.component';
import { PaymentPage } from './pages/transactions/new-transaction/payment-page/payment-page.component';
import { TransactionDetailComponent } from './pages/transactions/transaction-detail/transaction-detail.component';
import { TransactionsComponent } from './pages/transactions/transactions.component';
import { UserDetailsEditComponent } from './pages/user/details/edit/user-details-edit.component';
import { NewPasswordComponent } from './pages/user/details/newPassword/newPassword.component';
import { UserDetailsComponent } from './pages/user/details/user-details.component';
import { UserQueryComponent } from './pages/user/query/user-query.component';
import { UserComponent } from './pages/user/user.component';

registerLocaleData(localePt, 'pt-BR');

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

declare global {
  interface Navigator {
    msSaveBlob?: (blob: any, defaultName?: string) => boolean
  }
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    MyAccountComponent,
    HeaderComponent,
    FooterComponent,
    SidenavComponent,
    DataCardComponent,
    MiniDataCardComponent,
    CardBoxComponent,
    TransactionsComponent,
    UserComponent,
    ClientsComponent,
    ClientDetailsComponent,
    PercentPipe,
    DdsPipe,
    CardHidePipe,
    TransactionDetailComponent,
    ReportFilterComponent,
    ReportApprovedTransactionsFilterComponent,
    ClientFormComponent,
    NotFoundComponent,
    PaymentFormComponent,
    AdministradoraSelectComponent,
    UserQueryComponent,
    UserDetailsComponent,
    UserDetailsEditComponent,
    CpfValidator,
    SkuUnique,
    CardNumberValidator,
    PhoneMaskDirective,
    ClickStopPropagation,
    LiquidoReportComponent,
    NotAllowedComponent,
    ClientDetailsSummaryComponent,
    ClientDetailsSignatureComponent,
    ModalIntallmentsComponent,
    ClientDetailsComponent,
    TokenComponent,
    NewPlanComponent,
    PlanDetailsV1Component,
    PlanViewComponent,
    PlanEditComponent,
    PlansListComponent,
    NewPagepayComponent,
    PagePayDetailsV1Component,
    PagePayViewComponent,
    PagePayEditComponent,
    ImageUploadComponent,
    PagepayListComponent,
    SharedLinkFbComponent,
    SubscribersListComponent,
    SalesDetailsComponent,
    DisputedListComponent,
    DisputedComponent,
    ImportWalletComponent,
    NewImportWalletComponent,
    BatchTransactionsComponent,
    LogoutComponent,
    CardDashComponent,
    SaleCancelComponent,
    ReportsComponent,
    CancelationReasonOrderReportComponent,
    DeniedComponent,
    AcquirerMessageTransactionReportComponent,
    ApprovedTransactionReportComponent,
    ChargesComponent,
    CustomCurrencyPipe,
    CustomerImportWizardComponent,
    NewTransactionComponent,
    RefundTransactionReportComponent,
    SubscriptionReportComponent,
    TransactionsReportComponent,
    TransactionDetailFromSalesDetailComponent,
    PaymentPage,
    BillingRulePaymentPage,
    NewPasswordComponent,
    BillingRuleTransaction,
    PaymentPageComponent,
    ModalCancelamentoComponent,
    TransferDetailsComponent,
    PaymentRoutesComponent,
    BrandsListComponent,
    PaymentRoutesHistoryComponent,
    SettingsComponent,
    ProductCardComponent,
    ShowEnvironmentComponent,
    EstablishmentComponent,
    EstablishmentDetailsComponent,
    EstablishmentCreateComponent,
    EstablishmentEditComponent,
    CreditCardComponent,
    TwoCardsLabelComponent,
    CopyToClipboardComponent,
    CaptureChargeComponent,
    CancelChargeComponent,
    EventListComponent,
    TransactionHistoryComponent,
    SplitListComponent,
    MetadataListComponent,
    EmptyAccountsComponent,
    ReceivablesTableComponent,
    TransfersTableComponent,
    FutureTransfersTableComponent,
    EnvironmentLabelComponent
  ],
  imports: [
    RecaptchaModule,
    RecaptchaFormsModule,
    CurrencyMaskModule,
    FlexLayoutModule,
    MomentModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    NoopAnimationsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AngularMaterialModule,
    ClipboardModule,
    CalendarModule,
    DropdownModule,
    InputTextModule,
    BreadcrumbModule,
    NgxDropzoneModule,
    ImageCropperModule,
    InputMaskModule,
    SelectButtonModule,
    CheckboxModule,
    NgxPermissionsModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgxMaskModule.forRoot()
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.recaptcha.siteKey,
      } as RecaptchaSettings,
    },
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    {
      provide: LOCALE_ID,
      useValue: "pt-BR"
    },
    CurrencyPipe,
    CustomCurrencyPipe,
    Data,
    Title,
    AuthService,
    AuthGuard,
    DataFinder,
    TransacaoService,
    UserService,
    AlertService,
    PerfilService,
    EnderecoService,
    ValidationHelper,
    DataShare,
    StringUtils,
    StatusHelper,
    ErrorsHandler,
    OrderService,
    ErrorHandleService,
    CustomerImportService,
    FileUtilsService,
    SubscriptionServiceV1,
    PaymentGatewayService,
    ProductServiceV1,
    PlanServiceV1,
    CustomerImportStatusHelper,
    OrderStatusHelper,
    AffiliateServiceV1,
    ImageUploadService,
    AuthServiceV1,
    CardServiceV1,
    OnCustomer,
    OrderServiceV1,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
