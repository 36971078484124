import { Directive, Input } from "@angular/core";
import { ValidatorFn, AbstractControl, NG_ASYNC_VALIDATORS, ValidationErrors, AsyncValidator } from "@angular/forms";
import { PlanServiceV1 } from "../../services/v1/plan.service.v1";
import { Observable } from "rxjs";
import { AlertService } from "../../services/alert.service";
import { map } from "rxjs/operators";
import { PlanResponse } from "../../model/response/plan.response";

@Directive({
  selector: '[skuUnique]',
  providers: [
    { provide: NG_ASYNC_VALIDATORS, useExisting: SkuUnique, multi: true }
  ]
})
export class SkuUnique implements AsyncValidator {
  validator: ValidatorFn;
  @Input('plan') plan: PlanResponse;

  constructor(private planService: PlanServiceV1) {
  }

  validate(c: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
    return this.planService.getBySku(c.value).pipe(
      map(plans => {
        plans = this.plan ? plans.filter(plan => plan.id != this.plan.id) : plans;
        return plans.length > 0 ? { 'skuUnique': true } : null;
      })
    );
  }
}