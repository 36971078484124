<mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>

<div class="container" *ngIf="!loading">

  <div *ngxPermissionsOnly="tokenAdmin">

    <div fxLayout="column" fxLayoutGap="10px" style="margin-bottom: 10px;">
      <div fxLayout="row">

        <div fxFlex fxLayout="row" fxLayoutAlign="start center">
          <p-breadcrumb id="breadcrumb" [model]="crumbs"></p-breadcrumb>
        </div>

        <div fxFlex fxLayout="row" fxLayoutAlign="end center">
          <button type="button" class="btn btn-primary" name="gerarToken" (click)="generateToken()">Gerar
            Token</button>
        </div>

      </div>
    </div>

    <!-- Exibir novo token -->
    <div class="card" fxLayout="column" fxLayoutAlign="space-between stretch" fxLayoutGap="10px">

      <!-- Caso não exista token -->
      <div class="card-body bg-light  div-table" *ngIf="!hasToken">
        <span>Não existe <b>Token</b> para sua conta! Clique em "<b>Gerar Token</b>" para gerar.</span>
      </div>

      <div fxFlex="80" fxLayout="row" fxLayoutAlign="start center" *ngIf="showToken">
        <mat-form-field>
          <textarea matInput [(ngModel)]="generatedToken" placeholder="Token" id="token" readonly></textarea>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="end center" *ngIf="showToken">
        <mat-error *ngIf="copyMsg">Token copiado</mat-error>
        <button class="btn btn-search" type="button" ngxClipboard [cbContent]="generatedToken" (click)="showMsgCopy()">Copiar</button>
      </div>

      <!-- Caso já exista token -->
      <div class="card-body bg-light  div-table" *ngIf="hasToken && !showToken">
        <span>Você já possui um <b>Token</b> previamente gerado! Ao clicar em "<b>Gerar Token</b>" um novo será gerado. <i>Lembre-se de informar seu novo token em suas integrações existentes.</i></span>
      </div>

    </div>

  </div>

</div>