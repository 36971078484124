<form fxLayout="column" name="paymentForm" #paymentForm="ngForm" fxLayoutGap="15px;">
    <div fxLayout='row' fxLayout.lt-md="column" fxLayoutGap="15px">
        <div fxFlex="30">
            <mat-form-field>
                <mat-select placeholder="Bandeira" [(ngModel)]="card.cardBrand" required [disabled]="options.loading"
                    name="cardBrands">
                    <mat-option *ngFor=" let card of cardBrands " [value]="card.name" id="cardBrands">{{card.name}}
                    </mat-option>
                </mat-select>
                <mat-error>Bandeira é obrigatória</mat-error>
            </mat-form-field>
        </div>
        <!-- validar length do cartao -->
        <mat-form-field fxFlex>
            <input matInput placeholder="Número do cartão" mask="0000 0000 0000 0000" [(ngModel)]="card.number" required
                [disabled]="options.loading" name="cartao">
            <mat-error>Formato de cartão inválido</mat-error>
        </mat-form-field>
    </div>
    <div fxLayout='row' class="form-flex" fxLayout.lt-md="column" fxLayoutGap="15px">
        <mat-form-field fxFlex="30">
            <mat-select placeholder="MM" [(ngModel)]="card.expirationMonth" required [disabled]="options.loading"
                name="mes">
                <mat-option *ngFor=" let month of months " [value]="month" id="month_{{month}}">{{month}}</mat-option>
            </mat-select>
            <mat-error>Mês é obrigatório</mat-error>
        </mat-form-field>
        <mat-form-field fxFlex="30">
            <mat-select placeholder="AAAA" [(ngModel)]="card.expirationYear" required [disabled]="options.loading"
                name="ano">
                <mat-option *ngFor=" let year of years " [value]="year" id="year_{{year}}">{{year}}</mat-option>
            </mat-select>
            <mat-error>Ano é obrigatório</mat-error>
        </mat-form-field>
        <mat-form-field fxFlex>
            <input matInput placeholder="Código de Segurança" mask="000|0000" [(ngModel)]="card.securityCode" required
                [disabled]="options.loading" name="cvv">
            <mat-error>CVV é obrigatório</mat-error>
        </mat-form-field>
    </div>
    <div fxLayout='row' fxLayout.lt-md="column" fxLayoutGap="15px">
        <mat-form-field fxFlex="62">
            <!-- maior que 4 -->
            <input matInput placeholder="Nome Titular" [(ngModel)]="card.holderName" required
                [disabled]="options.loading" name="nomeTitular">
            <mat-error>Titular do cartão é obrigatório</mat-error>
        </mat-form-field>
        <mat-form-field fxFlex>
            <input matInput placeholder="CPF/CNPJ Titular" mask="00.000.000/0000-00||000.000.000-00" [(ngModel)]="card.holderDocument" required
                [disabled]="options.loading" name="cpfCnpjTitular" #cpf="ngModel">
            <mat-error *ngIf="cpf.errors && cpf.errors.msg">{{cpf.errors.msg}}</mat-error>
        </mat-form-field>
    </div>
    <div *ngIf="options.hasSubmit" fxLayout="row" fxLayoutAlign="end">
        <button mat-flat-button (click)="onSubmit()" [disabled]="options.loading"
            class="btnSubmit">{{options.submitBtnTxt}}</button>
    </div>
</form>