export enum CardBrand {
  VISA = 1,
  MASTERCARD = 2,
  AMEX = 4,
  ELO = 3,
  AURA = 6,
  JCB = 8,
  DINERS = 5,
  DISCOVER = 7,
  HIPERCARD = 9,
  SOROCRED = 10,
  CABAL = 11,
  CREDSYSTEM = 12,
  BANESCARD = 13,
  CREDZ = 14
}
