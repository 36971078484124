import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { AddressRequest, CustomerResponse } from "../../model/v1.model";

export class OnAddress {

    constructor(private http: HttpClient) { }

    public postAddress(customer: CustomerResponse, address: AddressRequest, token: string) {
        let options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            })
        };
        return this.http.post<any>(`${environment.apiUrl}/api/v1/customers/${customer.id}/addresses`, address, options);
    }
    public create(idCustomer: number, address: AddressRequest, token: string) {
        let options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            })
        };
        return this.http.post<any>(`${environment.apiUrl}/api/v1/customers/${idCustomer}/addresses`, address, options);
    }

}
